import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { Button } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { Formik, Field, FastField } from 'formik';
import * as Yup from 'yup';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

import {
  Container,
  TitlePageContainer,
  Content,
  ButtonsContainer,
} from './styles';

import Input from '@/components/Input';
import Radio from '@/components/Radio';
import InputMask from '@/components/InputMask';

import api from '@/services/api';
import useBeforeUnload from '@/hooks/useBeforeUnload';
import useMergeRefs from '@/hooks/useMergeRefs';
import { validateCpf } from '@/utils/validate';
import { UserGenderEnum } from '@/models/User';

function EditPersonalData({ admin, setAdmin, parentRouteMatch }) {
  const history = useHistory();
  const params = useParams();
  const mergeRefs = useMergeRefs();

  const formikRef = useRef(null);

  const [haveChanges, setHaveChanges] = useState(false);
  const [loadingsOfAsyncValidations, setLoadingsOfAsyncValidations] = useState({
    docNumber: false,
  });
  const [focusedField, setFocusedField] = useState(null);

  useBeforeUnload({
    when: haveChanges,
    message: 'Deseja realmente sair? As alterações serão perdidas',
  });

  const unblockPage = useMemo(() => {
    const messageComponents = {
      title: 'Deseja realmente cancelar a atualização dos dados?',
      content: 'Todos as alterações serão perdidas',
      cancelText: 'Voltar',
      okText: 'Cancelar',
    };

    if (haveChanges) {
      return history.block(JSON.stringify(messageComponents));
    }
    return () => {};
  }, [haveChanges, history]);

  useEffect(() => {
    return () => {
      unblockPage();
    };
  }, [unblockPage]);

  const checkIfHaveChanges = useCallback(
    (currentValues) => {
      if (_.isEqual(admin?.personalData, currentValues)) {
        if (haveChanges) {
          setHaveChanges(false);
        }
        return;
      }

      if (!haveChanges) {
        setHaveChanges(true);
      }
    },
    [admin.personalData, haveChanges]
  );

  async function checkIfFieldIsAvailable(
    value,
    fieldName,
    resolve,
    reject,
    fieldTouched,
    setFieldTouched
  ) {
    const query = {
      docNumber: fieldName === 'docNumber' ? value : undefined,
    };

    try {
      setLoadingsOfAsyncValidations({
        docNumber: !!(fieldName === 'docNumber'),
      });
      const response = await api.get('/api/account/checker', {
        params: query,
      });

      resolve(!response.data.length);
      if (!fieldTouched) setFieldTouched();
      setLoadingsOfAsyncValidations({
        docNumber: false,
      });
    } catch (error) {
      setLoadingsOfAsyncValidations({
        docNumber: false,
      });
      reject(false);
      toast.error(
        'Ocorreu um erro inesperado. Atualize a página e tente novamente!'
      );
    }
  }

  const docNumberValidationDebounced = useMemo(() => {
    return _.debounce(checkIfFieldIsAvailable, 500);
  }, []);

  const handleSubmit = useCallback(
    async (values, actions) => {
      const { id } = params;

      if (!id) {
        return toast.warn(
          'Ocorreu um problema ao atualizar os dados desse administrador, atualize a página e tente novamente!'
        );
      }

      const body = {
        name: values?.name,
        gender: values?.gender,
        docNumber:
          values?.docNumber &&
          values.docNumber.replace(/\./g, '').replace(/-/g, ''),
        phones: [
          values?.phone &&
            values.phone
              .replace(/\(/g, '')
              .replace(/\)/g, '')
              .replace(/-/g, ''),
        ],
      };

      try {
        await api.put(`/api/admin/${id}`, body);

        setAdmin({
          ...admin,
          personalData: { ...body, phones: undefined, phone: body.phones[0] },
        });

        toast.success('Dados atualizados com sucesso!');
        unblockPage();
        actions.setSubmitting(false);
        history.push(parentRouteMatch.url);
      } catch (error) {
        console.log(error);

        toast.error(
          'Aconteceu um erro inesperado ao atualizar os dados do administrador!'
        );
      }
      return null;
    },
    [admin, history, params, parentRouteMatch.url, setAdmin, unblockPage]
  );

  const eneableSubmitButton = useCallback(
    ({ touched, errors, values }) => {
      if (
        _.isEqual(admin?.personalData, {
          ...values,
          docNumber: values.docNumber.replace(/\./g, '').replace(/-/g, ''),
          phone: values.phone
            .replace(/\(/g, '')
            .replace(/\)/g, '')
            .replace(/-/g, ''),
        }) ||
        Object.entries(touched).length === 0 ||
        Object.entries(errors).length > 0
      ) {
        return false;
      }

      return true;
    },
    [admin.personalData]
  );

  const handleFormikRef = (node) => {
    if (node !== null) {
      checkIfHaveChanges(node.values);
    }
  };

  return (
    <Container>
      <TitlePageContainer>
        <h5>Editar</h5>
        <Button onClick={() => history.goBack()} danger type="text">
          Cancelar
        </Button>
      </TitlePageContainer>
      <Content>
        <h6>Dados pessoais</h6>
        <Formik
          innerRef={mergeRefs(formikRef, handleFormikRef)}
          initialValues={{
            name: admin?.personalData?.name || '',
            gender: admin?.personalData?.gender || '',
            docNumber: admin?.personalData?.docNumber || '',
            phone: admin?.personalData?.phone || '',
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('O nome é obrigatório'),
            gender: Yup.string()
              .required('O sexo é obrigatório')
              .oneOf(['M', 'F', 'O'], 'O sexo selecionado é inválido'),
            docNumber: Yup.string()
              .required('O CPF é obrigatório')
              .transform((value) => value.replace(/\./g, '').replace(/-/g, ''))
              .test('cpf', 'O CPF informado é inválido', (value) => {
                const cpfValid = validateCpf(value);

                if (cpfValid === 'valid') {
                  return true;
                }
                return false;
              })
              .test(
                'docNumberUnavailable',
                'Esse CPF já está sendo utilizado',
                function validate(value) {
                  docNumberValidationDebounced.cancel();
                  if (focusedField === 'docNumber') {
                    if (
                      !value ||
                      validateCpf(value) !== 'valid' ||
                      value === admin?.personalData?.docNumber
                    ) {
                      return true;
                    }

                    return new Promise((resolve, reject) =>
                      docNumberValidationDebounced(
                        value,
                        'docNumber',
                        resolve,
                        reject,
                        formikRef?.current?.touched?.docNumber,
                        () =>
                          formikRef.current.setFieldTouched('docNumber', true)
                      )
                    );
                  }
                  return true;
                }
              ),
            phone: Yup.string()
              .required('O telefone é obrigatório')
              .transform((value) =>
                value.replace(/\(/g, '').replace(/\)/g, '').replace(/-/g, '')
              )
              .matches(/[0-9]{11}/, {
                excludeEmptyString: true,
                message: 'O telefone informado é inválido',
              }),
          })}
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <form onSubmit={formikProps.handleSubmit}>
              <FastField name="name">
                {({ field, meta }) => {
                  return (
                    <Input
                      {...field}
                      label="Nome"
                      placeholder="Digite o nome da pessoa"
                      identifier="name"
                      type="text"
                      error={meta?.touched && meta?.error && meta?.error}
                      onFocus={() => setFocusedField('name')}
                    />
                  );
                }}
              </FastField>
              <FastField name="gender">
                {({ field, meta }) => {
                  return (
                    <Radio
                      {...field}
                      items={[
                        { value: UserGenderEnum.MALE, label: 'Masculino' },
                        { value: UserGenderEnum.FEMALE, label: 'Feminino' },
                        { value: UserGenderEnum.OTHER, label: 'Outros' },
                      ]}
                      label="Sexo"
                      error={meta?.touched && meta?.error && meta?.error}
                    />
                  );
                }}
              </FastField>
              <Field name="docNumber">
                {({ field, meta }) => {
                  return (
                    <InputMask
                      {...field}
                      mask="999.999.999-99"
                      label="CPF"
                      identifier="docNumber"
                      placeholder="999.999.999-99"
                      type="text"
                      error={meta?.touched && meta?.error && meta?.error}
                      isLoading={!!loadingsOfAsyncValidations?.docNumber}
                      onFocus={() => setFocusedField('docNumber')}
                    />
                  );
                }}
              </Field>
              <FastField name="phone">
                {({ field, meta }) => {
                  return (
                    <InputMask
                      {...field}
                      mask="(99)99999-9999"
                      label="Telefone celular"
                      identifier="phone"
                      placeholder="(99)99999-9999"
                      type="text"
                      error={meta?.touched && meta?.error && meta?.error}
                      onFocus={() => setFocusedField('phone')}
                    />
                  );
                }}
              </FastField>
              <ButtonsContainer>
                <Button
                  htmlType="submit"
                  disabled={
                    formikProps.isSubmitting ||
                    !eneableSubmitButton(formikProps)
                  }
                  type="primary"
                >
                  {!formikProps.isSubmitting ? 'Atualizar' : 'Carregando...'}
                </Button>
              </ButtonsContainer>
            </form>
          )}
        </Formik>
      </Content>
    </Container>
  );
}

export default EditPersonalData;
