import styled from 'styled-components';
import { darken } from 'polished';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  width: 100%;

  > div:nth-child(1) {
    width: 100%;
    margin-bottom: 32px;
  }
`;

export const SessionHeader = styled.div`
  width: 100%;
  display: flex;
  max-width: 424px;
  justify-content: space-between;
  align-items: center;

  /* AntDesign Button with Icon */
  button {
    line-height: 0;
    color: ${props => props.theme.colors.primary.primary0};

    &:hover,
    &:focus,
    &:active {
      color: ${props => props.theme.colors.primary['primary-1']};
    }
  }
`;

export const SessionItems = styled.ul`
  width: 100%;
  margin-top: 16px;

  li {
    list-style: none;
    width: 100%;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0px;
    }

    small {
      color: ${props => props.theme.colors.primary.primary0};
    }
  }
`;

export const CardGameItem = styled(Link)`
  width: 100%;
  background-color: ${props => props.theme.colors.light.light0};
  height: 120px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  justify-content: space-between;
  cursor: pointer;
  transition: 0.2s all;

  &:hover {
    background-color: ${props => darken(0.02, props.theme.colors.light.light0)};
  }

  @media only screen and (min-width: 1200px) {
    width: 49%;
  }

  div {
    width: 100%;
    display: flex;
    align-items: center;

    h5 {
      /* margin-left: 24px; */
      margin-bottom: 0;
    }
  }

  p {
    color: ${props => props.theme.colors.dark['dark+1']};
    font-size: ${props => props.theme.typograph.sizes.p};
    font-weight: ${props => props.theme.typograph.weights.medium};
  }
`;
