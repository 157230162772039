import React from 'react';
import { Button } from 'antd';
import { format, parseISO } from 'date-fns';
import ptLocale from 'date-fns/locale/pt-BR';

import { ButtonsContainer } from '../styles';
import {
  SessionContainer,
  SessionItems,
  MatchSelectedContainer,
  MatchSelectedTeam,
  MatchInfoContainer,
} from './styles';

function Confirm({ formik, prevStep, onSubmit }) {
  return (
    <>
      <SessionContainer>
        <h6>Informações da cartela</h6>
        <SessionItems>
          <li>
            <small>Nome da cartela</small>
            <p>{formik.values.matchCardInformations.name}</p>
          </li>
          <li>
            <small>Preço do jogo</small>
            <p>{formik.values.matchCardInformations.price}</p>
          </li>
        </SessionItems>
      </SessionContainer>
      <SessionContainer>
        <h6>Partidas</h6>
        <div>
          {formik.values.games.map(match => (
            <MatchSelectedContainer key={match._id}>
              <div>
                <MatchSelectedTeam>
                  <div>
                    <img src={match.local.image} alt={match.local.name} />
                  </div>
                  <strong>{match.local.name}</strong>
                </MatchSelectedTeam>
                <h6>VS</h6>
                <MatchSelectedTeam>
                  <div>
                    <img src={match.away.image} alt={match.away.name} />
                  </div>
                  <strong>{match.away.name}</strong>
                </MatchSelectedTeam>
              </div>
              <MatchInfoContainer>
                <div>
                  <small>Data e hora</small>
                  <small>
                    {format(
                      parseISO(match.info.date),
                      "cccc, dd/MM/yyyy 'às' HH:mm",
                      {
                        locale: ptLocale,
                      }
                    )}
                  </small>
                </div>
                <div>
                  <small>Local</small>
                  <small>{match.info.place}</small>
                </div>
              </MatchInfoContainer>
            </MatchSelectedContainer>
          ))}
        </div>
      </SessionContainer>
      <SessionContainer>
        <h6>Banner</h6>
        {formik.values.banner?.previewUrl ? (
          <img src={formik.values.banner?.previewUrl} alt="Banner" />
        ) : (
          <p>Não adicionado</p>
        )}
      </SessionContainer>
      <ButtonsContainer>
        <Button onClick={prevStep} type="default">
          Voltar
        </Button>
        <Button
          onClick={onSubmit}
          disabled={formik.isSubmitting}
          type="primary"
        >
          {!formik.isSubmitting ? 'Finalizar' : 'Carregando...'}
        </Button>
      </ButtonsContainer>
    </>
  );
}

export default Confirm;
