import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 520px;
  text-align: center;
  margin: 16px;

  img {
    width: 230px;
  }

  form {
    display: flex;
    flex-direction: column;
    margin-top: 30px;

    button.ant-btn-primary {
      margin-top: 24px;
    }
  }
`;

export const ForgotPasswordButton = styled.button`
  background: none;
  border: none;
  font-size: 12px;
  color: ${props => props.theme.colors.dark['dark+1']};
  margin-top: 16px;

  &:hover {
    color: ${props => props.theme.colors.dark['dark+2']};
  }
`;
