import React from 'react';
import { Button } from 'antd';
import * as dateFns from 'date-fns';
import ptLocale from 'date-fns/locale/pt-BR';

import { UploadRequests } from '@/services/api/requests/Upload';
import DefaultAvatar from '@/assets/DefaultAvatar.svg';

import { formatNumberToCurrency } from '@/utils/formatters';
import { EGameType } from '../../types';

import { ButtonsContainer } from '../styles';
import {
  AthleteScore,
  OptionsTopicsContainer,
  SelectedInfluencer,
  SessionContainer,
  SessionItems,
  TeamPlayer,
} from './styles';
import { IConfirmProps } from './types';
import {
  getAthleteNumberDescription,
  getPlayerPositionLabel,
} from '../../utils';

const Confirm: React.FC<IConfirmProps> = ({
  formik,
  prevStep,
  handleSubmit,
}) => {
  const cardType = formik.values.cardInformation.type;
  const { influencers } = formik.values.cardInformation;

  function getPercentagesDescription(): string {
    const { homePercent, influencerPercent, winnerPercent } =
      formik.values.cardInformation.percentages;
    const { hasFixedPrize } = formik.values.cardInformation;

    const homePercentDescription = `Casa: ${homePercent}%`;
    const influencerPercentDescription = influencerPercent
      ? ` | Influenciador(res): ${influencerPercent}%`
      : '';
    const winnerPercentDescription = !hasFixedPrize
      ? ` | Vencedor: ${winnerPercent}%`
      : '';

    return (
      homePercentDescription +
      influencerPercentDescription +
      winnerPercentDescription
    );
  }

  return (
    <>
      <SessionContainer>
        <h6>Informações da cartela</h6>
        <SessionItems>
          <li>
            <small>Nome da cartela</small>
            <p>{formik.values.cardInformation.name}</p>
          </li>
          <li>
            <small>Preço do jogo</small>
            <p>{formatNumberToCurrency(formik.values.cardInformation.price)}</p>
          </li>
          <li>
            <small>Data e hora inicial</small>
            <p>
              {formik.values.cardInformation.startDate &&
                dateFns.format(
                  formik.values.cardInformation.startDate.toDate(),
                  "cccc, dd/MM/yyyy 'às' HH:mm",
                  {
                    locale: ptLocale,
                  }
                )}
            </p>
          </li>
          <li>
            <small>Data e hora final</small>
            <p>
              {formik.values.cardInformation.endDate &&
                dateFns.format(
                  formik.values.cardInformation.endDate.toDate(),
                  "cccc, dd/MM/yyyy 'às' HH:mm",
                  {
                    locale: ptLocale,
                  }
                )}
            </p>
          </li>
          <li>
            <small>Premiação fixa</small>
            <p>{formik.values.cardInformation.hasFixedPrize ? 'Sim' : 'Não'}</p>
          </li>
          {formik.values.cardInformation.hasFixedPrize && (
            <li>
              <small>Valor da premiação</small>
              <p>
                {formatNumberToCurrency(
                  formik.values.cardInformation.fixedPrize
                )}
              </p>
            </li>
          )}
          <li>
            <small>Porcentagens de pagamento</small>
            <p>{getPercentagesDescription()}</p>
          </li>
        </SessionItems>
      </SessionContainer>
      {!!influencers.length && (
        <SessionContainer>
          <h6>Influenciadores</h6>
          <div>
            {formik.values.cardInformation.influencers.map((influencer) => (
              <SelectedInfluencer key={influencer._id}>
                <img
                  src={
                    influencer.photo?.filename
                      ? UploadRequests.getFileUrl(influencer.photo?.filename)
                      : DefaultAvatar
                  }
                  alt={influencer.name}
                />
                <div>
                  <p>{influencer.name || '---'}</p>
                  <small>{influencer.username || '---'}</small>
                </div>
              </SelectedInfluencer>
            ))}
          </div>
        </SessionContainer>
      )}
      <SessionContainer>
        <h6>Jogadores</h6>
        <div>
          {formik.values.athletes.map((player) => (
            <TeamPlayer key={player._id}>
              <div>
                <img
                  src={
                    player?.photo
                      ? UploadRequests.getFileUrl(player.photo)
                      : player?.photoUri || DefaultAvatar
                  }
                  alt={player.name}
                  onError={(e) => {
                    const target = e.target as HTMLImageElement;
                    target.onerror = null;
                    target.src = DefaultAvatar;
                  }}
                />
              </div>
              <div>
                <strong>{player.name}</strong>
                <small>
                  {getAthleteNumberDescription(player.number)}
                  {getPlayerPositionLabel(player.position)}
                </small>
                {cardType === EGameType.CLASSIC && (
                  <AthleteScore>{player.classicValue} pontos</AthleteScore>
                )}
                <div>
                  <img src={player.team.image} alt={player.team.name} />
                  <p>{player.team.name}</p>
                </div>
              </div>
            </TeamPlayer>
          ))}
        </div>
      </SessionContainer>
      {cardType === EGameType.OPTIONS && (
        <SessionContainer>
          <h6>Opções</h6>
          <OptionsTopicsContainer>
            <div>
              <strong>Gols</strong>
              {formik.values.options.goalOptions.map((option) => (
                <small key={option._id}>
                  {option.name} -{' '}
                  <span>
                    {option.value} ponto{option.value > 1 && 's'}
                  </span>
                </small>
              ))}
            </div>
            <div>
              <strong>Estatísticas</strong>
              {formik.values.options.statisticOptions.map((option) => (
                <small key={option._id}>
                  {option.name} -{' '}
                  <span>
                    {option.value} ponto{option.value > 1 && 's'}
                  </span>
                </small>
              ))}
            </div>
          </OptionsTopicsContainer>
        </SessionContainer>
      )}
      <SessionContainer>
        <h6>Banner</h6>
        {formik.values.banner?.previewUrl ? (
          <img src={formik.values.banner?.previewUrl} alt="Banner" />
        ) : (
          <p>Não adicionado</p>
        )}
      </SessionContainer>
      <ButtonsContainer>
        <Button onClick={prevStep} type="default">
          Voltar
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={formik.isSubmitting}
          type="primary"
        >
          {!formik.isSubmitting ? 'Finalizar' : 'Carregando...'}
        </Button>
      </ButtonsContainer>
    </>
  );
};

export default Confirm;
