export interface ITeam {
  image: string;
  initials?: string;
  name: string;
  players: [];
  status: boolean;
  teamType: TeamTypeEnum;
  _id: string;
}

export enum TeamTypeEnum {
  PROFESSIONAL = 'professional',
  AMATEUR = 'amateur',
  HOUSE = 'house',
}
