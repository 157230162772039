import React from 'react';
// import {
//   format,
//   subDays,
//   addDays,
//   setHours,
//   setMinutes,
//   setSeconds,
//   isBefore,
//   isEqual,
//   parseISO,
// } from 'date-fns';
// import { utcToZonedTime } from 'date-fns-tz';
// import pt from 'date-fns/locale/pt';

// import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
// import api from '../../services/api';

import { Container } from './styles';

export default function Dashboard() {
  return (
    <Container>
      <p>Dashboard</p>
    </Container>
  );
}
