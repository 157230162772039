import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';

import { FiEdit2 } from 'react-icons/fi';
import { AdversarySelector } from './styles';
import {
  SessionContainer,
  LoadingAndNotFoundContainer,
  LoadMoreButton,
} from '../../styles';

const loadingIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;

function AdversarySelection({
  loadingTeams,
  teams,
  formikProps,
  teamsPagination,
  handleGetTeams,
}) {
  const selectedAdversaryId = formikProps?.values?.away;
  const selectedAdversary = selectedAdversaryId
    ? teams.find(team => team._id === selectedAdversaryId)
    : null;

  if (loadingTeams && teams.length === 0) {
    return (
      <SessionContainer>
        <p>Selecione um adversário</p>
        <LoadingAndNotFoundContainer>
          <div>
            <Spin style={{ lineHeight: 0 }} indicator={loadingIcon} />
            <p>Carregando times...</p>
          </div>
        </LoadingAndNotFoundContainer>
      </SessionContainer>
    );
  }

  if (!selectedAdversary) {
    return (
      <SessionContainer>
        <p>Selecione um adversário</p>
        <ul>
          {teams.map(team => (
            <AdversarySelector
              key={team._id}
              onClick={() => formikProps.setFieldValue('away', team._id)}
            >
              <img src={team.image} alt={team.name} />
              <h6>{team.name}</h6>
            </AdversarySelector>
          ))}
          {teamsPagination.currentPage < teamsPagination.totalPages && (
            <LoadMoreButton
              disabled={loadingTeams}
              onClick={() => handleGetTeams(teamsPagination.currentPage + 1)}
            >
              <p>{!loadingTeams ? 'Ver mais' : 'Carregando...'}</p>
            </LoadMoreButton>
          )}
        </ul>
      </SessionContainer>
    );
  }

  return (
    <SessionContainer>
      <strong>Adversário</strong>
      <AdversarySelector onClick={() => formikProps.setFieldValue('away', '')}>
        <img src={selectedAdversary?.image} alt={selectedAdversary?.name} />
        <h6>{selectedAdversary?.name}</h6>
        <FiEdit2 size={20} />
      </AdversarySelector>
    </SessionContainer>
  );
}

export default AdversarySelection;
