import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-width: ${(props) => props.theme.containerWidth};
  min-height: 100vh;
  padding: 3rem 2rem;

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    padding: 2rem 1rem;
    min-height: calc(100vh - 61px);
  }

  > h4 {
    margin-top: 2rem;
  }
`;

export const TitlePageContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 2rem 0;
  justify-content: space-between;
  align-items: center;

  button {
    margin-left: 1rem;

    @media only screen and (min-width: ${(props) =>
        props.theme.breakpoints.sm}) {
      display: none;
    }
  }
`;

export const TabsWrapper = styled.div`
  width: 100%;
`;
