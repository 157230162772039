import React, { useState } from 'react';

import { FastField, Field } from 'formik';
import { Autocomplete, LoadScriptNext } from '@react-google-maps/api';
import { toast } from 'react-toastify';
import { FiXCircle } from 'react-icons/fi';

import Env from '../../../config/Environment';

import Input from '../../../components/Input';
import InputMask from '../../../components/InputMask';
import CustomAntButton from '../../../components/CustomAntButton';

import { ButtonsContainer } from '../styles';

const googleMapsLibs = ['places', 'geometry'];

function Team({
  formikProps,
  nextStep,
  loadingsOfAsyncValidations,
  setFocusedField,
  loadingUserPosition,
  userPositionCoords,
}) {
  const [loadingMapScript, setLoadingMapScript] = useState(true);
  const [mapAutoComplete, setMapAutoComplete] = useState(null);

  const loadingLocation =
    loadingUserPosition || loadingMapScript || !mapAutoComplete;

  const locationAlreadySelected =
    formikProps.values.team.address.coords.lat &&
    formikProps.values.team.address.coords.lng;

  function eneableNextStepButton({ touched, errors }) {
    if (!touched.team) {
      return false;
    }

    if (!errors.team) {
      return true;
    }

    return false;
  }

  return (
    <>
      <p>Primeiro, insira os dados do seu time</p>

      <FastField name="team.name">
        {({ field, meta }) => {
          return (
            <Input
              {...field}
              label="Nome"
              placeholder="Digite o nome do time"
              id="SignUpTeamName"
              type="text"
              error={meta?.touched && meta?.error && meta?.error}
              onFocus={() => setFocusedField('team.name')}
            />
          );
        }}
      </FastField>
      <Field name="team.docNumber">
        {({ field, meta }) => {
          return (
            <InputMask
              {...field}
              mask="99.999.999/9999-99"
              label="CNPJ"
              placeholder="99.999.999/9999-99"
              id="SignUpTeamDocNumber"
              type="text"
              error={meta?.touched && meta?.error && meta?.error}
              isLoading={!!loadingsOfAsyncValidations?.docNumber}
              onFocus={() => setFocusedField('team.docNumber')}
            />
          );
        }}
      </Field>
      <Field name="team.email">
        {({ field, meta }) => {
          return (
            <Input
              {...field}
              label="Email"
              placeholder="team@email.com"
              id="SignUpEmail"
              type="email"
              error={meta?.touched && meta?.error && meta?.error}
              showloading={!!loadingsOfAsyncValidations.email}
              onFocus={() => setFocusedField('team.email')}
            />
          );
        }}
      </Field>
      <FastField name="team.phone">
        {({ field, meta }) => {
          return (
            <InputMask
              {...field}
              mask="(99)99999-9999"
              id="SignUpPhone"
              label="Telefone Celular"
              placeholder="(99)99999-9999"
              type="text"
              error={meta?.touched && meta?.error && meta?.error}
              onFocus={() => setFocusedField('team.phone')}
            />
          );
        }}
      </FastField>
      <Field name="team.address.description">
        {({ field, meta }) => {
          return (
            <LoadScriptNext
              id="google-map-script"
              googleMapsApiKey={Env.GOOGLE_MAPS_API_KEY}
              libraries={googleMapsLibs}
              language="pt-BR"
              region="pt-BR"
              onLoad={() => setLoadingMapScript(false)}
            >
              <Autocomplete
                bounds={
                  userPositionCoords.lat && userPositionCoords.lng
                    ? {
                        north: userPositionCoords.lat + 1,
                        south: userPositionCoords.lat - 1,
                        east: userPositionCoords.lng + 1,
                        west: userPositionCoords.lng - 1,
                      }
                    : undefined
                }
                onLoad={(autocompleteProps) => {
                  setMapAutoComplete(autocompleteProps);
                }}
                onPlaceChanged={() => {
                  if (mapAutoComplete) {
                    const place = mapAutoComplete.getPlace();

                    if (!place.geometry || !place.geometry.location) {
                      // User entered the name of a Place that was not suggested and
                      // pressed the Enter key, or the Place Details request failed.
                      toast.warn('A localização informada é inválida');
                      return;
                    }

                    const latitude = place.geometry.location.lat();
                    const longitude = place.geometry.location.lng();

                    formikProps.setFieldValue(
                      'team.address.coords.lat',
                      latitude
                    );
                    formikProps.setFieldValue(
                      'team.address.coords.lng',
                      longitude
                    );
                    formikProps.setFieldValue(
                      'team.address.description',
                      place.formatted_address || ''
                    );
                  }
                }}
              >
                <Input
                  {...field}
                  label="Localização"
                  placeholder={
                    !loadingLocation ? 'Pesquisar localização' : 'Carregando...'
                  }
                  id="SignUpLocation"
                  type="text"
                  disabled={loadingLocation || locationAlreadySelected}
                  error={
                    meta?.touched &&
                    formikProps?.errors.team?.address?.coords?.lat &&
                    formikProps?.errors.team?.address?.coords?.lat
                  }
                  actionButton={
                    locationAlreadySelected && (
                      <CustomAntButton
                        danger
                        icon={<FiXCircle size={20} />}
                        onClick={() => {
                          formikProps.setFieldValue(
                            'team.address.description',
                            ''
                          );
                          formikProps.setFieldValue(
                            'team.address.coords.lng',
                            ''
                          );
                          formikProps.setFieldValue(
                            'team.address.coords.lat',
                            ''
                          );
                        }}
                      />
                    )
                  }
                  onFocus={() => setFocusedField('team.address.description')}
                />
              </Autocomplete>
            </LoadScriptNext>
          );
        }}
      </Field>
      <ButtonsContainer>
        <CustomAntButton
          onClick={nextStep}
          type="primary"
          disabled={!eneableNextStepButton(formikProps)}
        >
          Próximo
        </CustomAntButton>
      </ButtonsContainer>
    </>
  );
}

export default Team;
