import styled, { css } from 'styled-components';

interface IContainerProps {
  $selected: boolean;
}

export const Container = styled.li<IContainerProps>`
  width: 100%;
  background-color: ${(props) => props.theme.colors.light.light0};
  border-radius: 4px;
  padding: 1rem;
  border: 1px solid ${(props) => props.theme.colors.light['light-2']};
  box-shadow: 0px 1px 4px #00000020;
  cursor: grab;
  ${(props) =>
    !props.$selected &&
    css`
      cursor: default;
      opacity: 0.8;
    `}

  & + & {
    margin-top: 0.5rem;
  }

  > div:first-child {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    margin-bottom: 1rem;

    strong {
      flex: 1;
      color: ${(props) => props.theme.colors.dark['dark+1']};
    }
  }

  > div:last-child {
    width: 100%;
    max-width: 12rem;
  }
`;
