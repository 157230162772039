import React, { useRef, useState, useCallback } from 'react';
import { Button } from 'antd';
import ReactCrop from 'react-image-crop';

import { ButtonsContainer } from '../styles';
import {
  SelectImageButton,
  CropImageContainer,
  ImageContainer,
  ActionButtonsContainer,
  ViewerAvatarContainer,
  ActionButtonsAvatarContainer,
} from './styles';

// Increase pixel density for crop preview quality on retina screens.
const pixelRatio = window.devicePixelRatio || 1;

// function getResizedCanvas(canvas, newWidth, newHeight) {
//   const tmpCanvas = document.createElement('canvas');
//   tmpCanvas.width = newWidth;
//   tmpCanvas.height = newHeight;

//   const ctx = tmpCanvas.getContext('2d');
//   ctx.drawImage(
//     canvas,
//     0,
//     0,
//     canvas.width,
//     canvas.height,
//     0,
//     0,
//     newWidth,
//     newHeight
//   );

//   return tmpCanvas;
// }

// function generateDownload(previewCanvas, crop) {
//   if (!crop || !previewCanvas) {
//     return;
//   }

//   const canvas = getResizedCanvas(previewCanvas, crop.width, crop.height);

//   canvas.toBlob(
//     blob => {
//       const previewUrl = window.URL.createObjectURL(blob);

//       const anchor = document.createElement('a');
//       anchor.download = 'cropPreview.png';
//       anchor.href = URL.createObjectURL(blob);
//       anchor.click();

//       window.URL.revokeObjectURL(previewUrl);
//     },
//     'image/png',
//     1
//   );
// }

function Avatar({ formik, nextStep, prevStep }) {
  const [croppedContainerOpened, setCroppedContainerOpened] = useState(false);
  // const [croppedImage, setCroppedImage] = useState(null);

  const inputFileRef = useRef(null);
  const imgRef = useRef(null);
  // const previewCanvasRef = useRef(null);

  const [upImg, setUpImg] = useState(null);
  const [cropSettings, setCropSettings] = useState({
    unit: '%',
    width: 30,
    aspect: 1 / 1,
  });
  // const [completedCrop, setCompletedCrop] = useState(null);
  const [blobImage, setBlobImage] = useState(null);

  const handleSetCroppedImage = useCallback(
    croppedImage => {
      if (!croppedImage) {
        croppedImage = {
          file: null,
          previewUrl: '',
        };
      }

      formik.setFieldValue('avatar.file', croppedImage.file);
      formik.setFieldValue('avatar.previewUrl', croppedImage.previewUrl);
    },
    [formik]
  );

  function handleSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      setCroppedContainerOpened(true);

      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function handleRemoveSelectedImage() {
    setUpImg(null);
    setCroppedContainerOpened(false);
    handleSetCroppedImage(null);
    setBlobImage(null);
    setCropSettings({
      unit: '%',
      width: 30,
      aspect: 1 / 1,
    });
  }

  // function generateFileCropped(previewCanvas, crop) {
  //   if (!crop || !previewCanvas) {
  //     return;
  //   }

  //   const canvas = getResizedCanvas(previewCanvas, crop.width, crop.height);

  //   canvas.toBlob(
  //     blob => {
  //       // const anchor = document.createElement('a');
  //       // anchor.download = 'cropPreview.png';
  //       // anchor.href = URL.createObjectURL(blob);
  //       // anchor.click();

  //       // window.URL.revokeObjectURL(previewUrl);
  //       const fileName = `${Date.now()}.png`;
  //       const file = new File([blob], fileName, { type: 'image/png' });
  //       const preview = window.URL.createObjectURL(blob);

  //       setCroppedImage({ file, preview });
  //       setCroppedContainerOpened(false);
  //     },
  //     'image/png',
  //     1
  //   );
  // }

  const generateFileCropped = useCallback(() => {
    if (!blobImage) {
      return;
    }

    const fileName = `${Date.now()}.png`;
    const file = new File([blobImage], fileName, { type: 'image/png' });
    const previewUrl = window.URL.createObjectURL(blobImage);

    handleSetCroppedImage({ file, previewUrl });
    setCroppedContainerOpened(false);
  }, [blobImage, handleSetCroppedImage]);

  const handleImageLoaded = useCallback(img => {
    imgRef.current = img;
  }, []);

  const handleCompletedCrop = useCallback(crop => {
    // if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
    //   return;
    // }
    if (!crop || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = document.createElement('canvas');
    // const canvas = previewCanvasRef.current;
    // const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    // ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    canvas.toBlob(
      blob => {
        setBlobImage(blob);
      },
      'image/png',
      1
    );
  }, []);

  // useEffect(() => {
  //   if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
  //     return;
  //   }

  //   const image = imgRef.current;
  //   const canvas = previewCanvasRef.current;
  //   const crop = completedCrop;

  //   const scaleX = image.naturalWidth / image.width;
  //   const scaleY = image.naturalHeight / image.height;
  //   const ctx = canvas.getContext('2d');

  //   canvas.width = crop.width * pixelRatio;
  //   canvas.height = crop.height * pixelRatio;

  //   ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  //   ctx.imageSmoothingQuality = 'high';

  //   ctx.drawImage(
  //     image,
  //     crop.x * scaleX,
  //     crop.y * scaleY,
  //     crop.width * scaleX,
  //     crop.height * scaleY,
  //     0,
  //     0,
  //     crop.width,
  //     crop.height
  //   );
  // }, [completedCrop]);
  function eneableNextStepButton({ values }) {
    if (values?.avatar?.file) {
      return true;
    }

    return false;
  }

  return (
    <>
      <input
        style={{ display: 'none' }}
        type="file"
        ref={inputFileRef}
        accept="image/*"
        onChange={handleSelectFile}
      />
      {/* <canvas
        ref={previewCanvasRef}
        // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
        style={{
          width: Math.round(completedCrop?.width ?? 0),
          height: Math.round(completedCrop?.height ?? 0),
          display: 'none',
        }}
      /> */}
      {croppedContainerOpened && (
        <CropImageContainer>
          <ImageContainer>
            <ReactCrop
              src={upImg}
              onImageLoaded={handleImageLoaded}
              crop={cropSettings}
              onChange={c => setCropSettings(c)}
              // onComplete={c => setCompletedCrop(c)}
              onComplete={c => handleCompletedCrop(c)}
              keepSelection
            />
          </ImageContainer>
          <ActionButtonsContainer>
            <Button type="default" onClick={handleRemoveSelectedImage} danger>
              Cancelar
            </Button>
            <Button type="default" onClick={() => inputFileRef.current.click()}>
              Trocar imagem
            </Button>
            <Button
              type="primary"
              // disabled={!completedCrop?.width || !completedCrop?.height}
              // onClick={() =>
              //   generateFileCropped(previewCanvasRef.current, completedCrop)
              // }
              onClick={generateFileCropped}
            >
              Finalizar
            </Button>
          </ActionButtonsContainer>
        </CropImageContainer>
      )}
      {!croppedContainerOpened && (
        <>
          {!formik.values.avatar.previewUrl ? (
            <>
              <SelectImageButton onClick={() => inputFileRef.current.click()}>
                <p>
                  <span>Selecione</span> uma imagem para adicionar como avatar
                  do usuário
                  <small>
                    <span>(obrigatório)</span>
                  </small>
                </p>
              </SelectImageButton>
            </>
          ) : (
            <ViewerAvatarContainer>
              <img src={formik.values.avatar.previewUrl} alt="Avatar" />
              <ActionButtonsAvatarContainer>
                <Button
                  type="primary"
                  onClick={handleRemoveSelectedImage}
                  danger
                >
                  Remover imagem
                </Button>
                <Button
                  type="default"
                  onClick={() => setCroppedContainerOpened(true)}
                >
                  Editar imagem
                </Button>
              </ActionButtonsAvatarContainer>
            </ViewerAvatarContainer>
          )}
          <ButtonsContainer>
            <Button onClick={prevStep} type="default">
              Voltar
            </Button>
            <Button
              onClick={nextStep}
              type="primary"
              disabled={!eneableNextStepButton(formik)}
            >
              Próximo
            </Button>
          </ButtonsContainer>
        </>
      )}
      {/* {croppedImage && (
        <>
          <img
            src={croppedImage.preview}
            alt="Avatar"
            style={{ width: '100%' }}
          />
          {console.log(croppedImage.file)}
        </>
      )} */}
    </>
  );
}

export default Avatar;
