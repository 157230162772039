import React, {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';

import ConfirmPassword from '@/components/ConfirmPassword';

interface ConfirmPasswordProviderProps {
  children?: React.ReactNode;
}

interface ConfirmPasswordContextData {
  requestPassword(): Promise<unknown>;
}

const ConfirmPasswordContext = createContext<ConfirmPasswordContextData>(
  {} as ConfirmPasswordContextData
);

const ConfirmPasswordProvider: React.FC<ConfirmPasswordProviderProps> = ({
  children,
}) => {
  const passwordResolver = useRef<(value: unknown) => void>(() => ({}));
  const [isConfirmPasswordOpened, setIsConfirmPasswordOpened] = useState(false);

  const handleRequestPassword = useCallback(() => {
    setIsConfirmPasswordOpened(true);

    return new Promise((resolve) => {
      passwordResolver.current = resolve;
    });
  }, []);

  const handleClose = useCallback(() => {
    setIsConfirmPasswordOpened(false);
    passwordResolver.current(undefined);
  }, []);

  const handleConfirm = useCallback((password: string) => {
    setIsConfirmPasswordOpened(false);
    passwordResolver.current(password);
  }, []);

  return (
    <ConfirmPasswordContext.Provider
      value={{
        requestPassword: handleRequestPassword,
      }}
    >
      {children}
      {isConfirmPasswordOpened && (
        <ConfirmPassword
          handleClose={handleClose}
          handleConfirm={handleConfirm}
        />
      )}
    </ConfirmPasswordContext.Provider>
  );
};

function useConfirmPassword(): ConfirmPasswordContextData {
  const context = useContext(ConfirmPasswordContext);

  if (!context) {
    throw new Error(
      'useConfirmPassword must be used within an ConfirmPasswordProvider'
    );
  }

  return context;
}

export { useConfirmPassword, ConfirmPasswordProvider };
