export enum EGameType {
  OPTIONS = 'default',
  CLASSIC = 'classic',
}

export enum EAthletePosition {
  GOALKEEPER = 'G',
  DEFENSE = 'D',
  MIDFIELD = 'M',
  ATTACK = 'A',
  RIGHT_BACK = 'LD',
  LEFT_BACK = 'LE',
  ATP = 'ATP',
  WTA = 'WTA',
  F1_START = 'F1_START',
  F1_FINISH = 'F1_FINISH',
  POKER__HAND_CHAMPION_SEQUENCE = 'POKER__HAND_CHAMPION_SEQUENCE',
  POKER__ELIMINATION_SEQUENCE = 'POKER__ELIMINATION_SEQUENCE',
  GOAL_MINUTE = 'GOAL_MINUTE',
  FIRST_GOAL_MINUTE = 'FIRST_GOAL_MINUTE',
  SECOND_GOAL_MINUTE = 'SECOND_GOAL_MINUTE',
  FIRST_GOAL_MINUTE_OF_THE_ROUND = 'FIRST_GOAL_MINUTE_OF_THE_ROUND',
  SECOND_GOAL_MINUTE_OF_THE_ROUND = 'SECOND_GOAL_MINUTE_OF_THE_ROUND',
  FIRST_HALF_GOAL_MINUTE = 'FIRST_HALF_GOAL_MINUTE',
  SECOND_HALF_GOAL_MINUTE = 'SECOND_HALF_GOAL_MINUTE',
  GOAL_INSIDE_AREA = 'GOAL_INSIDE_AREA',
  GOAL_OUTSIDE_AREA = 'GOAL_OUTSIDE_AREA',
  GOAL_INSIDE_AREA_OF_THE_ROUND = 'GOAL_INSIDE_AREA_OF_THE_ROUND',
  GOAL_OUTSIDE_AREA_OF_THE_ROUND = 'GOAL_OUTSIDE_AREA_OF_THE_ROUND',
  GOALS_IN_THE_FIRST_HALF = 'GOALS_IN_THE_FIRST_HALF',
  GOALS_IN_THE_SECOND_HALF = 'GOALS_IN_THE_SECOND_HALF',
  GOALS_IN_THE_FIRST_HALF_OF_THE_ROUND = 'GOALS_IN_THE_FIRST_HALF_OF_THE_ROUND',
  GOALS_IN_THE_SECOND_HALF_OF_THE_ROUND = 'GOALS_IN_THE_SECOND_HALF_OF_THE_ROUND',
  FOOTBALL__FIELD_GOAL_AMOUNT = 'FOOTBALL__FIELD_GOAL_AMOUNT',
  FOOTBALL__TOUCHDOWN_AMOUNT = 'FOOTBALL__TOUCHDOWN_AMOUNT',
  FOOTBALL__TWO_POINT_CONVERSION_AMOUNT = 'FOOTBALL__TWO_POINT_CONVERSION_AMOUNT',
  FOOTBALL__EXTRA_KICK_AMOUNT = 'FOOTBALL__EXTRA_KICK_AMOUNT',
  FOOTBALL__SAFETY_AMOUNT = 'FOOTBALL__SAFETY_AMOUNT',
  FOOTBALL__PICK_SIX_TOUCHDOWN_AMOUNT = 'FOOTBALL__PICK_SIX_TOUCHDOWN_AMOUNT',
}

export enum AthletePositionDescriptionEnum {
  GOALKEEPER = 'Goleiro',
  DEFENSE = 'Defesa',
  MIDFIELD = 'Meio campo',
  ATTACK = 'Ataque',
  RIGHT_BACK = 'Lateral direito',
  LEFT_BACK = 'Lateral esquerdo',
  ATP = 'ATP',
  WTA = 'WTA',
  F1_START = 'F1 - Grid de largada',
  F1_FINISH = 'F1 - Linha de chegada',
  POKER__HAND_CHAMPION_SEQUENCE = 'Poker - Hand Champion Sequence',
  POKER__ELIMINATION_SEQUENCE = 'Poker - Elimination Sequence',
  GOAL_MINUTE = 'Minuto do gol',
  FIRST_GOAL_MINUTE = 'Minuto do 1˚ gol da partida',
  SECOND_GOAL_MINUTE = 'Minuto do 2˚ gol da partida',
  FIRST_GOAL_MINUTE_OF_THE_ROUND = 'Minuto do 1˚ gol da rodada',
  SECOND_GOAL_MINUTE_OF_THE_ROUND = 'Minuto do 2˚ gol da rodada',
  FIRST_HALF_GOAL_MINUTE = 'Minuto do gol no 1˚ tempo',
  SECOND_HALF_GOAL_MINUTE = 'Minuto do gol no 2˚ tempo',
  GOAL_INSIDE_AREA = 'Gols dentro da área na partida',
  GOAL_OUTSIDE_AREA = 'Gols fora da área na partida',
  GOAL_INSIDE_AREA_OF_THE_ROUND = 'Gols dentro da área na rodada',
  GOAL_OUTSIDE_AREA_OF_THE_ROUND = 'Gols fora da área na rodada',
  GOALS_IN_THE_FIRST_HALF = 'Quantidade de gols no 1˚ tempo',
  GOALS_IN_THE_SECOND_HALF = 'Quantidade de gols no 2˚ tempo',
  GOALS_IN_THE_FIRST_HALF_OF_THE_ROUND = 'Quantidade de gols no 1˚ tempo da rodada',
  GOALS_IN_THE_SECOND_HALF_OF_THE_ROUND = 'Quantidade de gols no 2˚ tempo da rodada',
  FOOTBALL__FIELD_GOAL_AMOUNT = 'Quantidade de "Field Goal"',
  FOOTBALL__TOUCHDOWN_AMOUNT = 'Quantidade de "Touchdown"',
  FOOTBALL__TWO_POINT_CONVERSION_AMOUNT = 'Quantidade de conversões de 2 pontos',
  FOOTBALL__EXTRA_KICK_AMOUNT = 'Quantidade de chutes extra',
  FOOTBALL__SAFETY_AMOUNT = 'Quantidade de "Safety"',
  FOOTBALL__PICK_SIX_TOUCHDOWN_AMOUNT = 'Quantidade de Pick-six "touchdown"',
}

export interface PlayerPositionSelectOption {
  label: AthletePositionDescriptionEnum;
  value: EAthletePosition;
}

export interface ITeam {
  _id: string;
  name: string;
  abbrev: string;
  image: string;
}

export interface IAthlete {
  _team: ITeam;
  _athlete: {
    _id: string;
    status: boolean;
    name: string;
    url: string;
    number: string;
    position: EAthletePosition;
    age: string;
    height: string;
    weight: string;
    from: string;
    updatedAt: string;
    createdAt: string;
    photo: string;
  };
  classicValue: number;
  athleteScore: number;
  _athleteGoals: string[];
  _athleteStatistics: string[];
  status: boolean;
}

export enum ECardGameOptionTopic {
  GOAL = 'goal',
  STATISTIC = 'statistic',
  OTHERS = 'others',
}

export interface ICardGameOption {
  _id: string;
  _option: string;
  name: string;
  topic: ECardGameOptionTopic;
  value: number;
}

export interface ICardGame {
  _id: string;
  name: string;
  startDate: string;
  cardType: 'new' | 'running' | 'finished';
  price: number;
  status: boolean;
  endDate: string;
  finished: boolean;
  paid: boolean;
  banner?: string;
  athletes: IAthlete[];
  goalOptions: ICardGameOption[];
  statisticOptions: ICardGameOption[];
  amount: number;
  cardCount: number;
  gameType: EGameType;
  players: number; // gamesAmount
  accumulated: number;
  fixedAmount: number;
  percentages: {
    home: number;
    influencer: number;
    winner: number;
  };
  paymentPercentage: {
    first: number;
    second: number;
    third: number;
    fourth: number;
    fifth: number;
  };
}

export interface ICardGameAd {
  _id: string;
  type: AdsTypeEnum;
  title: string;
  text: string;
  url: string;
  isTemporary: boolean;
  _upload?: {
    _id: string;
  };
  relayCount: number;
  status: boolean;
  _card: string;
  targets: ICardGameAdTarget[];
}

export interface ICardGameAdTarget {
  onlineTotal: number;
  playersTotal: number;
  createdAt: string;
}

export enum AdsTypeEnum {
  IMAGE = 'image',
  VIDEO = 'video',
}
