import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 8px;

  label {
    ${props =>
      props.error &&
      css`
        color: ${props.theme.colors.danger};
      `}
  }

  div.ant-select {
    width: 100%;
    ${props =>
      props.error &&
      css`
        div.ant-select-selector {
          border-color: ${props.theme.colors.danger};
        }

        &:hover {
          div.ant-select-selector {
            border-color: ${props.theme.colors.danger};
          }
        }

        &.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input) {
          .ant-select-selector {
            border-color: ${props.theme.colors.danger};
            box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
          }
        }


        /* &:focus {
          div.ant-select-selector {
            border-color: ${props.theme.colors.danger};
            box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
          }
        } */
      `}
  }

  > span {
    color: ${props => props.theme.colors.danger};
    font-size: ${props => props.theme.typograph.sizes.small}px;
    font-weight: ${props => props.theme.typograph.weights.medium};
  }
`;
