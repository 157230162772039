import Loading from '@/components/Loading';
import { Pagination } from 'antd';
import styled, { css } from 'styled-components';

export interface IUserDetailContainer {
  $type: 'user' | 'influencer';
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  > button {
    width: min-content;
    margin: 0 auto;
  }

  > div {
    > h6 {
      margin: 0 0 0.5rem;
    }
  }
`;

export const LoadingData = styled(Loading)`
  margin: 3rem 0;
`;

export const StatisticItemsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

export const StatisticItem = styled.div`
  padding: 1rem;
  background-color: ${(props) => props.theme.colors.light['light-1']};
  border-radius: 0.5rem;
  border: 1px solid ${(props) => props.theme.colors.dark['dark+4']};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.25rem;
  flex-shrink: 1;

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    gap: 0;
  }

  small,
  strong {
    margin: 0;
    text-align: center;

    @media only screen and (max-width: ${(props) =>
        props.theme.breakpoints.sm}) {
      font-size: ${(props) => props.theme.typograph.sizes.small}px;
    }
  }
`;

export const UserDetailContainer = styled.div<IUserDetailContainer>`
  display: flex;
  width: 100%;
  padding: 0.5rem 0;
  align-items: center;

  & + & {
    border-top: 1px solid ${(props) => props.theme.colors.light['light-2']};
  }

  img {
    background-color: ${(props) => props.theme.colors.light['light-2']};
    border-radius: 50%;
    object-fit: cover;
    ${(props) =>
      props.$type === 'user' &&
      css`
        width: 3rem;
        height: 3rem;
      `}
    ${(props) =>
      props.$type === 'influencer' &&
      css`
        width: 3.5rem;
        height: 3.5rem;
      `}
  }
`;

export const UsersList = styled.ul`
  max-width: 40rem;
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const UsersListPagination = styled(Pagination)`
  margin-top: 1rem;
`;

export const UserDetailDataContainer = styled.div`
  flex: 1;
  margin-left: 1rem;
  overflow: hidden;

  p {
    font-size: ${(props) => props.theme.typograph.sizes.p}px;
    font-weight: ${(props) => props.theme.typograph.weights.medium};
    line-height: 1.2;
    color: ${(props) => props.theme.colors.dark.dark0};
  }

  > small {
    font-size: ${(props) => props.theme.typograph.sizes.small}px;
    line-height: 1.2;
    font-weight: ${(props) => props.theme.typograph.weights.medium};
    color: ${(props) => props.theme.colors.dark['dark+2']};

    &:nth-child(2) {
      color: ${(props) => props.theme.colors.dark['dark+1']};
      margin-bottom: 0.5rem;
    }
  }

  p,
  > small {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const InfluencersGamesAmountDetails = styled.small`
  line-height: 1.2;
  text-align: end;
  color: ${(props) => props.theme.colors.dark['dark+2']};

  > span {
    display: block;
    font-size: ${(props) => props.theme.typograph.sizes.small}px;
    font-weight: ${(props) => props.theme.typograph.weights.bold};

    &:last-child {
      color: ${(props) => props.theme.colors.primary['primary-1']};
    }
  }
`;

export const RankingPositionDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  p {
    line-height: 1.2;
    text-align: end;
    color: ${(props) => props.theme.colors.dark['dark+1']};
    display: flex;
    align-items: center;
    gap: 0.5rem;

    svg {
      color: ${(props) => props.theme.colors.yellow};
    }
  }

  small {
    display: block;
    color: ${(props) => props.theme.colors.dark['dark+3']};
    font-size: ${(props) => props.theme.typograph.sizes.small}px;

    &:last-child {
      color: ${(props) => props.theme.colors.primary['primary-1']};
      font-weight: ${(props) => props.theme.typograph.weights.bold};
    }
  }
`;
