import styled, { css } from 'styled-components';

export const AdversarySelector = styled.button`
  border: 0;

  width: 100%;
  height: 69px;
  background: ${props => props.theme.colors.light.light0};
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);

  ${props =>
    props.disabled &&
    css`
      opacity: 0.6;
    `}

  /* For loadmore button */
  > p {
    text-align: center;
    width: 100%;
  }

  & + button {
    margin-top: 8px;
  }

  img {
    width: 33px;
  }

  h6 {
    color: ${props => props.theme.colors.dark.dark0};
    margin-left: 16px;
  }

  svg {
    margin-left: auto;
    color: ${props => props.theme.colors.dark.dark0};
  }
`;
