import React from 'react';
import { Input as InputAnt, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import InputMaskLib from 'react-input-mask';

import { Container } from './styles';
import { IInputMaskProps } from './types';

const InputMask: React.FC<IInputMaskProps> = ({
  mask,
  maskPlaceholder,
  identifier,
  label,
  error,
  isLoading,
  ...rest
}) => {
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 16, color: '#009420' }} spin />
  );

  return (
    <Container $error={!!error}>
      <label htmlFor={identifier}>{label}</label>
      <div>
        <InputMaskLib
          mask={mask}
          maskPlaceholder={maskPlaceholder || ''}
          {...rest}
        >
          <InputAnt id={identifier} />
        </InputMaskLib>
        {isLoading && <Spin indicator={antIcon} />}
      </div>
      {error && <span>{error}</span>}
    </Container>
  );
};

export default InputMask;
