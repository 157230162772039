import { combineReducers } from 'redux';

import application from './application/reducer';
import auth from './auth/reducer';
import user from './user/reducer';
import team from './team/reducer';

export default combineReducers({
  application,
  auth,
  user,
  team,
});
