import React, { useCallback, useMemo } from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';

import CustomAntButton from '@/components/CustomAntButton';
import AthleteCard from './AthleteCard';

import { Container, OptionsList, TopicContainer } from './styles';
import { IOptionsProps } from './types';
import { ButtonsContainer } from '../styles';
import { INewCardAthlete } from '../types';

const ManageAthletes: React.FC<IOptionsProps> = ({
  formik,
  prevStep,
  nextStep,
}) => {
  const reorderList = useCallback(
    (list: INewCardAthlete[], startIndex: number, endIndex: number) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
    },
    []
  );

  const onDragEnd = useCallback(
    (result: DropResult) => {
      if (!result.destination) {
        return;
      }

      const reorderedList = reorderList(
        formik.values.athletes,
        result.source.index,
        result.destination.index
      );

      formik.setFieldValue('athletes', reorderedList);
    },
    [formik, reorderList]
  );

  const handleChangeAthleteClassicValue = useCallback(
    (_id: string, value: string) => {
      const athletes = formik.values.athletes.map((athlete) => {
        if (athlete._id === _id) {
          return {
            ...athlete,
            classicValue: parseInt(value) || 0,
          };
        }
        return athlete;
      });

      formik.setFieldValue('athletes', athletes);
    },
    [formik]
  );

  const isNextButtonEnabled = useMemo(() => {
    const { athletes } = formik.values;
    const areAllAthletesValid = athletes.every(
      (athlete) => !!athlete.classicValue && athlete.classicValue > 0
    );

    return areAllAthletesValid;
  }, [formik.values]);

  return (
    <Container>
      <TopicContainer>
        <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <OptionsList {...provided.droppableProps} ref={provided.innerRef}>
                {formik.values.athletes.map((athlete, index) => (
                  <Draggable
                    key={athlete._id}
                    draggableId={athlete._id}
                    index={index}
                  >
                    {(draggableProvided) => (
                      <AthleteCard
                        draggableProvided={draggableProvided}
                        key={athlete._id}
                        athlete={athlete}
                        handleChangeAthleteClassicValue={
                          handleChangeAthleteClassicValue
                        }
                      />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </OptionsList>
            )}
          </Droppable>
        </DragDropContext>
      </TopicContainer>
      <ButtonsContainer>
        <CustomAntButton onClick={prevStep} type="default">
          Voltar
        </CustomAntButton>
        <CustomAntButton
          onClick={nextStep}
          type="primary"
          disabled={!isNextButtonEnabled}
        >
          Próximo
        </CustomAntButton>
      </ButtonsContainer>
    </Container>
  );
};

export default ManageAthletes;
