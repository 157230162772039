import React from 'react';
import { Button } from 'antd';
import { FastField, FastFieldProps, FormikProps } from 'formik';

import Input from '@/components/Input';

import InputMask from '@/components/InputMask';
import Select from '@/components/Select';
import { ButtonsContainer } from '../styles';
import { ITeamDataProps } from './types';
import { INewTeamFormData } from '../types';
import { TeamTypeEnum } from '../../types';

const TeamData: React.FC<ITeamDataProps> = ({ formik, nextStep }) => {
  function checkIfIsNextButtonEnabled({
    touched,
    errors,
  }: FormikProps<INewTeamFormData>): boolean {
    if (!touched.teamData) {
      return false;
    }

    if (!errors.teamData) {
      return true;
    }

    return false;
  }

  return (
    <>
      <FastField name="teamData.name">
        {({ field, meta }: FastFieldProps) => {
          return (
            <Input
              {...field}
              label="Nome"
              placeholder="Digite o nome do time"
              identifier="name"
              type="text"
              error={meta?.touched && meta?.error && meta?.error}
            />
          );
        }}
      </FastField>
      <FastField name="teamData.initials">
        {({ field, meta }: FastFieldProps) => {
          return (
            <InputMask
              {...field}
              mask="aaa"
              label="Abreviatura"
              identifier="initials"
              placeholder="ex.: FLA"
              type="text"
              error={meta?.touched && meta?.error && meta?.error}
              onChange={(e) => {
                e.target.value = e.target.value.toUpperCase();
                field.onChange(e);
              }}
            />
          );
        }}
      </FastField>
      <FastField name="teamData.type">
        {({ field, meta, form }: FastFieldProps) => {
          return (
            <Select
              {...field}
              label="Tipo de time"
              identifier="type"
              placeholder="Selecione"
              options={[
                { label: 'Profissional', value: TeamTypeEnum.PROFESSIONAL },
                { label: 'Amador', value: TeamTypeEnum.AMATEUR },
                { label: 'Casa', value: TeamTypeEnum.HOUSE },
              ]}
              error={meta?.touched && meta?.error}
              onChange={(value: TeamTypeEnum) => {
                form.setFieldValue('teamData.type', value);
              }}
            />
          );
        }}
      </FastField>
      <ButtonsContainer>
        <Button
          onClick={nextStep}
          type="primary"
          disabled={!checkIfIsNextButtonEnabled(formik)}
        >
          Próximo
        </Button>
      </ButtonsContainer>
    </>
  );
};

export default TeamData;
