import React from 'react';
import { createBrowserHistory } from 'history';

import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const history = createBrowserHistory({
  getUserConfirmation(messageComponents, callback) {
    const { title, content, cancelText, okText } = JSON.parse(
      messageComponents
    );

    const { confirm } = Modal;

    confirm({
      title: title || 'Aviso!',
      icon: <ExclamationCircleOutlined />,
      content: content || 'Deseja realmente realizar essa operação?',
      cancelText: cancelText || 'Não',
      okText: okText || 'Sim',
      okButtonProps: {
        danger: true,
      },
      onOk() {
        callback(true);
      },
      onCancel() {
        callback(false);
      },
    });

    // Show some custom dialog to the user and call
    // callback(true) to continue the transiton, or
    // callback(false) to abort it.
  },
});

export default history;
