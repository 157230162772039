import styled, { css } from 'styled-components';
import { darken } from 'polished';
import { Menu } from 'antd';

export const Container = styled.div`
  width: 100%;
  max-width: 632px;
  margin: 0 auto;
`;

export const InactiveAdvertsContent = styled.div`
  width: 100%;

  > small {
    width: 100%;
    text-align: center;
  }

  > button {
    width: 100%;
    max-width: 400px;
    margin: 0px auto 16px;
    display: block;
  }
`;

export const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const AdvertisementItemContainer = styled.div`
  width: 100%;
  position: relative;
  aspect-ratio: 21 / 9;
  margin-bottom: 8px;
  ${props =>
    props.$inactive &&
    css`
      opacity: 0.8;
    `}

  img {
    position: absolute;
    z-index: 5;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  div.shimmer {
    position: absolute !important;
    z-index: 5 !important;
    width: 100% !important;
    height: 100% !important;
    border: 1px solid ${props => props.theme.colors.light['light-2']} !important;
  }
`;

export const AdvertisementItemContent = styled.div`
/* width: 100%; */
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  /* flex: 1; */
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: default;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.6) 56.25%,
    #000000 100%
  );

  > button {
    align-self: flex-end;

    /* color: ${props => props.theme.colors.light.light0};

    &:hover,
    &:active,
    &:focus {
      color: ${props => darken(0.2, props.theme.colors.light.light0)};
    } */
  }

  ul {
    width: 100%;
    cursor: default;
    margin-bottom: 0px;

    li {
      list-style: none;
      width: 100%;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0px;
      }

      small {
        color: ${props => props.theme.colors.primary['primary+1']};
      }

      p {
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        color: ${props => props.theme.colors.light.light0};
      }
    }

    /* p {
      margin-bottom: 8px;
      color: ${props => props.theme.colors.light.light0};

      &:last-child {
        margin-bottom: 0px;
      }
    } */
  }
`;

export const ReactivateAdvertMenuItem = styled(Menu.Item)`
  color: ${props => props.theme.colors.primary.primary0};
`;

export const Session = styled.div`
  width: 100%;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;

  > p {
    color: ${props => props.theme.colors.dark['dark+1']};
  }

  .ant-pagination {
    text-align: center;
    margin-top: 16px;
  }

  > h6 {
    text-align: center;
    margin: auto 0;
    font-weight: ${props => props.theme.typograph.weights.medium};
    margin-top: 32px;
  }
`;

export const SessionHeader = styled.div`
  width: 100%;
  display: flex;
  /* max-width: 424px; */
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  /* AntDesign Button with Icon */
  /* button {
    line-height: 0;
    color: ${props => props.theme.colors.primary.primary0};

    &:hover,
    &:focus,
    &:active {
      color: ${props => props.theme.colors.primary['primary-1']};
    }
  } */
`;

export const SessionItems = styled.ul`
  width: 100%;

  li {
    list-style: none;
    width: 100%;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0px;
    }

    small {
      color: ${props => props.theme.colors.primary.primary0};
    }
  }

  p {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0px;
    }
  }
`;
