import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
  padding: 56px 32px;

  @media only screen and (max-width: 600px) {
    padding: 32px 16px;
    min-height: calc(100vh - 61px);
  }
`;

export const TitlePageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: center;

  /* AntD Button Changes */
  button {
    margin-left: 16px;

    @media only screen and (min-width: 601px) {
      display: none;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  max-width: 752px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto;
  margin-top: 40px;

  @media only screen and (max-width: 768px) {
    margin-top: 16px;
  }
`;

export const SessionContainer = styled.div`
  > ul {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 0;
  }

  > p {
    width: 100%;
    text-align: center;
    color: ${props => props.theme.colors.dark['dark+3']};
  }

  > strong {
    margin-bottom: 8px;
    display: block;
  }

  > div:nth-child(4) {
    margin-bottom: 32px;
  }

  &:nth-child(1) {
    > p {
      margin-bottom: 8px;
    }
  }

  &:nth-child(2) {
    padding-top: 16px;
    margin-bottom: 16px;
  }

  &:nth-child(3) {
    border-top: 1px solid ${props => props.theme.colors.light['light-2']};
  }
`;

export const ChampionshipSelector = styled.button`
  border: 0;

  width: 100%;
  height: 69px;
  background: ${props => props.theme.colors.light.light0};
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);

  ${props =>
    props.disabled &&
    css`
      opacity: 0.6;
    `}

  /* For loadmore button */
  > p {
    text-align: center;
    width: 100%;
  }

  & + button {
    margin-top: 8px;
  }

  img {
    width: 33px;
  }

  h6 {
    color: ${props => props.theme.colors.dark.dark0};
    margin-left: 16px;
  }

  svg {
    margin-left: auto;
    color: ${props => props.theme.colors.dark.dark0};
  }
`;

export const MatchSelector = styled.button`
  border: 0;
  width: 49.5%;
  border-radius: 8px;
  background: ${props => props.theme.colors.light.light0};
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  padding: 16px;
  position: relative;
  margin-bottom: 8px;

  @media only screen and (max-width: 1199px) {
    width: 100%;
  }

  ${props =>
    props.$selected &&
    css`
      width: 100% !important;
      margin-bottom: 0;
    `}

  > div:first-child {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;

    h6 {
      font-weight: ${props => props.theme.typograph.weights.medium};
      color: ${props => props.theme.colors.dark['dark+4']};
      margin: 0 24px;
    }
  }

  > svg {
    position: absolute;
    top: 16px;
    right: 16px;
  }
`;

export const MatchSelectorTeam = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;

  div {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background: ${props => props.theme.colors.light['light-2']};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4px;

    img {
      height: 36px;
    }
  }

  strong {
    text-align: center;
  }
`;

export const MatchInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  border-top: 1px solid ${props => props.theme.colors.light['light-2']};

  div {
    width: 100%;

    & + div {
      margin-top: 4px;
    }

    small {
      color: ${props => props.theme.colors.dark['dark+3']};
      text-align: start;
    }

    small:nth-child(1) {
      color: ${props => props.theme.colors.primary.primary0};
    }
  }
`;

export const MatchListDatePickerAndNewMatchContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
`;

export const LoadingAndNotFoundContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  > small {
    width: 100%;
    text-align: center;
    color: ${props => props.theme.colors.dark['dark+3']};
  }

  > div {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 56px;

    p {
      margin-left: 16px;
      color: ${props => props.theme.colors.dark['dark+3']};
    }

    h6 {
      text-align: center;

      span {
        color: ${props => props.theme.colors.primary.primary0};
      }
    }
  }
`;

export const LoadMoreButton = styled.button`
  border: 0;

  width: 100%;
  height: 69px;
  background: ${props => props.theme.colors.light.light0};
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0 16px;

  ${props =>
    props.disabled &&
    css`
      opacity: 0.6;
    `}

  > p {
    text-align: center;
    width: 100%;
  }
`;
