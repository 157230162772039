import React from 'react';
import { Button } from 'antd';
import {
  FastField,
  FieldProps,
  Field,
  FormikProps,
  FastFieldProps,
} from 'formik';

import Input from '@/components/Input';

import { ButtonsContainer } from '../styles';
import { IInfluencerInformationProps } from './types';
import { INewInfluencerFormData } from '../types';

const InfluencerInformation: React.FC<IInfluencerInformationProps> = ({
  formik,
  nextStep,
  loadingsOfAsyncValidations,
  setFocusedField,
}) => {
  function checkIfIsNextButtonEnabled({
    touched,
    errors,
  }: FormikProps<INewInfluencerFormData>): boolean {
    const isNotFormTouched = !touched.influencerInformation;
    const hasErrors = errors.influencerInformation;

    if (isNotFormTouched || hasErrors) {
      return false;
    }

    return true;
  }

  return (
    <>
      <Field name="influencerInformation.username">
        {({ field, meta }: FieldProps) => {
          return (
            <Input
              {...field}
              label="Nome de influenciador"
              placeholder="Digite um nome de influenciador"
              identifier="username"
              type="text"
              error={meta?.touched && meta?.error && meta?.error}
              showloading={!!loadingsOfAsyncValidations?.username}
              onFocus={() => setFocusedField('influencerInformation.username')}
              onChange={(e) => {
                e.target.value = e.target.value
                  .replace(/\s+/g, '')
                  .toLowerCase();
                field.onChange(e);
              }}
            />
          );
        }}
      </Field>
      <Field name="influencerInformation.email">
        {({ field, meta }: FieldProps) => {
          return (
            <Input
              {...field}
              label="Email"
              placeholder="example@example.com"
              identifier="email"
              type="email"
              error={meta?.touched && meta?.error && meta?.error}
              showloading={!!loadingsOfAsyncValidations?.email}
              onFocus={() => setFocusedField('influencerInformation.email')}
            />
          );
        }}
      </Field>
      <FastField name="influencerInformation.password">
        {({ field, meta }: FastFieldProps) => {
          return (
            <Input
              {...field}
              label="Senha"
              placeholder="No mínimo 6 dígitos"
              identifier="password"
              type="password"
              error={meta?.touched && meta?.error && meta?.error}
              onFocus={() => setFocusedField('influencerInformation.password')}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/\s+/g, '');
                field.onChange(e);
              }}
            />
          );
        }}
      </FastField>
      <FastField name="influencerInformation.confirmPassword">
        {({ field, meta }: FastFieldProps) => {
          return (
            <Input
              {...field}
              label="Confirmar senha"
              placeholder="Confirme a senha informada anteriormente"
              identifier="confirmPassword"
              type="password"
              error={meta?.touched && meta?.error && meta?.error}
              onFocus={() =>
                setFocusedField('influencerInformation.confirmPassword')
              }
              onChange={(e) => {
                e.target.value = e.target.value.replace(/\s+/g, '');
                field.onChange(e);
              }}
            />
          );
        }}
      </FastField>
      <Field name="influencerInformation.code">
        {({ field, meta }: FieldProps) => {
          return (
            <Input
              {...field}
              label="Código do influenciador"
              placeholder="Digite o código do influenciador"
              identifier="code"
              type="text"
              error={meta?.touched && meta?.error && meta?.error}
              showloading={!!loadingsOfAsyncValidations?.code}
              onFocus={() => setFocusedField('influencerInformation.code')}
              onChange={(e) => {
                e.target.value = e.target.value
                  .replace(/\s+/g, '')
                  .toUpperCase();
                field.onChange(e);
              }}
            />
          );
        }}
      </Field>
      <ButtonsContainer>
        <Button
          onClick={nextStep}
          type="primary"
          disabled={!checkIfIsNextButtonEnabled(formik)}
        >
          Próximo
        </Button>
      </ButtonsContainer>
    </>
  );
};

export default InfluencerInformation;
