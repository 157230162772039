import styled, { css } from 'styled-components';

interface IContainer {
  $hasError: boolean;
}

export const Container = styled.div<IContainer>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 0.5rem;

  label {
    ${(props) =>
      props.$hasError &&
      css`
        color: ${props.theme.colors.danger};
      `}
  }

  > span {
    color: ${(props) => props.theme.colors.danger};
    font-size: ${(props) => props.theme.typograph.sizes.small}px;
    font-weight: ${(props) => props.theme.typograph.weights.medium};
  }
`;
