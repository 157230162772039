import React, { useMemo } from 'react';
import { FastField, FastFieldProps, Field, FieldProps } from 'formik';

import Input from '@/components/Input';

import GamePaymentPercentBar from '@/components/GamePaymentPercentBar';
import theme from '@/styles/theme';
import { Container, PercentagesInputsContainer } from './styles';
import { IPercentagesProps } from './types';

const Percentages: React.FC<IPercentagesProps> = ({ formik }) => {
  const hasInfluencers = useMemo(() => {
    return !!formik.values.cardInformation.influencers.length;
  }, [formik.values.cardInformation.influencers.length]);
  const isWinnerPercentageVisible = useMemo(
    () => formik.values.cardInformation.hasFixedPrize === false,
    [formik.values.cardInformation.hasFixedPrize]
  );

  const formPercentages = formik.values.cardInformation.percentages;
  const paymentPercentBarItems = useMemo(() => {
    const items = [
      {
        label: 'Casa',
        color: theme.colors.primary['primary-1'],
        value: formPercentages.homePercent
          ? parseInt(formPercentages.homePercent)
          : 0,
      },
      {
        label: 'Influenciador(res)',
        color: theme.colors.blue,
        value: formPercentages.influencerPercent
          ? parseInt(formPercentages.influencerPercent)
          : 0,
      },
    ];

    if (isWinnerPercentageVisible) {
      items.push({
        label: 'Vencedor',
        color: theme.colors.secondary.secondary0,
        value: formPercentages.winnerPercent
          ? parseInt(formPercentages.winnerPercent)
          : 0,
      });
    }

    return items;
  }, [formPercentages, isWinnerPercentageVisible]);

  return (
    <Container>
      <PercentagesInputsContainer>
        <FastField name="cardInformation.percentages.homePercent">
          {({ field, meta }: FastFieldProps) => {
            return (
              <Input
                {...field}
                label="Porcentagem da casa"
                identifier="homePercent"
                type="text"
                error={meta?.touched && meta?.error && meta?.error}
                onChange={(e) => {
                  e.target.value = e.target.value
                    .replace(/\D/g, '')
                    .slice(0, 3);
                  field.onChange(e);
                }}
                suffix="%"
              />
            );
          }}
        </FastField>
        <Field name="cardInformation.percentages.influencerPercent">
          {({ field, meta }: FieldProps) => {
            return (
              <Input
                {...field}
                label="Porcentagem do influenciador"
                identifier="influencerPercent"
                type="text"
                error={meta?.touched && meta?.error && meta?.error}
                onChange={(e) => {
                  e.target.value = e.target.value
                    .replace(/\D/g, '')
                    .slice(0, 3);
                  field.onChange(e);
                }}
                disabled={!hasInfluencers}
                suffix="%"
              />
            );
          }}
        </Field>
        {isWinnerPercentageVisible && (
          <FastField name="cardInformation.percentages.winnerPercent">
            {({ field, meta }: FastFieldProps) => {
              return (
                <Input
                  {...field}
                  label="Porcentagem do vencedor"
                  identifier="winnerPercent"
                  type="text"
                  error={meta?.touched && meta?.error && meta?.error}
                  onChange={(e) => {
                    e.target.value = e.target.value
                      .replace(/\D/g, '')
                      .slice(0, 3);
                    field.onChange(e);
                  }}
                  suffix="%"
                />
              );
            }}
          </FastField>
        )}
      </PercentagesInputsContainer>
      <GamePaymentPercentBar items={paymentPercentBarItems} />
    </Container>
  );
};

export default Percentages;
