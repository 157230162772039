import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.light.light0};
  border-radius: 4px;
  padding: 1rem;
  box-shadow: 0px 1px 8px 1px #00000015;
`;

export const InputsContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;
