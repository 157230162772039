import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '@/services/api';
import socket from '@/services/socket';

import { getUserDataSuccess, getUserDataFailure } from './actions';

export function* getUser({ payload }) {
  try {
    /* When activated for signInSuccess action / When activated for rehydrate action */
    const token = payload.token ? payload.token : payload.auth.token;

    if (!token) {
      throw new Error('No token indentified');
    }

    const response = yield call(api.get, 'api/me', {
      headers: {
        Authorization: token,
      },
    });

    socket.io.opts.query = {
      _user: response.data._id,
    };

    socket.connect();

    yield put(getUserDataSuccess(response.data));
  } catch (error) {
    yield put(getUserDataFailure());
  }
}

export function* updateProfile({ payload }) {
  try {
    const { name, email, avatar_id, ...rest } = payload.data;

    const profile = {
      name,
      email,
      avatar_id,
      ...(rest.oldPassword ? rest : {}),
    };

    const response = yield call(api.put, 'users', profile);
    console.log(response);
    // yield put(updateProfileSuccess(response.data));
    toast.success('Perfil atualizado com sucesso!');
  } catch (error) {
    // yield put(updateProfileFailure());
    toast.error('Erro ao atualizar o perfil, confira seus dados!');
  }
}

export default all([
  takeLatest('persist/REHYDRATE', getUser),
  takeLatest('@auth/SIGN_IN_SUCCESS', getUser),
  takeLatest('@user/UPDATE_PROFILE_REQUEST', updateProfile),
]);
