import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  useParams,
  useRouteMatch,
  useHistory,
  useLocation,
  Link,
  Switch,
  Route,
} from 'react-router-dom';
import { Button, Spin, Modal } from 'antd';
import { toast } from 'react-toastify';
import { LoadingOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { IoMdCheckmarkCircle } from 'react-icons/io';
import { MdEdit, MdBlock } from 'react-icons/md';

import {
  Container,
  Header,
  Content,
  ImageWrapper,
  UserInformationsHeader,
  UserNotFoundContainer,
  ActionButtonAvatar,
} from './styles';

import Breadcrums from '@/components/Breadcrumbs';
import CustomAntButton from '@/components/CustomAntButton';

import api from '@/services/api';
import { UploadRequests } from '@/services/api/requests/Upload';
import NotFoundImage from '@/assets/NotFound.svg';
import DefaultAvatar from '@/assets/DefaultAvatar.svg';

import Details from './Details';
import UpdateAvatar from './UpdateAvatar';
import EditUserInformations from './EditUserInformations';
import EditPersonalData from './EditPersonalData';
import EditPermissions from './EditPermissions';

const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;
const { confirm } = Modal;

function Administrator({ match }) {
  const history = useHistory();
  const params = useParams();
  const routeMatch = useRouteMatch();
  const location = useLocation();

  const [admin, setAdmin] = useState(null);
  const [loadingAdmin, setLoadingAdmin] = useState(true);
  const [avatarModalVisible, setAvatarModalVisible] = useState(false);

  useEffect(() => {
    async function getAdmin(_id) {
      if (!_id) {
        return console.log('Erro!!');
      }

      try {
        const { data } = await api.get(`/api/admin/${_id}`);

        const isAdmin = !!data?.roles.find((role) => role === 'admin');

        if (!isAdmin) {
          toast.warn('Esse identificador não pertence a um administrador');
          return history.push('/settings/administrators');
        }

        setAdmin({
          userInformations: {
            username: data?.username || 'Não informado',
            email: data?.email || 'Não informado',
          },
          personalData: {
            name: data?.name || 'Não informado',
            gender: data?.gender || 'Não informado',
            docNumber: data?.docNumber || 'Não informado',
            phone: data?.phones[0] || 'Não informado',
          },
          permissions: {
            manager_user: !!data?.subRoles.find(
              (subRole) => subRole === 'manager_user'
            ),
            manager_sponsor: !!data?.subRoles.find(
              (subRole) => subRole === 'manager_sponsor'
            ),
            manager_team: !!data?.subRoles.find(
              (subRole) => subRole === 'manager_team'
            ),
            manager_admin: !!data?.subRoles.find(
              (subRole) => subRole === 'manager_admin'
            ),
            manager_games: !!data?.subRoles.find(
              (subRole) => subRole === 'manager_games'
            ),
            manager_influencer: !!data?.subRoles.find(
              (subRole) => subRole === 'manager_influencer'
            ),
          },
          avatar: data?.photo?.filename || null,
          status: data?.status,
        });
        setLoadingAdmin(false);
      } catch (error) {
        setLoadingAdmin(false);
      }
      return '';
    }

    getAdmin(params?.id);
  }, [history, params.id]);

  const handleDisableAdmin = useCallback(async () => {
    const _id = params.id;

    if (!_id) {
      return toast.warn(
        'Ocorreu um problema ao desabilitar esse administrador, atualize a página e tente novamente!'
      );
    }

    try {
      await new Promise((resolve, reject) => {
        confirm({
          title: 'Deseja realmente desabilitar esse administrador?',
          icon: <ExclamationCircleOutlined />,
          content: 'Ele não terá mais acesso a plataforma Lance Futebol',
          cancelText: 'Não',
          okText: 'Desativar administrador',
          okButtonProps: {
            danger: true,
          },
          onOk() {
            resolve();
          },
          onCancel() {
            reject(new Error('CANCELED_ADMIN_DELETE_ACTION'));
          },
        });
      });

      await api.delete(`/api/admin/${_id}`);

      toast.success('Administrador desativado com sucesso');
      setAdmin({ ...admin, status: 0 });
    } catch (error) {
      if (error.message === 'CANCELED_ADMIN_DELETE_ACTION') {
        return null;
      }
    }

    return null;
  }, [admin, params.id]);

  const handleEneableAdmin = useCallback(async () => {
    const _id = params.id;

    if (!_id) {
      return toast.warn(
        'Ocorreu um problema ao desabilitar esse administrador, atualize a página e tente novamente!'
      );
    }

    try {
      await new Promise((resolve, reject) => {
        confirm({
          title: 'Deseja realmente habilitar esse administrador?',
          icon: <ExclamationCircleOutlined />,
          content: 'Ele poderá ter acesso a plataforma Lance Futebol',
          cancelText: 'Não',
          okText: 'Ativar administrador',
          okButtonProps: {
            // danger: true,
          },
          onOk() {
            resolve();
          },
          onCancel() {
            reject(new Error('CANCELED_ADMIN_ENEABLE_ACTION'));
          },
        });
      });

      await api.put(`/api/admin/${_id}`, {
        status: 1,
      });

      toast.success('Administrador ativo com sucesso');
      setAdmin({ ...admin, status: 1 });
    } catch (error) {
      if (error.message === 'CANCELED_ADMIN_ENEABLE_ACTION') {
        return null;
      }
    }

    return null;
  }, [admin, params.id]);

  const contentModal = useMemo(() => {
    return (
      <>
        <Link
          to={`${routeMatch.url}/update_avatar`}
          onClick={() => setAvatarModalVisible(false)}
        >
          <ActionButtonAvatar>
            <MdEdit size={24} />
            <span>Atualizar avatar</span>
          </ActionButtonAvatar>
        </Link>
      </>
    );
  }, [routeMatch.url]);

  const currentLocation = useMemo(() => {
    const page = location.pathname;

    let currentLocationName = 'main';

    if (page.includes('/update_avatar')) {
      currentLocationName = 'updateAvatar';
    } else if (
      page.includes('/edit_user_informations') ||
      page.includes('/edit_personal_data') ||
      page.includes('/edit_permissions')
    ) {
      currentLocationName = 'edit';
    }

    return currentLocationName;
  }, [location.pathname]);

  const changeStatusAdminButton = useMemo(() => {
    if (!admin) return null;

    const { status } = admin;

    if (status === 1) {
      return (
        <Button onClick={handleDisableAdmin} type="text" danger>
          Desativar administrador
        </Button>
      );
    }
    if (status === 0) {
      return (
        <CustomAntButton
          onClick={handleEneableAdmin}
          type="text"
          useTextPrimary
        >
          Ativar administrador
        </CustomAntButton>
      );
    }
    return null;
  }, [admin, handleDisableAdmin, handleEneableAdmin]);

  const mobileChangeStatusAdminButton = useMemo(() => {
    if (!admin) return null;

    const { status } = admin;

    if (status === 1) {
      return (
        <Button
          onClick={handleDisableAdmin}
          type="text"
          danger
          icon={<MdBlock size={21} />}
        />
      );
    }
    if (status === 0) {
      return (
        <CustomAntButton
          onClick={handleEneableAdmin}
          type="text"
          useTextPrimary
          icon={<IoMdCheckmarkCircle size={21} />}
        />
      );
    }
    return null;
  }, [admin, handleDisableAdmin, handleEneableAdmin]);

  if (loadingAdmin) {
    return (
      <Container>
        <Spin
          style={{
            alignSelf: 'center',
            marginTop: 'auto',
            marginBottom: 'auto',
          }}
          indicator={antIcon}
        />
      </Container>
    );
  }

  if (!admin) {
    return (
      <Container>
        <UserNotFoundContainer>
          <Breadcrums />
          <div>
            <img src={NotFoundImage} alt="Not Found" />
            <h6>Esse administrador não foi encontrado</h6>
          </div>
        </UserNotFoundContainer>
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        <Breadcrums />
        <div>
          <ImageWrapper
            onClick={() => {
              if (currentLocation === 'main') {
                setAvatarModalVisible(true);
              }
            }}
            clickEneabled={currentLocation === 'main'}
          >
            <img
              src={
                admin?.avatar
                  ? UploadRequests.getFileUrl(admin?.avatar)
                  : DefaultAvatar
              }
              alt={admin?.personalData?.name || 'Avatar'}
            />
          </ImageWrapper>
          <UserInformationsHeader>
            <div>
              <div>
                <h5>{admin?.personalData?.name}</h5>
                {admin.status === 0 && <span>Inativo</span>}
              </div>
              <p>{admin?.userInformations?.username}</p>
              <p>{admin?.userInformations?.email}</p>
            </div>
            {currentLocation === 'main' && changeStatusAdminButton}
          </UserInformationsHeader>
          {currentLocation === 'main' && mobileChangeStatusAdminButton}
        </div>
      </Header>
      <Content>
        <Switch>
          <Route exact path={routeMatch.path}>
            <Details admin={admin} routeMatch={routeMatch} />
          </Route>
          <Route path={`${routeMatch.path}/update_avatar`}>
            <UpdateAvatar
              admin={admin}
              setAdmin={setAdmin}
              parentRouteMatch={routeMatch}
            />
          </Route>
          <Route path={`${routeMatch.path}/edit_user_informations`}>
            <EditUserInformations
              admin={admin}
              setAdmin={setAdmin}
              parentRouteMatch={routeMatch}
            />
          </Route>
          <Route path={`${routeMatch.path}/edit_personal_data`}>
            <EditPersonalData
              admin={admin}
              setAdmin={setAdmin}
              parentRouteMatch={routeMatch}
            />
          </Route>
          <Route path={`${routeMatch.path}/edit_permissions`}>
            <EditPermissions
              admin={admin}
              setAdmin={setAdmin}
              parentRouteMatch={routeMatch}
            />
          </Route>
        </Switch>
      </Content>
      <Modal
        title="Avatar do administrador"
        visible={avatarModalVisible}
        onCancel={() => {
          setAvatarModalVisible(false);
        }}
        okButtonProps={{
          style: {
            display: 'none',
          },
        }}
        cancelText="Voltar"
      >
        {contentModal}
      </Modal>
    </Container>
  );
}

export default Administrator;
