import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import logo from '@/assets/logo.svg';
import { signOutRequest } from '@/store/modules/auth/actions';

import MenuItem from './MenuItem';

import {
  Container,
  LogoutButton,
  LogoutButtonIcon,
  MobileMenuBackgroundMask,
} from './styles';

import { getMenu } from './menu.ts';

function Sidebar({ opened, handleToogleSidebarState, history }) {
  const dispatch = useDispatch();

  const loadingSignOut = useSelector(state => state.auth.loading);
  const me = useSelector(state => state.user.profile);

  const menuItems = getMenu(me.roles);

  useEffect(() => {
    if (opened) {
      handleToogleSidebarState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname]);

  const sideBarItems = useMemo(() => {
    menuItems.forEach(item => {
      const itemPath = `/${item.name}`;

      if (history.location.pathname.startsWith(itemPath)) {
        item.selected = true;
      } else {
        item.selected = false;
      }
    });

    return menuItems;
  }, [history.location.pathname, menuItems]);

  function handleSignOut() {
    dispatch(signOutRequest());
  }

  return (
    <>
      <Container opened={opened}>
        <img src={logo} alt="Lance Futebol" />
        <ul>
          {sideBarItems.map(sideBarItem => (
            <MenuItem key={sideBarItem.name} {...sideBarItem} />
          ))}
        </ul>
        <LogoutButton onClick={!loadingSignOut ? handleSignOut : () => {}}>
          <LogoutButtonIcon />
          <span>{!loadingSignOut ? 'Sair do app' : 'Carregando...'}</span>
        </LogoutButton>
      </Container>
      <MobileMenuBackgroundMask
        onClick={handleToogleSidebarState}
        opened={opened}
      />
    </>
  );
}

export default Sidebar;
