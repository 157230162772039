import styled, { css } from 'styled-components';
import { Button as AntButton } from 'antd';
import { darken } from 'polished';

interface IButtonProps {
  $useTextPrimary: boolean;
  $useSecondary: boolean;
}

export const Button = styled(AntButton)<IButtonProps>`
  ${(props) =>
    props.$useTextPrimary &&
    css`
      color: ${props.theme.colors.primary.primary0};

      &:hover,
      &:focus,
      &:active {
        color: ${props.theme.colors.primary['primary-1']};
      }
    `}

  ${(props) =>
    props.icon &&
    css`
      line-height: 0;
    `}

    ${(props) =>
    props.$useSecondary &&
    css`
      background-color: ${props.theme.colors.secondary.secondary0};
      border-color: ${props.theme.colors.secondary.secondary0};

      &:hover,
      &:focus,
      &:active {
        background-color: ${() =>
          darken(0.1, props.theme.colors.secondary.secondary0)};
        border-color: ${() =>
          darken(0.1, props.theme.colors.secondary.secondary0)};
      }
    `}
`;
