import { match } from 'react-router';
import { IInfluencerState } from '../types';

export interface IRouteParams {
  id: string;
}

export interface IEditInfluencerInformationProps {
  influencer: IInfluencerState;
  setInfluencer: React.Dispatch<React.SetStateAction<IInfluencerState>>;
  parentRouteMatch: match;
}

export interface IEditInfluencerInformationFormData {
  username: string;
  email: string;
  code: string;
}

export interface ICheckIfIsFieldAvailableProps {
  value: string;
  fieldName: UniqueFieldEnum;
  resolve: (isFieldAvailable: boolean) => void;
  reject(value: false): void;
  isFieldTouched: boolean;
  setFieldTouched(): void;
}

export enum UniqueFieldEnum {
  EMAIL = 'email',
  CODE = 'code',
}
