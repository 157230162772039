import styled, { css } from 'styled-components';

export const Container = styled.div`
  flex: 1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.header`
  width: 100%;
  background-color: ${props => props.theme.colors.light['light-2']};
  height: 194px;
  border-bottom: 1px solid ${props => props.theme.colors.dark['dark+4']};
  padding: 32px 56px 16px 56px;
  display: flex;
  flex-direction: column;

  > div:nth-child(2) {
    display: flex;
    margin-top: 24px;
    flex: 1;

    > button {
      display: none;
    }
  }

  @media only screen and (max-width: 768px) {
    padding: 16px 32px;
  }

  @media only screen and (max-width: 600px) {
    padding: 16px;
    height: 284px;

    > div:nth-child(2) {
      flex-direction: column;
      position: relative;

      > button {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
`;

export const ImageWrapper = styled.div`
  width: 150px;
  height: 150px;

  position: relative;
  background: ${props => props.theme.colors.light['light-2']};
  border-radius: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${props => props.theme.colors.dark['dark+4']};

  img {
    height: 100px;
    /* position: absolute; */
    /* border: 1px solid ${props => props.theme.colors.dark['dark+4']}; */

    ${props =>
      props.clickEneabled &&
      css`
        cursor: pointer;

        &:hover {
          opacity: 0.95;
        }
      `}
  }

  @media only screen and (max-width: 600px) {
    width: 110px;
    height: 110px;
    align-self: center;

    img {
      height: 70px;
    }
  }
`;

export const TeamInformationsHeader = styled.div`
  display: flex;
  flex: 1;
  margin-left: 40px;
  align-items: center;

  @media only screen and (max-width: 600px) {
    margin: 0 auto !important;
    text-align: center;

    button {
      display: none;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  margin-top: 80px;
  margin-bottom: 40px;
  padding: 0px 56px;

  @media only screen and (max-width: 768px) {
    margin-top: 56px;
    padding: 0px 32px;
  }
  @media only screen and (max-width: 600px) {
    margin-top: 32px;
    padding: 0px 16px;
  }
`;

export const SessionItems = styled.ul`
  width: 100%;
  margin-top: 16px;

  li {
    list-style: none;
    width: 100%;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0px;
    }

    small {
      color: ${props => props.theme.colors.primary.primary0};
    }
  }
`;

export const TeamNotFoundContainer = styled.div`
  padding: 32px 56px;
  flex: 1;
  display: flex;
  flex-direction: column;

  > div:nth-child(2) {
    width: 100%;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img {
      width: 300px;
    }

    h6 {
      margin-top: 24px;
      text-align: center;
    }
  }
  @media only screen and (max-width: 768px) {
    padding: 16px 32px;
  }
  @media only screen and (max-width: 600px) {
    padding: 16px 16px;
  }
`;

export const ActionButtonAvatar = styled.div`
  width: 100%;
  display: flex;
  padding: 16px;
  transition: background-color 0.2s;
  cursor: pointer;

  color: ${props => props.theme.colors.dark.dark0};

  ${props =>
    props.danger &&
    css`
      color: ${props.theme.colors.danger};
    `}

  span {
    font-size: 16px;
    margin-left: 16px;
  }

  &:hover {
    background-color: ${props => props.theme.colors.light['light-1']};
  }
`;
