import React from 'react';
import { Button } from 'antd';
import { FastField, Field } from 'formik';

import Input from '@/components/Input';
import InputMask from '@/components/InputMask';
import Radio from '@/components/Radio';

import { ButtonsContainer } from '../styles';
import { UserGenderEnum } from '@/models/User';

function PersonalData({
  formik,
  nextStep,
  prevStep,
  loadingsOfAsyncValidations,
  setFocusedField,
}) {
  function eneableNextStepButton({ touched, errors }) {
    if (!touched.personalData) {
      return false;
    }

    if (!errors.personalData) {
      return true;
    }

    return false;
  }
  return (
    <>
      <FastField name="personalData.name">
        {({ field, meta }) => {
          return (
            <Input
              {...field}
              label="Nome"
              placeholder="Digite o nome da pessoa"
              identifier="name"
              type="text"
              error={meta?.touched && meta?.error && meta?.error}
              onFocus={() => setFocusedField('personalData.name')}
            />
          );
        }}
      </FastField>
      <FastField name="personalData.gender">
        {({ field, meta }) => {
          return (
            <Radio
              {...field}
              items={[
                { value: UserGenderEnum.MALE, label: 'Masculino' },
                { value: UserGenderEnum.FEMALE, label: 'Feminino' },
                { value: UserGenderEnum.OTHER, label: 'Outros' },
              ]}
              label="Sexo"
              name="personalData.gender"
              setFieldValue={formik.setFieldValue}
              value={formik.values.personalData.gender}
              error={meta?.touched && meta?.error && meta?.error}
            />
          );
        }}
      </FastField>
      <Field name="personalData.docNumber">
        {({ field, meta }) => {
          return (
            <InputMask
              {...field}
              mask="999.999.999-99"
              label="CPF"
              identifier="docNumber"
              placeholder="999.999.999-99"
              type="text"
              error={meta?.touched && meta?.error && meta?.error}
              isLoading={!!loadingsOfAsyncValidations?.docNumber}
              onFocus={() => setFocusedField('personalData.docNumber')}
            />
          );
        }}
      </Field>
      <FastField name="personalData.phone">
        {({ field, meta }) => {
          return (
            <InputMask
              {...field}
              mask="(99)99999-9999"
              label="Telefone celular"
              identifier="phone"
              placeholder="(99)99999-9999"
              type="text"
              error={meta?.touched && meta?.error && meta?.error}
              onFocus={() => setFocusedField('personalData.phone')}
            />
          );
        }}
      </FastField>
      <ButtonsContainer>
        <Button onClick={prevStep} type="default">
          Voltar
        </Button>
        <Button
          onClick={nextStep}
          type="primary"
          disabled={!eneableNextStepButton(formik)}
        >
          Próximo
        </Button>
      </ButtonsContainer>
    </>
  );
}

export default PersonalData;
