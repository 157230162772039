export const loadingOfAsyncValidationsInitialState = {
  username: false,
  email: false,
  docNumber: false,
  code: false,
};

export const fieldsAvailablesInitialState = {
  username: true,
  email: true,
  docNumber: true,
  code: true,
};
