import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { FaCrown } from 'react-icons/fa';
import { toast } from 'react-toastify';

import api from '@/services/api';
import { UploadRequests } from '@/services/api/requests/Upload';
import DefaultAvatar from '@/assets/DefaultAvatar.svg';
import {
  formatDecimalPlacesIfCents,
  formatNumberToCurrency,
} from '@/utils/formatters';
import CustomAntButton from '@/components/CustomAntButton';
import { FiRefreshCw } from 'react-icons/fi';
import { IRouteParams } from '../types';

import {
  Container,
  InfluencersGamesAmountDetails,
  LoadingData,
  RankingPositionDetails,
  StatisticItem,
  StatisticItemsList,
  UserDetailContainer,
  UserDetailDataContainer,
  UsersList,
  UsersListPagination,
} from './styles';
import {
  ICardInfluencer,
  ICardInfluencersState,
  IRankingPosition,
  IRankingState,
  IStatisticsProps,
} from './types';

const Statistics: React.FC<IStatisticsProps> = ({ card, getCardDetails }) => {
  const params = useParams<IRouteParams>();
  const isFirstRender = useRef(true);

  const { percentages } = card;

  const homePercentage = percentages.home * 0.01;
  const influencerPercentage = percentages.influencer * 0.01;

  const totalAccumulatedValue = card.players * card.price;
  const homeValue = totalAccumulatedValue * homePercentage;
  const influencersValue = totalAccumulatedValue * influencerPercentage;
  const winnerValue = card.accumulated;

  const [isLoading, setIsLoading] = useState(true);
  const [cardInfluencers, setCardInfluencers] = useState<ICardInfluencersState>(
    {
      influencers: [],
      limit: 10,
      currentPage: 0,
      totalPages: 0,
      totalItems: 0,
      isLoading: false,
    } as ICardInfluencersState
  );
  const [ranking, setRanking] = useState<IRankingState>({
    positions: [],
    limit: 10,
    currentPage: 0,
    totalPages: 0,
    totalItems: 0,
    isLoading: false,
  } as IRankingState);

  const getCardInfluencers = useCallback(
    async (page = 1): Promise<void> => {
      try {
        setCardInfluencers((oldState) => ({
          ...oldState,
          isLoading: true,
        }));

        const { data } = await api.get<{
          docs: ICardInfluencer[];
          limit: number;
          total: number;
          page: number;
          pages: number;
        }>(`/api/athlete-card/${params.id}/influencer`, {
          params: {
            limit: cardInfluencers.limit,
            page,
          },
        });

        setCardInfluencers((oldState) => ({
          influencers: data.docs,
          limit: oldState.limit,
          currentPage: page,
          totalPages: data.pages,
          totalItems: data.total,
          isLoading: false,
        }));
      } catch (error) {
        toast.error(
          'Ocorreu um erro ao obter os influenciadores da cartela. Recarregue a página e tente novamente!'
        );
        setCardInfluencers((oldState) => ({
          ...oldState,
          isLoading: false,
        }));
      }
    },
    [cardInfluencers.limit, params.id]
  );

  const getRanking = useCallback(
    async (page = 1): Promise<void> => {
      setRanking((oldState) => ({
        ...oldState,
        isLoading: true,
      }));

      try {
        const { data } = await api.get<{
          docs: IRankingPosition[];
          limit: number;
          total: number;
          page: number;
          pages: number;
        }>('/api/ranking-athlete', {
          params: {
            card: params.id,
            limit: ranking.limit,
            page,
          },
        });

        setRanking((oldState) => ({
          positions: data.docs,
          limit: oldState.limit,
          currentPage: page,
          totalPages: data.pages,
          totalItems: data.total,
          isLoading: false,
        }));
      } catch (error) {
        toast.error(
          'Ocorreu um erro ao obter o ranking da cartela. Recarregue a página e tente novamente!'
        );
        setRanking((oldState) => ({
          ...oldState,
          isLoading: false,
        }));
      }
    },
    [params.id, ranking.limit]
  );

  const getData = useCallback(async () => {
    setIsLoading(true);
    try {
      const requests = [getCardDetails(), getCardInfluencers()];

      if (card.finished) {
        requests.push(getRanking());
      }

      await Promise.all(requests);
      setIsLoading(false);
    } catch (error) {
      toast.error('Aconteceu um erro inesperado! Tente novamente...');
    }
  }, [card.finished, getCardDetails, getCardInfluencers, getRanking]);

  const getInfluencerReceivingValue = (percentage: number): string => {
    const receivingValue = influencersValue * percentage;
    return formatNumberToCurrency(receivingValue);
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      getData();
    }
  }, [getData]);

  if (isLoading) {
    return <LoadingData />;
  }

  return (
    <Container>
      <CustomAntButton
        type="default"
        icon={<FiRefreshCw size={16} />}
        useCustomIconWithText
        onClick={() => getData()}
      >
        Atualizar
      </CustomAntButton>
      <div>
        <h6>Cartela</h6>
        <StatisticItemsList>
          <StatisticItem>
            <small>Total de jogos</small>
            <strong>{card.players}</strong>
          </StatisticItem>
        </StatisticItemsList>
      </div>
      <div>
        <h6>Valores</h6>
        <StatisticItemsList>
          <StatisticItem>
            <small>Acumulado total</small>
            <strong>{formatNumberToCurrency(totalAccumulatedValue)}</strong>
          </StatisticItem>
          <StatisticItem>
            <small>Casa ({percentages.home}%)</small>
            <strong>{formatNumberToCurrency(homeValue)}</strong>
          </StatisticItem>
          <StatisticItem>
            <small>Influenciador(es) ({percentages.influencer}%)</small>
            <strong>{formatNumberToCurrency(influencersValue)}</strong>
          </StatisticItem>
          <StatisticItem>
            <small>
              Vencedor(es){!card.fixedAmount && ` (${percentages.winner}%)`}
            </small>
            <strong>{formatNumberToCurrency(winnerValue)}</strong>
          </StatisticItem>
        </StatisticItemsList>
      </div>
      {cardInfluencers.influencers.length > 0 && (
        <div>
          <h6>Influenciador(es)</h6>
          <UsersList>
            {cardInfluencers.influencers.map(
              ({ _user: influencer, ...cardInfluencer }) => (
                <UserDetailContainer
                  $type="influencer"
                  key={cardInfluencer._id}
                >
                  <img
                    src={
                      influencer.photo
                        ? UploadRequests.getFileUrl(influencer.photo)
                        : DefaultAvatar
                    }
                    alt={influencer.name}
                  />
                  <UserDetailDataContainer>
                    <p>{influencer.name}</p>
                    <small>{influencer.username}</small>
                    <small>cod.: {influencer.code}</small>
                  </UserDetailDataContainer>
                  <InfluencersGamesAmountDetails>
                    {cardInfluencer.count} jogo{cardInfluencer.count > 1 && 's'}
                    <span>
                      {formatDecimalPlacesIfCents(
                        (cardInfluencer.count / card.players) * 100
                      )}
                      %
                    </span>
                    <span>
                      {getInfluencerReceivingValue(
                        cardInfluencer.count / card.players
                      )}
                    </span>
                  </InfluencersGamesAmountDetails>
                </UserDetailContainer>
              )
            )}
            <UsersListPagination
              current={cardInfluencers.currentPage}
              onChange={(page) => getCardInfluencers(page)}
              total={cardInfluencers.totalItems}
              pageSize={cardInfluencers.limit}
              disabled={cardInfluencers.isLoading}
              hideOnSinglePage
            />
          </UsersList>
        </div>
      )}
      {card.finished && !!ranking.positions.length && (
        <div>
          <h6>Ranking</h6>
          <UsersList>
            {ranking.positions.map(({ _user: user, ...pos }) => (
              <UserDetailContainer $type="user" key={pos._id}>
                <img
                  src={
                    user.photo
                      ? UploadRequests.getFileUrl(user.photo._id)
                      : DefaultAvatar
                  }
                  alt={user.name}
                />
                <UserDetailDataContainer>
                  <p>{user.name}</p>
                  <small>{user.username}</small>
                </UserDetailDataContainer>
                <RankingPositionDetails>
                  <p>
                    {pos.position === 1 && <FaCrown size={14} />}
                    {pos.position}˚ lugar
                  </p>
                  <small>
                    {pos.totalScore} ponto{pos.totalScore > 1 && 's'}
                  </small>
                  <small>{formatNumberToCurrency(pos.prize)}</small>
                </RankingPositionDetails>
              </UserDetailContainer>
            ))}
            <UsersListPagination
              current={ranking.currentPage}
              onChange={(page) => getRanking(page)}
              total={ranking.totalItems}
              pageSize={ranking.limit}
              disabled={ranking.isLoading}
              hideOnSinglePage
            />
          </UsersList>
        </div>
      )}
    </Container>
  );
};

export default Statistics;
