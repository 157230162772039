import React from 'react';
import { Modal } from 'antd';
import * as dateFns from 'date-fns';
import ptLocale from 'date-fns/locale/pt-BR';
import { IAdStatisticsModalProps } from './types';

import { RelayDetails } from './styles';

const AdStatisticsModal: React.FC<IAdStatisticsModalProps> = ({
  visible,
  handleClose,
  targets = [],
}) => {
  return (
    <Modal
      title="Estatísticas do anúncio"
      visible={visible}
      onCancel={handleClose}
      okButtonProps={{
        style: {
          display: 'none',
        },
      }}
      cancelText="Voltar"
    >
      {targets.map((target) => (
        <RelayDetails>
          <small>
            {dateFns.format(
              dateFns.parseISO(target.createdAt),
              "'Transmissão realizada em' dd/MM/yyyy 'às' HH:mm:ss",
              {
                locale: ptLocale,
              }
            )}
          </small>

          <div>
            <small>Total de jogadores</small>
            <p>{target.playersTotal}</p>
          </div>
          <div>
            <small>Jogadores online</small>
            <p>{target.onlineTotal}</p>
          </div>
        </RelayDetails>
      ))}
    </Modal>
  );
};

export default AdStatisticsModal;
