import React, { useState } from 'react';

import { Tabs } from 'antd';
import TabPane from 'antd/lib/tabs/TabPane';
import Breadcrumbs from '@/components/Breadcrumbs';
import { Container, TabsWrapper, TitlePageContainer } from './styles';
import { TabKeyEnum } from './types';
import AthleteCardsTabContent from './AthleteCardsTabContent';

const ManageGamesPayment: React.FC = () => {
  const [currentView, setCurrentView] = useState<TabKeyEnum>(
    TabKeyEnum.ATHLETE_CARDS
  );

  return (
    <Container>
      <Breadcrumbs />
      <TitlePageContainer>
        <h4>Pagamentos dos jogos</h4>
      </TitlePageContainer>
      <TabsWrapper>
        <Tabs
          activeKey={currentView}
          onTabClick={(tabKey) => {
            setCurrentView(tabKey as TabKeyEnum);
          }}
          centered
        >
          <TabPane tab="Cartelas de artilheiros" key={TabKeyEnum.ATHLETE_CARDS}>
            <AthleteCardsTabContent />
          </TabPane>
        </Tabs>
      </TabsWrapper>
    </Container>
  );
};

export default ManageGamesPayment;
