import React, { useCallback, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { toast } from 'react-toastify';

import { InfluencerRequests } from '@/services/api/requests/Influencer';
import { UploadRequests } from '@/services/api/requests/Upload';
import DefaultAvatar from '@/assets/DefaultAvatar.svg';

import CustomAntButton from '@/components/CustomAntButton';
import { FiTrash2 } from 'react-icons/fi';
import {
  Container,
  AutoComplete,
  SearchAutocompleteContentContainer,
  LoadingInfluencer,
  SearchInfluencerItem,
  ViewMoreButton,
  SelectedInfluencer,
  SelectedInfluencersContainer,
} from './styles';
import { IInfluencersProps, ISearchResultState } from './types';
import { searchResultInitialData } from './constants';

const Influencers: React.FC<IInfluencersProps> = ({
  formik,
  cardPaymentPercentage,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [searchResult, setSearchResult] = useState<ISearchResultState>(
    searchResultInitialData
  );

  const searchInfluencer = useCallback(
    async (search: string, page = 1): Promise<void> => {
      setIsSearching(true);
      try {
        const { data } = await InfluencerRequests.getInfluencers({
          search,
          page,
          limit: searchResult.limit,
        });

        setSearchResult((oldState) => ({
          limit: data.limit,
          currentPage: data.page,
          totalPages: data.pages,
          influencers:
            data.page === 1
              ? data.docs
              : [...oldState.influencers, ...data.docs],
        }));
      } catch (error) {
        toast.error('Aconteceu um erro inesperado! Tente novamente...');
      }
      setIsSearching(false);
    },
    [searchResult.limit]
  );

  const searchDebounced = useMemo(() => {
    return debounce(searchInfluencer, 500);
  }, [searchInfluencer]);

  const handleSearchInfluencer = useCallback(
    (value: string) => {
      searchDebounced.cancel();

      setSearchValue(value);

      if (value.length >= 3) {
        setIsSearching(true);
        searchDebounced(value);
      } else {
        setIsSearching(false);
        setSearchResult(searchResultInitialData);
      }
    },
    [searchDebounced]
  );

  const handleCardInfluencerPercent = useCallback(
    ({ hasInfluencers }: { hasInfluencers: boolean }) => {
      if (hasInfluencers) {
        formik.setFieldValue(
          'cardInformation.percentages.influencerPercent',
          cardPaymentPercentage.influencer.toString()
        );
      } else {
        formik.setFieldValue(
          'cardInformation.percentages.influencerPercent',
          '0'
        );
      }
    },
    [cardPaymentPercentage.influencer, formik]
  );

  const handleSelectInfluencer = useCallback(
    (influencerId: string) => {
      const { influencers } = formik.values.cardInformation;

      const selectedInfluencer = searchResult.influencers.find(
        (influencer) => influencer._id === influencerId
      );
      const isInfluencerAlreadySelected = influencers.some(
        (influencer) => influencer._id === influencerId
      );

      if (!isInfluencerAlreadySelected) {
        const isFirstInfluencerSelected = !influencers.length;
        if (isFirstInfluencerSelected) {
          handleCardInfluencerPercent({ hasInfluencers: true });
        }

        formik.setFieldValue('cardInformation.influencers', [
          ...influencers,
          selectedInfluencer,
        ]);
        handleSearchInfluencer('');
      }
    },
    [
      formik,
      handleCardInfluencerPercent,
      handleSearchInfluencer,
      searchResult.influencers,
    ]
  );

  const handleRemoveInfluencer = useCallback(
    (influencerId: string) => {
      const { influencers } = formik.values.cardInformation;

      const formInfluencers = influencers.filter(
        (influencer) => influencer._id !== influencerId
      );

      formik.setFieldValue('cardInformation.influencers', formInfluencers);

      const areAllInfluencerRemoved = !formInfluencers.length;
      if (areAllInfluencerRemoved) {
        handleCardInfluencerPercent({ hasInfluencers: false });
      }
    },
    [formik, handleCardInfluencerPercent]
  );

  const autoCompleteContent = useMemo(() => {
    const isMinSearchValueInvalid = searchValue.length < 3;
    const { influencers } = searchResult;

    if (isMinSearchValueInvalid || (isSearching && influencers.length === 0)) {
      return (
        <AutoComplete.Option value="" disabled>
          <SearchAutocompleteContentContainer>
            {isMinSearchValueInvalid && (
              <p>Digite acima de 3 caracteres para realizar a busca</p>
            )}
            {isSearching && <LoadingInfluencer />}
          </SearchAutocompleteContentContainer>
        </AutoComplete.Option>
      );
    }

    if (influencers.length > 0) {
      return (
        <>
          {influencers.map((influencer) => (
            <AutoComplete.Option value={influencer._id} key={influencer._id}>
              <SearchInfluencerItem>
                <img
                  src={
                    influencer.photo?.filename
                      ? UploadRequests.getFileUrl(influencer.photo?.filename)
                      : DefaultAvatar
                  }
                  alt={influencer.name}
                />
                <div>
                  <p>{influencer.name || '---'}</p>
                  <small>{influencer.username || '---'}</small>
                </div>
              </SearchInfluencerItem>
            </AutoComplete.Option>
          ))}
          {searchResult.currentPage < searchResult.totalPages && (
            <AutoComplete.Option value="" disabled>
              <ViewMoreButton
                disabled={isSearching}
                onClick={() =>
                  searchInfluencer(searchValue, searchResult.currentPage + 1)
                }
              >
                {!isSearching ? 'Ver mais' : <LoadingInfluencer />}
              </ViewMoreButton>
            </AutoComplete.Option>
          )}
        </>
      );
    }

    return <></>;
  }, [isSearching, searchInfluencer, searchResult, searchValue]);

  return (
    <Container>
      <AutoComplete
        placeholder="Pesquisar influenciador"
        onSearch={handleSearchInfluencer}
        onSelect={(value) => {
          handleSelectInfluencer(value as string);
        }}
        value={searchValue}
        notFoundContent="Influenciador não encontrado"
      >
        {autoCompleteContent}
      </AutoComplete>
      <SelectedInfluencersContainer>
        {formik.values.cardInformation.influencers.map((influencer) => (
          <SelectedInfluencer key={influencer._id}>
            <img
              src={
                influencer.photo?.filename
                  ? UploadRequests.getFileUrl(influencer.photo?.filename)
                  : DefaultAvatar
              }
              alt={influencer.name}
            />
            <div>
              <p>{influencer.name || '---'}</p>
              <small>{influencer.username || '---'}</small>
            </div>
            <CustomAntButton
              type="text"
              danger
              icon={<FiTrash2 height={18} />}
              onClick={() => handleRemoveInfluencer(influencer._id)}
            />
          </SelectedInfluencer>
        ))}
      </SelectedInfluencersContainer>
    </Container>
  );
};

export default Influencers;
