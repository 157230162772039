import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Button, Modal, Spin } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import Steps from '@/components/Steps';
import Breadcrumbs from '@/components/Breadcrumbs';

import { getYouTubeVideoId } from '@/utils/validate';
import api from '@/services/api';
import { UploadRequests } from '@/services/api/requests/Upload';

import AdsDetails from './AdsDetails';
import Banner from './Banner';
import Confirm from './Confirm';
import { IAdsFormData, IRouteParams } from './types';
import {
  Container,
  TitlePageContainer,
  Content,
  LoadingDescription,
} from './styles';
import { AdsTypeEnum, ICardGameAd } from '../types';

const { confirm } = Modal;

const SetCardAds: React.FC = () => {
  const history = useHistory();
  const params = useParams<IRouteParams>();

  const isFirstRender = useRef(true);
  const formikRef = useRef<FormikProps<IAdsFormData>>(null);
  const newAdsInitialFormData = useRef<IAdsFormData>({
    adsDetails: {
      type: undefined as unknown as AdsTypeEnum,
      title: '',
      description: '',
      isTemporary: true,
      url: '',
    },
    photo: {
      file: undefined,
      previewUrl: '',
    },
  } as IAdsFormData);
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoadingAd, setIsLoadingAd] = useState(true);
  const [adPhotoId, setAdPhotoId] = useState<string | undefined>(undefined);

  const getAd = useCallback(async () => {
    try {
      const { data } = await api.get<ICardGameAd>(
        `/api/athlete-card/${params.id}/ad/${params.ad_id}`
      );

      newAdsInitialFormData.current = {
        adsDetails: {
          type: data.type,
          title: data.title,
          description: data.text,
          isTemporary: data.isTemporary,
          url: data.url,
        },
        photo: {
          file: undefined,
          previewUrl:
            data.type === AdsTypeEnum.IMAGE
              ? UploadRequests.getFileUrl(data._upload?._id as string)
              : '',
        },
      } as IAdsFormData;
      if (data.type === AdsTypeEnum.IMAGE) {
        setAdPhotoId(data._upload?._id);
      }
      setIsLoadingAd(false);
      formikRef.current?.setFieldTouched('adsDetails');
    } catch (error) {
      setIsLoadingAd(false);
    }
    return '';
  }, [params.ad_id, params.id]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;

      if (params.ad_id) {
        getAd();
      } else {
        setIsLoadingAd(false);
      }
    }
  }, [getAd, params.ad_id]);

  const handleToggleCurrentStep = useCallback((newCurrentStep: number) => {
    setCurrentStep(() => {
      if (newCurrentStep < 0) {
        return 0;
      }
      return newCurrentStep;
    });
  }, []);

  const handleSubmit = useCallback(
    async (values: IAdsFormData, actions: FormikHelpers<IAdsFormData>) => {
      await new Promise((resolve) => {
        confirm({
          title: 'Deseja realmente salvar esse anúncio?',
          icon: <ExclamationCircleOutlined />,
          cancelText: 'Cancelar',
          okText: 'Salvar anúncio',
          onOk() {
            resolve(true);
          },
          onCancel() {
            actions.setSubmitting(false);
          },
        });
      });

      const { adsDetails, photo } = values;

      let photoId = adPhotoId || null;
      if (adsDetails.type === AdsTypeEnum.IMAGE && photo.file) {
        try {
          const { data } = await UploadRequests.upload({
            file: photo.file,
            from: 'athletesCardAds',
          });
          photoId = data._id;
        } catch (error) {
          toast.error(
            'Aconteceu um erro inesperado ao enviar o banner do anúncio!'
          );
          return;
        }
      }
      const body = {
        type: adsDetails.type,
        title: adsDetails.title,
        text: adsDetails.description,
        urlOption: 'url',
        url: adsDetails.url,
        isTemporary: adsDetails.isTemporary,
        _upload: photoId,
      };

      try {
        if (!params.ad_id) {
          await api.post(`/api/athlete-card/${params.id}/ad`, body);
        } else {
          await api.put(
            `/api/athlete-card/${params.id}/ad/${params.ad_id}`,
            body
          );
        }
        toast.success('Anúncio salvo com sucesso!');
        history.push(`/cards/${params.id}`);
      } catch (error) {
        toast.error(
          'Aconteceu um erro inesperado... Tente novamente mais tarde'
        );
      }
    },
    [adPhotoId, history, params.ad_id, params.id]
  );

  if (isLoadingAd) {
    return (
      <Container>
        <Breadcrumbs />
        <TitlePageContainer>
          <h4>{!params.ad_id ? 'Novo' : 'Editar'} anúncio</h4>
          <Button
            onClick={() => history.push(`/cards/${params.id}`)}
            danger
            type="text"
          >
            Cancelar
          </Button>
        </TitlePageContainer>
        <LoadingDescription>
          <Spin />
          <p>Carregando...</p>
        </LoadingDescription>
      </Container>
    );
  }

  return (
    <Container>
      <Breadcrumbs />
      <TitlePageContainer>
        <h4>{!params.ad_id ? 'Novo' : 'Editar'} anúncio</h4>
        <Button
          onClick={() => history.push(`/cards/${params.id}`)}
          danger
          type="text"
        >
          Cancelar
        </Button>
      </TitlePageContainer>
      <Content>
        <Steps
          current={currentStep}
          stepsList={[
            { title: 'Detalhes do anúncio' },
            { title: 'Banner' },
            { title: 'Confirmar' },
          ]}
        />
        <Formik
          innerRef={formikRef}
          initialValues={newAdsInitialFormData.current}
          validationSchema={Yup.object().shape({
            adsDetails: Yup.object().shape({
              type: Yup.string().required('O tipo do anúncio é obrigatório'),
              title: Yup.string().required('O título do anúncio é obrigatório'),
              description: Yup.string().required(
                'A descrição do anúncio é obrigatória'
              ),
              url: Yup.string()
                .url('Essa não é uma URL válida')
                .required('A URL é obrigatória')
                .test(
                  'urlInvalid',
                  'A URL inserida não pertence a um vídeo do Youtube',
                  function validate(value) {
                    const formikProps = formikRef.current;

                    if (
                      formikProps?.values.adsDetails.type === AdsTypeEnum.IMAGE
                    ) {
                      return true;
                    }

                    const isValidYoutubeVideo = !!getYouTubeVideoId(value);
                    return isValidYoutubeVideo;
                  }
                ),
            }),
          })}
          onSubmit={handleSubmit}
          validateOnMount
        >
          {(formikProps) => (
            <form onSubmit={formikProps.handleSubmit}>
              {currentStep === 0 && (
                <AdsDetails
                  formik={formikProps}
                  nextStep={() => handleToggleCurrentStep(1)}
                />
              )}
              {currentStep === 1 && (
                <Banner
                  formik={formikProps}
                  prevStep={() => handleToggleCurrentStep(0)}
                  nextStep={() => handleToggleCurrentStep(2)}
                />
              )}
              {currentStep === 2 && (
                <Confirm
                  formik={formikProps}
                  prevStep={() => handleToggleCurrentStep(1)}
                  handleSubmit={() => formikProps.handleSubmit()}
                />
              )}
            </form>
          )}
        </Formik>
      </Content>
    </Container>
  );
};

export default SetCardAds;
