import React, { useState, useCallback, useEffect } from 'react';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import * as _ from 'lodash';

import Switch from '@/components/Switch';
import CustomAntButton from '@/components/CustomAntButton';

import api from '@/services/api';

import Scoreboard from './Scoreboard';
import Throws from './Throws';
import Statistics from './Statistics';

import { Container } from './styles';

const { confirm } = Modal;

function getFinishButtonName(gameIsFinished) {
  return gameIsFinished ? 'Finalizar partida' : 'Atualizar dados da partida';
}

function Docket({ selectedMatchDetails, handleGetSelectedMatchData }) {
  const [gameToBeFinished, setGameToBeFinished] = useState({
    localScore: 0,
    awayScore: 0,
    redCards: 0,
    yellowCards: 0,
    cornerNumber: 0,
    offsideNumber: 0,
    localPlayers: [],
    awayPlayers: [],
    finished: false,
  });

  const [
    loadingSendGameToBeFinished,
    setLoadingSendGameToBeFinished,
  ] = useState(false);

  useEffect(() => {
    if (!selectedMatchDetails) {
      setGameToBeFinished({
        localScore: 0,
        awayScore: 0,
        redCards: 0,
        yellowCards: 0,
        cornerNumber: 0,
        offsideNumber: 0,
        localPlayers: [],
        awayPlayers: [],
        finished: false,
      });
      return;
    }

    setGameToBeFinished({
      localScore: selectedMatchDetails.localScore || 0,
      awayScore: selectedMatchDetails.awayScore || 0,
      redCards: selectedMatchDetails.redCards || 0,
      yellowCards: selectedMatchDetails.yellowCards || 0,
      cornerNumber: selectedMatchDetails.cornerNumber || 0,
      offsideNumber: selectedMatchDetails.offsideNumber || 0,
      localPlayers: selectedMatchDetails.localPlayers.map(localPlayer => ({
        tempId: localPlayer._id,
        player: localPlayer.player,
        playerName: localPlayer.playerName,
        goalTime: localPlayer.goalTime,
      })),
      awayPlayers: selectedMatchDetails.awayPlayers.map(awayPlayer => ({
        tempId: awayPlayer._id,
        player: awayPlayer.player,
        playerName: awayPlayer.playerName,
        goalTime: awayPlayer.goalTime,
      })),
      finished: selectedMatchDetails.finished || false,
    });
  }, [selectedMatchDetails]);

  const handleChangeScoreOfTeam = useCallback((team, score) => {
    if (score < 0) {
      return;
    }

    setGameToBeFinished(oldGame => {
      const updatedGame = { ...oldGame };

      if (team === 'local') {
        updatedGame.localScore = score;

        if (oldGame.localScore < updatedGame.localScore) {
          updatedGame.localPlayers.push({
            tempId: Date.now(),
            player: '',
            playerName: '',
            goalTime: 0,
          });
        } else {
          updatedGame.localPlayers.pop();
        }
      } else if (team === 'away') {
        updatedGame.awayScore = score;

        if (oldGame.awayScore < updatedGame.awayScore) {
          updatedGame.awayPlayers.push({
            tempId: Date.now(),
            player: '',
            playerName: '',
            goalTime: 0,
          });
        } else {
          updatedGame.awayPlayers.pop();
        }
      }

      return updatedGame;
    });
  }, []);

  const handleSelectPlayerAndTimeOfThrow = useCallback(
    (typeOfPlayers, throwPlayerAndTime) => {
      /* tempId, playerId, playerName */

      setGameToBeFinished(oldGame => {
        const updatedGame = { ...oldGame };

        updatedGame[typeOfPlayers] = updatedGame[typeOfPlayers].map(
          addedPlayer => {
            if (addedPlayer.tempId === throwPlayerAndTime.tempId) {
              return {
                ...addedPlayer,
                player: throwPlayerAndTime.player,
                playerName: throwPlayerAndTime.playerName,
                goalTime: throwPlayerAndTime.goalTime,
              };
            }

            return addedPlayer;
          }
        );

        return updatedGame;
      });
    },
    []
  );

  const handleChangeQuantityOfStatistic = useCallback((type, quantity) => {
    if (quantity < 0) {
      return;
    }

    setGameToBeFinished(oldGame => {
      const updatedGame = { ...oldGame };
      updatedGame[type] = quantity;
      return updatedGame;
    });
  }, []);

  const handleSubmit = useCallback(async () => {
    try {
      const body = {
        ...gameToBeFinished,
      };

      [...body.localPlayers, ...body.awayPlayers].forEach(throwOfTeam => {
        if (!_.every(throwOfTeam, propertyValue => !!propertyValue)) {
          toast.warn(
            'Selecione os jogadores de todos os lances antes de continuar'
          );
          throw new Error('ERROR_FOR_VALIDATION');
        }
      });

      const titleModal = body.finished
        ? 'Deseja realmente finalizar essa partida?'
        : 'Deseja realmente atualizar os dados dessa partida?';

      const okButtonTextModal = body.finished ? 'Finalizar' : 'Atualizar';

      const successText = body.finished
        ? 'Partida finalizada com sucesso'
        : 'Dados da partida atualizados com sucesso';

      await new Promise(resolve => {
        confirm({
          title: titleModal,
          icon: <ExclamationCircleOutlined />,
          content: 'Os dados inseridos anteriormente serão substituídos',
          cancelText: 'Cancelar',
          okText: okButtonTextModal,
          onOk() {
            resolve(true);
          },
        });
      });

      setLoadingSendGameToBeFinished(true);
      await api.put(
        `/api/championship/${selectedMatchDetails._champ}/end-game/${selectedMatchDetails._id}`,
        body
      );

      toast.success(successText);

      handleGetSelectedMatchData(null);
      setLoadingSendGameToBeFinished(false);
    } catch (error) {
      if (error.message && error.message !== 'ERROR_FOR_VALIDATION') {
        toast.error(
          'Ocorreu um erro ao adicionar os dados dessa partida. Recarregue a página e tente novamente!'
        );
      }
      setLoadingSendGameToBeFinished(false);
    }
  }, [gameToBeFinished, handleGetSelectedMatchData, selectedMatchDetails]);

  return (
    <Container>
      <Scoreboard
        match={selectedMatchDetails}
        gameToBeFinished={gameToBeFinished}
        handleChangeScoreOfTeam={handleChangeScoreOfTeam}
      />
      <Throws
        match={selectedMatchDetails}
        gameToBeFinished={gameToBeFinished}
        handleSelectPlayerAndTimeOfThrow={handleSelectPlayerAndTimeOfThrow}
      />
      <Statistics
        match={selectedMatchDetails}
        gameToBeFinished={gameToBeFinished}
        handleChangeQuantityOfStatistic={handleChangeQuantityOfStatistic}
      />
      <div>
        <Switch
          label="Jogo finalizado"
          checked={gameToBeFinished.finished}
          onChange={checked =>
            setGameToBeFinished(oldGameToBeFinished => ({
              ...oldGameToBeFinished,
              finished: checked,
            }))
          }
          size="small"
        />
      </div>
      <CustomAntButton
        type="primary"
        onClick={handleSubmit}
        disabled={loadingSendGameToBeFinished}
      >
        {!loadingSendGameToBeFinished
          ? getFinishButtonName(gameToBeFinished.finished)
          : 'Carregando...'}
      </CustomAntButton>
    </Container>
  );
}

export default Docket;
