import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  > div {
    width: 100%;
    max-width: 424px;
    margin-bottom: 32px;
  }
`;

export const SessionHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  /* AntDesign Button with Icon */
  button {
    line-height: 0;

    &.ant-btn-text {
      color: ${props => props.theme.colors.primary.primary0};

      &:hover,
      &:focus,
      &:active {
        color: ${props => props.theme.colors.primary['primary-1']};
      }
    }
  }
`;

export const SessionItems = styled.ul`
  width: 100%;

  li {
    list-style: none;
    width: 100%;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0px;
    }

    small {
      color: ${props => props.theme.colors.primary.primary0};
    }
  }
`;

export const AdvertisementItemContainer = styled.div`
  width: 100%;
  /* padding-top: 56.25%; */
  position: relative;
  aspect-ratio: 21 / 9;
  margin-bottom: 8px;
  /* background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.6) 56.25%,
      #000000 100%
    ),
    url(${props => props.$bg}) no-repeat center;
  background-size: cover; */
  /* display: flex; */
  /* flex-direction: column; */

  img {
    position: absolute;
    z-index: 5;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  div.shimmer {
    position: absolute !important;
    z-index: 5 !important;
    width: 100% !important;
    height: 100% !important;
    border: 1px solid ${props =>
      props.theme.colors.light['light-2']} !important;
  }
`;

export const AdvertisementItemContent = styled.div`
/* width: 100%; */
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  /* flex: 1; */
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: default;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.6) 56.25%,
    #000000 100%
  );

  > button {
    align-self: flex-end;
  }

  ul {
    width: 100%;
    cursor: default;
    margin-bottom: 0px;

    li {
      list-style: none;
      width: 100%;
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0px;
      }

      small {
        color: ${props => props.theme.colors.primary['primary+1']};
      }

      p {
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
        color: ${props => props.theme.colors.light.light0};
      }
    }

    /* p {
      margin-bottom: 8px;
      color: ${props => props.theme.colors.light.light0};

      &:last-child {
        margin-bottom: 0px;
      }
    } */
  }
`;
