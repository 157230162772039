import styled, { css } from 'styled-components';
import { shade } from 'polished';

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  > ul {
    width: 100%;
    margin-top: 16px;
  }
`;

export const SearchContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;

  /* AntD Button Changes */
  button {
    margin-left: 16px;

    @media only screen and (max-width: 600px) {
      display: none;
    }
  }
`;

export const NamePageForIndicator = styled.span`
  color: ${props => props.theme.colors.primary['primary-1']};
`;

export const TeamsListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: space-between;

  h5,
  h6,
  div.ant-spin {
    text-align: center;
    margin: auto 0;
  }

  > h6 {
    font-weight: ${props => props.theme.typograph.weights.medium};
  }
`;

export const TeamsList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 16px;
`;

export const TeamDetailContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 8px 0px;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.light['light-2']};
  cursor: pointer;
  transition: 0.2s background-color;

  &:hover {
    background-color: ${props => props.theme.colors.light['light-2']};
  }

  &:last-child {
    border-bottom: none;
  }

  div {
    width: 57px;
    height: 57px;
    background-color: ${props => props.theme.colors.light['light-2']};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;

    img {
      height: 37px;
    }
  }
`;

export const TeamSelected = styled.button`
  border: 0;

  width: 100%;
  height: 69px;
  background: ${props => props.theme.colors.light.light0};
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);

  ${props =>
    props.disabled &&
    css`
      opacity: 0.6;
    `}

  /* For loadmore button */
  > p {
    text-align: center;
    width: 100%;
  }

  & + button {
    margin-top: 8px;
  }

  img {
    width: 33px;
  }

  h6 {
    color: ${props => props.theme.colors.dark.dark0};
    margin-left: 16px;
  }

  svg {
    margin-left: auto;
    color: ${props => props.theme.colors.dark.dark0};
  }
`;

export const TeamPlayer = styled.button`
  border: 0;
  background: ${props => props.theme.colors.light['light-1']};
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px 0;
  text-align: start;
  transition: 0.2s background;
  cursor: pointer;

  ${props =>
    props.disabled &&
    css`
      opacity: 0.8;
      cursor: not-allowed;
    `}

  &:hover {
    background: ${props => shade(0.1, props.theme.colors.light['light-1'])};
  }

  & + button {
    border-top: 1px solid ${props => props.theme.colors.light['light-2']};
  }

  > div:nth-child(1) {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid ${props => props.theme.colors.dark['dark+4']};
    background: ${props => props.theme.colors.light['light-2']};
    transition: 0.2s opacity;
    margin-right: 16px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  div:nth-child(2) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    small {
      overflow: inherit;
      text-overflow: inherit;
      color: ${props => props.theme.colors.dark['dark+2']};
    }
  }
`;

export const SelectedAthletesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 32px;
  margin-top: 16px;
`;

export const SelectedAthlete = styled.button`
  border: 0;
  width: 23.5%;
  margin-right: 1.5%;
  margin-bottom: 8px;
  background: ${props => props.theme.colors.light.light0};
  border-radius: 8px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px 6px rgb(0 0 0 / 8%);
  position: relative;

  > img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 8px;
  }

  > p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  svg {
    position: absolute;
    top: -4px;
    right: -4px;
    color: ${props => props.theme.colors.dark['dark+2']};
  }

  @media only screen and (max-width: 768px) {
    width: 48.5%;
  }
`;
