import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
`;

export const LoadMoreButton = styled.button`
  border: 0;

  width: 100%;
  height: 69px;
  background: ${props => props.theme.colors.light.light0};
  box-shadow: 1px 1px 3px 3px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  margin-top: 16px;

  ${props =>
    props.disabled &&
    css`
      opacity: 0.6;
    `}

  > p {
    text-align: center;
    width: 100%;
  }
`;
