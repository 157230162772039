import styled, { css } from 'styled-components';

interface IImageWrapperProps {
  $clickEnabled: boolean;
}

export const Container = styled.div`
  flex: 1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.header`
  width: 100%;
  background-color: ${(props) => props.theme.colors.light['light-2']};
  height: 12.125rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.dark['dark+4']};
  padding: 2rem 3.5rem 1rem 3.5rem;
  display: flex;
  flex-direction: column;

  > div:nth-child(2) {
    display: flex;
    margin-top: 1.5rem;
    flex: 1;

    > button {
      display: none;
    }
  }

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    padding: 1rem 2rem;
  }

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    padding: 1rem;
    height: 17.75rem;

    > div:nth-child(2) {
      flex-direction: column;
      position: relative;

      > button {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
`;

export const ImageWrapper = styled.div<IImageWrapperProps>`
  width: 9.375rem;
  position: relative;

  img {
    width: 9.375rem;
    height: 9.375rem;
    position: absolute;
    border-radius: 50%;
    border: 1px solid ${(props) => props.theme.colors.dark['dark+4']};

    ${(props) =>
      props.$clickEnabled &&
      css`
        cursor: pointer;

        &:hover {
          opacity: 0.95;
        }
      `}
  }

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    width: 6.875rem;
    height: 6.875rem;
    align-self: center;

    img {
      width: 6.875rem;
      height: 6.875rem;
    }
  }
`;

export const UserInformationHeader = styled.div`
  display: flex;
  flex: 1;
  margin-left: 2.5rem;
  align-items: flex-end;

  div {
    display: flex;
    flex-direction: column;

    div {
      display: flex;
      width: 100%;
      flex-direction: row;
      width: 100%;
      align-items: center;

      @media only screen and (max-width: ${(props) =>
          props.theme.breakpoints.sm}) {
        flex-direction: column;
        h5 {
          margin-right: 0;
        }
      }

      h5 {
        margin-bottom: 0;
        margin-right: 1rem;
      }

      span {
        font-size: ${(props) => props.theme.typograph.sizes.small}px;
        font-weight: ${(props) => props.theme.typograph.weights.medium};
        color: ${(props) => props.theme.colors.danger};

        &::before {
          content: '';
          width: 0.5rem;
          height: 0.5rem;
          background-color: ${(props) => props.theme.colors.danger};
          border-radius: 4px;
          margin-right: 0.5rem;
          display: inline-block;
        }
      }
    }

    p {
      color: ${(props) => props.theme.colors.dark['dark+2']};
    }

    p:last-child {
      margin-top: 1rem;
    }
  }

  button {
    margin-left: auto;
  }

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin: 0 auto !important;
    text-align: center;

    button {
      display: none;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  margin-top: 5rem;
  margin-bottom: 2.5rem;
  padding: 0 3.5rem;

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-top: 3.5rem;
    padding: 0 2rem;
  }
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    margin-top: 2rem;
    padding: 0 1rem;
  }
`;

export const SessionItems = styled.ul`
  width: 100%;
  margin-top: 1rem;

  li {
    list-style: none;
    width: 100%;
    margin-bottom: 0.5rem;

    &:last-child {
      margin-bottom: 0;
    }

    small {
      color: ${(props) => props.theme.colors.primary.primary0};
    }
  }
`;

export const UserNotFoundContainer = styled.div`
  padding: 2rem 3.5rem;
  flex: 1;
  display: flex;
  flex-direction: column;

  > div:nth-child(2) {
    width: 100%;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    img {
      width: 18.75rem;
    }

    h6 {
      margin-top: 1.5rem;
      text-align: center;
    }
  }
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    padding: 1rem 2rem;
  }
  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    padding: 1rem 1rem;
  }
`;

export const ActionButtonAvatar = styled.div`
  width: 100%;
  display: flex;
  padding: 1rem;
  transition: background-color 0.2s;
  cursor: pointer;

  color: ${(props) => props.theme.colors.dark.dark0};

  span {
    font-size: 1rem;
    margin-left: 1rem;
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.light['light-1']};
  }
`;
