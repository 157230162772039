import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { Button } from 'antd';
import { MdEdit } from 'react-icons/md';

import { Container, SessionHeader, SessionItems, CardGameItem } from './styles';

function Details({ championship }) {
  const { url } = useRouteMatch();

  return (
    <Container>
      <div>
        <SessionHeader>
          <h6>Informações do campeonato</h6>
          <Link to={`${url}/edit_championship_informations`}>
            <Button type="text" icon={<MdEdit height={18} />} />
          </Link>
        </SessionHeader>
        <SessionItems>
          <li>
            <small>Nome do campeonato</small>
            <p>{championship?.name}</p>
          </li>
          <li>
            <small>Peso</small>
            <p>{championship?.weight}</p>
          </li>
        </SessionItems>
      </div>
      <CardGameItem to={`${url}/cards`}>
        <div>
          {/* <AdminIcon /> */}
          <h5>Cartelas</h5>
        </div>
        <p>Gerencie as cartelas desse campeonato</p>
      </CardGameItem>
    </Container>
  );
}

export default Details;
