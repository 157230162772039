import styled from 'styled-components';
import { FaUserCog } from 'react-icons/fa';
import { RiAdvertisementFill } from 'react-icons/ri';
import { BiSelectMultiple, BiTransferAlt } from 'react-icons/bi';
import { darken } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
  padding: 56px 32px;

  @media only screen and (max-width: 600px) {
    padding: 32px 16px;
    min-height: calc(100vh - 61px);
  }
`;

export const TitlePageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: center;

  /* AntD Button Changes */
  button {
    margin-left: 16px;

    @media only screen and (min-width: 601px) {
      display: none;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 40px;

  @media only screen and (max-width: 768px) {
    margin-top: 16px;
  }
`;

export const SettingItem = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.light.light0};
  height: 158px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  justify-content: space-between;
  cursor: pointer;
  transition: 0.2s all;
  margin-bottom: 16px;

  &:hover {
    background-color: ${(props) =>
      darken(0.02, props.theme.colors.light.light0)};
  }

  @media only screen and (min-width: 1200px) {
    width: 49%;
  }

  div {
    width: 100%;
    display: flex;
    align-items: center;

    h5 {
      margin-left: 24px;
      margin-bottom: 0;
    }
  }

  p {
    color: ${(props) => props.theme.colors.dark.dark0};
    font-size: ${(props) => props.theme.typograph.sizes.p};
    font-weight: ${(props) => props.theme.typograph.weights.medium};
  }
`;

export const AdminIcon = styled(FaUserCog).attrs((props) => ({
  color: props.theme.colors.primary.primary0,
  size: 40,
}))``;

export const AdvertisementIcon = styled(RiAdvertisementFill).attrs((props) => ({
  color: props.theme.colors.primary.primary0,
  size: 40,
}))``;

export const CardOptionsIcon = styled(BiSelectMultiple).attrs((props) => ({
  color: props.theme.colors.primary.primary0,
  size: 40,
}))``;

export const ManageGamesPaymentIcon = styled(BiTransferAlt).attrs((props) => ({
  color: props.theme.colors.primary.primary0,
  size: 40,
}))``;
