import React, {
  useCallback,
  useMemo,
  useState,
  useRef,
  useEffect,
} from 'react';
import { notification } from 'antd';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import * as Yup from 'yup';
import * as _ from 'lodash';

import logoImg from '../../assets/logo.svg';

import api from '../../services/api';

import { Container, Content, AnimationContainer, Background } from './styles';

import { validateCnpj } from '../../utils/validate';

import Team from './Team';
import Responsible from './Responsible';
import User from './User';

const SignUpTeam = () => {
  const history = useHistory();
  const formikRef = useRef(null);

  const [loadingUserPosition, setLoadingUserPosition] = useState(true);
  const [userPositionCoords, setUserPositionCoords] = useState({
    lat: null,
    lng: null,
  });

  const [currentStep, setCurrentStep] = useState(0);

  const signUpFormData = {
    team: {
      name: '',
      docNumber: '',
      email: '',
      phone: '',
      address: {
        description: '',
        coords: {
          lat: '',
          lng: '',
        },
      },
    },
    responsible: {
      altName: '',
      gender: '',
    },
    user: {
      username: '',
      password: '',
      confirmPassword: '',
    },
  };

  const loadingsOfAsyncValidationsInitialData = {
    checkingSomeField: false,
    username: false,
    email: false,
    docNumber: false,
  };

  const [loadingsOfAsyncValidations, setLoadingsOfAsyncValidations] = useState(
    loadingsOfAsyncValidationsInitialData
  );
  const [focusedField, setFocusedField] = useState('');
  const [fieldsAvailable, setFieldsAvailable] = useState({
    username: true,
    email: true,
    docNumber: true,
  });

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      position => {
        setLoadingUserPosition(false);
        setUserPositionCoords({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      () => {
        setLoadingUserPosition(false);
        notification.open({
          message: 'Localização indisponível',
          description:
            'Para uma melhor experiência, permita o acesso à sua localização nas configurações desse site e reinicie a página',
          onClick: () => {},
        });
      }
    );
  }, []);

  const checkIfFieldIsAvailable = useCallback(
    async (value, fieldName, resolve) => {
      const params = {
        username: fieldName === 'user.username' ? value : undefined,
        email: fieldName === 'team.email' ? value : undefined,
        docNumber: fieldName === 'team.docNumber' ? value : undefined,
      };

      try {
        setLoadingsOfAsyncValidations({
          checkingSomeField: true,
          username: !!(fieldName === 'user.username'),
          email: !!(fieldName === 'team.email'),
          docNumber: !!(fieldName === 'team.docNumber'),
        });
        const response = await api.get('/api/account/checker', {
          params,
        });

        resolve(!response.data.length);
        setLoadingsOfAsyncValidations(loadingsOfAsyncValidationsInitialData);
      } catch (error) {
        setLoadingsOfAsyncValidations(loadingsOfAsyncValidationsInitialData);
        toast.error(
          'Ocorreu um erro inesperado. Atualize a página e tente novamente!'
        );
      }
    },
    [loadingsOfAsyncValidationsInitialData]
  );

  const usernameValidationDebounced = useMemo(() => {
    return _.debounce(checkIfFieldIsAvailable, 500);
  }, [checkIfFieldIsAvailable]);

  const emailValidationDebounced = useMemo(() => {
    return _.debounce(checkIfFieldIsAvailable, 500);
  }, [checkIfFieldIsAvailable]);

  const docNumberValidationDebounced = useMemo(() => {
    return _.debounce(checkIfFieldIsAvailable, 500);
  }, [checkIfFieldIsAvailable]);

  const handleToogleCurrentStep = useCallback(newCurrentStep => {
    if (newCurrentStep < 0) {
      newCurrentStep = 0;
    }

    setCurrentStep(newCurrentStep);
  }, []);

  const handleSubmit = useCallback(
    async data => {
      const body = {
        name: data.team.name,
        docNumber: data.team.docNumber
          .replace(/\./g, '')
          .replace(/\//g, '')
          .replace(/-/g, ''),
        email: data.team.email,
        phones: [
          data.team.phone
            .replace(/\(/g, '')
            .replace(/\)/g, '')
            .replace(/-/g, ''),
        ],
        address: {
          street: data.team.address.description,
          coords: [data.team.address.coords.lng, data.team.address.coords.lat],
        },
        altName: data.responsible.altName,
        gender: data.responsible.gender,
        username: data.user.username,
        password: data.user.password,
        role: 'amateur',
      };

      try {
        await api.post('/api/user-team', body);

        toast.success('Time criado com sucesso!');

        history.push('/');
      } catch (err) {
        toast.error('Ocorreu um erro ao fazer o cadastro, tente novamente.');
      }
    },
    [history]
  );

  return (
    <Container>
      <Background />
      <Content>
        <AnimationContainer>
          <img src={logoImg} alt="Lance Futebol" />
          <h5>Novo time</h5>
          <Formik
            innerRef={formikRef}
            initialValues={signUpFormData}
            validationSchema={Yup.object().shape({
              team: Yup.object().shape({
                name: Yup.string().required('O nome do time é obrigatório'),
                docNumber: Yup.string()
                  .required('O CNPJ é obrigatório')
                  .transform(
                    value => value.replace(/\./g, '').replace(/-/g, '')
                    // .replace(/\//g, '')
                  )
                  .test('cnpj', 'O CNPJ informado é inválido', value => {
                    const cnpjValid = validateCnpj(value);

                    if (cnpjValid === 'valid') {
                      return true;
                    }
                    return false;
                  })
                  .test(
                    'docNumberUnavailable',
                    'Esse CNPJ já está sendo utilizado',
                    function validate(value) {
                      docNumberValidationDebounced.cancel();
                      if (focusedField === 'team.docNumber') {
                        if (!value || validateCnpj(value) !== 'valid') {
                          return true;
                        }

                        return new Promise(resolve =>
                          docNumberValidationDebounced(
                            value,
                            'team.docNumber',
                            fieldAvailable => {
                              resolve(fieldAvailable);
                              setFieldsAvailable({
                                ...fieldsAvailable,
                                teamDocNumber: fieldAvailable,
                              });
                            }
                          )
                        );
                      }
                      return fieldsAvailable.teamDocNumber;
                    }
                  ),
                phone: Yup.string()
                  .required('O telefone do time é obrigatório')
                  .transform(value =>
                    value
                      .replace(/\(/g, '')
                      .replace(/\)/g, '')
                      .replace(/-/g, '')
                  )
                  .matches(/[0-9]{11}/, {
                    excludeEmptyString: true,
                    message: 'O telefone informado é inválido',
                  }),
                email: Yup.string()
                  .required('O email do time é obrigatório')
                  .email('O email informado não é válido')
                  .test(
                    'emailUnavailable',
                    'Esse email já está sendo utilizado',
                    async function validate(value) {
                      emailValidationDebounced.cancel();
                      if (focusedField === 'team.email') {
                        if (
                          !value ||
                          !(await Yup.string()
                            .email()
                            .isValid(value))
                        ) {
                          return true;
                        }

                        return new Promise(resolve =>
                          emailValidationDebounced(
                            value,
                            'team.email',
                            fieldAvailable => {
                              resolve(fieldAvailable);
                              setFieldsAvailable({
                                ...fieldsAvailable,
                                email: fieldAvailable,
                              });
                            }
                          )
                        );
                      }
                      return fieldsAvailable.email;
                    }
                  ),
                address: Yup.object().shape({
                  coords: Yup.object().shape({
                    lat: Yup.string().required('Selecione a localização'),
                  }),
                }),
              }),
              responsible: Yup.object().shape({
                altName: Yup.string().required('O nome é obrigatório'),
                gender: Yup.string()
                  .required('O sexo é obrigatório')
                  .oneOf(['M', 'F', 'O'], 'O sexo selecionado é inválido'),
              }),
              user: Yup.object().shape({
                username: Yup.string()
                  .required('O nome do usuário é obrigatório')
                  .test(
                    'usernameUnavailable',
                    'Esse nome de usuário já está sendo utilizado',
                    function validate(value) {
                      usernameValidationDebounced.cancel();
                      if (focusedField === 'user.username') {
                        if (!value) {
                          return true;
                        }

                        return new Promise(resolve =>
                          usernameValidationDebounced(
                            value,
                            'user.username',
                            fieldAvailable => {
                              resolve(fieldAvailable);
                              setFieldsAvailable({
                                ...fieldsAvailable,
                                username: fieldAvailable,
                              });
                            }
                          )
                        );
                      }
                      return fieldsAvailable.username;
                    }
                  ),
                password: Yup.string()
                  .min(6, 'A senha precisa ter mais de 6 dígitos')
                  .required('A senha é obrigatória'),
                confirmPassword: Yup.string().when(
                  'password',
                  (password, field) => {
                    return password
                      ? field
                          .required('A confirmação da senha é obrigatória')
                          .oneOf(
                            [Yup.ref('password')],
                            'A confirmação da senha está incorreta'
                          )
                      : field;
                  }
                ),
              }),
            })}
            onSubmit={handleSubmit}
          >
            {formikProps => (
              <form
                onSubmit={e => {
                  e.preventDefault();
                  if (!loadingsOfAsyncValidations.checkingSomeField) {
                    if (Object.entries(formikProps.errors).length > 0) {
                      toast.warn('Dados inválidos. Tente novamente!');
                    }
                    formikProps.handleSubmit(e);
                  }
                }}
              >
                {currentStep === 0 && (
                  <Team
                    formikProps={formikProps}
                    nextStep={() => handleToogleCurrentStep(1)}
                    loadingsOfAsyncValidations={loadingsOfAsyncValidations}
                    setFocusedField={setFocusedField}
                    loadingUserPosition={loadingUserPosition}
                    userPositionCoords={userPositionCoords}
                  />
                )}
                {currentStep === 1 && (
                  <Responsible
                    formikProps={formikProps}
                    prevStep={() => handleToogleCurrentStep(0)}
                    nextStep={() => handleToogleCurrentStep(2)}
                    loadingsOfAsyncValidations={loadingsOfAsyncValidations}
                    setFocusedField={setFocusedField}
                    focusedField={focusedField}
                  />
                )}
                {currentStep === 2 && (
                  <User
                    formikProps={formikProps}
                    prevStep={() => handleToogleCurrentStep(1)}
                    loadingsOfAsyncValidations={loadingsOfAsyncValidations}
                    setFocusedField={setFocusedField}
                    focusedField={focusedField}
                  />
                )}
              </form>
            )}
          </Formik>
        </AnimationContainer>
      </Content>
    </Container>
  );
};

export default SignUpTeam;
