import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
  padding: 56px 32px;

  @media only screen and (max-width: 600px) {
    padding: 32px 16px;
    min-height: calc(100vh - 61px);
  }
`;

export const TitlePageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  align-items: center;

  /* AntD Button Changes */
  button {
    margin-left: 16px;

    @media only screen and (min-width: 601px) {
      display: none;
    }
  }
`;

export const SearchAndNewUserContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;

  /* AntD Button Changes */
  button {
    margin-left: 16px;

    @media only screen and (max-width: 600px) {
      display: none;
    }
  }
`;

export const NamePageForIndicator = styled.span`
  color: ${(props) => props.theme.colors.primary['primary-1']};
`;

export const ChampionshipsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: space-between;

  h5,
  h6,
  div.ant-spin {
    text-align: center;
    margin: auto 0;
  }

  > h6 {
    font-weight: ${(props) => props.theme.typograph.weights.medium};
  }
`;

export const ChampionshipsList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ChampionshipDetailContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 8px 0px;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.light['light-2']};
  cursor: pointer;
  transition: 0.2s background-color;

  &:hover {
    background-color: ${(props) => props.theme.colors.light['light-2']};
  }

  &:last-child {
    border-bottom: none;
  }

  div {
    width: 57px;
    height: 57px;
    background-color: ${(props) => props.theme.colors.light['light-2']};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;

    img {
      width: 57px;
      height: 57px;
      border-radius: 50%;
      padding: 4px;
    }
  }
`;

export const UserDataContainer = styled.div`
  flex: 1;
  margin-left: 16px;

  > div {
    display: flex;
    align-items: center;

    @media only screen and (max-width: 600px) {
      justify-content: space-between;
    }

    p {
      font-size: ${(props) => props.theme.typograph.sizes.p}px;
      font-weight: ${(props) => props.theme.typograph.weights.medium};
      color: ${(props) => props.theme.colors.dark.dark0};
      margin-right: 16px;
    }

    span {
      font-size: ${(props) => props.theme.typograph.sizes.small}px;
      font-weight: ${(props) => props.theme.typograph.weights.medium};
      color: ${(props) => props.theme.colors.danger};

      &::before {
        content: '';
        width: 8px;
        height: 8px;
        background-color: ${(props) => props.theme.colors.danger};
        border-radius: 4px;
        margin-right: 8px;
        display: inline-block;
      }
    }
  }

  > span {
    display: block;
    font-size: ${(props) => props.theme.typograph.sizes.small}px;
    font-weight: ${(props) => props.theme.typograph.weights.medium};
    color: ${(props) => props.theme.colors.dark['dark+2']};

    &:nth-child(2) {
      color: ${(props) => props.theme.colors.dark['dark+1']};
      margin-bottom: 8px;
    }
  }
`;
