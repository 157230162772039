import {
  AthletePositionDescriptionEnum,
  EAthletePosition,
  PlayerPositionSelectOption,
} from './types';

export function getPlayerPositionLabel(
  playerPosition: EAthletePosition
): string {
  switch (playerPosition) {
    case EAthletePosition.GOALKEEPER:
      return AthletePositionDescriptionEnum.GOALKEEPER;
    case EAthletePosition.DEFENSE:
      return AthletePositionDescriptionEnum.DEFENSE;
    case EAthletePosition.MIDFIELD:
      return AthletePositionDescriptionEnum.MIDFIELD;
    case EAthletePosition.ATTACK:
      return AthletePositionDescriptionEnum.ATTACK;
    case EAthletePosition.RIGHT_BACK:
      return AthletePositionDescriptionEnum.RIGHT_BACK;
    case EAthletePosition.LEFT_BACK:
      return AthletePositionDescriptionEnum.LEFT_BACK;
    case EAthletePosition.ATP:
      return AthletePositionDescriptionEnum.ATP;
    case EAthletePosition.WTA:
      return AthletePositionDescriptionEnum.WTA;
    case EAthletePosition.F1_START:
      return AthletePositionDescriptionEnum.F1_START;
    case EAthletePosition.F1_FINISH:
      return AthletePositionDescriptionEnum.F1_FINISH;
    case EAthletePosition.POKER__HAND_CHAMPION_SEQUENCE:
      return AthletePositionDescriptionEnum.POKER__HAND_CHAMPION_SEQUENCE;
    case EAthletePosition.POKER__ELIMINATION_SEQUENCE:
      return AthletePositionDescriptionEnum.POKER__ELIMINATION_SEQUENCE;
    case EAthletePosition.GOAL_MINUTE:
      return AthletePositionDescriptionEnum.GOAL_MINUTE;
    case EAthletePosition.FIRST_GOAL_MINUTE:
      return AthletePositionDescriptionEnum.FIRST_GOAL_MINUTE;
    case EAthletePosition.SECOND_GOAL_MINUTE:
      return AthletePositionDescriptionEnum.SECOND_GOAL_MINUTE;
    case EAthletePosition.FIRST_GOAL_MINUTE_OF_THE_ROUND:
      return AthletePositionDescriptionEnum.FIRST_GOAL_MINUTE_OF_THE_ROUND;
    case EAthletePosition.SECOND_GOAL_MINUTE_OF_THE_ROUND:
      return AthletePositionDescriptionEnum.SECOND_GOAL_MINUTE_OF_THE_ROUND;
    case EAthletePosition.FIRST_HALF_GOAL_MINUTE:
      return AthletePositionDescriptionEnum.FIRST_HALF_GOAL_MINUTE;
    case EAthletePosition.SECOND_HALF_GOAL_MINUTE:
      return AthletePositionDescriptionEnum.SECOND_HALF_GOAL_MINUTE;
    case EAthletePosition.GOAL_INSIDE_AREA:
      return AthletePositionDescriptionEnum.GOAL_INSIDE_AREA;
    case EAthletePosition.GOAL_OUTSIDE_AREA:
      return AthletePositionDescriptionEnum.GOAL_OUTSIDE_AREA;
    case EAthletePosition.GOAL_INSIDE_AREA_OF_THE_ROUND:
      return AthletePositionDescriptionEnum.GOAL_INSIDE_AREA_OF_THE_ROUND;
    case EAthletePosition.GOAL_OUTSIDE_AREA_OF_THE_ROUND:
      return AthletePositionDescriptionEnum.GOAL_OUTSIDE_AREA_OF_THE_ROUND;
    case EAthletePosition.GOALS_IN_THE_FIRST_HALF:
      return AthletePositionDescriptionEnum.GOALS_IN_THE_FIRST_HALF;
    case EAthletePosition.GOALS_IN_THE_SECOND_HALF:
      return AthletePositionDescriptionEnum.GOALS_IN_THE_SECOND_HALF;
    case EAthletePosition.GOALS_IN_THE_FIRST_HALF_OF_THE_ROUND:
      return AthletePositionDescriptionEnum.GOALS_IN_THE_FIRST_HALF_OF_THE_ROUND;
    case EAthletePosition.GOALS_IN_THE_SECOND_HALF_OF_THE_ROUND:
      return AthletePositionDescriptionEnum.GOALS_IN_THE_SECOND_HALF_OF_THE_ROUND;

    case EAthletePosition.FOOTBALL__FIELD_GOAL_AMOUNT:
      return AthletePositionDescriptionEnum.FOOTBALL__FIELD_GOAL_AMOUNT;
    case EAthletePosition.FOOTBALL__TOUCHDOWN_AMOUNT:
      return AthletePositionDescriptionEnum.FOOTBALL__TOUCHDOWN_AMOUNT;
    case EAthletePosition.FOOTBALL__TWO_POINT_CONVERSION_AMOUNT:
      return AthletePositionDescriptionEnum.FOOTBALL__TWO_POINT_CONVERSION_AMOUNT;
    case EAthletePosition.FOOTBALL__EXTRA_KICK_AMOUNT:
      return AthletePositionDescriptionEnum.FOOTBALL__EXTRA_KICK_AMOUNT;
    case EAthletePosition.FOOTBALL__SAFETY_AMOUNT:
      return AthletePositionDescriptionEnum.FOOTBALL__SAFETY_AMOUNT;
    case EAthletePosition.FOOTBALL__PICK_SIX_TOUCHDOWN_AMOUNT:
      return AthletePositionDescriptionEnum.FOOTBALL__PICK_SIX_TOUCHDOWN_AMOUNT;
    default:
      return playerPosition;
  }
}

export function getPlayerPositionSelectOptions(): PlayerPositionSelectOption[] {
  return [
    {
      label: AthletePositionDescriptionEnum.GOALKEEPER,
      value: EAthletePosition.GOALKEEPER,
    },
    {
      label: AthletePositionDescriptionEnum.DEFENSE,
      value: EAthletePosition.DEFENSE,
    },
    {
      label: AthletePositionDescriptionEnum.MIDFIELD,
      value: EAthletePosition.MIDFIELD,
    },
    {
      label: AthletePositionDescriptionEnum.ATTACK,
      value: EAthletePosition.ATTACK,
    },
    {
      label: AthletePositionDescriptionEnum.RIGHT_BACK,
      value: EAthletePosition.RIGHT_BACK,
    },
    {
      label: AthletePositionDescriptionEnum.LEFT_BACK,
      value: EAthletePosition.LEFT_BACK,
    },
    {
      label: AthletePositionDescriptionEnum.ATP,
      value: EAthletePosition.ATP,
    },
    {
      label: AthletePositionDescriptionEnum.WTA,
      value: EAthletePosition.WTA,
    },
    {
      label: AthletePositionDescriptionEnum.F1_START,
      value: EAthletePosition.F1_START,
    },
    {
      label: AthletePositionDescriptionEnum.F1_FINISH,
      value: EAthletePosition.F1_FINISH,
    },
    {
      label: AthletePositionDescriptionEnum.POKER__HAND_CHAMPION_SEQUENCE,
      value: EAthletePosition.POKER__HAND_CHAMPION_SEQUENCE,
    },
    {
      label: AthletePositionDescriptionEnum.POKER__ELIMINATION_SEQUENCE,
      value: EAthletePosition.POKER__ELIMINATION_SEQUENCE,
    },
    {
      label: AthletePositionDescriptionEnum.GOAL_MINUTE,
      value: EAthletePosition.GOAL_MINUTE,
    },
    {
      label: AthletePositionDescriptionEnum.FIRST_GOAL_MINUTE,
      value: EAthletePosition.FIRST_GOAL_MINUTE,
    },
    {
      label: AthletePositionDescriptionEnum.SECOND_GOAL_MINUTE,
      value: EAthletePosition.SECOND_GOAL_MINUTE,
    },
    {
      label: AthletePositionDescriptionEnum.FIRST_GOAL_MINUTE_OF_THE_ROUND,
      value: EAthletePosition.FIRST_GOAL_MINUTE_OF_THE_ROUND,
    },
    {
      label: AthletePositionDescriptionEnum.SECOND_GOAL_MINUTE_OF_THE_ROUND,
      value: EAthletePosition.SECOND_GOAL_MINUTE_OF_THE_ROUND,
    },
    {
      label: AthletePositionDescriptionEnum.FIRST_HALF_GOAL_MINUTE,
      value: EAthletePosition.FIRST_HALF_GOAL_MINUTE,
    },
    {
      label: AthletePositionDescriptionEnum.SECOND_HALF_GOAL_MINUTE,
      value: EAthletePosition.SECOND_HALF_GOAL_MINUTE,
    },
    {
      label: AthletePositionDescriptionEnum.GOAL_INSIDE_AREA,
      value: EAthletePosition.GOAL_INSIDE_AREA,
    },
    {
      label: AthletePositionDescriptionEnum.GOAL_OUTSIDE_AREA,
      value: EAthletePosition.GOAL_OUTSIDE_AREA,
    },
    {
      label: AthletePositionDescriptionEnum.GOAL_INSIDE_AREA_OF_THE_ROUND,
      value: EAthletePosition.GOAL_INSIDE_AREA_OF_THE_ROUND,
    },
    {
      label: AthletePositionDescriptionEnum.GOAL_OUTSIDE_AREA_OF_THE_ROUND,
      value: EAthletePosition.GOAL_OUTSIDE_AREA_OF_THE_ROUND,
    },
    {
      label: AthletePositionDescriptionEnum.GOALS_IN_THE_FIRST_HALF,
      value: EAthletePosition.GOALS_IN_THE_FIRST_HALF,
    },
    {
      label: AthletePositionDescriptionEnum.GOALS_IN_THE_SECOND_HALF,
      value: EAthletePosition.GOALS_IN_THE_SECOND_HALF,
    },
    {
      label:
        AthletePositionDescriptionEnum.GOALS_IN_THE_FIRST_HALF_OF_THE_ROUND,
      value: EAthletePosition.GOALS_IN_THE_FIRST_HALF_OF_THE_ROUND,
    },
    {
      label:
        AthletePositionDescriptionEnum.GOALS_IN_THE_SECOND_HALF_OF_THE_ROUND,
      value: EAthletePosition.GOALS_IN_THE_SECOND_HALF_OF_THE_ROUND,
    },
    {
      label: AthletePositionDescriptionEnum.FOOTBALL__FIELD_GOAL_AMOUNT,
      value: EAthletePosition.FOOTBALL__FIELD_GOAL_AMOUNT,
    },
    {
      label: AthletePositionDescriptionEnum.FOOTBALL__TOUCHDOWN_AMOUNT,
      value: EAthletePosition.FOOTBALL__TOUCHDOWN_AMOUNT,
    },
    {
      label:
        AthletePositionDescriptionEnum.FOOTBALL__TWO_POINT_CONVERSION_AMOUNT,
      value: EAthletePosition.FOOTBALL__TWO_POINT_CONVERSION_AMOUNT,
    },
    {
      label: AthletePositionDescriptionEnum.FOOTBALL__EXTRA_KICK_AMOUNT,
      value: EAthletePosition.FOOTBALL__EXTRA_KICK_AMOUNT,
    },
    {
      label: AthletePositionDescriptionEnum.FOOTBALL__SAFETY_AMOUNT,
      value: EAthletePosition.FOOTBALL__SAFETY_AMOUNT,
    },
    {
      label: AthletePositionDescriptionEnum.FOOTBALL__PICK_SIX_TOUCHDOWN_AMOUNT,
      value: EAthletePosition.FOOTBALL__PICK_SIX_TOUCHDOWN_AMOUNT,
    },
  ];
}

export function getAthleteNumberDescription(number?: string): string {
  if (number) {
    return `Camisa - ${number} · `;
  }
  return '';
}
