import { takeLatest, call, put, all } from 'redux-saga/effects';
import api from '@/services/api';

import { getMyTeamDataSuccess, getMyTeamDataFailure } from './actions';

async function newTeam(team_user) {
  try {
    const body = {
      name: team_user.name,
    };

    await api.post('/api/manager-team', body);

    window.location.reload();
  } catch (error) {
    //
  }
}

export function* getTeam({ payload }) {
  const me = payload.user;
  if (!me.roles.includes('team')) {
    yield put(getMyTeamDataFailure());
    return;
  }

  try {
    const response = yield call(api.get, 'api/manager-team');

    yield put(getMyTeamDataSuccess(response.data));
  } catch (error) {
    if (error.response.status === 404) {
      newTeam(me);
    }

    // yield put(getUserDataFailure());
  }
}
// export function* updateProfile({ payload }) {
//   try {
//     const { name, email, avatar_id, ...rest } = payload.data;

//     const profile = {
//       name,
//       email,
//       avatar_id,
//       ...(rest.oldPassword ? rest : {}),
//     };

//     const response = yield call(api.put, 'users', profile);
//     console.log(response);
//     // yield put(updateProfileSuccess(response.data));
//     toast.success('Perfil atualizado com sucesso!');
//   } catch (error) {
//     // yield put(updateProfileFailure());
//     toast.error('Erro ao atualizar o perfil, confira seus dados!');
//   }
// }

export default all([
  takeLatest('@user/GET_USER_DATA_SUCCESS', getTeam),
  takeLatest('@team/GET_TEAM_DATA_REQUEST', getTeam),
  // takeLatest('@user/UPDATE_PROFILE_REQUEST', updateProfile),
]);
