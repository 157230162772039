import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  FastField,
  FastFieldProps,
  Field,
  Formik,
  FormikHelpers,
  FormikProps,
} from 'formik';
import * as Yup from 'yup';

import Input from '@/components/Input';
import GamePaymentPercentBar from '@/components/GamePaymentPercentBar';
import theme from '@/styles/theme';

import CustomAntButton from '@/components/CustomAntButton';
import confirm from 'antd/lib/modal/confirm';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import api from '@/services/api';
import { toast } from 'react-toastify';
import Loading from '@/components/Loading';
import {
  AthleteCardPaymentPercentsFormData,
  IAthleteCardPaymentPercentage,
} from './types';
import { Container, InputsContainer } from './styles';

const AthleteCardsTabContent: React.FC = () => {
  const formikRef =
    useRef<FormikProps<AthleteCardPaymentPercentsFormData>>(null);

  const initialFormValue = useRef({
    minHousePercent: '',
    housePercent: '',
    influencerPercent: '',
    winnerPercent: '',
  } as AthleteCardPaymentPercentsFormData);

  const [cardPaymentPercentage, setCardPaymentPercentage] =
    useState<IAthleteCardPaymentPercentage>(
      {} as IAthleteCardPaymentPercentage
    );
  const [isLoading, setIsLoading] = useState(true);
  const [isFormChanged, setIsFormChanged] = useState(false);

  useEffect(() => {
    async function getPercentageConfig(): Promise<void> {
      try {
        const { data } = await api.get<IAthleteCardPaymentPercentage>(
          '/api/card-percentage'
        );

        setCardPaymentPercentage(data);
        initialFormValue.current = {
          minHousePercent: data.homeMin.toString(),
          housePercent: data.home.toString(),
          influencerPercent: data.influencer.toString(),
          winnerPercent: data.winner.toString(),
        };

        setIsLoading(false);
      } catch (error) {
        toast.error('Aconteceu um erro inesperado! Tente novamente...');
      }
    }

    getPercentageConfig();
  }, []);

  function getTotalPercent(
    formData: AthleteCardPaymentPercentsFormData
  ): number {
    const values = [
      formData.housePercent,
      formData.influencerPercent,
      formData.winnerPercent,
    ];
    return values.reduce((total, value) => total + parseInt(value), 0);
  }

  function checkIfIsSaveButtonEnabled({
    touched,
    errors,
    values,
  }: FormikProps<AthleteCardPaymentPercentsFormData>): boolean {
    const totalPercent = getTotalPercent(values);

    const isNotFormTouched = !touched;
    const hasErrors = Object.keys(errors).length;
    const isTotalPercentInvalid = totalPercent !== 100;

    if (
      isNotFormTouched ||
      hasErrors ||
      isTotalPercentInvalid ||
      !isFormChanged
    ) {
      return false;
    }

    return true;
  }

  const handleSubmit = useCallback(
    async (
      values: AthleteCardPaymentPercentsFormData,
      actions: FormikHelpers<AthleteCardPaymentPercentsFormData>
    ) => {
      await new Promise((resolve) => {
        confirm({
          title: 'Deseja realmente salvar as porcentagens de pagamentos?',
          icon: <ExclamationCircleOutlined />,
          cancelText: 'Cancelar',
          okText: 'Salvar',
          onOk() {
            resolve(true);
          },
          onCancel() {
            actions.setSubmitting(false);
          },
        });
      });

      const body = {
        homeMin: parseInt(values.minHousePercent),
        home: parseInt(values.housePercent),
        influencer: parseInt(values.influencerPercent),
        winner: parseInt(values.winnerPercent),
      } as Omit<IAthleteCardPaymentPercentage, '_id'>;

      try {
        await api.put(
          `/api/card-percentage/${cardPaymentPercentage._id}`,
          body
        );
        toast.success('Definições salvas com sucesso!');
        setCardPaymentPercentage((oldState) => ({
          ...oldState,
          ...body,
        }));
        setIsFormChanged(false);
      } catch (error) {
        toast.error('Aconteceu um erro inesperado! Tente novamente...');
      }
    },
    [cardPaymentPercentage._id]
  );

  if (isLoading) {
    return (
      <Container>
        <Loading />
      </Container>
    );
  }

  return (
    <Container>
      <Formik
        innerRef={formikRef}
        initialValues={initialFormValue.current}
        validationSchema={Yup.object().shape({
          minHousePercent: Yup.number()
            .min(1, 'O valor mínimo é 1')
            .max(100, 'O valor máximo é 100')
            .required('A porcentagem mínima da casa é obrigatória'),
          housePercent: Yup.number()
            .max(100, 'O valor máximo é 100')
            .test(
              'housePercentInvalid',
              'O percentual deve ser maior ou igual ao mínimo definido',
              function validate(value) {
                const formikProps = formikRef.current;
                const minHousePercent = formikProps?.values.minHousePercent;

                if (!minHousePercent || !value) return false;

                return value >= parseInt(minHousePercent);
              }
            )
            .required('A porcentagem da casa é obrigatória'),
          influencerPercent: Yup.number()
            .min(0, 'O valor mínimo é 0')
            .max(100, 'O valor máximo é 100')
            .required('A porcentagem do influenciador é obrigatória'),
          winnerPercent: Yup.number()
            .min(1, 'O valor mínimo é 1')
            .max(100, 'O valor máximo é 100')
            .required('A porcentagem do vencedor é obrigatória'),
        })}
        onSubmit={handleSubmit}
        validateOnMount
      >
        {(formikProps) => (
          <form onSubmit={formikProps.handleSubmit}>
            <div>
              <InputsContainer>
                <Field name="minHousePercent">
                  {({ field, meta }: FastFieldProps) => {
                    return (
                      <Input
                        {...field}
                        label="Porcentagem mínima da casa"
                        identifier="minHousePercent"
                        type="text"
                        error={meta?.touched && meta?.error && meta?.error}
                        onChange={(e) => {
                          const value = e.target.value.replace(/\D/g, '');
                          formikProps.setFieldValue('minHousePercent', value);
                          setIsFormChanged(true);

                          if (value) {
                            const { housePercent } = formikProps.values;
                            if (
                              !housePercent ||
                              parseInt(housePercent) < parseInt(value)
                            ) {
                              formikProps.setFieldValue('housePercent', value);
                            }
                          } else {
                            formikProps.setFieldValue('housePercent', '');
                          }
                        }}
                        suffix="%"
                      />
                    );
                  }}
                </Field>
                <Field name="housePercent">
                  {({ field, meta }: FastFieldProps) => {
                    return (
                      <Input
                        {...field}
                        label="Porcentagem da casa"
                        identifier="housePercent"
                        type="text"
                        error={meta?.touched && meta?.error && meta?.error}
                        disabled={!formikProps.values.minHousePercent}
                        onChange={(e) => {
                          e.target.value = e.target.value
                            .replace(/\D/g, '')
                            .slice(0, 3);
                          field.onChange(e);
                          setIsFormChanged(true);
                        }}
                        suffix="%"
                      />
                    );
                  }}
                </Field>
                <FastField name="influencerPercent">
                  {({ field, meta }: FastFieldProps) => {
                    return (
                      <Input
                        {...field}
                        label="Porcentagem do influenciador"
                        identifier="influencerPercent"
                        type="text"
                        error={meta?.touched && meta?.error && meta?.error}
                        onChange={(e) => {
                          e.target.value = e.target.value
                            .replace(/\D/g, '')
                            .slice(0, 3);
                          field.onChange(e);
                          setIsFormChanged(true);
                        }}
                        suffix="%"
                      />
                    );
                  }}
                </FastField>
                <FastField name="winnerPercent">
                  {({ field, meta }: FastFieldProps) => {
                    return (
                      <Input
                        {...field}
                        label="Porcentagem do vencedor"
                        identifier="winnerPercent"
                        type="text"
                        error={meta?.touched && meta?.error && meta?.error}
                        onChange={(e) => {
                          e.target.value = e.target.value
                            .replace(/\D/g, '')
                            .slice(0, 3);
                          field.onChange(e);
                          setIsFormChanged(true);
                        }}
                        suffix="%"
                      />
                    );
                  }}
                </FastField>
              </InputsContainer>
              <GamePaymentPercentBar
                items={[
                  {
                    label: 'Casa',
                    color: theme.colors.primary['primary-1'],
                    value: formikProps.values.housePercent
                      ? parseInt(formikProps.values.housePercent)
                      : 0,
                  },
                  {
                    label: 'Influenciador(res)',
                    color: theme.colors.blue,
                    value: formikProps.values.influencerPercent
                      ? parseInt(formikProps.values.influencerPercent)
                      : 0,
                  },
                  {
                    label: 'Vencedor',
                    color: theme.colors.secondary.secondary0,
                    value: formikProps.values.winnerPercent
                      ? parseInt(formikProps.values.winnerPercent)
                      : 0,
                  },
                ]}
              />
            </div>
            <CustomAntButton
              disabled={!checkIfIsSaveButtonEnabled(formikProps)}
              loading={formikProps.isSubmitting}
              type="primary"
              htmlType="submit"
            >
              Salvar
            </CustomAntButton>
          </form>
        )}
      </Formik>
    </Container>
  );
};

export default AthleteCardsTabContent;
