import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from 'react';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

import Breadcrumbs from '@/components/Breadcrumbs';
import Steps from '@/components/Steps';

import api from '@/services/api';

import { InfluencerRequests } from '@/services/api/requests/Influencer';
import { ICreateInfluencerRequestBody } from '@/services/api/requests/Influencer/types';
import { UploadRequests } from '@/services/api/requests/Upload';
import { validateCpf } from '@/utils/validate';
import { UserGenderEnum, UserRoleEnum } from '@/models/User';

import { Container, TitlePageContainer, Content } from './styles';

import InfluencerInformation from './InfluencerInformation';
import PersonalData from './PersonalData';
import Avatar from './Avatar';
import Confirm from './Confirm';
import {
  ICheckIfIsFieldAvailableProps,
  INewInfluencerFormData,
  UniqueFieldEnum,
} from './types';
import {
  fieldsAvailablesInitialState,
  loadingOfAsyncValidationsInitialState,
} from './constants';

const New: React.FC = () => {
  const history = useHistory();
  const [currentStep, setCurrentStep] = useState(0);
  const [loadingsOfAsyncValidations, setLoadingsOfAsyncValidations] = useState({
    username: false,
    email: false,
    docNumber: false,
    code: false,
  });
  const [focusedField, setFocusedField] = useState<string | null>(null);
  const [fieldsAvailables, setFieldsAvailables] = useState(
    fieldsAvailablesInitialState
  );

  const newInfluencerInitialFormData = useRef<INewInfluencerFormData>({
    influencerInformation: {
      username: '',
      email: '',
      password: '',
      confirmPassword: '',
      code: '',
    },
    personalData: {
      name: '',
      gender: '' as UserGenderEnum,
      docNumber: '',
      phone: '',
    },
    avatar: {
      file: null,
      previewUrl: '',
    },
  });

  const unblockPage = useMemo(() => {
    const messageComponents = {
      title: 'Deseja realmente cancelar o cadastro desse influenciador?',
      content: 'Todos os dados inseridos serão perdidos',
      cancelText: 'Voltar',
      okText: 'Cancelar',
    };

    return history.block(JSON.stringify(messageComponents));
  }, [history]);

  const handleBeforeUnload = useCallback((e: { returnValue: string }) => {
    e.returnValue = '';
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      unblockPage();
    };
  }, [handleBeforeUnload, unblockPage]);

  const checkIfFieldIsAvailable = useCallback(
    async ({ value, fieldName, resolve }: ICheckIfIsFieldAvailableProps) => {
      const params = {
        username: fieldName === UniqueFieldEnum.USERNAME ? value : undefined,
        email: fieldName === UniqueFieldEnum.EMAIL ? value : undefined,
        docNumber: fieldName === UniqueFieldEnum.DOCNUMBER ? value : undefined,
      };

      try {
        setLoadingsOfAsyncValidations({
          ...loadingOfAsyncValidationsInitialState,
          [fieldName]: true,
        });
        let isFieldAvailable = false;

        if (fieldName !== UniqueFieldEnum.CODE) {
          const response = await api.get('/api/account/checker', {
            params,
          });

          isFieldAvailable = !response.data;
        } else if (fieldName === UniqueFieldEnum.CODE) {
          const response =
            await InfluencerRequests.checkInfluencerCodeAvailability(value);

          isFieldAvailable = !response.data;
        }

        resolve(isFieldAvailable);
        setLoadingsOfAsyncValidations(loadingOfAsyncValidationsInitialState);
      } catch (error) {
        setLoadingsOfAsyncValidations(loadingOfAsyncValidationsInitialState);
        toast.error(
          'Ocorreu um erro inesperado. Atualize a página e tente novamente!'
        );
      }
    },
    []
  );

  const usernameValidationDebounced = useMemo(() => {
    return _.debounce(checkIfFieldIsAvailable, 500);
  }, [checkIfFieldIsAvailable]);

  const emailValidationDebounced = useMemo(() => {
    return _.debounce(checkIfFieldIsAvailable, 500);
  }, [checkIfFieldIsAvailable]);

  const docNumberValidationDebounced = useMemo(() => {
    return _.debounce(checkIfFieldIsAvailable, 500);
  }, [checkIfFieldIsAvailable]);

  const influencerCodeValidationDebounced = useMemo(() => {
    return _.debounce(checkIfFieldIsAvailable, 500);
  }, [checkIfFieldIsAvailable]);

  const handleToogleCurrentStep = useCallback((newCurrentStep: number) => {
    if (newCurrentStep < 0) {
      setCurrentStep(0);
    }

    setCurrentStep(newCurrentStep);
  }, []);

  const handleSubmit = useCallback(
    async (
      values: INewInfluencerFormData,
      actions: FormikHelpers<INewInfluencerFormData>
    ) => {
      const { influencerInformation, personalData, avatar } = values;

      let photoId;

      if (avatar.file) {
        try {
          const { data } = await UploadRequests.upload({
            file: avatar.file,
            from: 'userAvatar',
          });

          photoId = data._id;
        } catch (error) {
          toast.error(
            'Aconteceu um erro inesperado ao enviar o avatar do influenciador!'
          );

          return;
        }
      }

      const body: ICreateInfluencerRequestBody = {
        username: influencerInformation.username,
        email: influencerInformation.email,
        password: influencerInformation.password,
        name: personalData.name,
        gender: personalData.gender,
        docNumber:
          personalData.docNumber &&
          personalData.docNumber.replace(/\./g, '').replace(/-/g, ''),
        phones: [
          personalData.phone &&
            personalData.phone
              .replace(/\(/g, '')
              .replace(/\)/g, '')
              .replace(/-/g, ''),
        ],
        photo: photoId,
        roles: [UserRoleEnum.USER, UserRoleEnum.INFLUENCER],
      };

      try {
        const { data } = await InfluencerRequests.createInfluencer(body);

        await InfluencerRequests.updateInfluencer(data._id, {
          code: influencerInformation.code,
        });

        toast.success('Influenciador criado com sucesso!');
        actions.setSubmitting(false);

        window.removeEventListener('beforeunload', handleBeforeUnload);
        unblockPage();

        history.push('/influencers');
      } catch (error) {
        toast.error(
          'Aconteceu um erro inesperado ao cadastrar o influenciador!'
        );
      }
    },
    [handleBeforeUnload, history, unblockPage]
  );

  return (
    <Container>
      <Breadcrumbs />
      <TitlePageContainer>
        <h4>Novo influenciador</h4>
        <Button onClick={() => history.goBack()} danger type="text">
          Cancelar
        </Button>
      </TitlePageContainer>
      <Content>
        <Steps
          current={currentStep}
          stepsList={[
            { title: 'Informações do influenciador' },
            { title: 'Dados pessoais' },
            { title: 'Avatar' },
            { title: 'Confirmar' },
          ]}
        />
        <Formik
          initialValues={newInfluencerInitialFormData.current}
          validationSchema={Yup.object().shape({
            influencerInformation: Yup.object().shape({
              username: Yup.string()
                .required('O nome do influenciador é obrigatório')
                .test(
                  'usernameUnavailable',
                  'Esse nome de influenciador já está sendo utilizado',
                  function validate(value) {
                    usernameValidationDebounced.cancel();
                    if (focusedField === 'influencerInformation.username') {
                      if (!value) {
                        return true;
                      }

                      return new Promise((resolve) =>
                        usernameValidationDebounced({
                          value,
                          fieldName: UniqueFieldEnum.USERNAME,
                          resolve: (isFieldAvailable) => {
                            resolve(isFieldAvailable);
                            setFieldsAvailables({
                              ...fieldsAvailables,
                              username: isFieldAvailable,
                            });
                          },
                        })
                      );
                    }
                    return fieldsAvailables.username;
                  }
                ),
              email: Yup.string()
                .required('O email é obrigatório')
                .email('O email informado não é válido')
                .test(
                  'emailUnavailable',
                  'Esse email já está sendo utilizado',
                  async function validate(value) {
                    emailValidationDebounced.cancel();
                    if (focusedField === 'influencerInformation.email') {
                      if (
                        !value ||
                        !(await Yup.string().email().isValid(value))
                      ) {
                        return true;
                      }

                      return new Promise((resolve) =>
                        emailValidationDebounced({
                          value,
                          fieldName: UniqueFieldEnum.EMAIL,
                          resolve: (isFieldAvailable) => {
                            resolve(isFieldAvailable);
                            setFieldsAvailables({
                              ...fieldsAvailables,
                              email: isFieldAvailable,
                            });
                          },
                        })
                      );
                    }
                    return fieldsAvailables.email;
                  }
                ),
              password: Yup.string()
                .min(6, 'A senha precisa ter mais de 6 dígitos')
                .required('A senha é obrigatória'),
              confirmPassword: Yup.string().when(
                'password',
                (password, field) => {
                  return password
                    ? field
                        .required('A confirmação da senha é obrigatória')
                        .oneOf(
                          [Yup.ref('password')],
                          'A confirmação da senha está incorreta'
                        )
                    : field;
                }
              ),
              code: Yup.string()
                .required('O código do influenciador é obrigatório')
                .test(
                  'influencerCodeUnavailable',
                  'Esse código de influenciador já está sendo utilizado',
                  function validate(value) {
                    influencerCodeValidationDebounced.cancel();
                    if (focusedField === 'influencerInformation.code') {
                      if (!value) {
                        return true;
                      }

                      return new Promise((resolve) =>
                        influencerCodeValidationDebounced({
                          value,
                          fieldName: UniqueFieldEnum.CODE,
                          resolve: (isFieldAvailable) => {
                            resolve(isFieldAvailable);
                            setFieldsAvailables({
                              ...fieldsAvailables,
                              code: isFieldAvailable,
                            });
                          },
                        })
                      );
                    }
                    return fieldsAvailables.code;
                  }
                ),
            }),
            personalData: Yup.object().shape({
              name: Yup.string().required('O nome é obrigatório'),
              gender: Yup.string()
                .required('O sexo é obrigatório')
                .oneOf(['M', 'F', 'O'], 'O sexo selecionado é inválido'),
              docNumber: Yup.string()
                .required('O CPF é obrigatório')
                .transform((value) =>
                  value.replace(/\./g, '').replace(/-/g, '')
                )
                .test('cpf', 'O CPF informado é inválido', (value) => {
                  const cpfValid = validateCpf(value);

                  if (cpfValid === 'valid') {
                    return true;
                  }
                  return false;
                })
                .test(
                  'docNumberUnavailable',
                  'Esse CPF já está sendo utilizado',
                  function validate(value) {
                    docNumberValidationDebounced.cancel();
                    if (focusedField === 'personalData.docNumber') {
                      if (!value || validateCpf(value) !== 'valid') {
                        return true;
                      }

                      return new Promise((resolve) =>
                        docNumberValidationDebounced({
                          value,
                          fieldName: UniqueFieldEnum.DOCNUMBER,
                          resolve: (isFieldAvailable) => {
                            resolve(isFieldAvailable);
                            setFieldsAvailables({
                              ...fieldsAvailables,
                              docNumber: isFieldAvailable,
                            });
                          },
                        })
                      );
                    }
                    return fieldsAvailables.docNumber;
                  }
                ),
              phone: Yup.string()
                .required('O telefone é obrigatório')
                .transform((value) =>
                  value.replace(/\(/g, '').replace(/\)/g, '').replace(/-/g, '')
                )
                .matches(/[0-9]{11}/, {
                  excludeEmptyString: true,
                  message: 'O telefone informado é inválido',
                }),
            }),
          })}
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <form onSubmit={formikProps.handleSubmit}>
              {currentStep === 0 && (
                <InfluencerInformation
                  formik={formikProps}
                  nextStep={() => handleToogleCurrentStep(1)}
                  loadingsOfAsyncValidations={loadingsOfAsyncValidations}
                  setFocusedField={setFocusedField}
                />
              )}
              {currentStep === 1 && (
                <PersonalData
                  formik={formikProps}
                  prevStep={() => handleToogleCurrentStep(0)}
                  nextStep={() => handleToogleCurrentStep(2)}
                  loadingsOfAsyncValidations={loadingsOfAsyncValidations}
                  setFocusedField={setFocusedField}
                />
              )}
              {currentStep === 2 && (
                <Avatar
                  formik={formikProps}
                  prevStep={() => handleToogleCurrentStep(1)}
                  nextStep={() => handleToogleCurrentStep(3)}
                />
              )}
              {currentStep === 3 && (
                <Confirm
                  formik={formikProps}
                  prevStep={() => handleToogleCurrentStep(2)}
                  onSubmit={formikProps.handleSubmit}
                />
              )}
            </form>
          )}
        </Formik>
      </Content>
    </Container>
  );
};

export default New;
