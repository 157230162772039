import React, { useState, useMemo, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import * as _ from 'lodash';
import { Input, Button, Pagination, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import {
  Container,
  TitlePageContainer,
  SearchAndNewUserContainer,
  UsersListContainer,
  UsersList,
  NamePageForIndicator,
  UserDetailContainer,
  UserDataContainer,
} from './styles';

import api from '@/services/api';
import { UploadRequests } from '@/services/api/requests/Upload';
import DefaultAvatar from '@/assets/DefaultAvatar.svg';

const { Search } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;

function Sponsors() {
  const history = useHistory();

  const [sponsors, setSponsors] = useState({
    total: 0,
    currentPage: 0,
    pageSize: 0,
    items: [],
    searchCompleted: false,
  });
  const [searchInputValue, setSearchInputValue] = useState('');
  const [loadingSponsors, setLoadingSponsors] = useState(false);

  async function handleGetSponsors(search) {
    setLoadingSponsors(true);
    try {
      const { data } = await api.get('/api/sponsor-admin', {
        params: {
          search,
        },
      });

      setSponsors({
        total: data.total,
        currentPage: data.page,
        pageSize: data.limit,
        items: data.docs,
        searchCompleted: true,
      });

      setLoadingSponsors(false);
    } catch (error) {
      setLoadingSponsors(false);
    }
  }

  const userSearchDebounced = useMemo(() => {
    return _.debounce(handleGetSponsors, 500);
  }, []);

  useEffect(() => {
    userSearchDebounced.cancel();

    if (searchInputValue.length > 2) {
      setLoadingSponsors(true);
      userSearchDebounced(searchInputValue);
    } else {
      setLoadingSponsors(false);
    }

    if (!searchInputValue) {
      setSponsors({
        total: 0,
        currentPage: 0,
        pageSize: 0,
        items: [],
        searchCompleted: false,
      });
    }
  }, [searchInputValue, userSearchDebounced]);

  const contentPage = useMemo(() => {
    if (loadingSponsors) {
      return <Spin indicator={antIcon} />;
    }

    if (!searchInputValue) {
      return (
        <h5>
          Pesquise por{' '}
          <NamePageForIndicator>patrocinadores</NamePageForIndicator>{' '}
          cadastrados no Lance Futebol
        </h5>
      );
    }
    if (searchInputValue.length <= 2) {
      return <h6>Digite acima de 2 caracteres para realizar a busca</h6>;
    }

    if (sponsors?.items?.length > 0) {
      return (
        <>
          <UsersList>
            {sponsors?.items.map((sponsor) => (
              <UserDetailContainer
                onClick={() => history.push(`/sponsors/${sponsor?._id}`)}
                key={sponsor?.username}
              >
                <img
                  src={
                    sponsor?.photo?.filename
                      ? UploadRequests.getFileUrl(sponsor?.photo?.filename)
                      : DefaultAvatar
                  }
                  alt={sponsor?.name || 'Avatar'}
                />
                <UserDataContainer>
                  <div>
                    <p>{sponsor?.name}</p>
                    {sponsor?.status === 0 && <span>Inativo</span>}
                  </div>
                  <span>{sponsor?.username}</span>
                  <span>{sponsor?.email}</span>
                </UserDataContainer>
              </UserDetailContainer>
            ))}
          </UsersList>
          <Pagination
            current={sponsors.currentPage}
            onChange={(page) => setSponsors({ ...sponsors, currentPage: page })}
            total={sponsors.total}
            pageSize={sponsors.pageSize}
          />
        </>
      );
    }

    if (sponsors?.items?.length === 0 && sponsors?.searchCompleted) {
      return <h6>Nenhum patrocinador encontrado</h6>;
    }

    return '';
  }, [loadingSponsors, searchInputValue, sponsors, history]);

  return (
    <Container>
      <TitlePageContainer>
        <h4>Patrocinadores</h4>
        <Link to="/sponsors/new">
          <Button type="primary">Novo patrocinador</Button>
        </Link>
      </TitlePageContainer>
      <SearchAndNewUserContainer>
        <Search
          onChange={(e) => setSearchInputValue(e.target.value)}
          placeholder="Pesquisar patrocinador"
        />
        <Link to="/sponsors/new">
          <Button type="primary">Novo patrocinador</Button>
        </Link>
      </SearchAndNewUserContainer>
      <UsersListContainer>{contentPage}</UsersListContainer>
    </Container>
  );
}

export default Sponsors;
