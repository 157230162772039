export interface ITheme {
  colors: {
    primary: {
      primary0: string;
      'primary+1': string;
      'primary-1': string;
    };
    secondary: {
      secondary0: string;
      'secondary+1': string;
      'secondary-1': string;
    };
    light: {
      light0: string;
      'light-1': string;
      'light-2': string;
    };
    dark: {
      dark0: string;
      'dark+1': string;
      'dark+2': string;
      'dark+3': string;
      'dark+4': string;
    };
    danger: string;
    blue: string;
    yellow: string;
  };
  typograph: {
    sizes: {
      h2: number;
      h3: number;
      h4: number;
      h5: number;
      h6: number;
      xmd: number;
      p: number;
      small: number;
      xs: number;
      xxs: number;
    };
    weights: {
      light: number;
      medium: number;
      bold: number;
    };
  };
  breakpoints: {
    sm: string;
    md: string;
    lg: string;
    xl: string;
  };
  containerWidth: string;
}

const theme: ITheme = {
  colors: {
    primary: {
      primary0: '#009420',
      'primary+1': '#00AD26',
      'primary-1': '#007A1B',
    },
    secondary: {
      secondary0: '#FFA007',
      'secondary+1': '#FFAC26',
      'secondary-1': '#D98400',
    },
    light: {
      light0: '#fff',
      'light-1': '#F2F2F2',
      'light-2': '#E5E5E5',
    },
    dark: {
      dark0: '#2B2B2B',
      'dark+1': '#454545',
      'dark+2': '#5E5E5E',
      'dark+3': '#787878',
      'dark+4': '#919191',
    },
    danger: 'rgb(255, 77, 79)',
    blue: '#0041A7',
    yellow: '#f8c700',
  },
  typograph: {
    sizes: {
      h2: 67,
      h3: 50,
      h4: 38,
      h5: 28,
      h6: 21,
      xmd: 18,
      p: 16,
      small: 14,
      xs: 12,
      xxs: 9,
    },
    weights: {
      light: 300,
      medium: 400,
      bold: 700,
    },
  },
  breakpoints: {
    sm: '600px',
    md: '758px',
    lg: '992px',
    xl: '1200px',
  },
  containerWidth: '1366px',
};

export default theme;
