import styled from 'styled-components';

export const Container = styled.div``;

export const TopicContainer = styled.div`
  flex: 1;
  background-color: ${(props) => props.theme.colors.light['light-2']};
  border-radius: 8px;
  padding: 1rem;
  width: 100%;
  max-width: 30rem;
  margin: 0 auto;

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    max-width: 100%;
  }
`;

export const OptionsList = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  list-style: none;
`;
