/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Key, useMemo } from 'react';
import { Radio as RadioAnt, Space } from 'antd';

import { Container } from './styles';
import { IRadioProps } from './types';

const Radio: React.FC<IRadioProps> = ({
  label,
  items,
  error,
  vertical,
  ...rest
}) => {
  const radios = useMemo(() => {
    return items.map((item) => (
      <RadioAnt key={item.value as Key} value={item.value}>
        {item.label}
      </RadioAnt>
    ));
  }, [items]);

  return (
    <Container $hasError={!!error}>
      {label && <label>{label}</label>}
      <RadioAnt.Group {...rest}>
        {vertical ? <Space direction="vertical">{radios}</Space> : radios}
      </RadioAnt.Group>
      {error && <span>{error}</span>}
    </Container>
  );
};

export default Radio;
