import React, { useCallback, useMemo, useState } from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd';
import { Tabs } from 'antd';
import TabPane from 'antd/lib/tabs/TabPane';

import CustomAntButton from '@/components/CustomAntButton';
import OptionCard from './OptionCard';

import {
  Container,
  DisabledOptionsList,
  OptionsList,
  TopicContainer,
} from './styles';
import { IOptionsProps } from './types';
import { ButtonsContainer } from '../styles';
import { ICardOption, INewCardFormOption } from '../types';
import { ECardGameOptionTopic } from '../../types';

const Options: React.FC<IOptionsProps> = ({
  formik,
  cardGoalOptions,
  cardStatisticOptions,
  prevStep,
  nextStep,
}) => {
  const [currentView, setCurrentView] = useState('goal-options');

  const reorderList = useCallback(
    (list: INewCardFormOption[], startIndex: number, endIndex: number) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
    },
    []
  );

  const onDragEnd = useCallback(
    (result: DropResult, topic: ECardGameOptionTopic) => {
      if (!result.destination) {
        return;
      }

      const options = {
        ...formik.values.options,
      };

      if (topic === ECardGameOptionTopic.GOAL) {
        const reorderedList = reorderList(
          formik.values.options.goalOptions,
          result.source.index,
          result.destination.index
        );
        options.goalOptions = reorderedList;
      } else if (topic === ECardGameOptionTopic.STATISTIC) {
        const reorderedList = reorderList(
          formik.values.options.statisticOptions,
          result.source.index,
          result.destination.index
        );
        options.statisticOptions = reorderedList;
      }

      formik.setFieldValue('options', options);
    },
    [formik, reorderList]
  );

  const handleChangeOptionValue = useCallback(
    (_id: string, value: string, topic: ECardGameOptionTopic) => {
      function updateOptionValue(
        list: INewCardFormOption[]
      ): INewCardFormOption[] {
        return list.map((option) => {
          if (option._id === _id) {
            return {
              ...option,
              value: parseInt(value) || 0,
            };
          }
          return option;
        });
      }

      const options = {
        ...formik.values.options,
      };

      if (topic === ECardGameOptionTopic.GOAL) {
        options.goalOptions = updateOptionValue(options.goalOptions);
      } else if (topic === ECardGameOptionTopic.STATISTIC) {
        options.statisticOptions = updateOptionValue(options.statisticOptions);
      }

      formik.setFieldValue('options', options);
    },
    [formik]
  );

  const handleEnableOption = useCallback(
    (_id: string, topic: ECardGameOptionTopic) => {
      const options = {
        ...formik.values.options,
      };

      if (topic === ECardGameOptionTopic.GOAL) {
        const selectedOption = cardGoalOptions.find(
          (option) => option._id === _id
        );
        options.goalOptions.push({
          ...selectedOption,
          selected: true,
        } as INewCardFormOption);
      } else if (topic === ECardGameOptionTopic.STATISTIC) {
        const selectedOption = cardStatisticOptions.find(
          (option) => option._id === _id
        );
        options.statisticOptions.push({
          ...selectedOption,
          selected: true,
        } as INewCardFormOption);
      }

      formik.setFieldValue('options', options);
    },
    [cardGoalOptions, cardStatisticOptions, formik]
  );

  const handleDisableOption = useCallback(
    (_id: string, topic: ECardGameOptionTopic) => {
      const options = {
        ...formik.values.options,
      };

      if (topic === ECardGameOptionTopic.GOAL) {
        options.goalOptions = options.goalOptions.filter(
          (option) => option._id !== _id
        );
      } else if (topic === ECardGameOptionTopic.STATISTIC) {
        options.statisticOptions = options.statisticOptions.filter(
          (option) => option._id !== _id
        );
      }

      formik.setFieldValue('options', options);
    },
    [formik]
  );

  const getDisabledOptions = useCallback(
    (options: ICardOption[], selectedOptions: INewCardFormOption[]) => {
      return options.filter((option) => {
        const isDisabledOption = !selectedOptions.find(
          (selectedOption) => selectedOption._id === option._id
        );

        return isDisabledOption;
      });
    },
    []
  );

  const disabledGoalOptions = useMemo(() => {
    const { goalOptions: selectedOptions } = formik.values.options;
    return getDisabledOptions(cardGoalOptions, selectedOptions);
  }, [cardGoalOptions, formik.values.options, getDisabledOptions]);

  const disabledStatisticOptions = useMemo(() => {
    const { statisticOptions: selectedOptions } = formik.values.options;
    return getDisabledOptions(cardStatisticOptions, selectedOptions);
  }, [cardStatisticOptions, formik.values.options, getDisabledOptions]);

  const isNextButtonEnabled = useMemo(() => {
    const { statisticOptions, goalOptions } = formik.values.options;
    const areAllSelectedOptionsValid = [
      ...goalOptions,
      ...statisticOptions,
    ].every((option) => !!option.value && option.value > 0);
    const isAtLeastGoalOptionSelected = goalOptions.length > 0;
    const isAtLeastStatisticOptionSelected = statisticOptions.length > 0;

    return (
      isAtLeastGoalOptionSelected &&
      isAtLeastStatisticOptionSelected &&
      areAllSelectedOptionsValid
    );
  }, [formik.values.options]);

  return (
    <Container>
      <Tabs
        activeKey={currentView}
        onTabClick={(tabKey) => {
          setCurrentView(tabKey);
        }}
        centered
      >
        <TabPane tab="Gols" key="goal-options">
          <TopicContainer>
            <DragDropContext
              onDragEnd={(result) =>
                onDragEnd(result, ECardGameOptionTopic.GOAL)
              }
            >
              <Droppable droppableId="droppable">
                {(provided) => (
                  <OptionsList
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {formik.values.options.goalOptions.map((option, index) => (
                      <Draggable
                        key={option._id}
                        draggableId={option._id}
                        index={index}
                      >
                        {(draggableProvided) => (
                          <OptionCard
                            draggableProvided={draggableProvided}
                            key={option._id}
                            option={option}
                            handleDisableOption={handleDisableOption}
                            handleChangeOptionValue={handleChangeOptionValue}
                          />
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </OptionsList>
                )}
              </Droppable>
            </DragDropContext>
            {!!disabledGoalOptions.length && (
              <DisabledOptionsList>
                {disabledGoalOptions.map((option) => (
                  <OptionCard
                    key={option._id}
                    option={{ ...option, selected: false }}
                    handleEnableOption={handleEnableOption}
                  />
                ))}
              </DisabledOptionsList>
            )}
          </TopicContainer>
        </TabPane>
        <TabPane tab="Estatísticas" key="statistic-options">
          <TopicContainer>
            <DragDropContext
              onDragEnd={(result) =>
                onDragEnd(result, ECardGameOptionTopic.STATISTIC)
              }
            >
              <Droppable droppableId="droppable">
                {(provided) => (
                  <OptionsList
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {formik.values.options.statisticOptions.map(
                      (option, index) => (
                        <Draggable
                          key={option._id}
                          draggableId={option._id}
                          index={index}
                        >
                          {(draggableProvided) => (
                            <OptionCard
                              draggableProvided={draggableProvided}
                              key={option._id}
                              option={option}
                              handleDisableOption={handleDisableOption}
                              handleChangeOptionValue={handleChangeOptionValue}
                            />
                          )}
                        </Draggable>
                      )
                    )}
                    {provided.placeholder}
                  </OptionsList>
                )}
              </Droppable>
            </DragDropContext>
            {!!disabledStatisticOptions.length && (
              <DisabledOptionsList>
                {disabledStatisticOptions.map((option) => (
                  <OptionCard
                    key={option._id}
                    option={{ ...option, selected: false }}
                    handleEnableOption={handleEnableOption}
                  />
                ))}
              </DisabledOptionsList>
            )}
          </TopicContainer>
        </TabPane>
      </Tabs>
      <ButtonsContainer>
        <CustomAntButton onClick={prevStep} type="default">
          Voltar
        </CustomAntButton>
        <CustomAntButton
          onClick={nextStep}
          type="primary"
          disabled={!isNextButtonEnabled}
        >
          Próximo
        </CustomAntButton>
      </ButtonsContainer>
    </Container>
  );
};

export default Options;
