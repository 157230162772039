import styled, { css } from 'styled-components';

const getCardTypeColor = styleProps => ({
  new: styleProps.theme.colors.primary.primary0,
  running: '#e4ce00',
  finished: styleProps.theme.colors.danger,
});

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const TitlePageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    /* AntD Button Changes */
    button {
      margin-left: 16px;
    }
  }
`;

export const MatchCardsListContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;

  > h5,
  > h6,
  > div.ant-spin {
    text-align: center;
    margin: auto 0;
  }

  > h6 {
    font-weight: ${props => props.theme.typograph.weights.medium};
  }
`;

export const MatchCardsList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const MatchCardDetailContainer = styled.button`
  width: 100%;
  background: ${props => props.theme.colors.light.light0};
  border: 0;
  /* padding: 16px; */
  border-radius: 8px;
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.15);
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 32px;
  }
`;

export const MatchCardDetailMainContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  ${props =>
    props.bg &&
    css`
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.8),
          rgba(255, 255, 255, 0.8)
        ),
        url(${props.bg}) center no-repeat;
      background-size: cover;
    `}
  ${props =>
    props.collapsedOnList &&
    css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `}

  > div {
    width: 100%;
    display: flex;
    margin-bottom: 4px;

    h6 {
      text-align: start;
      flex: 1;
      line-height: 110%;
    }

    svg {
      color: ${props => props.theme.colors.dark['dark+4']};
    }
  }

  small {
    color: ${props => props.theme.colors.dark['dark+1']};

    &:nth-child(2) {
      color: ${props => getCardTypeColor(props)[props.cardType]};
      margin-bottom: 16px;
    }
  }
`;

export const MatchCardDetailMatchsContent = styled.div`
  /* margin-top: 16px; */
  /* padding-top: 16px; */
  padding: 16px;
  border-top: 1px solid ${props => props.theme.colors.light['light-2']};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const MatchCardMatchItem = styled.div`
  /* border: 0; */
  width: 49.5%;
  border-radius: 8px;
  background: ${props => props.theme.colors.light.light0};
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  padding: 16px;
  position: relative;
  margin-bottom: 8px;
  border: 1px solid ${props => props.theme.colors.light['light-2']};

  @media only screen and (max-width: 1199px) {
    width: 100%;
  }

  ${props =>
    props.$selected &&
    css`
      width: 100% !important;
    `}

  > div:first-child {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;

    h6 {
      font-weight: ${props => props.theme.typograph.weights.medium};
      color: ${props => props.theme.colors.dark['dark+4']};
      margin: 0 24px;
    }
  }

  > svg {
    position: absolute;
    top: 16px;
    right: 16px;
  }
`;

export const MatchCardMatchItemTeam = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;

  div {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background: ${props => props.theme.colors.light['light-2']};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4px;

    img {
      height: 36px;
    }
  }

  strong {
    text-align: center;
  }
`;

export const MatchCardMatchItemInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  border-top: 1px solid ${props => props.theme.colors.light['light-2']};

  div {
    width: 100%;

    & + div {
      margin-top: 4px;
    }

    small {
      color: ${props => props.theme.colors.dark['dark+3']};
      text-align: start;
    }

    small:nth-child(1) {
      color: ${props => props.theme.colors.primary.primary0};
    }
  }
`;

export const LoadingMatchCardDetailsContainer = styled.div`
  width: 100%;
  justify-content: center;
`;
