import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

import { Container } from './styles';
import { ILoadingProps } from './types';

const loadingIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;

const Loading: React.FC<ILoadingProps> = ({ text, className }) => {
  return (
    <Container className={className}>
      <Spin indicator={loadingIcon} />
      <p>{text || 'Carregando...'}</p>
    </Container>
  );
};

export default Loading;
