import { UserGenderEnum } from '@/models/User';

export interface INewInfluencerFormData {
  influencerInformation: {
    username: string;
    email: string;
    password: string;
    confirmPassword: string;
    code: string;
  };
  personalData: {
    name: string;
    gender: UserGenderEnum;
    docNumber: string;
    phone: string;
  };
  avatar: {
    file: string | null;
    previewUrl: string;
  };
}

export enum UniqueFieldEnum {
  USERNAME = 'username',
  EMAIL = 'email',
  DOCNUMBER = 'docNumber',
  CODE = 'code',
}

export interface ICheckIfIsFieldAvailableProps {
  value: string;
  fieldName: UniqueFieldEnum;
  resolve: (isFieldAvailable: boolean) => void;
}

export interface ILoadingsOfAsyncValidations {
  username: boolean;
  email: boolean;
  docNumber: boolean;
  code: boolean;
}
