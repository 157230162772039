import React from 'react';
import { DatePicker as DatePickerAnt } from 'antd';
import 'moment/locale/pt-br';
import locale from 'antd/es/date-picker/locale/pt_BR';

import { Container } from './styles';

function DatePicker({ label, identifier, error, ...rest }) {
  return (
    <Container error={error}>
      <label htmlFor={identifier}>{label}</label>
      <div>
        <DatePickerAnt id={identifier} {...rest} locale={locale} />
      </div>
      {error && <span>{error}</span>}
    </Container>
  );
}

export default DatePicker;
