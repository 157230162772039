import { produce } from 'immer';

const INITIAL_STATE = {
  data: null,
  loading: false,
};

export default function team(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@user/GET_USER_DATA_SUCCESS':
      case '@team/GET_TEAM_DATA_REQUEST':
        draft.loading = true;
        break;
      case '@team/GET_TEAM_DATA_SUCCESS': {
        draft.data = action.payload.team;
        draft.loading = false;
        break;
      }
      case '@team/GET_TEAM_DATA_FAILURE': {
        draft.loading = false;
        break;
      }
      case '@team/UPDATE_TEAM': {
        draft.data = action.payload.team;
        break;
      }
      case '@auth/SIGN_OUT_SUCCESS': {
        draft = INITIAL_STATE;
        break;
      }

      default:
    }
  });
}
