import React, { useRef } from 'react';

import { Container, BarProgressKnob } from './styles';

const MessageAudioBar = ({ duration, curTime, onTimeUpdate }) => {
  const curPercentage = (curTime / duration) * 100;

  const barRef = useRef(null);

  function calcClickedTime(pageX) {
    if (!barRef.current) return 0;

    const clickPositionInPage = pageX;
    const bar = barRef.current;
    const barStart = bar.getBoundingClientRect().left + window.scrollX;
    const barWidth = bar.offsetWidth;
    const clickPositionInBar = clickPositionInPage - barStart;
    const timePerPixel = duration / barWidth;

    return timePerPixel * clickPositionInBar;
  }

  function handleTimeDrag(e) {
    onTimeUpdate(calcClickedTime(e.pageX));

    function updateTimeOnMove(eMove) {
      onTimeUpdate(calcClickedTime(eMove.pageX));
    }

    document.addEventListener('mousemove', updateTimeOnMove);

    document.addEventListener('mouseup', () => {
      document.removeEventListener('mousemove', updateTimeOnMove);
    });
  }

  function handleTouchTimeDrag(e) {
    onTimeUpdate(calcClickedTime(e.touches[0].pageX));

    function updateTimeOnTouchMove(eTouchMove) {
      const touch = eTouchMove.changedTouches[0];

      onTimeUpdate(calcClickedTime(touch.pageX));
    }

    document.addEventListener('touchmove', updateTimeOnTouchMove);

    document.addEventListener('touchend', () => {
      document.removeEventListener('touchmove', updateTimeOnTouchMove);
    });
  }

  return (
    <Container
      ref={barRef}
      $curPercentage={curPercentage}
      onClick={e => {
        e.stopPropagation();
      }}
      onMouseDown={e => handleTimeDrag(e)}
      onTouchStart={e => handleTouchTimeDrag(e)}
    >
      <BarProgressKnob $curPercentage={curPercentage} />
    </Container>
  );
};

export default MessageAudioBar;
