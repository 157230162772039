import React, { useState, useCallback } from 'react';
import { Modal } from 'antd';
import { FiEdit2, FiCheck, FiX } from 'react-icons/fi';
import { FaCrown } from 'react-icons/fa';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';

import CustomAntButton from '@/components/CustomAntButton';
import Select from '@/components/Select';

import api from '@/services/api';

import {
  Container,
  Content,
  GameInfoContainer,
  PositiveResultText,
  NegativeResultText,
} from './styles';

const { confirm } = Modal;

function getResultText(result) {
  if (typeof result === 'boolean') {
    if (result) {
      return <PositiveResultText>Sim</PositiveResultText>;
    }
    return <NegativeResultText>Não</NegativeResultText>;
  }

  return <p>-----</p>;
}

function getButtonType(buttonType) {
  if (buttonType === 1) {
    return `Somente botão SIM`;
  }
  if (buttonType === 2) {
    return `Somente botão NÃO`;
  }

  return `Botões SIM e NÃO`;
}

function PushGameCard({ pushGame, getPushGames }) {
  const [isEditingResult, setIsEditingResult] = useState(false);

  const [result, setResult] = useState(pushGame.answer);
  const [loadingSendGameToBeFinished, setLoadingSendGameToBeFinished] =
    useState(false);

  const handleSubmit = useCallback(async () => {
    try {
      const body = {
        answer: result,
        endGame: true,
      };

      await new Promise((resolve) => {
        confirm({
          title: 'Deseja realmente finalizar esse jogo?',
          icon: <ExclamationCircleOutlined />,
          content: 'Os dados inseridos anteriormente serão substituídos',
          cancelText: 'Cancelar',
          okText: 'Finalizar jogo',
          onOk() {
            resolve(true);
          },
        });
      });

      setLoadingSendGameToBeFinished(true);
      await api.put(`/api/push-game/${pushGame._id}`, body);

      toast.success('Jogo finalizado com sucesso');

      getPushGames();
      // setLoadingSendGameToBeFinished(false);
    } catch (error) {
      toast.error(
        'Ocorreu um erro ao finalizar esse jogo. Recarregue a página e tente novamente!'
      );
      setLoadingSendGameToBeFinished(false);
    }
  }, [getPushGames, pushGame._id, result]);

  return (
    <Container>
      <Content>
        <h6>{pushGame.question}</h6>
        <p>
          <FaCrown size={16} />
          R${pushGame.amount}
        </p>
        <GameInfoContainer>
          <div>
            <small>Valor do jogo</small>
            <p>R${pushGame.price}</p>
          </div>
          <div>
            <small>Tipo de botão</small>
            <p>{getButtonType(pushGame.buttonType)}</p>
          </div>
          {pushGame.isFinished && (
            <>
              <div>
                <small>Total acumulado</small>
                <p>R${pushGame.amountReceived}</p>
              </div>
              <div>
                <small>Quantidade de jogadores</small>
                <p>{pushGame.playersCount} jogadores</p>
              </div>
              <div>
                <small>Quantidade de vencedores</small>
                <p>{pushGame.winnerCount} vencedores</p>
              </div>
            </>
          )}
        </GameInfoContainer>
        {!isEditingResult ? (
          <div>
            <div>
              <small>Resultado</small>
              {getResultText(pushGame.answer)}
            </div>
            <CustomAntButton
              type="text"
              useTextPrimary
              icon={<FiEdit2 size={20} />}
              onClick={() => setIsEditingResult(true)}
            />
          </div>
        ) : (
          <div>
            <Select
              label="Resultado"
              identifier="result"
              placeholder="Selecione um resultado"
              value={result}
              onChange={(value) => {
                setResult(value);
              }}
              options={[
                { label: 'Sim', value: true },
                { label: 'Não', value: false },
              ]}
            />
            <CustomAntButton
              type="text"
              danger
              icon={<FiX size={20} />}
              onClick={() => {
                setIsEditingResult(false);
                setResult(pushGame.answer);
              }}
              style={{ marginBottom: 8, marginLeft: 16, flexShrink: 0 }}
            />
            <CustomAntButton
              type="primary"
              icon={<FiCheck size={20} />}
              loading={loadingSendGameToBeFinished}
              onClick={() => {
                if (
                  typeof result === 'boolean' ||
                  !loadingSendGameToBeFinished
                ) {
                  handleSubmit();
                }
              }}
              disabled={
                !(typeof result === 'boolean') || loadingSendGameToBeFinished
              }
              style={{ marginBottom: 8, marginLeft: 8, flexShrink: 0 }}
            />
          </div>
        )}
      </Content>
    </Container>
  );
}

export default PushGameCard;
