import React from 'react';
import { Button } from 'antd';
import { FastField, Field } from 'formik';

import Input from '@/components/Input';
import InputMask from '@/components/InputMask';

import { ButtonsContainer } from '../styles';

function CompanyData({
  formik,
  nextStep,
  prevStep,
  loadingsOfAsyncValidations,
  setFocusedField,
}) {
  function eneableNextStepButton({ touched, errors }) {
    if (!touched.companyData) {
      return false;
    }

    if (!errors.companyData) {
      return true;
    }

    return false;
  }
  return (
    <>
      <FastField name="companyData.name">
        {({ field, meta }) => {
          return (
            <Input
              {...field}
              label="Razão Social"
              placeholder="Digite a razão social da empreas"
              identifier="name"
              type="text"
              error={meta?.touched && meta?.error && meta?.error}
              onFocus={() => setFocusedField('companyData.name')}
            />
          );
        }}
      </FastField>
      <FastField name="companyData.altName">
        {({ field, meta }) => {
          return (
            <Input
              {...field}
              label="Nome Fantasia"
              placeholder="Digite o nome fantasia da empreas"
              identifier="altName"
              type="text"
              error={meta?.touched && meta?.error && meta?.error}
              onFocus={() => setFocusedField('companyData.altName')}
            />
          );
        }}
      </FastField>
      <Field name="companyData.docNumber">
        {({ field, meta }) => {
          return (
            <InputMask
              {...field}
              mask="99.999.999/9999-99"
              label="CNPJ"
              identifier="docNumber"
              placeholder="99.999.999/9999-99"
              type="text"
              error={meta?.touched && meta?.error && meta?.error}
              isLoading={!!loadingsOfAsyncValidations?.docNumber}
              onFocus={() => setFocusedField('companyData.docNumber')}
            />
          );
        }}
      </Field>
      <FastField name="companyData.phone">
        {({ field, meta }) => {
          return (
            <InputMask
              {...field}
              mask="(99)99999-9999"
              label="Telefone celular"
              identifier="phone"
              placeholder="(99)99999-9999"
              type="text"
              error={meta?.touched && meta?.error && meta?.error}
              onFocus={() => setFocusedField('companyData.phone')}
            />
          );
        }}
      </FastField>
      <FastField name="companyData.url">
        {({ field, meta }) => {
          return (
            <Input
              {...field}
              label="URL"
              placeholder="Digite a URL do patrocinador"
              identifier="url"
              type="text"
              error={meta?.touched && meta?.error && meta?.error}
              onFocus={() => setFocusedField('companyData.url')}
              onChange={(e) => {
                e.target.value = e.target.value.replace(/\s+/g, '');
                field.onChange(e);
              }}
            />
          );
        }}
      </FastField>
      <ButtonsContainer>
        <Button onClick={prevStep} type="default">
          Voltar
        </Button>
        <Button
          onClick={nextStep}
          type="primary"
          disabled={!eneableNextStepButton(formik)}
        >
          Próximo
        </Button>
      </ButtonsContainer>
    </>
  );
}

export default CompanyData;
