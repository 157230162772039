import React from 'react';
import { Breadcrumb } from 'antd';
import { Link, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { getRoutes } from '@/routes/routes';

function Breadcrumbs() {
  const me = useSelector((state) => state.user.profile);
  const routesList = getRoutes(me?.roles);
  const routeMatch = useRouteMatch();

  const crumbs = routesList
    // Get all routes that contain the current one.
    .filter(
      (routeDefinition) =>
        routeMatch.path.includes(routeDefinition.path) &&
        !!routeDefinition.isPrivate
    )
    // Swap out any dynamic routes with their param values.
    // E.g. "/pizza/:pizzaId" will become "/pizza/1"
    .map(({ path, name }) => ({
      path: Object.keys(routeMatch.params).length
        ? Object.keys(routeMatch.params).reduce(
            (currentPath, param) =>
              currentPath.replace(`:${param}`, routeMatch.params[param]),
            path
          )
        : path,
      name,
    }));

  const preRoutes = crumbs;
  const currentRoute = crumbs.pop();
  return (
    <Breadcrumb>
      {preRoutes.map(({ path, name }) => (
        <Breadcrumb.Item key={`crumb_${path}`}>
          <Link to={path}>{name}</Link>
        </Breadcrumb.Item>
      ))}
      <Breadcrumb.Item>{currentRoute?.name}</Breadcrumb.Item>
    </Breadcrumb>
  );
}

export default Breadcrumbs;
