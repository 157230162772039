import React, { useCallback } from 'react';
import { Formik, FastField } from 'formik';
import { Button, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import * as Yup from 'yup';
import * as _ from 'lodash';
import { parseISO } from 'date-fns';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import Input from '@/components/Input';
import DatePicker from '@/components/DatePicker';

import api from '@/services/api';

import { Container, ButtonsContainer } from './styles';

const { confirm } = Modal;

function disabledDateTime() {
  function range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  return {
    // disabledHours: () => range(0, 24).splice(4, 20),
    // disabledMinutes: () => range(30, 60),
    disabledSeconds: () => range(0, 60),
  };
}

function Details({ selectedMatchDetails, handleGetSelectedMatchData }) {
  const me = useSelector(store => store.user);

  let canEdit = true;
  if (me?.type === 'team')
    canEdit = selectedMatchDetails?.managedBy?.includes(me?.profile?._id);

  const handleSubmit = useCallback(
    async (values, actions) => {
      try {
        const body = {
          info: {
            place: values.place,
            date: values.date.toDate(),
            address: values.address,
          },
        };

        const initialData = {
          info: {
            place: selectedMatchDetails.info.place,
            date: parseISO(selectedMatchDetails.info.date),
            address: selectedMatchDetails.info.address,
          },
        };
        if (_.isEqual(initialData, body)) {
          toast.warn('Não houveram alterações nos dados da partida!');
          actions.setSubmitting(false);
          return;
        }

        await new Promise(resolve => {
          confirm({
            title: 'Deseja realmente atualizar os detalhes dessa partida?',
            icon: <ExclamationCircleOutlined />,
            content: 'Os dados atuais serão substituídos',
            cancelText: 'Cancelar',
            okText: 'Atualizar detalhes',
            onOk() {
              resolve(true);
            },
          });
        });

        await api.put(
          `/api/championship/${selectedMatchDetails._champ}/games/${selectedMatchDetails._id}`,
          body
        );
        toast.success('Os detalhes da partida foram atualizos!');
        handleGetSelectedMatchData();
      } catch (error) {
        toast.error(
          'Ocorreu um erro ao atualizar os detalhes dessa partida. Recarregue a página e tente novamente!'
        );
        actions.setSubmitting(false);
      }
    },
    [handleGetSelectedMatchData, selectedMatchDetails]
  );

  function eneableFinishButton({ isSubmitting, errors }) {
    // if (!touched) {
    //   return false;
    // }
    if (isSubmitting) {
      return false;
    }

    if (!errors.place && !errors.date && !errors.address) {
      return true;
    }

    return false;
  }

  return (
    <Container>
      <Formik
        initialValues={{
          place: selectedMatchDetails.info.place,
          date: moment(
            parseISO(selectedMatchDetails.info.date),
            'DD/MM/YYYY kk:mm:ss'
          ),
          address: selectedMatchDetails.info.address,
        }}
        validationSchema={Yup.object().shape({
          place: Yup.string().required('O local da partida é obrigatório'),
          date: Yup.mixed().required('A data da partida é obrigatório'),
          address: Yup.string().required('O endereço é obrigatório'),
        })}
        onSubmit={handleSubmit}
        validateOnMount
      >
        {formikProps => (
          <form onSubmit={formikProps.handleSubmit}>
            <FastField name="place">
              {({ field, meta }) => {
                return (
                  <Input
                    {...field}
                    label="Local"
                    placeholder="Digite o local da partida"
                    identifier="place"
                    type="text"
                    error={meta?.touched && meta?.error && meta?.error}
                    disabled={!canEdit}
                  />
                );
              }}
            </FastField>
            <FastField name="date">
              {({ field, meta }) => {
                return (
                  <DatePicker
                    {...field}
                    label="Data e hora"
                    identifier="date"
                    error={meta?.error}
                    onChange={momentDate => {
                      if (momentDate) {
                        momentDate.set('second', 0);
                      }
                      formikProps.setFieldValue('date', momentDate);
                    }}
                    showTime
                    disabledTime={disabledDateTime}
                    format="dddd, DD/MM/YYYY [às] HH:mm:ss"
                    disabled={!canEdit}
                  />
                );
              }}
            </FastField>
            <FastField name="address">
              {({ field, meta }) => {
                return (
                  <Input
                    {...field}
                    label="Endereço da partida"
                    placeholder="Cidade, Estado, País"
                    identifier="address"
                    type="text"
                    error={meta?.touched && meta?.error && meta?.error}
                    disabled={!canEdit}
                  />
                );
              }}
            </FastField>
            {canEdit && (
              <ButtonsContainer>
                <Button
                  disabled={!eneableFinishButton(formikProps)}
                  type="primary"
                  htmlType="submit"
                >
                  {!formikProps.isSubmitting
                    ? 'Atualizar detalhes da partida'
                    : 'Carregando...'}
                </Button>
              </ButtonsContainer>
            )}
          </form>
        )}
      </Formik>
    </Container>
  );
}

export default Details;
