import { createGlobalStyle } from 'styled-components';

import './App.less';
import 'react-toastify/dist/ReactToastify.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-image-crop/dist/ReactCrop.css';

export default createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        outline: 0;
        box-sizing: border-box;
    }

    *:focus {
        outline: 0;
    }

    html, body, #root {
        height: 100%;
        font-family: 'Roboto', sans-serif;
    }

    body {
        -webkit-font-smoothing: antialiased;
    }

    body, input, button {
        font: 14px 'Roboto', sans-serif;
    }

    label {
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        color: ${(props) => props.theme.colors.dark['dark+4']};
        margin-top: 4px;
    }

    a {
      text-decoration: none;
      font-size: ${(props) => props.theme.typograph.sizes.p}px;
      font-weight: ${(props) => props.theme.typograph.weights.medium};
      color: ${(props) => props.theme.colors.dark['dark+1']};
      margin-bottom: 0;
    }

    ul {
        list-style: none;
    }

    button {
        cursor: pointer;
    }

    h4 {
      font-size: ${(props) => props.theme.typograph.sizes.h4}px;
      font-weight: ${(props) => props.theme.typograph.weights.bold};
      color: ${(props) => props.theme.colors.dark.dark0};
      margin-bottom: 0px;
    }

    h5 {
      font-size: ${(props) => props.theme.typograph.sizes.h5}px;
      font-weight: ${(props) => props.theme.typograph.weights.bold};
      color: ${(props) => props.theme.colors.dark.dark0};
      display: inline-block;
    }

    h6 {
      font-size: ${(props) => props.theme.typograph.sizes.h6}px;
      font-weight: ${(props) => props.theme.typograph.weights.bold};
      color: ${(props) => props.theme.colors.dark.dark0};
      margin-bottom: 0px;
    }

    p {
      font-size: ${(props) => props.theme.typograph.sizes.p}px;
      font-weight: ${(props) => props.theme.typograph.weights.medium};
      color: ${(props) => props.theme.colors.dark.dark0};
      margin-bottom: 0;
    }

    strong {
      font-size: ${(props) => props.theme.typograph.sizes.p}px;
      font-weight: ${(props) => props.theme.typograph.weights.bold};
      color: ${(props) => props.theme.colors.dark.dark0};
      margin-bottom: 0;
    }

    small {
      font-size: ${(props) => props.theme.typograph.sizes.small}px;
      font-weight: ${(props) => props.theme.typograph.weights.medium};
      color: ${(props) => props.theme.colors.dark.dark0};
      display: block;
    }

    img {
      max-width: 100%;
      object-fit: cover;
      object-position: center;
    }
`;
