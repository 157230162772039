import env from '@/env';

const environment = {};

const { NODE_ENV } = process.env;

const { BASE_URL, API_URL, SOCKET_URL } = env[NODE_ENV];

const isUrl = (url) => {
  if (!url) return false;
  // using regex to check if the url is valid
  const regex = new RegExp(
    // eslint-disable-next-line no-useless-escape
    /^(http|https):\/\/[^ "]+$/
  );
  return regex.test(url);
};

if (NODE_ENV === 'development') {
  environment.BASE_URL =
    isUrl(BASE_URL) && BASE_URL ? BASE_URL : 'http://localhost:3000';
  environment.API_URL =
    isUrl(API_URL) && API_URL ? API_URL : 'http://localhost:8000';
  environment.SOCKET_URL =
    isUrl(SOCKET_URL) && SOCKET_URL ? SOCKET_URL : 'http://localhost:8000';
} else if (NODE_ENV === 'production') {
  environment.BASE_URL =
    isUrl(BASE_URL) && BASE_URL ? BASE_URL : 'https://adm.titan369.com';
  environment.API_URL =
    isUrl(API_URL) && API_URL ? API_URL : 'https://api.titan369.com';
  environment.SOCKET_URL =
    isUrl(SOCKET_URL) && SOCKET_URL ? SOCKET_URL : 'https://api.titan369.com';
}
environment.GOOGLE_MAPS_API_KEY = '';

export default environment;
