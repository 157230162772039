import styled from 'styled-components';

export const SessionContainer = styled.div`
  width: 100%;
  margin-bottom: 2rem;

  > img {
    width: 6.5rem;
    margin-top: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  }

  > p {
    margin-top: 1rem;
  }

  > div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 1rem;
  }
`;

export const SessionItems = styled.ul`
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 0;

  li {
    list-style: none;
    width: 100%;
    margin-bottom: 0.5rem;

    &:last-child {
      margin-bottom: 0;
    }

    small {
      color: ${(props) => props.theme.colors.primary.primary0};
    }
  }
`;

export const TeamPlayer = styled.div`
  border: 0;
  background: ${(props) => props.theme.colors.light['light-1']};
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1rem 0;
  text-align: start;

  & + div {
    border-top: 1px solid ${(props) => props.theme.colors.light['light-2']};
  }

  > div:nth-child(1) {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    border: 1px solid ${(props) => props.theme.colors.dark['dark+4']};
    background: ${(props) => props.theme.colors.light['light-2']};
    transition: 0.2s opacity;
    margin-right: 1rem;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  div:nth-child(2) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    small {
      overflow: inherit;
      text-overflow: inherit;
      color: ${(props) => props.theme.colors.dark['dark+2']};
    }

    div {
      margin-top: 0.5rem;
      display: flex;
      align-items: center;

      img {
        height: 1.125rem;
        margin-right: 0.5rem;
      }

      p {
        color: ${(props) => props.theme.colors.dark['dark+2']};
      }
    }
  }
`;

export const AthleteScore = styled.small`
  color: ${(props) => props.theme.colors.primary['primary-1']} !important;
`;

export const OptionsTopicsContainer = styled.div`
  display: flex;
  gap: 1rem;

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    flex-direction: column;
  }

  > div {
    flex: 1;

    strong {
      color: ${(props) => props.theme.colors.dark.dark0};
      margin-bottom: 0.5rem;
      display: block;
    }

    small {
      display: block;
      color: ${(props) => props.theme.colors.dark['dark+1']};

      & + small {
        margin-top: 0.125rem;
      }

      span {
        color: ${(props) => props.theme.colors.primary.primary0};
      }
    }
  }
`;

export const SelectedInfluencer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1rem;
  transition: 0.2s background;
  margin-top: 0.5rem;
  background: ${(props) => props.theme.colors.light.light0};

  &:not(:first-child) {
    border-radius: 0.5rem;
  }

  img {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 0.5rem;
  }

  > div {
    text-align: start;
    flex: 1;
    small {
      color: ${(props) => props.theme.colors.dark['dark+3']};
    }
  }
`;
