import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
  padding: 56px 32px;

  @media only screen and (max-width: 600px) {
    padding: 32px 16px;
    min-height: calc(100vh - 61px);
  }

  .ant-carousel .slick-dots-bottom {
    bottom: 0px;
  }
`;

export const TitlePageContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 32px 0px;
  justify-content: space-between;
  align-items: center;

  /* AntD Button Changes */
  button {
    margin-left: 16px;

    @media only screen and (min-width: 601px) {
      display: none;
    }
  }
`;

// export const Content = styled.div`
//   width: 100%;
//   max-width: 632px;
//   margin: 0 auto;
// `;

// export const AdvertisementItemContainer = styled.div`
//   width: 100%;
//   padding-top: 56.25%;
//   position: relative;
//   /* background: linear-gradient(
//       180deg,
//       rgba(0, 0, 0, 0) 0%,
//       rgba(0, 0, 0, 0.6) 56.25%,
//       #000000 100%
//     ),
//     url(${props => props.$bg}) no-repeat center;
//   background-size: cover; */
//   /* display: flex; */
//   /* flex-direction: column; */

//   img {
//     position: absolute;
//     z-index: 5;
//     top: 0px;
//     right: 0px;
//     bottom: 0px;
//     left: 0px;
//     width: 100%;
//     object-fit: cover;
//   }

//   div.shimmer {
//     position: absolute !important;
//     z-index: 5 !important;
//     top: 0px !important;
//     right: 0px !important;
//     bottom: 0px !important;
//     left: 0px !important;
//     width: 100% !important;
//     border: 1px solid ${props =>
//       props.theme.colors.light['light-2']} !important;
//   }
// `;

// export const AdvertisementItemContent = styled.div`
// /* width: 100%; */
//   position: absolute;
//   z-index: 10;
//   top: 0px;
//   right: 0px;
//   bottom: 0px;
//   left: 0px;
//   /* flex: 1; */
//   padding: 16px;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
//   cursor: default;
//   background: linear-gradient(
//     180deg,
//     rgba(0, 0, 0, 0) 0%,
//     rgba(0, 0, 0, 0.6) 56.25%,
//     #000000 100%
//   );

//   > button {
//     align-self: flex-end;

//     /* color: ${props => props.theme.colors.light.light0};

//     &:hover,
//     &:active,
//     &:focus {
//       color: ${props => darken(0.2, props.theme.colors.light.light0)};
//     } */
//   }

//   ul {
//     width: 100%;
//     cursor: default;

//     li {
//       list-style: none;
//       width: 100%;
//       margin-bottom: 8px;

//       &:last-child {
//         margin-bottom: 0px;
//       }

//       small {
//         color: ${props => props.theme.colors.primary['primary+1']};
//       }
//     }

//     p {
//       margin-bottom: 8px;
//       color: ${props => props.theme.colors.light.light0};

//       &:last-child {
//         margin-bottom: 0px;
//       }
//     }
//   }
// `;
