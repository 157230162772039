import React from 'react';
import { Button } from 'antd';
import moment from 'moment';
import {
  FastField,
  FastFieldProps,
  Field,
  FieldProps,
  FormikProps,
} from 'formik';

import Input from '@/components/Input';
import DatePicker from '@/components/DatePicker';

import Select from '@/components/Select';
import { ButtonsContainer } from '../styles';
import { ICardInformationProps } from './types';
import { INewCardFormData } from '../types';
import { EGameType } from '../../types';
import { Container } from './styles';
import Influencers from './Influencers';
import Percentages from './Percentages';
import Prize from './Prize';

const CardInformation: React.FC<ICardInformationProps> = ({
  formik,
  nextStep,
  cardPaymentPercentage,
}) => {
  function getTotalPercent(formData: INewCardFormData): number {
    const { percentages } = formData.cardInformation;
    const values = [
      percentages.homePercent,
      percentages.influencerPercent,
      percentages.winnerPercent,
    ];
    return values.reduce((total, value) => total + parseInt(value), 0);
  }

  function checkIfIsNextButtonEnabled({
    touched,
    errors,
    values,
  }: FormikProps<INewCardFormData>): boolean {
    const totalPercent = getTotalPercent(values);

    const isNotFormTouched = !touched.cardInformation;
    const hasErrors = errors.cardInformation;
    const isTotalPercentInvalid = totalPercent !== 100;

    if (isNotFormTouched || hasErrors || isTotalPercentInvalid) {
      return false;
    }

    return true;
  }

  return (
    <Container>
      <FastField name="cardInformation.type">
        {({ field, meta, form }: FastFieldProps) => {
          return (
            <Select
              {...field}
              label="Modo de jogo"
              identifier="buttonType"
              placeholder="Selecione o modo de jogo"
              options={[
                { label: 'Opções', value: EGameType.OPTIONS },
                { label: 'Clássico', value: EGameType.CLASSIC },
              ]}
              error={meta?.touched && meta?.error}
              onChange={(value: string) => {
                form.setFieldValue('cardInformation.type', value);
              }}
            />
          );
        }}
      </FastField>
      <FastField name="cardInformation.name">
        {({ field, meta }: FastFieldProps) => {
          return (
            <Input
              {...field}
              label="Nome da cartela"
              placeholder="Digite o nome da cartela"
              identifier="matchCardName"
              type="text"
              error={meta?.touched && meta?.error && meta?.error}
            />
          );
        }}
      </FastField>
      <FastField name="cardInformation.price">
        {({ field, meta }: FastFieldProps) => {
          return (
            <Input
              {...field}
              label="Valor do jogo"
              placeholder="Digite o valor do jogo da cartela"
              identifier="matchCardPrice"
              type="text"
              error={meta?.touched && meta?.error && meta?.error}
              addonBefore="R$"
            />
          );
        }}
      </FastField>
      <Field name="cardInformation.startDate">
        {({ field, meta }: FieldProps) => {
          return (
            <DatePicker
              {...field}
              label="Data e hora inicial"
              identifier="startDate"
              error={meta?.touched && meta?.error}
              onChange={(momentDate: moment.Moment) => {
                if (momentDate) {
                  momentDate.set('second', 0);
                }
                formik.setFieldValue('cardInformation.startDate', momentDate);
              }}
              showTime
              format="dddd, DD/MM/YYYY [às] HH:mm"
            />
          );
        }}
      </Field>
      <Field name="cardInformation.endDate">
        {({ field, meta, form }: FieldProps<INewCardFormData>) => {
          return (
            <DatePicker
              {...field}
              label="Data e hora final"
              identifier="endDate"
              error={meta?.touched && meta?.error}
              onChange={(momentDate: moment.Moment) => {
                if (momentDate) {
                  momentDate.set('second', 0);
                }
                formik.setFieldValue('cardInformation.endDate', momentDate);
              }}
              showTime
              format="dddd, DD/MM/YYYY [às] HH:mm"
              disabled={!form.values.cardInformation.startDate}
            />
          );
        }}
      </Field>
      <h6>Premiação</h6>
      <Prize formik={formik} cardPaymentPercentage={cardPaymentPercentage} />
      <h6>Influenciadores</h6>
      <Influencers
        formik={formik}
        cardPaymentPercentage={cardPaymentPercentage}
      />
      <h6>Porcentagens da cartela</h6>
      <Percentages formik={formik} />
      <ButtonsContainer>
        <Button
          onClick={nextStep}
          type="primary"
          disabled={!checkIfIsNextButtonEnabled(formik)}
        >
          Próximo
        </Button>
      </ButtonsContainer>
    </Container>
  );
};

export default CardInformation;
