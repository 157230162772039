import React, { useState } from 'react';
import { Tabs } from 'antd';

import ChatCardGame from './ChatCardGame';
import ChatAthletesOfTheWeek from './ChatAthletesOfTheWeek';
import ChatCoachFriend from './ChatCoachFriend';
import ChatVersus from './ChatVersus';

import { Container, TitlePageContainer } from './styles';

const { TabPane } = Tabs;

function ChatsReports() {
  const [currentView, setCurrentView] = useState('chat-coach-friend');

  return (
    <Container>
      <TitlePageContainer>
        <h4>Denúncias dos Chats</h4>
      </TitlePageContainer>
      <Tabs
        activeKey={currentView}
        onTabClick={tabKey => {
          setCurrentView(tabKey);
        }}
        centered
      >
        <TabPane tab="Família VS Amigos" key="chat-coach-friend">
          <ChatCoachFriend />
        </TabPane>
        <TabPane tab="Versus" key="chat-versus">
          <ChatVersus />
        </TabPane>
        <TabPane tab="Cartelas de campeonatos" key="chat-card-game">
          <ChatCardGame />
        </TabPane>
        <TabPane tab="Artilheiros da semana" key="chat-athletes-of-the-week">
          <ChatAthletesOfTheWeek />
        </TabPane>
      </Tabs>
    </Container>
  );
}

export default ChatsReports;
