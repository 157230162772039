import styled, { css } from 'styled-components';
import { shade } from 'polished';

export const Container = styled.ul`
  margin-bottom: 0;
  margin-top: 24px;
`;

export const Player = styled.li`
  border: 0;
  background: ${props => props.theme.colors.light['light-1']};
  width: 100%;
  padding: 16px 0;
  text-align: start;
  transition: 0.2s background;
  cursor: pointer;

  &:hover {
    background: ${props => shade(0.1, props.theme.colors.light['light-1'])};
  }

  & + li {
    border-top: 1px solid ${props => props.theme.colors.light['light-2']};
  }
`;

export const PlayerMainContent = styled.div`
  display: flex;
  /* justify-content: space-between; */
  align-items: center;

  > button:nth-child(1) {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid ${props => props.theme.colors.dark['dark+4']};
    background: ${props => props.theme.colors.light['light-2']};
    transition: 0.2s opacity;
    margin-right: 16px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }

    &:hover {
      opacity: 0.8;
    }
  }


  div:nth-child(2) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    small {
      overflow: inherit;
      text-overflow: inherit;
      color: ${props => props.theme.colors.dark['dark+2']};
    }
  }

  > div:nth-child(3) {
    display: flex;
    margin-left: auto;

    button {
      margin-left: 8px;

      /* &:last-child {
        margin-left: 16px;
        color: ${props => props.theme.colors.dark['dark+4']};
      } */
    }

    div {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 16px;
      color: ${props => props.theme.colors.dark['dark+4']};
    }
  }
`;

export const PlayerRestContent = styled.ul`
  width: 100%;
  margin-top: 16px;

  li {
    list-style: none;
    width: 100%;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0px;
    }

    small {
      color: ${props => props.theme.colors.primary.primary0};
    }
  }
`;

export const EditPlayerContainer = styled.li`
  width: 100%;
  background: ${props => props.theme.colors.light.light0};
  border-radius: 8px;
  /* box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25); */
  padding: 24px;
  border-top: 0;

  form {
    width: 100%;
    max-width: 728px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: space-between;
  margin-top: 24px;

  button.ant-btn-primary {
    flex: 1;
    margin-left: 16px;
  }
`;

export const ActionButtonAvatar = styled.div`
  width: 100%;
  display: flex;
  padding: 16px;
  transition: background-color 0.2s;
  cursor: pointer;

  color: ${props => props.theme.colors.dark.dark0};

  ${props =>
    props.danger &&
    css`
      color: ${props.theme.colors.danger};
    `}

  span {
    font-size: 16px;
    margin-left: 16px;
  }

  &:hover {
    background-color: ${props => props.theme.colors.light['light-1']};
  }
`;
