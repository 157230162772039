export function formatCpf(cpf) {
  if (cpf && cpf.length === 11) {
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }
  return cpf;
}

export function formatCnpj(cnpj) {
  if (cnpj && cnpj.length === 14) {
    return cnpj.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
      '$1.$2.$3/$4-$5'
    );
  }
  return cnpj;
}

export function formatGender(initialLetterOfGender) {
  if (initialLetterOfGender === 'M') {
    return 'Masculino';
  }
  if (initialLetterOfGender === 'F') {
    return 'Feminino';
  }
  if (initialLetterOfGender === 'O') {
    return 'Outro';
  }

  return 'Sexo inválido';
}

export function formatPhone(phone) {
  if (phone && phone.length === 11) {
    return phone.replace(/(\d{2})(\d{5})(\d{4})/, '($1)$2-$3');
  }
  if (phone && phone.length === 10) {
    return phone.replace(/(\d{2})(\d{4})(\d{4})/, '($1)$2-$3');
  }

  return phone;
}

export function formatPassword(password) {
  if (!password) {
    return 'Não informado';
  }

  let formattedPassword = password.split('');
  formattedPassword.fill('*');
  formattedPassword = formattedPassword.join('');

  return formattedPassword;
}

export function formatNumberToCurrency(value) {
  value = !Number.isNaN(value) ? value : 0;

  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(value);
}

export function formatDecimalPlacesIfCents(amount, limit = 2) {
  amount = !Number.isNaN(amount) ? amount : 0;

  return amount % 1 !== 0 ? amount.toFixed(limit) : amount;
}
