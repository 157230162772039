import React, { useState, useEffect, useCallback } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import api from '@/services/api';
import Breadcrumbs from '@/components/Breadcrumbs';
import { Container, TitlePageContainer } from './styles';

import Details from './Details';
import EditAdvertisementSettings from './EditAdvertisementSettings';

function Users({ match }) {
  const routeMatch = useRouteMatch();

  const [advertisementSettings, setAdvertisementSettings] = useState(null);
  const [loadingAdvertisementSettings, setLoadingAdvertisementSettings] =
    useState(true);

  const handleGetAdvertisementSettings = useCallback(async () => {
    setLoadingAdvertisementSettings(true);
    try {
      const { data } = await api.get('/api/slide-main/settings');

      setAdvertisementSettings(data.docs.length ? data.docs[0] : null);
    } catch (error) {
      /* */
    }
    setLoadingAdvertisementSettings(false);
  }, []);

  // const handleGetHomeData = useCallback(() => {
  //   Promise.all([handleGetAdverts(), handleGetAdvertisementSettings()])
  //     .then(() => {
  //       setLoadingData(false);
  //     })
  //     .catch(() => {
  //       setLoadingData(false);
  //       toast.error(
  //         'Ocorreu um erro inesperado ao carregar as configurações da publicidade!'
  //       );
  //     });
  // }, [handleGetAdvertisementSettings, handleGetAdverts]);

  useEffect(() => {
    handleGetAdvertisementSettings();
  }, [handleGetAdvertisementSettings]);

  // if (loadingData || loadingAdverts) {
  //   return (
  //     <Container>
  //       <Breadcrumbs match={match} />
  //       <TitlePageContainer>
  //         <h4>Publicidade</h4>
  //       </TitlePageContainer>

  //     </Container>
  //   );
  // }

  return (
    <Container>
      <Breadcrumbs />
      <TitlePageContainer>
        <h4>Publicidade</h4>
      </TitlePageContainer>
      <Switch>
        <Route exact path={routeMatch.path}>
          <Details
            // loadingData={loadingData}
            // loadingAdverts={loadingAdverts}
            // routeMatch={routeMatch.path}
            // adverts={adverts}
            // setAdverts={setAdverts}
            // advertsPagination={advertsPagination}
            // setAdvertsPagination={setAdvertsPagination}
            // handleGetAdverts={handleGetAdverts}
            // inactiveAdverts={inactiveAdverts}
            // setInactiveAdverts={setInactiveAdverts}
            // inactiveAdvertsPagination={inactiveAdvertsPagination}
            // setInactiveAdvertsPagination={setInactiveAdvertsPagination}
            // handleGetInactiveAdverts={handleGetInactiveAdverts}
            loadingAdvertisementSettings={loadingAdvertisementSettings}
            advertisementSettings={advertisementSettings}
          />
        </Route>
        <Route path={`${routeMatch.path}/edit_advertisement_settings`}>
          <EditAdvertisementSettings
            advertisementSettings={advertisementSettings}
            setAdvertisementSettings={setAdvertisementSettings}
          />
        </Route>
      </Switch>
    </Container>
  );
}

export default Users;
