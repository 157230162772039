import styled from 'styled-components';

export const Container = styled.div``;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: space-between;
  margin-top: 24px;

  button.ant-btn-primary {
    flex: 1;
  }

  button.ant-btn-default {
    margin-right: 16px;
  }
`;
