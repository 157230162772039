import React, { useState } from 'react';
import { Modal, Slider } from 'antd';
import * as _ from 'lodash';

import { FiCheck } from 'react-icons/fi';

import {
  Container,
  Header,
  Content,
  TeamImageContainer,
  ThrowContainer,
  SelectPlayerOfThrowButton,
  AddThrowContentModal,
  TeamPlayerSelector,
} from './styles';

// interface IPayGoalMode {
//   payGoalGame: IPayGoalGameState;
//   handleSelectPlayerAndTimeOfThrowOnPayGoal(
//     team: 'local' | 'away',
//     throwOfTeam: 'oneThrow' | 'twoThrow' | 'threeThrow',
//     playerId: string,
//     playerName: string,
//   ): void;
//   localTeam: ITeam;
//   awayTeam: ITeam;
// }

function handleDisableButtonOfModal(currentValues, initialValues) {
  return (
    _.isEqual(initialValues, currentValues) ||
    !currentValues?.goalTime ||
    !currentValues?.player
  );
}

const PayGoalMode = ({
  match,
  gameToBeFinished,
  handleSelectPlayerAndTimeOfThrow,
}) => {
  const [openedLocalTeamModal, setOpenedLocalTeamModal] = useState(null);
  const [openedAwayTeamModal, setOpenedAwayTeamModal] = useState(null);

  return (
    <Container>
      <Modal
        title={
          openedLocalTeamModal ? `Lance ${openedLocalTeamModal.throwIndex}` : ''
        }
        visible={!!openedLocalTeamModal}
        onCancel={() => setOpenedLocalTeamModal(null)}
        style={{
          top: 20,
          maxHeight: 'calc(100vh - 40px)',
        }}
        okButtonProps={{
          disabled: handleDisableButtonOfModal(
            openedLocalTeamModal?.localPlayer,
            openedLocalTeamModal?.initialLocalPlayer
          ),
        }}
        okText="Atualizar lance"
        onOk={() => {
          if (openedLocalTeamModal) {
            handleSelectPlayerAndTimeOfThrow(
              'localPlayers',
              openedLocalTeamModal.localPlayer
            );
          }
          setOpenedLocalTeamModal(null);
        }}
      >
        {/* {match.local.players.map(player => (
          <TeamPlayerSelector
            onClick={() => {
              if (openedLocalTeamModal) {
                handleSelectPlayerAndTimeOfThrow(
                  'localPlayers',
                  openedLocalTeamModal.localPlayer.tempId,
                  player._id,
                  `${player.name} - ${player.number}`
                );
              }
              setOpenedLocalTeamModal(null);
            }}
            key={player._id}
          >
            <div>
              <p>
                <strong>{player.name}</strong>
              </p>
              <small>Camisa {player.number}</small>
            </div>
            {openedLocalTeamModal &&
              player._id === openedLocalTeamModal.localPlayer.player && (
                <FiCheck size={20} />
              )}
          </TeamPlayerSelector>
        ))} */}
        {openedLocalTeamModal && (
          <AddThrowContentModal>
            <div>
              <p>
                <strong>Minuto do lance: </strong>
                {openedLocalTeamModal.localPlayer.goalTime}&apos;
              </p>
              <p>
                <strong>Jogador do lance: </strong>
                {openedLocalTeamModal.localPlayer.playerName}
              </p>
            </div>
            <div>
              <small>Selecione o minuto que esse lance aconteceu</small>
              <Slider
                value={openedLocalTeamModal.localPlayer.goalTime}
                onChange={value => {
                  setOpenedLocalTeamModal(oldState => {
                    const updatedState = { ...oldState };
                    updatedState.localPlayer.goalTime = value;

                    return updatedState;
                  });
                }}
                min={0}
                max={99}
              />
            </div>
            <div>
              <small>Selecione o jogador que realizou esse lance</small>
              <ul>
                {match.local.players.map(player => (
                  <TeamPlayerSelector
                    onClick={() => {
                      setOpenedLocalTeamModal(oldState => {
                        const updatedState = { ...oldState };
                        updatedState.localPlayer.player = player._id;
                        updatedState.localPlayer.playerName = `${player.name} - ${player.number}`;

                        return updatedState;
                      });
                    }}
                    key={player._id}
                  >
                    <div>
                      <p>
                        <strong>{player.name}</strong>
                      </p>
                      <small>Camisa {player.number}</small>
                    </div>
                    {openedLocalTeamModal &&
                      player._id ===
                        openedLocalTeamModal.localPlayer.player && (
                        <FiCheck size={20} />
                      )}
                  </TeamPlayerSelector>
                ))}
              </ul>
            </div>
          </AddThrowContentModal>
        )}
      </Modal>
      <Modal
        title={
          openedAwayTeamModal ? `Lance ${openedAwayTeamModal.throwIndex}` : ''
        }
        visible={!!openedAwayTeamModal}
        onCancel={() => setOpenedAwayTeamModal(null)}
        style={{
          top: 20,
          maxHeight: 'calc(100vh - 40px)',
        }}
        okButtonProps={{
          disabled: handleDisableButtonOfModal(
            openedAwayTeamModal?.awayPlayer,
            openedAwayTeamModal?.initialAwayPlayer
          ),
        }}
        okText="Atualizar lance"
        onOk={() => {
          if (openedAwayTeamModal) {
            handleSelectPlayerAndTimeOfThrow(
              'awayPlayers',
              openedAwayTeamModal.awayPlayer
            );
          }
          setOpenedAwayTeamModal(null);
        }}
      >
        {openedAwayTeamModal && (
          <AddThrowContentModal>
            <div>
              <p>
                <strong>Minuto do lance: </strong>
                {openedAwayTeamModal.awayPlayer.goalTime}&apos;
              </p>
              <p>
                <strong>Jogador do lance: </strong>
                {openedAwayTeamModal.awayPlayer.playerName}
              </p>
            </div>
            <div>
              <small>Selecione o minuto que esse lance aconteceu</small>
              <Slider
                value={openedAwayTeamModal.awayPlayer.goalTime}
                onChange={value => {
                  setOpenedAwayTeamModal(oldState => {
                    const updatedState = { ...oldState };
                    updatedState.awayPlayer.goalTime = value;

                    return updatedState;
                  });
                }}
                min={0}
                max={99}
              />
            </div>
            <div>
              <small>Selecione o jogador que realizou esse lance</small>
              <ul>
                {match.away.players.map(player => (
                  <TeamPlayerSelector
                    onClick={() => {
                      setOpenedAwayTeamModal(oldState => {
                        const updatedState = { ...oldState };
                        updatedState.awayPlayer.player = player._id;
                        updatedState.awayPlayer.playerName = `${player.name} - ${player.number}`;

                        return updatedState;
                      });
                      // if (openedAwayTeamModal) {
                      //   handleSelectPlayerAndTimeOfThrow(
                      //     'awayPlayers',
                      //     openedAwayTeamModal.awayPlayer.tempId,
                      //     player._id,
                      //     `${player.name} - ${player.number}`
                      //   );
                      // }
                      // setOpenedAwayTeamModal(null);
                    }}
                    key={player._id}
                  >
                    <div>
                      <p>
                        <strong>{player.name}</strong>
                      </p>
                      <small>Camisa {player.number}</small>
                    </div>
                    {openedAwayTeamModal &&
                      player._id === openedAwayTeamModal.awayPlayer.player && (
                        <FiCheck size={20} />
                      )}
                  </TeamPlayerSelector>
                ))}
              </ul>
            </div>
          </AddThrowContentModal>
        )}
      </Modal>
      <Header>
        <h6>Lances</h6>
      </Header>
      <Content>
        <div>
          <div>
            <TeamImageContainer>
              <img src={match.local.image} alt={match.local.name} />
            </TeamImageContainer>
            <strong>{match.local.name}</strong>
          </div>
          <ul>
            {gameToBeFinished.localPlayers.length > 0 ? (
              gameToBeFinished.localPlayers.map((localPlayer, index) => (
                <ThrowContainer key={localPlayer.tempId}>
                  <small>Lance {index + 1}</small>
                  <SelectPlayerOfThrowButton
                    selected={!!localPlayer.player}
                    onClick={() =>
                      setOpenedLocalTeamModal({
                        localPlayer: { ...localPlayer },
                        initialLocalPlayer: { ...localPlayer },
                        throwIndex: index + 1,
                      })
                    }
                  >
                    {!localPlayer.player ? (
                      <p>Clique para adicionar</p>
                    ) : (
                      <div>
                        <p>{localPlayer.playerName}</p>
                        <p>{localPlayer.goalTime}&apos;</p>
                      </div>
                    )}
                  </SelectPlayerOfThrowButton>
                </ThrowContainer>
              ))
            ) : (
              <p>Não foram adicionados gols para esse time</p>
            )}
          </ul>
        </div>
        <div>
          <div>
            <TeamImageContainer>
              <img src={match.away.image} alt={match.away.name} />
            </TeamImageContainer>
            <strong>{match.away.name}</strong>
          </div>
          <ul>
            {gameToBeFinished.awayPlayers.length > 0 ? (
              gameToBeFinished.awayPlayers.map((awayPlayer, index) => (
                <ThrowContainer key={awayPlayer.tempId}>
                  <small>Lance {index + 1}</small>
                  <SelectPlayerOfThrowButton
                    selected={!!awayPlayer.player}
                    onClick={() =>
                      setOpenedAwayTeamModal({
                        awayPlayer: { ...awayPlayer },
                        initialAwayPlayer: { ...awayPlayer },
                        throwIndex: index + 1,
                      })
                    }
                  >
                    {!awayPlayer.player ? (
                      <p>Clique para adicionar</p>
                    ) : (
                      <div>
                        <p>{awayPlayer.playerName}</p>
                        <p>{awayPlayer.goalTime}&apos;</p>
                      </div>
                    )}
                  </SelectPlayerOfThrowButton>
                </ThrowContainer>
              ))
            ) : (
              <p>Não foram adicionados gols para esse time</p>
            )}
          </ul>
        </div>
      </Content>
    </Container>
  );
};

export default PayGoalMode;
