import styled from 'styled-components';

export const Wrapper = styled.div`
  /* width: 100%;
  height: 100vh; */
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  display: flex;
  align-items: flex-end;
`;

export const Mask = styled.div`
  position: absolute;
  z-index: 105;
  background-color: ${(props) => props.theme.colors.dark.dark0}80;
  cursor: pointer;
  width: 100%;
  height: 100%;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 36rem;
  z-index: 110;
  background-color: ${(props) => props.theme.colors.light.light0};
  margin: 0 auto;
  padding: 2rem 1rem;
  border-radius: 8px 8px 0 0;
  box-shadow: 0px 0px 12px #00000050;

  h6 {
    width: 100%;
    text-align: center;
    margin: 0 0 1.5rem;
  }

  > button {
    width: 100%;
  }
`;
