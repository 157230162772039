import { Button } from 'antd';
import React from 'react';
import { MdEdit } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import Players from './Players';

import { Container, SessionHeader, SessionItems } from './styles';
import { TeamTypeEnum } from '../../types';

function Details({ team, setTeam, routeMatch }) {
  const me = useSelector((state) => state.user);

  function getTeamType() {
    if (team?.teamType === TeamTypeEnum.AMATEUR) {
      return 'Amador';
    }
    if (team?.teamType === TeamTypeEnum.HOUSE) {
      return 'Casa';
    }
    return 'Profissional';
  }

  return (
    <Container>
      <div>
        <SessionHeader>
          <h6>Dados do time</h6>
          <Link to={`${routeMatch.url}/edit_team_data`}>
            <Button type="text" icon={<MdEdit height={18} />} />
          </Link>
        </SessionHeader>
        <SessionItems>
          <li>
            <small>Nome do time</small>
            <p>{team?.name}</p>
          </li>
          <li>
            <small>Iniciais</small>
            <p>{team?.initials || 'Não informado'}</p>
          </li>
          <li>
            <small>Tipo do time</small>
            <p>{getTeamType()}</p>
          </li>
        </SessionItems>
      </div>
      <Players players={team.players} setTeam={setTeam} />
    </Container>
  );
}

export default Details;
