import styled from 'styled-components';
import { MdExitToApp } from 'react-icons/md';

export const Container = styled.div`
  height: 100vh;
  max-width: 320px;
  /* min-width: 20%; */
  border-right: 1px solid ${props => props.theme.colors.light['light-2']};
  background-color: ${props => props.theme.colors.light['light-1']};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 0;
  flex-grow: 1;

  /* @media only screen and (max-width: 768px) { */
  @media only screen and (max-width: 1199px) {
    transition: 0.5s transform;
    transform: ${props =>
      props.opened ? 'translateX(0)' : 'translateX(-80vw)'};
    position: absolute;
    z-index: 1000;
    width: 80%;
    max-width: 80%;
    height: calc(100vh - 61px);
    bottom: 0;
  }

  img {
    width: 143px;

    /* @media only screen and (max-width: 768px) { */
    @media only screen and (max-width: 1199px) {
      display: none;
    }
  }

  ul {
    width: 100%;
    margin-top: 40px;

    /* @media only screen and (max-width: 768px) { */
    @media only screen and (max-width: 1199px) {
      margin-top: 10px;
    }
  }
`;

export const MobileMenuBackgroundMask = styled.div`
  position: absolute;
  bottom: 0;
  z-index: 5;
  height: calc(100vh - 61px);
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: none;

  /* @media only screen and (max-width: 768px) { */
  @media only screen and (max-width: 1199px) {
    display: ${props => (props.opened ? 'block' : 'none')};
  }
`;

export const LogoutButton = styled.div`
  width: 100%;
  height: 40px;
  padding: 0 32px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.2s all;
  margin-bottom: 8px;
  margin-top: auto;

  &:hover {
    background-color: ${props => props.theme.colors.light['light-2']};
  }

  span {
    font-size: 16px;
    font-weight: 700;
    color: ${props => props.theme.colors.danger};
    margin-left: 16px;
  }
`;
export const LogoutButtonIcon = styled(MdExitToApp).attrs(props => ({
  color: props.theme.colors.danger,
  size: '24px',
}))``;
