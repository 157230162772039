import React from 'react';

import Input from '@/components/Input';
import { UploadRequests } from '@/services/api/requests/Upload';
import DefaultAvatar from '@/assets/DefaultAvatar.svg';

import {
  getAthleteNumberDescription,
  getPlayerPositionLabel,
} from '@/pages/AthleteCards/utils';
import { Container, TeamPlayer } from './styles';
import { IOptionCardProps } from './types';

const AthleteCard: React.FC<IOptionCardProps> = ({
  athlete,
  handleChangeAthleteClassicValue,
  draggableProvided,
}) => {
  return (
    <Container
      ref={draggableProvided?.innerRef}
      {...draggableProvided?.draggableProps}
      {...draggableProvided?.dragHandleProps}
      style={{ ...draggableProvided?.draggableProps?.style }}
    >
      <TeamPlayer>
        <div>
          <img
            src={
              athlete?.photo
                ? UploadRequests.getFileUrl(athlete.photo)
                : DefaultAvatar
            }
            alt={athlete.name}
          />
        </div>
        <div>
          <strong>{athlete.name}</strong>
          <small>
            {getAthleteNumberDescription(athlete.number)}
            {getPlayerPositionLabel(athlete.position)}
          </small>
          <div>
            <img src={athlete.team.image} alt={athlete.team.name} />
            <p>{athlete.team.name}</p>
          </div>
        </div>
      </TeamPlayer>
      <Input
        label="Valor"
        identifier="optionValue"
        type="text"
        value={athlete.classicValue}
        onChange={(e) => {
          const value = e.target.value.replace(/\D/g, '');
          handleChangeAthleteClassicValue(athlete._id, value);
        }}
        error={athlete.classicValue < 1 && 'Insira um valor maior que 0'}
      />
    </Container>
  );
};

export default AthleteCard;
