import React, { useCallback } from 'react';
import { Checkbox } from 'antd';

import Input from '@/components/Input';

import { Container } from './styles';
import { IOptionCardProps } from './types';

const OptionCard: React.FC<IOptionCardProps> = ({
  option,
  handleDisableOption,
  handleEnableOption,
  handleChangeOptionValue,
  draggableProvided,
}) => {
  const handleCheck = useCallback(() => {
    if (option.selected && handleDisableOption) {
      handleDisableOption(option._id, option.topic);
    } else if (handleEnableOption) {
      handleEnableOption(option._id, option.topic);
    }
  }, [
    handleDisableOption,
    handleEnableOption,
    option._id,
    option.selected,
    option.topic,
  ]);

  return (
    <Container
      ref={draggableProvided?.innerRef}
      {...draggableProvided?.draggableProps}
      {...draggableProvided?.dragHandleProps}
      style={{ ...draggableProvided?.draggableProps?.style }}
      $selected={option.selected}
    >
      <div>
        <strong>{option.name}</strong>
        <Checkbox checked={option.selected} onChange={handleCheck} />
      </div>
      <Input
        label="Valor"
        identifier="optionValue"
        type="text"
        value={option.value}
        onChange={(e) => {
          if (!option.selected || !handleChangeOptionValue) return;
          const value = e.target.value.replace(/\D/g, '');
          handleChangeOptionValue(option._id, value, option.topic);
        }}
        error={
          option.selected && option.value < 1 && 'Insira um valor maior que 0'
        }
        disabled={!option.selected}
      />
    </Container>
  );
};

export default OptionCard;
