import styled, { css } from 'styled-components';

interface IAthleteCardDetailMainContent {
  bg?: string;
  cardType: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
  padding: 56px 32px;

  @media only screen and (max-width: 600px) {
    padding: 32px 16px;
    min-height: calc(100vh - 61px);
  }
`;

export const TitlePageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* margin-top: 32px; */
  margin-bottom: 32px;
  margin-top: 32px;
  align-items: center;

  @media only screen and (max-width: 768px) {
    margin-bottom: 16px;
  }

  /* AntD Button Changes */
  button {
    margin-left: 16px;
  }
`;

export const Content = styled.div`
  padding: 0 15%;
  display: flex;
  flex-direction: column;

  form {
    align-self: center;
    display: flex;
    flex-direction: column;
    width: 80%;
    min-width: 728px;
    margin-top: 40px;
  }

  @media only screen and (max-width: 768px) {
    padding: 0;

    form {
      width: 100%;
      min-width: 100%;
      margin-top: 16px;
    }
  }
`;

export const AthleteCardDetailContainer = styled.div`
  width: 100%;
  background: ${(props) => props.theme.colors.light.light0};
  border: 0;
  /* padding: 16px; */
  border-radius: 8px;
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.15);
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 32px;
  }
`;

export const AthleteCardDetailMainContent = styled.div<IAthleteCardDetailMainContent>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  ${(props) =>
    props.bg &&
    css`
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.8),
          rgba(255, 255, 255, 0.8)
        ),
        url(${props.bg}) center no-repeat;
      background-size: cover;
    `}

  > div {
    width: 100%;
    display: flex;
    margin-bottom: 4px;

    h6 {
      text-align: start;
      flex: 1;
      line-height: 110%;
    }

    svg {
      color: ${(props) => props.theme.colors.dark['dark+4']};
    }
  }

  small {
    color: ${(props) => props.theme.colors.dark['dark+1']};

    &:nth-child(2) {
      margin-bottom: 16px;
      ${(props) =>
        props.cardType === 'new' &&
        css`
          color: ${props.theme.colors.primary.primary0};
        `}
      ${(props) =>
        props.cardType === 'running' &&
        css`
          color: #e4ce00;
        `}
      ${(props) =>
        props.cardType === 'finished' &&
        css`
          color: ${props.theme.colors.danger};
        `}
    }
  }
`;

export const TabsWrapper = styled.div`
  padding: 0 1rem 1rem;
  width: 100%;

  > div {
    width: 100%;
  }
`;
