import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { Button } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { Formik, Field, FormikProps, FormikHelpers, FieldProps } from 'formik';
import * as Yup from 'yup';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

import Input from '@/components/Input';
import api from '@/services/api';
import { InfluencerRequests } from '@/services/api/requests/Influencer';
import { IUpdateInfluencerRequestBody } from '@/services/api/requests/Influencer/types';
import useBeforeUnload from '@/hooks/useBeforeUnload';
import useMergeRefs from '@/hooks/useMergeRefs';

import {
  Container,
  TitlePageContainer,
  Content,
  ButtonsContainer,
} from './styles';
import {
  ICheckIfIsFieldAvailableProps,
  IEditInfluencerInformationFormData,
  IEditInfluencerInformationProps,
  IRouteParams,
  UniqueFieldEnum,
} from './types';
import {
  availableFieldsInitialState,
  loadingOfAsyncValidationsInitialState,
} from './constants';

const EditInfluencerInformation: React.FC<IEditInfluencerInformationProps> = ({
  influencer,
  setInfluencer,
  parentRouteMatch,
}) => {
  const history = useHistory();
  const params = useParams<IRouteParams>();
  const mergeRefs = useMergeRefs();

  const formikRef =
    useRef<FormikProps<IEditInfluencerInformationFormData>>(null);

  const [loadingsOfAsyncValidations, setLoadingsOfAsyncValidations] = useState(
    loadingOfAsyncValidationsInitialState
  );
  const [focusedField, setFocusedField] = useState<string | null>(null);
  const [haveChanges, setHaveChanges] = useState(false);

  const [fieldsAvailables, setFieldsAvailables] = useState(
    availableFieldsInitialState
  );

  useBeforeUnload({
    when: haveChanges,
    message: 'Deseja realmente sair? As alterações serão perdidas',
  });

  const unblockPage = useMemo(() => {
    const messageComponents = {
      title: 'Deseja realmente cancelar a atualização dos dados?',
      content: 'Todos as alterações serão perdidas',
      cancelText: 'Voltar',
      okText: 'Cancelar',
    };

    if (haveChanges) {
      return history.block(JSON.stringify(messageComponents));
    }
    return () => ({});
  }, [haveChanges, history]);

  useEffect(() => {
    return () => {
      unblockPage();
    };
  }, [unblockPage]);

  const checkIfHaveChanges = useCallback(
    (currentValues: IEditInfluencerInformationFormData) => {
      if (_.isEqual(influencer?.influencerInformation, currentValues)) {
        if (haveChanges) {
          setHaveChanges(false);
        }
        return;
      }

      if (!haveChanges) {
        setHaveChanges(true);
      }
    },
    [haveChanges, influencer.influencerInformation]
  );

  const checkIfIsFieldAvailable = useCallback(
    async ({
      value,
      fieldName,
      reject,
      resolve,
      isFieldTouched,
      setFieldTouched,
    }: ICheckIfIsFieldAvailableProps): Promise<void> => {
      try {
        setLoadingsOfAsyncValidations({
          ...loadingOfAsyncValidationsInitialState,
          [fieldName]: true,
        });
        let isFieldAvailable = false;

        if (fieldName === UniqueFieldEnum.EMAIL) {
          const response = await api.get('/api/account/checker', {
            params: {
              email: value,
            },
          });
          isFieldAvailable = !response.data;
        } else if (fieldName === UniqueFieldEnum.CODE) {
          const response =
            await InfluencerRequests.checkInfluencerCodeAvailability(value);

          isFieldAvailable = !response.data;
        }

        resolve(isFieldAvailable);
        if (!isFieldTouched) setFieldTouched();
      } catch (error) {
        reject(false);
        toast.error(
          'Ocorreu um erro inesperado. Atualize a página e tente novamente!'
        );
      }
      setLoadingsOfAsyncValidations(loadingOfAsyncValidationsInitialState);
    },
    []
  );

  const emailValidationDebounced = useMemo(() => {
    return _.debounce(checkIfIsFieldAvailable, 500);
  }, [checkIfIsFieldAvailable]);

  const influencerCodeValidationDebounced = useMemo(() => {
    return _.debounce(checkIfIsFieldAvailable, 500);
  }, [checkIfIsFieldAvailable]);

  const handleSubmit = useCallback(
    async (
      values: IEditInfluencerInformationFormData,
      actions: FormikHelpers<IEditInfluencerInformationFormData>
    ) => {
      const { id } = params;

      const body: IUpdateInfluencerRequestBody = {
        email: values?.email,
      };

      if (influencer?.influencerInformation?.code !== values?.code) {
        body.code = values?.code;
      }

      try {
        await InfluencerRequests.updateInfluencer(id, body);

        toast.success('Dados atualizados com sucesso!');
        setInfluencer((oldState) => ({
          ...oldState,
          influencerInformation: {
            ...oldState.influencerInformation,
            ...body,
          },
        }));
        actions.setSubmitting(false);
        unblockPage();

        history.push(parentRouteMatch.url);
      } catch (error) {
        toast.error(
          'Aconteceu um erro inesperado ao atualizar o influenciador!'
        );
      }
      return null;
    },
    [
      history,
      params,
      parentRouteMatch.url,
      setInfluencer,
      unblockPage,
      influencer,
    ]
  );

  const checkIfIsSubmitButtonEnabled = useCallback(
    ({
      touched,
      errors,
      values,
    }: FormikProps<IEditInfluencerInformationFormData>) => {
      if (
        _.isEqual(influencer?.influencerInformation, values) ||
        Object.entries(touched).length === 0 ||
        Object.entries(errors).length > 0
      ) {
        return false;
      }

      return true;
    },
    [influencer]
  );

  const handleFormikRef = (
    formikProps: FormikProps<IEditInfluencerInformationFormData>
  ): void => {
    if (formikProps !== null) {
      checkIfHaveChanges(formikProps.values);
    }
  };

  return (
    <Container>
      <TitlePageContainer>
        <h5>Editar</h5>
        <Button onClick={() => history.goBack()} danger type="text">
          Cancelar
        </Button>
      </TitlePageContainer>
      <Content>
        <h6>Informações do influenciador</h6>
        <Formik
          innerRef={mergeRefs(formikRef, handleFormikRef)}
          initialValues={
            {
              username: influencer?.influencerInformation?.username || '',
              email: influencer?.influencerInformation?.email || '',
              code: influencer?.influencerInformation?.code || '',
            } as IEditInfluencerInformationFormData
          }
          validationSchema={Yup.object().shape({
            username: Yup.string().required(
              'O nome do influenciador é obrigatório'
            ),
            email: Yup.string()
              .required('O email é obrigatório')
              .email('O email informado não é válido')
              .test(
                'emailUnavailable',
                'Esse email já está sendo utilizado',
                async function validate(value) {
                  emailValidationDebounced.cancel();
                  if (focusedField === 'email') {
                    if (
                      !value ||
                      !(await Yup.string().email().isValid(value)) ||
                      value === influencer?.influencerInformation?.email
                    ) {
                      return true;
                    }

                    return new Promise((resolve, reject) =>
                      emailValidationDebounced({
                        value,
                        fieldName: UniqueFieldEnum.EMAIL,
                        resolve: (isFieldAvailable) => {
                          resolve(isFieldAvailable);
                          setFieldsAvailables({
                            ...fieldsAvailables,
                            email: isFieldAvailable,
                          });
                        },
                        reject,
                        isFieldTouched: !!formikRef?.current?.touched?.email,
                        setFieldTouched: () =>
                          formikRef.current?.setFieldTouched('email', true),
                      })
                    );
                  }

                  return fieldsAvailables.email;
                }
              ),
            code: Yup.string()
              .required('O código do influenciador é obrigatório')
              .test(
                'influencerCodeUnavailable',
                'Esse código de influenciador já está sendo utilizado',
                function validate(value) {
                  influencerCodeValidationDebounced.cancel();
                  if (focusedField === 'code') {
                    if (
                      !value ||
                      value === influencer?.influencerInformation?.code
                    ) {
                      return true;
                    }

                    return new Promise((resolve, reject) =>
                      influencerCodeValidationDebounced({
                        value,
                        fieldName: UniqueFieldEnum.CODE,
                        resolve: (isFieldAvailable) => {
                          resolve(isFieldAvailable);
                          setFieldsAvailables({
                            ...fieldsAvailables,
                            code: isFieldAvailable,
                          });
                        },
                        reject,
                        isFieldTouched: !!formikRef?.current?.touched?.code,
                        setFieldTouched: () =>
                          formikRef.current?.setFieldTouched('code', true),
                      })
                    );
                  }

                  return fieldsAvailables.code;
                }
              ),
          })}
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <form onSubmit={formikProps.handleSubmit}>
              <Field name="username">
                {({ field, meta }: FieldProps) => {
                  return (
                    <Input
                      disabled
                      {...field}
                      label="Nome de influenciador"
                      placeholder="Digite um nome do influenciador"
                      identifier="username"
                      type="text"
                      error={meta?.touched && meta?.error && meta?.error}
                      onFocus={() => setFocusedField('username')}
                      onChange={(e) => {
                        e.target.value = e.target.value
                          .replace(/\s+/g, '')
                          .toLowerCase();
                        field.onChange(e);
                      }}
                    />
                  );
                }}
              </Field>
              <Field name="email">
                {({ field, meta }: FieldProps) => {
                  return (
                    <Input
                      {...field}
                      label="Email"
                      placeholder="example@example.com"
                      identifier="email"
                      type="email"
                      error={meta?.touched && meta?.error && meta?.error}
                      showloading={!!loadingsOfAsyncValidations?.email}
                      onFocus={() => setFocusedField('email')}
                    />
                  );
                }}
              </Field>
              <Field name="code">
                {({ field, meta }: FieldProps) => {
                  return (
                    <Input
                      {...field}
                      label="Código do Influenciador"
                      placeholder="Digite o código do influenciador"
                      identifier="code"
                      type="text"
                      error={meta?.touched && meta?.error && meta?.error}
                      showloading={!!loadingsOfAsyncValidations?.code}
                      onFocus={() => setFocusedField('code')}
                      onChange={(e) => {
                        e.target.value = e.target.value
                          .replace(/\s+/g, '')
                          .toUpperCase();
                        field.onChange(e);
                      }}
                    />
                  );
                }}
              </Field>
              <ButtonsContainer>
                <Button
                  htmlType="submit"
                  disabled={
                    formikProps.isSubmitting ||
                    !checkIfIsSubmitButtonEnabled(formikProps)
                  }
                  type="primary"
                >
                  {!formikProps.isSubmitting ? 'Atualizar' : 'Carregando...'}
                </Button>
              </ButtonsContainer>
            </form>
          )}
        </Formik>
      </Content>
    </Container>
  );
};

export default EditInfluencerInformation;
