import React from 'react';

import { ConfirmPasswordProvider } from './ConfirmPasswordContext';

interface AppProviderProps {
  children?: React.ReactNode;
}

const AppProvider: React.FC<AppProviderProps> = ({ children }) => (
  <ConfirmPasswordProvider>{children}</ConfirmPasswordProvider>
);

export default AppProvider;
