import React from 'react';
import { Steps as StepsAntd } from 'antd';

import {
  Container,
  LargeDevicesContainer,
  SmallDevicesContainer,
} from './styles';

function Steps({ current, stepsList }) {
  return (
    <Container>
      <LargeDevicesContainer>
        <StepsAntd labelPlacement="vertical" size="small" current={current}>
          {stepsList.map(({ title, ...rest }) => (
            <StepsAntd.Step key={`step_${title}`} title={title} {...rest} />
          ))}
        </StepsAntd>
      </LargeDevicesContainer>
      <SmallDevicesContainer>
        <StepsAntd direction="vertical" size="small" current={current}>
          {stepsList.map(({ title, ...rest }) => (
            <StepsAntd.Step key={`step_${title}`} title={title} {...rest} />
          ))}
        </StepsAntd>
      </SmallDevicesContainer>
    </Container>
  );
}

export default Steps;
