import React, { useMemo } from 'react';
import { Button } from 'antd';

import { getYouTubeVideoId } from '@/utils/validate';

import { ButtonsContainer } from '../styles';
import {
  SessionContainer,
  SessionItems,
  YoutubeVideoContainer,
} from './styles';
import { IConfirmProps } from './types';
import { AdsTypeEnum } from '../../types';

const Confirm: React.FC<IConfirmProps> = ({
  formik,
  prevStep,
  handleSubmit,
}) => {
  const adsType = formik.values.adsDetails.type;
  const youtubeUrl = useMemo(() => {
    const adsUrl = formik.values.adsDetails.url;
    const youTubeVideoId = getYouTubeVideoId(adsUrl);
    return adsType === AdsTypeEnum.VIDEO
      ? `https://www.youtube.com/embed/${youTubeVideoId}`
      : null;
  }, [adsType, formik.values.adsDetails.url]);

  return (
    <>
      <SessionContainer>
        <h6>Detalhes do anúncio</h6>
        <SessionItems>
          <li>
            <small>Tipo</small>
            <p>{adsType === AdsTypeEnum.IMAGE ? 'Imagem' : 'Vídeo'}</p>
          </li>
          <li>
            <small>Título</small>
            <p>{formik.values.adsDetails.title}</p>
          </li>
          <li>
            <small>Descrição</small>
            <p>{formik.values.adsDetails.description}</p>
          </li>
          <li>
            <small>
              {adsType === AdsTypeEnum.IMAGE ? 'URL' : 'URL do Youtube'}
            </small>
            <p>{formik.values.adsDetails.url}</p>
          </li>
          <li>
            <small>O anúncio é temporário?</small>
            <p>{formik.values.adsDetails.isTemporary ? 'Sim' : 'Não'}</p>
          </li>
        </SessionItems>
      </SessionContainer>
      <SessionContainer>
        <h6>Banner</h6>
        {adsType === AdsTypeEnum.IMAGE && formik.values.photo?.previewUrl && (
          <img src={formik.values.photo?.previewUrl} alt="Banner" />
        )}
        {adsType === AdsTypeEnum.VIDEO && (
          <YoutubeVideoContainer>
            <iframe title={youtubeUrl || ''} src={youtubeUrl || ''} />
          </YoutubeVideoContainer>
        )}
      </SessionContainer>
      <ButtonsContainer>
        <Button onClick={prevStep} type="default">
          Voltar
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={formik.isSubmitting}
          type="primary"
        >
          {!formik.isSubmitting ? 'Finalizar' : 'Carregando...'}
        </Button>
      </ButtonsContainer>
    </>
  );
};

export default Confirm;
