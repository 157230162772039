import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Button, Spin, Tabs } from 'antd';
import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as dateFns from 'date-fns';
import ptLocale from 'date-fns/locale/pt-BR';

import { UploadRequests } from '@/services/api/requests/Upload';

import Breadcrumbs from '@/components/Breadcrumbs';

import api from '@/services/api';

import TabPane from 'antd/lib/tabs/TabPane';

import confirm from 'antd/lib/modal/confirm';
import { useConfirmPassword } from '@/context/ConfirmPasswordContext';
import {
  Container,
  TitlePageContainer,
  AthleteCardDetailContainer,
  AthleteCardDetailMainContent,
  TabsWrapper,
} from './styles';
import { CardDetailsTabEnum, IRouteParams } from './types';
import { EGameType, ICardGame } from '../types';
import UpdateResults from './UpdateResults';
import Ads from './Ads';
import Statistics from './Statistics';

const loadingIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;
const { format, parseISO } = dateFns;

const cardTypesLabels = {
  new: 'Disponível para jogar',
  running: 'Em execução',
  finished: 'Finalizada',
};

const CardDetails: React.FC = () => {
  const history = useHistory();
  const { params } = useRouteMatch<IRouteParams>();
  const confirmPassword = useConfirmPassword();

  const [card, setCard] = useState<ICardGame>({} as ICardGame);
  const [loadingCard, setLoadingCard] = useState(true);

  const [currentView, setCurrentView] = useState<CardDetailsTabEnum>(
    CardDetailsTabEnum.UPDATE_RESULTS
  );

  const getCardDetails = useCallback(async () => {
    try {
      const { data } = await api.get<{
        doc: Omit<ICardGame, 'amount' | 'cardCount'>;
        cardCount: number;
        amount: number;
      }>(`/api/athlete-card/${params.id}`);

      setCard({
        ...data.doc,
        athletes: data.doc.athletes.map((athlete) => ({
          ...athlete,
          status: athlete.status || false,
        })),
        cardCount: data.cardCount,
        amount: data.amount,
        goalOptions: data.doc.goalOptions,
        statisticOptions: data.doc.statisticOptions,
      });
    } catch (error) {
      toast.error(
        'Ocorreu um erro ao carregar os detalhes da cartela. Recarregue a página e tente novamente!'
      );
    }
    setLoadingCard(false);
  }, [params.id]);

  const isDeleteButtonVisible = useMemo(() => {
    return !card.finished && !card.paid;
  }, [card.finished, card.paid]);

  useEffect(() => {
    getCardDetails();
  }, [getCardDetails]);

  const handleDelete = useCallback(async (): Promise<void> => {
    if (!isDeleteButtonVisible) return;

    await new Promise((resolve) => {
      confirm({
        title: 'Deseja realmente excluir essa cartela?',
        icon: <ExclamationCircleOutlined />,
        content: 'Essa ação é irreversível',
        cancelText: 'Cancelar',
        okText: 'Excluir cartela',
        onOk() {
          resolve(true);
        },
      });
    });

    const password = await confirmPassword.requestPassword();
    if (!password) return;

    const body = {
      password,
    };

    try {
      await api.delete(`/api/athlete-card/${params.id}`, { data: body });
      toast.success('Cartela excluída com sucesso!');
      history.push(`/cards`);
    } catch (error) {
      const err = error.response.data;
      if (err?.code && err?.code === 'INVALID_PASSWORD') {
        toast.error('Senha incorreta!');
        return;
      }
      toast.error('Aconteceu um erro inesperado!');
    }
  }, [confirmPassword, history, isDeleteButtonVisible, params.id]);

  if (loadingCard) {
    return (
      <Container>
        <Breadcrumbs />
        <TitlePageContainer>
          <h4>Detalhes da cartela</h4>
        </TitlePageContainer>
        <Spin indicator={loadingIcon} />
      </Container>
    );
  }

  return (
    <Container>
      <Breadcrumbs />
      <TitlePageContainer>
        <h4>Detalhes da cartela</h4>
        {isDeleteButtonVisible && (
          <Button onClick={handleDelete} danger type="text">
            Excluir
          </Button>
        )}
      </TitlePageContainer>
      <AthleteCardDetailContainer key={card._id}>
        <AthleteCardDetailMainContent
          bg={card.banner && UploadRequests.getFileUrl(card.banner)}
          cardType={card.cardType}
        >
          <div>
            <h6>{card.name}</h6>
          </div>
          <small>{cardTypesLabels[card.cardType]}</small>
          <small>
            <b>Tipo de jogo: </b>
            {card.gameType === EGameType.OPTIONS ? 'Opções' : 'Clássico'}
          </small>
          <small>
            <b>Valor do jogo: </b>R$ {card.price}
          </small>
          <small>
            <b>Data de início da cartela: </b>
            {format(parseISO(card.startDate), "cccc, dd/MM/yyyy 'às' HH:mm", {
              locale: ptLocale,
            })}
          </small>
          <small>
            <b>Data de fim da cartela: </b>
            {format(parseISO(card.endDate), "cccc, dd/MM/yyyy 'às' HH:mm", {
              locale: ptLocale,
            })}
          </small>
          <small>
            <b>Cartela finalizada: </b>
            {card.finished ? 'Sim' : 'Não'}
          </small>
        </AthleteCardDetailMainContent>
        <TabsWrapper>
          <Tabs
            activeKey={currentView}
            onTabClick={(tabKey) =>
              setCurrentView(tabKey as CardDetailsTabEnum)
            }
            centered
          >
            <TabPane
              tab="Atualizar resultados"
              key={CardDetailsTabEnum.UPDATE_RESULTS}
            >
              <UpdateResults card={card} />
            </TabPane>
            <TabPane tab="Publicidade" key={CardDetailsTabEnum.ADS}>
              <Ads />
            </TabPane>
            <TabPane tab="Estatísticas" key={CardDetailsTabEnum.STATISTICS}>
              <Statistics card={card} getCardDetails={getCardDetails} />
            </TabPane>
          </Tabs>
        </TabsWrapper>
      </AthleteCardDetailContainer>
    </Container>
  );
};

export default CardDetails;
