import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  gap: 2rem;
  align-items: flex-end;

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }
`;

export const PercentagesInputsContainer = styled.div`
  width: 100%;
  max-width: 15rem;
  margin-top: 0.5rem;

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    max-width: 100%;
  }
`;
