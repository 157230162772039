import { ApiResponse, IListParams, IListResponse } from '@/services/api/types';
import api from '@/services/api';

import {
  ICreateInfluencerRequestBody,
  ICreateInfluencerResponse,
  IGetInfluencerDetailsResponse,
  IGetInfluencersResponseItem,
  IUpdateInfluencerRequestBody,
} from './types';

function getInfluencers(
  params: IListParams
): ApiResponse<IListResponse<IGetInfluencersResponseItem>> {
  return api.get<IListResponse<IGetInfluencersResponseItem>>(
    '/api/influencer-admin',
    {
      params,
    }
  );
}

function getInfluencerDetails(
  _id: string
): ApiResponse<IGetInfluencerDetailsResponse> {
  return api.get<IGetInfluencerDetailsResponse>(`/api/influencer-admin/${_id}`);
}

function createInfluencer(
  body: ICreateInfluencerRequestBody
): ApiResponse<ICreateInfluencerResponse> {
  return api.post<ICreateInfluencerResponse>('/api/influencer-admin', body);
}

function updateInfluencer(
  influencerId: string,
  body: IUpdateInfluencerRequestBody
): ApiResponse {
  return api.put(`/api/influencer-admin/${influencerId}`, body);
}

function deleteInfluencer(influencerId: string): ApiResponse {
  return api.delete(`/api/influencer-admin/${influencerId}`);
}

function checkInfluencerCodeAvailability(code: string): ApiResponse<boolean> {
  return api.get<boolean>(`/api/check-influencer-code/${code}`);
}

export const InfluencerRequests = {
  getInfluencers,
  getInfluencerDetails,
  createInfluencer,
  updateInfluencer,
  deleteInfluencer,
  checkInfluencerCodeAvailability,
};
