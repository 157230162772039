import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  h6 {
    margin-left: 16px;
    cursor: pointer;
  }
`;
