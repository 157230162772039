import React, { useState, useCallback, useEffect } from 'react';
import { Button, Pagination, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import * as dateFns from 'date-fns';
import ptLocale from 'date-fns/locale/pt-BR';

import { useRouteMatch, Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import api from '@/services/api';
import { UploadRequests } from '@/services/api/requests/Upload';

import {
  Container,
  TitlePageContainer,
  AthleteCardsListContainer,
  CardsList,
  AthleteCardDetailContainer,
  AthleteCardDetailMainContent,
  LoadingAndNotFoundContainer,
} from './styles';
import { IRouteParams, ICardGameListItem } from './types';
import { EGameType } from '../types';

const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;
const { format, parseISO } = dateFns;

const cardTypesLabels = {
  new: 'Disponível para jogar',
  running: 'Em execução',
  finished: 'Finalizada',
};

const AthleteCardsList: React.FC = () => {
  const { params, url } = useRouteMatch<IRouteParams>();

  const [cards, setCards] = useState<ICardGameListItem[]>([]);
  const [loadingCards, setLoadingCards] = useState(true);
  const [cardsPagination, setCardsPagination] = useState({
    currentPage: 1,
    totalPages: 0,
    limit: 10,
  });

  const getCards = useCallback(
    async (page = 1) => {
      setLoadingCards(true);
      try {
        const { data } = await api.get<{
          docs: ICardGameListItem[];
          page: number;
          pages: number;
          total: number;
        }>(`/api/athlete-card`, {
          params: {
            champ: params.id,
            page,
            limit: cardsPagination.limit,
          },
        });

        setCards(
          data.docs.map((cardGame) => ({
            ...cardGame,
            collapsedOnList: false,
            loadingDetails: false,
            loadedDetails: false,
          }))
        );
        setCardsPagination((oldState) => ({
          currentPage: data.page,
          totalPages: data.total,
          limit: oldState.limit,
        }));
        setLoadingCards(false);
      } catch (error) {
        setLoadingCards(false);
      }
      return '';
    },
    [cardsPagination.limit, params.id]
  );

  const getCardDetails = useCallback(async (_cardId: string) => {
    setCards((oldCards) =>
      oldCards.map((oldCard) => {
        if (oldCard._id === _cardId) {
          return {
            ...oldCard,
            collapsedOnList: true,
            loadingDetails: true,
          };
        }
        return { ...oldCard, collapsedOnList: false };
      })
    );

    try {
      const { data } = await api.get(`/api/athlete-card/${_cardId}`);

      setCards((oldCards) =>
        oldCards.map((oldCard) => {
          if (oldCard._id === _cardId) {
            return {
              ...oldCard,
              athletes: data.doc.athletes,
              gameCount: data.cardCount,
              amount: data.amount,
              loadingDetails: false,
              loadedDetails: true,
            };
          }
          return { ...oldCard };
        })
      );
    } catch (error) {
      toast.error(
        'Ocorreu um erro ao carregar os detalhes da cartela. Recarregue a página e tente novamente!'
      );
      setCards((oldCards) =>
        oldCards.map((oldCard) => {
          if (oldCard._id === _cardId) {
            return {
              ...oldCard,
              loadingDetails: false,
            };
          }
          return { ...oldCard, collapsedOnList: false };
        })
      );
    }
    return '';
  }, []);

  useEffect(() => {
    getCards();
  }, [getCards]);

  if (loadingCards) {
    return (
      <Container>
        <TitlePageContainer>
          <h4>Cartelas</h4>
          <div>
            <Link to={`${url}/new`}>
              <Button type="primary">Nova cartela</Button>
            </Link>
          </div>
        </TitlePageContainer>
        <AthleteCardsListContainer>
          <Spin indicator={antIcon} />
        </AthleteCardsListContainer>
      </Container>
    );
  }

  return (
    <Container>
      <TitlePageContainer>
        <h4>Cartelas</h4>
        <div>
          <Link to={`${url}/new`}>
            <Button type="primary">Nova cartela</Button>
          </Link>
        </div>
      </TitlePageContainer>
      <AthleteCardsListContainer>
        <CardsList>
          {cards.length > 0 ? (
            cards.map((card) => (
              <AthleteCardDetailContainer key={card._id}>
                <Link to={`/cards/${card._id}`}>
                  <AthleteCardDetailMainContent
                    onClick={() => {
                      if (!card.loadingDetails && !card.loadedDetails) {
                        getCardDetails(card._id);
                        return;
                      }
                      if (card.loadedDetails) {
                        setCards((oldCards) =>
                          oldCards.map((oldCard) => {
                            if (oldCard._id === card._id) {
                              return {
                                ...oldCard,
                                collapsedOnList: !oldCard.collapsedOnList,
                              };
                            }
                            return { ...oldCard, collapsedOnList: false };
                          })
                        );
                      }
                    }}
                    bg={
                      card.banner?.filename &&
                      UploadRequests.getFileUrl(card.banner?.filename)
                    }
                    cardType={card.cardType}
                    collapsedOnList={card.collapsedOnList}
                  >
                    <div>
                      <h6>{card.name}</h6>
                    </div>
                    <small>{cardTypesLabels[card.cardType]}</small>
                    <small>
                      <b>Tipo de jogo: </b>
                      {card.gameType === EGameType.OPTIONS
                        ? 'Opções'
                        : 'Clássico'}
                    </small>
                    <small>
                      <b>Valor do jogo: </b>R$ {card.price}
                    </small>
                    <small>
                      <b>Data de início da cartela: </b>
                      {format(
                        parseISO(card.startDate),
                        "cccc, dd/MM/yyyy 'às' HH:mm",
                        {
                          locale: ptLocale,
                        }
                      )}
                    </small>
                    <small>
                      <b>Data de fim da cartela: </b>
                      {format(
                        parseISO(card.endDate),
                        "cccc, dd/MM/yyyy 'às' HH:mm",
                        {
                          locale: ptLocale,
                        }
                      )}
                    </small>
                    <small>
                      <b>Cartela finalizada: </b>
                      {card.finished ? 'Sim' : 'Não'}
                    </small>
                  </AthleteCardDetailMainContent>
                </Link>
              </AthleteCardDetailContainer>
            ))
          ) : (
            <LoadingAndNotFoundContainer>
              <div>
                <h6>
                  Não foram encontradas <span>cartelas</span>
                </h6>
              </div>
            </LoadingAndNotFoundContainer>
          )}
        </CardsList>
        <Pagination
          current={cardsPagination.currentPage}
          onChange={(page) => {
            setCardsPagination((oldState) => ({
              ...oldState,
              currentPage: page,
            }));
            getCards(page);
          }}
          total={cardsPagination.totalPages}
          pageSize={cardsPagination.limit}
        />
      </AthleteCardsListContainer>
    </Container>
  );
};

export default AthleteCardsList;
