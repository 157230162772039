import React, { useState, useMemo, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import * as _ from 'lodash';
import { Input, Button, Pagination, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import {
  Container,
  TitlePageContainer,
  SearchAndNewUserContainer,
  UsersListContainer,
  UsersList,
  NamePageForIndicator,
  UserDetailContainer,
  UserDataContainer,
} from './styles';

import api from '@/services/api';
import { UploadRequests } from '@/services/api/requests/Upload';
import DefaultAvatar from '@/assets/DefaultAvatar.svg';

const { Search } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;

function Users() {
  const history = useHistory();

  const [users, setUsers] = useState({
    total: 0,
    currentPage: 0,
    pageSize: 0,
    items: [],
    searchCompleted: false,
  });
  const [searchInputValue, setSearchInputValue] = useState('');
  const [loadingUsers, setLoadingUsers] = useState(false);

  async function handleGetUsers(search) {
    setLoadingUsers(true);
    try {
      const { data } = await api.get('/api/user-admin', {
        params: {
          search,
        },
      });

      setUsers({
        total: data.total,
        currentPage: data.page,
        pageSize: data.limit,
        items: data.docs,
        searchCompleted: true,
      });

      setLoadingUsers(false);
    } catch (error) {
      setLoadingUsers(false);
    }
  }

  const userSearchDebounced = useMemo(() => {
    return _.debounce(handleGetUsers, 500);
  }, []);

  useEffect(() => {
    userSearchDebounced.cancel();

    if (searchInputValue.length > 3) {
      setLoadingUsers(true);
      userSearchDebounced(searchInputValue);
    } else {
      setLoadingUsers(false);
    }

    if (!searchInputValue) {
      setUsers({
        total: 0,
        currentPage: 0,
        pageSize: 0,
        items: [],
        searchCompleted: false,
      });
    }
  }, [searchInputValue, userSearchDebounced]);

  const contentPage = useMemo(() => {
    if (loadingUsers) {
      return <Spin indicator={antIcon} />;
    }

    if (!searchInputValue) {
      return (
        <h5>
          Pesquise por <NamePageForIndicator>usuários</NamePageForIndicator>{' '}
          cadastrados no Lance Futebol
        </h5>
      );
    }
    if (searchInputValue.length <= 3) {
      return <h6>Digite acima de 3 caracteres para realizar a busca</h6>;
    }

    if (users?.items?.length > 0) {
      return (
        <>
          <UsersList>
            {users?.items.map((user) => (
              <UserDetailContainer
                onClick={() => history.push(`/users/${user?._id}`)}
                key={user?.username}
              >
                <img
                  src={
                    user?.photo?.filename
                      ? UploadRequests.getFileUrl(user?.photo?.filename)
                      : DefaultAvatar
                  }
                  alt={user?.name || 'Avatar'}
                />
                <UserDataContainer>
                  <div>
                    <p>{user?.name}</p>
                    {user?.status === 0 && <span>Inativo</span>}
                  </div>
                  <span>{user?.username}</span>
                  <span>{user?.email}</span>
                </UserDataContainer>
              </UserDetailContainer>
            ))}
          </UsersList>
          <Pagination
            current={users.currentPage}
            onChange={(page) => setUsers({ ...users, currentPage: page })}
            total={users.total}
            pageSize={users.pageSize}
          />
        </>
      );
    }

    if (users?.items?.length === 0 && users?.searchCompleted) {
      return <h6>Nenhum usuário encontrado</h6>;
    }

    return '';
  }, [searchInputValue, loadingUsers, users, history]);

  return (
    <Container>
      <TitlePageContainer>
        <h4>Usuários</h4>
        <Link to="/users/new">
          <Button type="primary">Novo usuário</Button>
        </Link>
      </TitlePageContainer>
      <SearchAndNewUserContainer>
        <Search
          onChange={(e) => setSearchInputValue(e.target.value)}
          placeholder="Pesquisar usuário"
        />
        <Link to="/users/new">
          <Button type="primary">Novo usuário</Button>
        </Link>
      </SearchAndNewUserContainer>
      <UsersListContainer>{contentPage}</UsersListContainer>
    </Container>
  );
}

export default Users;
