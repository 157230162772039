import styled from 'styled-components';

export const SessionContainer = styled.div`
  width: 100%;
  margin-bottom: 32px;

  img {
    width: 88px;
    margin-top: 16px;
    border-radius: 8px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  }

  > p {
    margin-top: 16px;
  }
`;

export const SessionItems = styled.ul`
  width: 100%;
  margin-top: 16px;

  li {
    list-style: none;
    width: 100%;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0px;
    }

    small {
      color: ${props => props.theme.colors.primary.primary0};
    }
  }
`;
