import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  background: ${props => props.theme.colors.light.light0};
  box-shadow: 1px 1px 3px 3px rgba(0, 0, 0, 0.05);
  margin-top: 16px;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  /* padding-top: 16px; */
  /* border-top: 1px solid ${props => props.theme.colors.light['light-2']}; */

  h6 {
    color: ${props => props.theme.colors.dark0};
    line-height: 120%;
  }

  > p:nth-child(2) {
    color: ${props => props.theme.colors.dark['dark+4']};
    line-height: 100%;

    svg {
      margin-right: 4px;
      color: #f8c700;
    }
  }

  /* p {
    color: ${props => props.theme.colors.dark['dark+4']};
  } */

  > div:last-child {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 16px;

    > div {
      width: 100%;

      > small {
        color: ${props => props.theme.colors.dark['dark+3']};
        text-align: start;
        line-height: 100%;
      }
    }
  }
`;

export const GameInfoContainer = styled.div`
  margin-top: 16px;

  div {
    width: 100%;

    & + div {
      margin-top: 4px;
    }

    small {
      /* color: ${props => props.theme.colors.dark['dark+3']}; */
      color: ${props => props.theme.colors.primary.primary0};
      text-align: start;
      line-height: 120%;
    }
    p {
      color: ${props => props.theme.colors.dark['dark+3']};
      line-height: 120%;
    }

    /* small:nth-child(1) {
      color: ${props => props.theme.colors.primary.primary0};
    } */
  }
`;

export const PositiveResultText = styled.p`
  color: ${props => props.theme.colors.primary['primary+1']};
`;

export const NegativeResultText = styled.p`
  color: ${props => props.theme.colors.danger};
`;
