import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;

  > h5,
  > h6,
  > div.ant-spin {
    text-align: center;
    margin: auto 0;
  }

  > h6 {
    font-weight: ${props => props.theme.typograph.weights.medium};
  }
`;

export const ToogleViewerButton = styled.button`
  background: transparent;
  border: 0;
  font-size: ${props => props.theme.typograph.sizes.p}px;
  color: ${props =>
    !props.$danger
      ? props.theme.colors.dark['dark+3']
      : props.theme.colors.danger};
  text-align: center;
  display: flex;
  align-items: center;
  transition: 0.2s color;

  &:hover {
    color: ${props =>
      shade(
        0.2,
        !props.$danger
          ? props.theme.colors.dark['dark+3']
          : props.theme.colors.danger
      )};
  }

  svg {
    margin-right: 8px;
  }
`;

export const ReportedMessagesList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 16px;
`;

export const ReportedMessageContainer = styled.div`
  width: 100%;
  background: ${props => props.theme.colors.light.light0};
  border: 0;
  /* padding: 16px; */
  border-radius: 8px;
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.15);
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 32px;
  }
`;

export const ReportedMessageMainContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  cursor: pointer;

  > div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;

    /* h6 {
      text-align: start;
      flex: 1;
      line-height: 110%;
    } */

    svg {
      color: ${props => props.theme.colors.dark['dark+4']};
    }
  }

  > small {
    color: ${props => props.theme.colors.dark['dark+1']};
  }
`;

export const ReportedMessageDetailsContent = styled.div`
  padding: 16px;
  border-top: 1px solid ${props => props.theme.colors.light['light-2']};

  > div:first-child {
    margin-bottom: 32px;

    div {
      display: flex;

      p {
        color: ${props => props.theme.colors.dark['dark+1']};

        &:first-child {
          color: ${props => props.theme.colors.primary['primary-1']};
          margin-right: 8px;
        }
      }

      & + div {
        margin-top: 8px;
      }
    }
  }

  > div:nth-child(2) {
    margin-bottom: 16px;
  }

  > button {
    margin-top: 8px;
  }
`;

export const LoadingReportedMessageDetailsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const LoadingAndNotFoundContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 16px 0 32px;

  > small {
    width: 100%;
    text-align: center;
    color: ${props => props.theme.colors.dark['dark+3']};
  }

  > div {
    width: 100%;
    display: flex;
    justify-content: center;

    p {
      margin-left: 16px;
      color: ${props => props.theme.colors.dark['dark+3']};
    }

    h6 {
      text-align: center;

      span {
        color: ${props => props.theme.colors.primary.primary0};
      }
    }
  }
`;
