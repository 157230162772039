import React, { useMemo } from 'react';
import { Button } from 'antd';

import { ButtonsContainer } from '../styles';
import { SessionContainer, SessionItems } from './styles';

function getWeightLabel(weight) {
  switch (weight) {
    case -1:
      return 'Irrelevante';
    case 0:
      return 'Pouco relevante';
    case 1:
      return 'Relevante';
    case 2:
      return 'Muito relevante';
    default:
      return '';
  }
}

function Confirm({ formik, prevStep, onSubmit }) {
  const formItems = useMemo(() => {
    const { image, advertSettings } = formik.values;

    return {
      advertSettings: [
        {
          label: 'Nome',
          value: advertSettings?.title || 'Não informado',
        },
        {
          label: 'URL',
          value: advertSettings?.url || 'Não informado',
        },
        {
          label: 'Relevância',
          value: advertSettings?.weight
            ? getWeightLabel(advertSettings?.weight)
            : 'Não informado',
        },
        {
          label: 'Data de expiração',
          value: advertSettings?.endDate
            ? advertSettings?.endDate.format('dddd, DD/MM/YYYY [às] HH:mm')
            : 'Não informado',
        },
      ],
      image: image?.previewUrl,
    };
  }, [formik.values]);

  return (
    <>
      <SessionContainer>
        <h6>Imagem</h6>
        {formItems.image ? (
          <img src={formItems.image} alt="adsimage" />
        ) : (
          <p>Não adicionado</p>
        )}
      </SessionContainer>
      <SessionContainer>
        <h6>Configurações do anúncio</h6>
        <SessionItems>
          {formItems.advertSettings.map(item => (
            <li key={`advertSettings_${item.label}`}>
              <small>{item.label}</small>
              <p>{item.value}</p>
            </li>
          ))}
        </SessionItems>
      </SessionContainer>
      <ButtonsContainer>
        <Button onClick={prevStep} type="default">
          Voltar
        </Button>
        <Button
          onClick={onSubmit}
          disabled={formik.isSubmitting}
          type="primary"
        >
          {!formik.isSubmitting ? 'Finalizar' : 'Carregando...'}
        </Button>
      </ButtonsContainer>
    </>
  );
}

export default Confirm;
