import React, { useMemo } from 'react';
import { Button } from 'antd';

import { formatGender, formatPassword } from '@/utils/formatters';
import { ButtonsContainer } from '../styles';
import { SessionContainer, SessionItems } from './styles';
import { IConfirmProps } from './types';

const Confirm: React.FC<IConfirmProps> = ({ formik, prevStep, onSubmit }) => {
  const formItems = useMemo(() => {
    const { influencerInformation, personalData, avatar } = formik.values;

    return {
      influencerInformation: [
        {
          label: 'Nome do influenciador',
          value: influencerInformation?.username || 'Não informado',
        },
        {
          label: 'Email',
          value: influencerInformation?.email || 'Não informado',
        },
        {
          label: 'Senha',
          value: formatPassword(influencerInformation?.password),
        },
        {
          label: 'Código do influenciador',
          value: influencerInformation?.code || 'Não informado',
        },
      ],
      personalData: [
        { label: 'Nome', value: personalData?.name || 'Não informado' },
        { label: 'Sexo', value: formatGender(personalData?.gender) },
        { label: 'CPF', value: personalData?.docNumber || 'Não informado' },
        {
          label: 'Telefone celular',
          value: personalData?.phone || 'Não informado',
        },
      ],
      avatar: avatar?.previewUrl,
    };
  }, [formik.values]);

  return (
    <>
      <SessionContainer>
        <h6>Informações do influenciador</h6>
        <SessionItems>
          {formItems.influencerInformation.map((item) => (
            <li key={`influencerInformation_${item.label}`}>
              <small>{item.label}</small>
              <p>{item.value}</p>
            </li>
          ))}
        </SessionItems>
      </SessionContainer>
      <SessionContainer>
        <h6>Dados pessoais</h6>
        <SessionItems>
          {formItems.personalData.map((item) => (
            <li key={`personalData_${item.label}`}>
              <small>{item.label}</small>
              <p>{item.value}</p>
            </li>
          ))}
        </SessionItems>
      </SessionContainer>
      <SessionContainer>
        <h6>Avatar</h6>
        {formItems.avatar ? (
          <img src={formItems.avatar} alt="Avatar" />
        ) : (
          <p>Não adicionado</p>
        )}
      </SessionContainer>
      <ButtonsContainer>
        <Button onClick={prevStep} type="default">
          Voltar
        </Button>
        <Button
          onClick={onSubmit}
          disabled={formik.isSubmitting}
          type="primary"
        >
          {!formik.isSubmitting ? 'Finalizar' : 'Carregando...'}
        </Button>
      </ButtonsContainer>
    </>
  );
};

export default Confirm;
