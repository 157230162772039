import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { Button } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { Formik, FastField } from 'formik';
import * as Yup from 'yup';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

import {
  Container,
  TitlePageContainer,
  Content,
  ButtonsContainer,
} from './styles';

import Input from '@/components/Input';
import api from '@/services/api';
import useBeforeUnload from '@/hooks/useBeforeUnload';
import useMergeRefs from '@/hooks/useMergeRefs';

function EditChampionshipInformations({
  championship,
  setChampionship,
  parentRouteMatch,
}) {
  const history = useHistory();
  const params = useParams();
  const mergeRefs = useMergeRefs();

  const formikRef = useRef(null);

  const [haveChanges, setHaveChanges] = useState(false);

  useBeforeUnload({
    when: haveChanges,
    message: 'Deseja realmente sair? As alterações serão perdidas',
  });

  const unblockPage = useMemo(() => {
    const messageComponents = {
      title: 'Deseja realmente cancelar a atualização dos dados?',
      content: 'Todos as alterações serão perdidas',
      cancelText: 'Voltar',
      okText: 'Cancelar',
    };

    if (haveChanges) {
      return history.block(JSON.stringify(messageComponents));
    }
    return () => {};
  }, [haveChanges, history]);

  useEffect(() => {
    return () => {
      unblockPage();
    };
  }, [unblockPage]);

  const checkIfHaveChanges = useCallback(
    currentValues => {
      if (_.isEqual(championship, currentValues)) {
        if (haveChanges) {
          setHaveChanges(false);
        }
        return;
      }

      if (!haveChanges) {
        setHaveChanges(true);
      }
    },
    [haveChanges, championship]
  );

  const handleSubmit = useCallback(
    async values => {
      const { id } = params;

      if (!id) {
        return toast.warn(
          'Ocorreu um problema ao atualizar os dados desse campeonato, atualize a página e tente novamente!'
        );
      }

      const body = {
        name: values?.name,
        weight: values?.weight,
      };

      try {
        await api.put(`/api/championship/${id}`, body);

        toast.success('Dados atualizados com sucesso!');
        setChampionship(oldState => ({ ...oldState, ...body }));
        // actions.setSubmitting(false);
        unblockPage();

        history.push(parentRouteMatch.url);
      } catch (error) {
        console.log(error);

        toast.error('Aconteceu um erro inesperado ao atualizar o campeonato!');
      }
      return null;
    },
    [history, params, parentRouteMatch.url, setChampionship, unblockPage]
  );

  const eneableSubmitButton = useCallback(
    ({ touched, errors, values }) => {
      if (
        _.isEqual(championship, values) ||
        Object.entries(touched).length === 0 ||
        Object.entries(errors).length > 0
      ) {
        return false;
      }

      return true;
    },
    [championship]
  );

  const handleFormikRef = node => {
    if (node !== null) {
      checkIfHaveChanges(node.values);
    }
  };

  return (
    <Container>
      <TitlePageContainer>
        <h5>Editar</h5>
        <Button onClick={() => history.goBack()} danger type="text">
          Cancelar
        </Button>
      </TitlePageContainer>
      <Content>
        <h6>Informações do usuário</h6>
        <Formik
          innerRef={mergeRefs(formikRef, handleFormikRef)}
          initialValues={championship}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('O nome do campeonato é obrigatório'),
            weight: Yup.number()
              .typeError('O peso informado não é válido')
              .required('O peso do campeonato é obrigatório'),
          })}
          onSubmit={handleSubmit}
        >
          {formikProps => (
            <form onSubmit={formikProps.handleSubmit}>
              <FastField name="name">
                {({ field, meta }) => {
                  return (
                    <Input
                      {...field}
                      label="Nome do campeonato"
                      placeholder="Digite o nome do campeonato"
                      identifier="name"
                      type="text"
                      error={meta?.touched && meta?.error && meta?.error}
                    />
                  );
                }}
              </FastField>
              <FastField name="weight">
                {({ field, meta }) => {
                  return (
                    <Input
                      {...field}
                      label="Peso"
                      placeholder="Digite o peso do campeonato"
                      identifier="weight"
                      type="text"
                      error={meta?.touched && meta?.error}
                      onChange={e => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        field.onChange(e);
                      }}
                    />
                  );
                }}
              </FastField>
              <ButtonsContainer>
                <Button
                  htmlType="submit"
                  disabled={
                    formikProps.isSubmitting ||
                    !eneableSubmitButton(formikProps)
                  }
                  type="primary"
                >
                  {!formikProps.isSubmitting ? 'Atualizar' : 'Carregando...'}
                </Button>
              </ButtonsContainer>
            </form>
          )}
        </Formik>
      </Content>
    </Container>
  );
}

export default EditChampionshipInformations;
