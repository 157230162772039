import React from 'react';
import { Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import RouteWrapper from './RouteWrapper';

import { getRoutes } from './routes';

export default function Routes() {
  const me = useSelector(state => state.user.profile);
  const routesList = getRoutes(me?.roles);

  return (
    <Switch>
      {routesList.map(route => (
        <RouteWrapper key={route.path} {...route} />
      ))}
    </Switch>
  );
}
