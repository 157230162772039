import React, { useMemo } from 'react';

import {
  Bar,
  BarColor,
  Container,
  Label,
  LabelsContainer,
  TotalPercent,
} from './styles';
import { IGamePaymentPercentBarProps } from './types';

const GamePaymentPercentBar: React.FC<IGamePaymentPercentBarProps> = ({
  items,
}) => {
  const totalPercent = useMemo(() => {
    return items.reduce((total, item) => total + item.value, 0);
  }, [items]);

  const isPercentOverflowed = useMemo(() => {
    return totalPercent > 100;
  }, [totalPercent]);

  return (
    <Container>
      <TotalPercent $total={totalPercent}>{totalPercent}%</TotalPercent>
      <Bar>
        {items.map((item) => {
          if (!item.value) return <React.Fragment key={item.label} />;

          return (
            <BarColor
              key={item.label}
              $percent={item.value}
              $color={item.color}
              $error={isPercentOverflowed}
            />
          );
        })}
      </Bar>
      <LabelsContainer>
        {items.map((item) => (
          <Label key={item.label} $color={item.color}>
            <small>{item.label}</small>
            <strong>{item.value}%</strong>
          </Label>
        ))}
      </LabelsContainer>
    </Container>
  );
};

export default GamePaymentPercentBar;
