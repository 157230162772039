import React from 'react';
import { Button } from 'antd';
import { FastField } from 'formik';

import Input from '@/components/Input';

import { ButtonsContainer } from '../styles';

function MatchCardInformations({ formik, nextStep, setFocusedField }) {
  function eneableNextStepButton({ touched, errors }) {
    if (!touched.matchCardInformations) {
      return false;
    }

    if (!errors.matchCardInformations) {
      return true;
    }

    return false;
  }

  return (
    <>
      <FastField name="matchCardInformations.name">
        {({ field, meta }) => {
          return (
            <Input
              {...field}
              label="Nome da cartela"
              placeholder="Digite o nome da cartela"
              identifier="matchCardName"
              type="text"
              error={meta?.touched && meta?.error && meta?.error}
              onFocus={() => setFocusedField('matchCardInformations.name')}
            />
          );
        }}
      </FastField>
      <FastField name="matchCardInformations.price">
        {({ field, meta }) => {
          return (
            <Input
              {...field}
              label="Valor do jogo"
              placeholder="Digite o valor do jogo da cartela"
              identifier="matchCardPrice"
              type="text"
              error={meta?.touched && meta?.error && meta?.error}
              onFocus={() => setFocusedField('matchCardInformations.price')}
              addonBefore="R$"
            />
          );
        }}
      </FastField>
      <ButtonsContainer>
        <Button
          onClick={nextStep}
          type="primary"
          disabled={!eneableNextStepButton(formik)}
        >
          Próximo
        </Button>
      </ButtonsContainer>
    </>
  );
}

export default MatchCardInformations;
