import React from 'react';
import { Button } from 'antd';

import { ButtonsContainer } from '../styles';

import AvatarCropper from '@/components/AvatarCropper';

function Avatar({ formik, nextStep, prevStep }) {
  return (
    <>
      <AvatarCropper
        croppedAvatar={formik?.values?.banner}
        selectImageButtonLabel="Selecione uma imagem para adicionar como capa dessa cartela"
        aspect={16 / 9}
        setCroppedAvatar={({ file, previewUrl }) => {
          formik.setFieldValue('banner.file', file);
          formik.setFieldValue('banner.previewUrl', previewUrl);
        }}
      />
      <ButtonsContainer>
        <Button onClick={prevStep} type="default">
          Voltar
        </Button>
        <Button onClick={nextStep} type="primary">
          Próximo
        </Button>
      </ButtonsContainer>
    </>
  );
}

export default Avatar;
