import React from 'react';
import { Select as SelectAnt } from 'antd';

import { Container } from './styles';

const { Option } = SelectAnt;

function Select({ label, identifier, options, error, ...rest }) {
  return (
    <Container error={error}>
      <label htmlFor={identifier}>{label}</label>
      <SelectAnt id={identifier} bordered {...rest}>
        {options.map(({ label: optionLabel, ...restOptionProps }) => (
          <Option key={restOptionProps.value} {...restOptionProps}>
            {optionLabel}
          </Option>
        ))}
      </SelectAnt>
      {error && <span>{error}</span>}
    </Container>
  );
}

export default Select;
