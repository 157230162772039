import React from 'react';
import { Button } from 'antd';
import { MdEdit } from 'react-icons/md';
import { Link, useRouteMatch } from 'react-router-dom';

import * as formatters from '@/utils/formatters';
import { Container, SessionHeader, SessionItems } from './styles';

import { IDetailsProps } from './types';

const Details: React.FC<IDetailsProps> = ({ influencer }) => {
  const routeMatch = useRouteMatch();

  return (
    <Container>
      <div>
        <SessionHeader>
          <h6>Informações do influenciador</h6>
          <Link to={`${routeMatch.url}/edit_influencer_information`}>
            <Button type="text" icon={<MdEdit height={18} />} />
          </Link>
        </SessionHeader>
        <SessionItems>
          <li>
            <small>Nome do influenciador</small>
            <p>{influencer?.influencerInformation?.username}</p>
          </li>
          <li>
            <small>Email</small>
            <p>{influencer?.influencerInformation?.email}</p>
          </li>
          <li>
            <small>Código do influenciador</small>
            <p>{influencer?.influencerInformation?.code}</p>
          </li>
        </SessionItems>
      </div>
      <div>
        <SessionHeader>
          <h6>Dados pessoais</h6>
          <Link to={`${routeMatch.url}/edit_personal_data`}>
            <Button type="text" icon={<MdEdit height={18} />} />
          </Link>
        </SessionHeader>
        <SessionItems>
          <li>
            <small>Nome</small>
            <p>{influencer?.personalData?.name}</p>
          </li>
          <li>
            <small>Sexo</small>
            <p>{formatters.formatGender(influencer?.personalData?.gender)}</p>
          </li>
          <li>
            <small>CPF</small>
            <p>{formatters.formatCpf(influencer?.personalData?.docNumber)}</p>
          </li>
          <li>
            <small>Telefone</small>
            <p>{formatters.formatPhone(influencer?.personalData?.phone)}</p>
          </li>
        </SessionItems>
      </div>
    </Container>
  );
};

export default Details;
