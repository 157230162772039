import React, { useMemo } from 'react';
import { Button } from 'antd';

import AvatarCropper from '@/components/AvatarCropper';
import { getYouTubeVideoId } from '@/utils/validate';
import { ButtonsContainer } from '../styles';

import { IBannerProps } from './types';
import { AdsTypeEnum } from '../../types';
import { YoutubeVideoContainer } from './styles';

const Avatar: React.FC<IBannerProps> = ({ formik, nextStep, prevStep }) => {
  const adsType = formik.values.adsDetails.type;

  const youtubeUrl = useMemo(() => {
    const adsUrl = formik.values.adsDetails.url;
    const youTubeVideoId = getYouTubeVideoId(adsUrl);
    return adsType === AdsTypeEnum.VIDEO
      ? `https://www.youtube.com/embed/${youTubeVideoId}?autoplay=1`
      : null;
  }, [adsType, formik.values.adsDetails.url]);

  const isNextButtonEnabled = useMemo(() => {
    if (adsType === AdsTypeEnum.VIDEO) return true;
    if (formik?.values?.photo.previewUrl) return true;
    return false;
  }, [adsType, formik?.values?.photo.previewUrl]);

  return (
    <>
      {adsType === AdsTypeEnum.IMAGE && (
        <AvatarCropper
          croppedAvatar={formik?.values?.photo}
          selectImageButtonLabel="Selecione uma imagem para adicionar como capa dessa cartela"
          aspect={16 / 9}
          setCroppedAvatar={({
            file,
            previewUrl,
          }: {
            file?: unknown;
            previewUrl?: string;
          }) => {
            formik.setFieldValue('photo.file', file);
            formik.setFieldValue('photo.previewUrl', previewUrl);
          }}
          required
          circularCrop={false}
          setHaveChanges={() => ({})}
        />
      )}
      {adsType === AdsTypeEnum.VIDEO && (
        <YoutubeVideoContainer>
          <iframe
            title={youtubeUrl || ''}
            src={youtubeUrl || ''}
            allow="autoplay"
          />
        </YoutubeVideoContainer>
      )}
      <ButtonsContainer>
        <Button onClick={prevStep} type="default">
          Voltar
        </Button>
        <Button
          onClick={nextStep}
          type="primary"
          disabled={!isNextButtonEnabled}
        >
          Próximo
        </Button>
      </ButtonsContainer>
    </>
  );
};

export default Avatar;
