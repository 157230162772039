import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: ${(props) => props.theme.containerWidth};
  margin: 0 auto;

  > ul {
    margin: 2rem 0 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;

    @media only screen and (max-width: ${(props) =>
        props.theme.breakpoints.xl}) {
      grid-template-columns: 1fr 1fr;
    }

    @media only screen and (max-width: ${(props) =>
        props.theme.breakpoints.md}) {
      grid-template-columns: 1fr;
    }
  }
`;

export const LoadingDescription = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  margin: 2rem auto 0;
  width: fit-content;
`;
