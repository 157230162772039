import React from 'react';

import { Button } from './styles';
import { ICustomAntButtonProps } from './types';

const CustomAntButton: React.FC<ICustomAntButtonProps> = ({
  useTextPrimary,
  useSecondary,
  useCustomIconWithText,
  icon,
  ...rest
}) => {
  return (
    <Button
      $useTextPrimary={!!useTextPrimary}
      $useSecondary={!!useSecondary}
      icon={
        useCustomIconWithText ? <span className="anticon">{icon}</span> : icon
      }
      {...rest}
    />
  );
};

export default CustomAntButton;
