import styled, { css } from 'styled-components';

interface IBarColor {
  $percent: number;
  $color: string;
  $error?: boolean;
}

interface ITotalPercent {
  $total: number;
}

interface ILabel {
  $color: string;
}

export const Container = styled.div`
  width: 100%;
  max-width: 40rem;
`;

export const TotalPercent = styled.h6<ITotalPercent>`
  width: 100%;
  text-align: end;

  color: ${(props) => props.theme.colors.dark['dark+3']};

  ${(props) =>
    props.$total === 100 &&
    css`
      color: ${props.theme.colors.primary['primary+1']};
    `}
  ${(props) =>
    props.$total > 100 &&
    css`
      color: ${props.theme.colors.danger};
    `}
`;

export const Bar = styled.div`
  flex: 1;
  height: 1.5rem;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.light['light-2']};
  display: flex;
  box-shadow: 0px 1px 5px #00000050;
`;

export const BarColor = styled.div<IBarColor>`
  width: ${(props) => props.$percent}%;
  height: 100%;
  background-color: ${(props) => props.$color};
  display: flex;
  align-items: center;

  ${(props) =>
    props.$error &&
    css`
      @keyframes light {
        0% {
          opacity: 1;
        }
        50% {
          opacity: 0.4;
        }
        100% {
          opacity: 1;
        }
      }

      background-color: ${props.theme.colors.danger};
      animation: light 1s ease-in-out infinite;
    `}

  &:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  &:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

export const LabelsContainer = styled.ul`
  display: flex;
  gap: 1.5rem;
  margin: 1rem 0 0;
  padding: 0;
  list-style: none;
  flex-wrap: wrap;

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    flex-direction: column;
    gap: 0.5rem;
  }
`;

export const Label = styled.li<ILabel>`
  small {
    color: ${(props) => props.theme.colors.dark['dark+2']};
    display: flex;
    align-items: center;

    &::before {
      content: '';
      display: block;
      width: 0.75rem;
      height: 0.75rem;
      border-radius: 2px;
      background-color: ${(props) => props.$color};
      margin-right: 0.5rem;
    }
  }

  strong {
    margin: 0 0 0 1.25rem;
    line-height: 100%;
  }
`;
