import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { Button } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import {
  Formik,
  Field,
  FastField,
  FormikProps,
  FormikHelpers,
  FastFieldProps,
  FieldProps,
} from 'formik';
import * as Yup from 'yup';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

import Input from '@/components/Input';
import Radio from '@/components/Radio';
import InputMask from '@/components/InputMask';

import { InfluencerRequests } from '@/services/api/requests/Influencer';
import useBeforeUnload from '@/hooks/useBeforeUnload';
import useMergeRefs from '@/hooks/useMergeRefs';
import { UserGenderEnum } from '@/models/User';
import { IUpdateInfluencerRequestBody } from '@/services/api/requests/Influencer/types';
import {
  Container,
  TitlePageContainer,
  Content,
  ButtonsContainer,
} from './styles';
import {
  IEditPersonalDataProps,
  IEditPersonalFormData,
  IRouteParams,
} from './types';

const EditPersonalData: React.FC<IEditPersonalDataProps> = ({
  influencer,
  setInfluencer,
  parentRouteMatch,
}) => {
  const history = useHistory();
  const params = useParams<IRouteParams>();
  const mergeRefs = useMergeRefs();

  const formikRef = useRef<FormikProps<IEditPersonalFormData>>(null);

  const [haveChanges, setHaveChanges] = useState(false);

  useBeforeUnload({
    when: haveChanges,
    message: 'Deseja realmente sair? As alterações serão perdidas',
  });

  const unblockPage = useMemo(() => {
    const messageComponents = {
      title: 'Deseja realmente cancelar a atualização dos dados?',
      content: 'Todos as alterações serão perdidas',
      cancelText: 'Voltar',
      okText: 'Cancelar',
    };

    if (haveChanges) {
      return history.block(JSON.stringify(messageComponents));
    }
    return () => ({});
  }, [haveChanges, history]);

  useEffect(() => {
    return () => {
      unblockPage();
    };
  }, [unblockPage]);

  const checkIfHaveChanges = useCallback(
    (currentValues: IEditPersonalFormData) => {
      if (_.isEqual(influencer?.personalData, currentValues)) {
        if (haveChanges) {
          setHaveChanges(false);
        }
        return;
      }

      if (!haveChanges) {
        setHaveChanges(true);
      }
    },
    [haveChanges, influencer.personalData]
  );

  const handleSubmit = useCallback(
    async (
      values: IEditPersonalFormData,
      actions: FormikHelpers<IEditPersonalFormData>
    ) => {
      const { id } = params;

      if (!id) {
        return toast.warn(
          'Ocorreu um problema ao atualizar os dados desse influenciador, atualize a página e tente novamente!'
        );
      }

      const phone =
        values?.phone &&
        values.phone.replace(/\(/g, '').replace(/\)/g, '').replace(/-/g, '');
      const body: IUpdateInfluencerRequestBody = {
        gender: values?.gender,
        phones: [phone],
      };

      try {
        await InfluencerRequests.updateInfluencer(id, body);

        setInfluencer((oldState) => ({
          ...oldState,
          personalData: {
            ...oldState.personalData,
            gender: values?.gender,
            phone,
          },
        }));

        toast.success('Dados atualizados com sucesso!');
        unblockPage();
        actions.setSubmitting(false);
        history.push(parentRouteMatch.url);
      } catch (error) {
        toast.error(
          'Aconteceu um erro inesperado ao atualizar o avatar do influenciador!'
        );
      }
      return null;
    },
    [history, params, parentRouteMatch.url, setInfluencer, unblockPage]
  );

  const checkIfIsSubmitButtonEnabled = useCallback(
    ({ touched, errors, values }: FormikProps<IEditPersonalFormData>) => {
      if (
        _.isEqual(influencer?.personalData, {
          ...values,
          docNumber: values.docNumber.replace(/\./g, '').replace(/-/g, ''),
          phone: values.phone
            .replace(/\(/g, '')
            .replace(/\)/g, '')
            .replace(/-/g, ''),
        }) ||
        Object.entries(touched).length === 0 ||
        Object.entries(errors).length > 0
      ) {
        return false;
      }

      return true;
    },
    [influencer.personalData]
  );

  const handleFormikRef = (
    formikProps: FormikProps<IEditPersonalFormData>
  ): void => {
    if (formikProps !== null) {
      checkIfHaveChanges(formikProps.values);
    }
  };

  return (
    <Container>
      <TitlePageContainer>
        <h5>Editar</h5>
        <Button onClick={() => history.goBack()} danger type="text">
          Cancelar
        </Button>
      </TitlePageContainer>
      <Content>
        <h6>Dados pessoais</h6>
        <Formik
          innerRef={mergeRefs(formikRef, handleFormikRef)}
          initialValues={{
            name: influencer?.personalData?.name || '',
            gender: influencer?.personalData?.gender || '',
            docNumber: influencer?.personalData?.docNumber || '',
            phone: influencer?.personalData?.phone || '',
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('O nome é obrigatório'),
            gender: Yup.string()
              .required('O sexo é obrigatório')
              .oneOf(['M', 'F', 'O'], 'O sexo selecionado é inválido'),
            docNumber: Yup.string().required('O CPF é obrigatório'),
            phone: Yup.string()
              .required('O telefone é obrigatório')
              .transform((value) =>
                value.replace(/\(/g, '').replace(/\)/g, '').replace(/-/g, '')
              )
              .matches(/[0-9]{11}/, {
                excludeEmptyString: true,
                message: 'O telefone informado é inválido',
              }),
          })}
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <form onSubmit={formikProps.handleSubmit}>
              <FastField name="name">
                {({ field, meta }: FastFieldProps) => {
                  return (
                    <Input
                      {...field}
                      disabled
                      label="Nome"
                      placeholder="Digite o nome da pessoa"
                      identifier="name"
                      type="text"
                      error={meta?.touched && meta?.error && meta?.error}
                    />
                  );
                }}
              </FastField>
              <FastField name="gender">
                {({ field, meta }: FastFieldProps) => {
                  return (
                    <Radio
                      {...field}
                      items={[
                        { value: UserGenderEnum.MALE, label: 'Masculino' },
                        { value: UserGenderEnum.FEMALE, label: 'Feminino' },
                        { value: UserGenderEnum.OTHER, label: 'Outros' },
                      ]}
                      label="Sexo"
                      error={meta?.touched && meta?.error && meta?.error}
                    />
                  );
                }}
              </FastField>
              <Field name="docNumber">
                {({ field, meta }: FieldProps) => {
                  return (
                    <InputMask
                      {...field}
                      disabled
                      mask="999.999.999-99"
                      label="CPF"
                      identifier="docNumber"
                      placeholder="999.999.999-99"
                      type="text"
                      error={meta?.touched && meta?.error && meta?.error}
                    />
                  );
                }}
              </Field>
              <FastField name="phone">
                {({ field, meta }: FastFieldProps) => {
                  return (
                    <InputMask
                      {...field}
                      mask="(99)99999-9999"
                      label="Telefone celular"
                      identifier="phone"
                      placeholder="(99)99999-9999"
                      type="text"
                      error={meta?.touched && meta?.error && meta?.error}
                    />
                  );
                }}
              </FastField>
              <ButtonsContainer>
                <Button
                  htmlType="submit"
                  disabled={
                    formikProps.isSubmitting ||
                    !checkIfIsSubmitButtonEnabled(formikProps)
                  }
                  type="primary"
                >
                  {!formikProps.isSubmitting ? 'Atualizar' : 'Carregando...'}
                </Button>
              </ButtonsContainer>
            </form>
          )}
        </Formik>
      </Content>
    </Container>
  );
};

export default EditPersonalData;
