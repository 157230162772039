import styled from 'styled-components';
import { darken } from 'polished';

export const SelectImageButton = styled.div`
  width: 100%;
  max-width: 550px;
  /* height: 102px; */
  border: 1px solid ${(props) => props.theme.colors.dark['dark+4']};
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.light['light-2']};
  border-radius: 8px;
  padding: 32px;
  cursor: pointer;
  align-self: center;
  transition: 0.2s background-color;

  &:hover {
    background-color: ${(props) =>
      darken(0.05, props.theme.colors.light['light-2'])};
  }

  p {
    text-align: center;
    span {
      font-weight: ${(props) => props.theme.typograph.weights.bold};
    }
  }
`;

export const CropImageContainer = styled.div`
  background-color: ${(props) => props.theme.colors.light.light0};
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  width: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;
export const ImageContainer = styled.div`
  flex: 1;
  padding: 16px;
  display: flex;
  justify-content: center;
`;

export const ActionButtonsContainer = styled.div`
  background-color: ${(props) => props.theme.colors.light.light0};
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  button:nth-child(1) {
    margin-right: 16px;
  }

  button:nth-child(3) {
    margin-left: auto;
  }

  @media only screen and (max-width: 767px) {
    justify-content: space-between;
    button {
      margin: 0px !important;
    }
  }
`;

export const ViewerAvatarContainer = styled.div`
  width: 100%;
  max-width: 450px;
  background-color: ${(props) => props.theme.colors.light.light0};
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${(props) => props.theme.colors.light['light-2']};
  align-self: center;

  img {
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
`;

export const ActionButtonsAvatarContainer = styled.div`
  background-color: ${(props) => props.theme.colors.light.light0};
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  display: flex;

  button:nth-child(2) {
    margin-left: 16px;
  }

  @media only screen and (max-width: 767px) {
    button:nth-child(2) {
      margin-left: auto;
    }
  }
`;

export const YoutubeVideoContainer = styled.div`
  width: 100%;
  padding-top: 56.25%;
  position: relative;

  iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`;
