import styled from 'styled-components';

export const Container = styled.div`
  background: ${props => props.theme.colors.light['light-1']};
  height: 61px;
  width: 100%;
  padding: 0px 16px;
  display: none;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.light['light-2']};

  /* @media only screen and (max-width: 768px) { */
  @media only screen and (max-width: 1199px) {
    display: flex;
  }

  /* AntD Button */
  button {
    padding: 2.4px;
  }

  img {
    width: 75px;
  }

  div {
    width: 34.78px;
  }
`;
