import React, { useState } from 'react';

import { Wrapper, Container, Mask } from './styles';
import CustomAntButton from '../CustomAntButton';
import Input from '../Input';

interface IConfirmPasswordProps {
  handleConfirm(password: string): void;
  handleClose(): void;
}

const ConfirmPassword: React.FC<IConfirmPasswordProps> = ({
  handleClose,
  handleConfirm,
}) => {
  const [password, setPassword] = useState('');

  return (
    <Wrapper>
      <Mask onClick={handleClose} />
      <Container>
        <h6>Confirme sua senha</h6>
        <Input
          name="password"
          type="password"
          placeholder="Digite"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
        />
        <CustomAntButton
          disabled={!password}
          type="primary"
          onClick={() => handleConfirm(password)}
        >
          Continuar
        </CustomAntButton>
      </Container>
    </Wrapper>
  );
};

export default ConfirmPassword;
