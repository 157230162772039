import React, { useMemo } from 'react';
import { Button } from 'antd';

import { ButtonsContainer } from '../styles';
import { SessionContainer, SessionItems } from './styles';

function Confirm({ formik, prevStep, onSubmit }) {
  const formItems = useMemo(() => {
    const { userInformations, personalData, avatar } = formik.values;

    function formatGender(gender) {
      let formattedGender = '';
      switch (gender) {
        case 'M':
          formattedGender = 'Masculino';
          break;
        case 'F':
          formattedGender = 'Feminino';
          break;
        case 'O':
          formattedGender = 'Outro';

          break;

        default:
          formattedGender = 'Não informado';
          break;
      }

      return formattedGender;
    }

    function formatPassword(password) {
      if (!password) {
        return 'Não informado';
      }

      let formattedPassword = password.split('');
      formattedPassword.fill('*');
      formattedPassword = formattedPassword.join('');

      return formattedPassword;
    }

    return {
      userInformations: [
        {
          label: 'Nome do usuário',
          value: userInformations?.username || 'Não informado',
        },
        { label: 'Email', value: userInformations?.email || 'Não informado' },
        {
          label: 'Senha',
          value: formatPassword(userInformations?.password),
        },
      ],
      personalData: [
        { label: 'Nome', value: personalData?.name || 'Não informado' },
        { label: 'Sexo', value: formatGender(personalData?.gender) },
        { label: 'CPF', value: personalData?.docNumber || 'Não informado' },
        {
          label: 'Telefone celular',
          value: personalData?.phone || 'Não informado',
        },
      ],
      avatar: avatar?.previewUrl,
    };
  }, [formik.values]);

  return (
    <>
      <SessionContainer>
        <h6>Informações do usuário</h6>
        <SessionItems>
          {formItems.userInformations.map(item => (
            <li key={`userInformations_${item.label}`}>
              <small>{item.label}</small>
              <p>{item.value}</p>
            </li>
          ))}
        </SessionItems>
      </SessionContainer>
      <SessionContainer>
        <h6>Dados pessoais</h6>
        <SessionItems>
          {formItems.personalData.map(item => (
            <li key={`personalData_${item.label}`}>
              <small>{item.label}</small>
              <p>{item.value}</p>
            </li>
          ))}
        </SessionItems>
      </SessionContainer>
      <SessionContainer>
        <h6>Avatar</h6>
        {formItems.avatar ? (
          <img src={formItems.avatar} alt="Avatar" />
        ) : (
          <p>Não adicionado</p>
        )}
      </SessionContainer>
      <ButtonsContainer>
        <Button onClick={prevStep} type="default">
          Voltar
        </Button>
        <Button
          onClick={onSubmit}
          disabled={formik.isSubmitting}
          type="primary"
        >
          {!formik.isSubmitting ? 'Finalizar' : 'Carregando...'}
        </Button>
      </ButtonsContainer>
    </>
  );
}

export default Confirm;
