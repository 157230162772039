import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from 'antd';
import { toast } from 'react-toastify';

import { Container, TitlePageContainer, ButtonsContainer } from './styles';

import api from '@/services/api';
import AvatarCropper from '@/components/AvatarCropper';
import useBeforeUnload from '@/hooks/useBeforeUnload';
import { UploadRequests } from '@/services/api/requests/Upload';

function UpdateAvatar({ user, setUser }) {
  const history = useHistory();
  const params = useParams();

  const [newAvatar, setNewAvatar] = useState({
    file: null,
    previewUrl: '',
  });
  const [haveChanges, setHaveChanges] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useBeforeUnload({
    when: haveChanges,
    message: 'Deseja realmente sair? As alterações serão perdidas',
  });

  const unblockPage = useMemo(() => {
    const messageComponents = {
      title: 'Deseja realmente cancelar a atualização do avatar?',
      content: 'Todos as alterações serão perdidas',
      cancelText: 'Voltar',
      okText: 'Cancelar',
    };

    if (haveChanges) {
      return history.block(JSON.stringify(messageComponents));
    }
    return () => {};
  }, [haveChanges, history]);

  useEffect(() => {
    return () => {
      unblockPage();
    };
  }, [unblockPage]);

  const handleSubmit = useCallback(async () => {
    const { file } = newAvatar;

    if (!file) {
      toast.warn(
        'Aconteceu um erro inesperado ao atualizar o avatar do usuário! Atualize a página'
      );
      return;
    }

    setSubmitting(true);

    let newPhotoId = null;
    let newPhotoFilename = null;

    try {
      const { data } = await UploadRequests.upload({
        file,
        from: 'userAvatar',
      });

      newPhotoId = data._id;
      newPhotoFilename = data.filename;
    } catch (error) {
      console.log(error);

      setSubmitting(false);
      toast.error(
        'Aconteceu um erro inesperado ao atualizar o avatar do usuário!'
      );

      return;
    }

    const body = {
      photo: newPhotoId,
    };

    try {
      await api.put(`/api/user-admin/${params.id}`, body);

      toast.success('Avatar do usuário atualizado com sucesso');
      setUser({ ...user, avatar: newPhotoFilename });
      setSubmitting(false);
      unblockPage();

      history.goBack();
    } catch (error) {
      console.log(error);
      toast.error(
        'Aconteceu um erro inesperado ao atualizar o avatar do usuário!'
      );
      setSubmitting(false);
    }
  }, [history, newAvatar, params.id, setUser, unblockPage, user]);

  return (
    <Container>
      <TitlePageContainer>
        <h5>Atualizar avatar</h5>
        <Button onClick={() => history.goBack()} danger type="text">
          Cancelar
        </Button>
      </TitlePageContainer>
      <AvatarCropper
        croppedAvatar={newAvatar}
        setCroppedAvatar={setNewAvatar}
        setHaveChanges={setHaveChanges}
      />
      {newAvatar?.previewUrl && (
        <ButtonsContainer>
          <Button onClick={handleSubmit} disabled={submitting} type="primary">
            Atualizar avatar
          </Button>
        </ButtonsContainer>
      )}
    </Container>
  );
}

export default UpdateAvatar;
