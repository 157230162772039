import React, { useCallback } from 'react';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { Formik, FastField } from 'formik';
import * as Yup from 'yup';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

import {
  Container,
  TitlePageContainer,
  Content,
  ButtonsContainer,
} from './styles';

import Input from '@/components/Input';

import api from '@/services/api';

function EditAdvertisementSettings({
  advertisementSettings,
  setAdvertisementSettings,
}) {
  const history = useHistory();

  const handleSubmit = useCallback(
    async (values, actions) => {
      const body = {
        time: values?.time,
        amount: values?.amount,
      };

      try {
        const { data: responseData } = await api.post(
          `/api/slide-main/settings`,
          body
        );

        setAdvertisementSettings(responseData);

        toast.success('Dados atualizados com sucesso!');
        actions.setSubmitting(false);
        history.push('/settings/advertisement');
      } catch (error) {
        console.log(error);

        toast.error(
          'Aconteceu um erro inesperado ao atualizar as configurações da publicidade!'
        );
      }
      return null;
    },
    [history, setAdvertisementSettings]
  );

  const eneableSubmitButton = useCallback(
    ({ touched, errors, values }) => {
      if (
        _.isEqual(
          {
            time: advertisementSettings?.time,
            amount: advertisementSettings?.amount,
          },
          {
            ...values,
          }
        ) ||
        Object.entries(touched).length === 0 ||
        Object.entries(errors).length > 0
      ) {
        return false;
      }

      return true;
    },
    [advertisementSettings.amount, advertisementSettings.time]
  );

  return (
    <Container>
      <TitlePageContainer>
        <h5>Editar</h5>
        <Button
          onClick={() => history.push('/settings/advertisement')}
          danger
          type="text"
        >
          Cancelar
        </Button>
      </TitlePageContainer>
      <Content>
        <h6>Configurações dos anúncios</h6>
        <Formik
          initialValues={{
            time: advertisementSettings?.time || '',
            amount: advertisementSettings?.amount || '',
          }}
          validationSchema={Yup.object().shape({
            time: Yup.number()
              .typeError('A duração dos slides não é válida')
              .required('A duração dos slides é obrigatória'),
            amount: Yup.number()
              .typeError('A quantidade de slides não é válida')
              .required('A quantidade de slides é obrigatória'),
          })}
          onSubmit={handleSubmit}
        >
          {formikProps => (
            <form onSubmit={formikProps.handleSubmit}>
              <FastField name="time">
                {({ field, meta }) => {
                  return (
                    <Input
                      {...field}
                      label="Duração dos slides"
                      placeholder="Digite uma duração a partir de 5 segundos"
                      identifier="time"
                      type="text"
                      error={meta?.touched && meta?.error && meta?.error}
                      addonAfter="segundos"
                      onChange={e => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, '');

                        if (parseInt(e.target.value) < 5) {
                          e.target.value = 5;
                          toast.warn('A duração mínima é de 5 segundos');
                        }
                        field.onChange(e);
                      }}
                    />
                  );
                }}
              </FastField>
              <FastField name="amount">
                {({ field, meta }) => {
                  return (
                    <Input
                      {...field}
                      label="Quantidade slides"
                      placeholder="Digite a quantidade de slides"
                      identifier="amount"
                      type="text"
                      error={meta?.touched && meta?.error && meta?.error}
                      onChange={e => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, '');

                        if (parseInt(e.target.value) < 1) {
                          e.target.value = 1;
                          toast.warn('A quantidade mínima é 1 slide');
                        }
                        field.onChange(e);
                      }}
                    />
                  );
                }}
              </FastField>
              <ButtonsContainer>
                <Button
                  htmlType="submit"
                  disabled={
                    formikProps.isSubmitting ||
                    !eneableSubmitButton(formikProps)
                  }
                  type="primary"
                >
                  {!formikProps.isSubmitting ? 'Atualizar' : 'Carregando...'}
                </Button>
              </ButtonsContainer>
            </form>
          )}
        </Formik>
      </Content>
    </Container>
  );
}

export default EditAdvertisementSettings;
