import React from 'react';

import { Container } from './styles';
import logo from '@/assets/logo.svg';

function LoadingUserData() {
  return (
    <Container>
      <img src={logo} alt="Lance Futebol" />
      <p>Carregando...</p>
    </Container>
  );
}

export default LoadingUserData;
