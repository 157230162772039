import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { toast } from 'react-toastify';

import Input from '@/components/Input';
import CustomAntButton from '@/components/CustomAntButton';
import api from '@/services/api';

import { ICardOption } from '../types';

import { Container, InputsContainer } from './styles';
import { IEditOptionCardProps } from './types';

const EditOptionCard: React.FC<IEditOptionCardProps> = ({
  optionToEdit,
  handleClose,
  topic,
  handleSavedCardOption,
}) => {
  const isFirstRender = useRef(true);

  const [optionName, setOptionName] = useState('');
  const [optionValue, setOptionValue] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;

      if (optionToEdit) {
        setOptionName(optionToEdit.name);
        setOptionValue(optionToEdit.value.toString());
      }
    }
  }, [optionToEdit]);

  const handleNewOption = useCallback(async () => {
    setIsSubmitting(true);
    const body = {
      name: optionName,
      value: parseInt(optionValue),
      topic,
    };

    try {
      const { data: newCardOption } = await api.post<ICardOption>(
        '/api/athlete-option-setting',
        body
      );
      toast.success('Opção adicionada!');
      setIsSubmitting(false);
      handleSavedCardOption(newCardOption);
      handleClose();
    } catch (error) {
      setIsSubmitting(false);
      toast.error('Aconteceu um erro inesperado...');
    }
    setIsSubmitting(false);
  }, [handleClose, handleSavedCardOption, optionName, optionValue, topic]);
  const handleUpdateOption = useCallback(async () => {
    if (!optionToEdit) return;
    setIsSubmitting(true);
    const body = {
      name: optionName,
      value: parseInt(optionValue),
    };

    try {
      await api.put<ICardOption>(
        `/api/athlete-option-setting/${optionToEdit?._id}`,
        body
      );
      const updatedCardOption: ICardOption = {
        ...optionToEdit,
        ...body,
      };

      toast.success('Opção atualizada!');
      setIsSubmitting(false);
      handleSavedCardOption(updatedCardOption);
      handleClose();
    } catch (error) {
      setIsSubmitting(false);
      toast.error('Aconteceu um erro inesperado...');
    }
    setIsSubmitting(false);
  }, [
    handleClose,
    handleSavedCardOption,
    optionName,
    optionToEdit,
    optionValue,
  ]);

  const handleSave = useCallback(() => {
    if (optionToEdit) {
      handleUpdateOption();
    } else {
      handleNewOption();
    }
  }, [handleNewOption, handleUpdateOption, optionToEdit]);

  const isSaveButtonEnabled = useMemo(() => {
    return !!optionName && !!optionValue && parseInt(optionValue) > 0;
  }, [optionName, optionValue]);

  return (
    <Container>
      <Input
        value={optionName}
        onChange={(e) => setOptionName(e.target.value)}
        identifier="optionName"
        label="Nome da opção"
        type="text"
      />
      <Input
        value={optionValue}
        onChange={(e) => {
          const value = e.target.value.replace(/\D/g, '');
          setOptionValue(value);
        }}
        identifier="optionValue"
        label="Valor da opção"
        type="text"
      />
      <InputsContainer>
        <CustomAntButton
          disabled={isSubmitting}
          type="default"
          onClick={handleClose}
        >
          Cancelar
        </CustomAntButton>
        <CustomAntButton
          disabled={!isSaveButtonEnabled}
          type="primary"
          onClick={() => handleSave()}
          loading={isSubmitting}
        >
          Salvar
        </CustomAntButton>
      </InputsContainer>
    </Container>
  );
};

export default EditOptionCard;
