import React from 'react';
import { Link } from 'react-router-dom';

import { Container } from './styles';

function MenuItem({ selected, name, label, icon }) {
  const Icon = icon;

  const path = '/'.concat(name);

  return (
    <Link to={path}>
      <Container selected={selected}>
        <Icon selected={selected} />
        <span>{label}</span>
      </Container>
    </Link>
  );
}

export default MenuItem;
