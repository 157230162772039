import React, { useRef } from 'react';
import { Switch as AntSwitch } from 'antd';

import { Container } from './styles';

function Switch({ label, onChange, ...rest }) {
  const switchRef = useRef(null);

  return (
    <Container>
      <AntSwitch ref={switchRef} {...rest} onChange={onChange} />
      {label && (
        <h6 onClick={() => switchRef.current.click()} aria-hidden="true">
          {label}
        </h6>
      )}
    </Container>
  );
}

export default Switch;
