import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { toast } from 'react-toastify';
import { Spin, DatePicker, Tabs } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { FiEdit2 } from 'react-icons/fi';
import * as dateFns from 'date-fns';
import ptLocale from 'date-fns/locale/pt-BR';
import moment from 'moment';
import 'moment/locale/pt-br';
import locale from 'antd/es/date-picker/locale/pt_BR';

import api from '@/services/api';

import CustomAntButton from '@/components/CustomAntButton';
import { Link } from 'react-router-dom';
import Details from './Details';
import Docket from './Docket';
import PushGame from './PushGame';

import {
  Container,
  TitlePageContainer,
  Content,
  SessionContainer,
  ChampionshipSelector,
  MatchSelector,
  MatchSelectorTeam,
  MatchInfoContainer,
  LoadingAndNotFoundContainer,
  LoadMoreButton,
  MatchListDatePickerAndNewMatchContainer,
} from './styles';

const loadingIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;
const {
  format,
  parseISO,
  isSameDay,
  setMinutes,
  setSeconds,
  setHours,
  isAfter,
} = dateFns;

const { TabPane } = Tabs;

function shouldShowDocket({ me, match }) {
  if (me?.type === 'admin') return true;
  if (me?.type === 'team' && match?.managedBy?.includes(me?.profile?._id))
    return true;

  return false;
}

function Matches() {
  const me = useSelector((store) => store.user);
  const myTeam = useSelector((store) => store.team.data);

  const [filterDate, setFilterDate] = useState(
    moment(new Date(), 'DD/MM/YYYY')
  );

  const [selectedChampionship, setSelectedChampionship] = useState(null);
  const [selectedMatchOfChampionship, setSelectedMatchOfChampionship] =
    useState(null);

  const [championships, setChampionships] = useState([]);
  const [loadingChampionships, setLoadingChampionships] = useState(true);
  const [championshipsPagination, setChampionshipsPagination] = useState({
    currentPage: 1,
    totalPages: 0,
  });

  const [matchsOfChampionship, setMatchsOfChampionship] = useState([]);
  const [loadingMatchsOfChampionship, setLoadingMatchsOfChampionship] =
    useState(false);
  const [matchsOfChampionshipPagination, setMatchsOfChampionshipPagination] =
    useState({
      currentPage: 1,
      totalPages: 0,
    });

  const [selectedMatchDetails, setSelectedMatchDetails] = useState(null);
  const [loadingSelectedMatchDetails, setLoadingSelectedMatchDetails] =
    useState(null);

  const [currentView, setCurrentView] = useState('details');

  const handleGetChampionships = useCallback(async (page = 1) => {
    setLoadingChampionships(true);
    try {
      const { data } = await api.get('/api/championship', {
        params: {
          page,
          limit: 10,
        },
      });
      setChampionships((oldChampionships) => [
        ...oldChampionships,
        ...data.docs,
      ]);
      setChampionshipsPagination({
        currentPage: data.page,
        totalPages: data.pages,
      });
    } catch (error) {
      // showToast({
      //   type: 'error',
      //   title: 'Ocorreu um erro ao carregar os campeonatos',
      //   description: 'Recarregue a página!',
      // });
      toast.error(
        'Ocorreu um erro ao carregar os campeonatos. Recarregue a página!'
      );
    }
    setLoadingChampionships(false);
  }, []);

  const handleGetMatchesOfChampionship = useCallback(
    async (championshipId, fromThisDate = null, page = 1) => {
      if (!championshipId || fromThisDate) {
        setMatchsOfChampionship([]);
        if (!championshipId) {
          return;
        }
      }
      setLoadingMatchsOfChampionship(true);

      function removeHoursFromDate(date) {
        return setHours(setMinutes(setSeconds(date, 0), 0), 0);
      }

      const queryParams = {
        page,
        limit: 10,
        date: removeHoursFromDate(new Date()),
      };

      if (me.type === 'team') {
        queryParams.gameType = 'amateur';
        queryParams.teamId = myTeam?._id;
      }

      if (fromThisDate) {
        queryParams.date = removeHoursFromDate(fromThisDate.toDate());
      }

      try {
        const { data } = await api.get(
          `/api/championship/${championshipId}/games`,
          {
            params: queryParams,
          }
        );

        const matchsWithAbbrevOfTeams = data.docs.map((match) => ({
          ...match,
          local: {
            ...match.local,
            abbrev: match.local.name.substring(0, 3).toUpperCase(),
          },
          away: {
            ...match.away,
            abbrev: match.away.name.substring(0, 3).toUpperCase(),
          },
        }));

        if (page === 1) {
          setMatchsOfChampionship(matchsWithAbbrevOfTeams);
        } else {
          setMatchsOfChampionship((oldMatchs) => [
            ...oldMatchs,
            ...matchsWithAbbrevOfTeams,
          ]);
        }

        setMatchsOfChampionshipPagination({
          currentPage: data.page,
          totalPages: data.pages,
        });
      } catch (error) {
        // showToast({
        //   type: 'error',
        //   title: 'Ocorreu um erro ao carregar os jogos desse campeonato',
        //   description: 'Recarregue a página!',
        // });
        toast.error(
          'Ocorreu um erro ao carregar os jogos desse campeonato. Recarregue a página!'
        );
      }
      setLoadingMatchsOfChampionship(false);
    },
    [me.type, myTeam]
  );

  const handleGetSelectedMatchData = useCallback(
    async (championshipId, matchId) => {
      if (!matchId) {
        return;
      }
      setLoadingSelectedMatchDetails(true);
      try {
        const { data: match } = await api.get(
          `/api/championship/${championshipId}/games/${matchId}`
        );

        const matchWithAbbrevOfTeams = {
          ...match,
          local: {
            ...match.local,
            abbrev: match.local.name.substring(0, 3).toUpperCase(),
          },
          away: {
            ...match.away,
            abbrev: match.away.name.substring(0, 3).toUpperCase(),
          },
        };

        setSelectedMatchDetails(matchWithAbbrevOfTeams);

        if (
          isAfter(new Date(), parseISO(matchWithAbbrevOfTeams.info.date)) &&
          !matchWithAbbrevOfTeams.finished
        ) {
          setCurrentView('docket');
        } else {
          setCurrentView('details');
        }
        // setCurrentView('pushGame');
      } catch (error) {
        toast.error(
          'Ocorreu um erro ao os detalhes da partida selecionada. Recarregue a página!'
        );
      }
      setLoadingSelectedMatchDetails(false);
    },
    []
  );

  const handleSelectChampionship = useCallback(
    (championship) => {
      // formik.setFieldValue('championship', championship);
      setSelectedChampionship(championship);
      setFilterDate(moment(new Date(), 'DD/MM/YYYY'));

      if (!championship) {
        // formik.setFieldValue('match', null);
        setSelectedMatchOfChampionship(null);
        handleGetMatchesOfChampionship(undefined);
        return;
      }
      handleGetMatchesOfChampionship(championship._id);
    },
    [handleGetMatchesOfChampionship]
  );

  const handleGetAmateurChampionship = useCallback(async () => {
    setLoadingChampionships(true);
    try {
      const { data } = await api.get(`/api/championship/AMATEUR`);
      handleSelectChampionship(data);
    } catch (error) {
      // showToast({
      //   type: 'error',
      //   title: 'Ocorreu um erro ao carregar os campeonatos',
      //   description: 'Recarregue a página!',
      // });
      toast.error('Ocorreu um erro inesperado. Recarregue a página!');
    }
    setLoadingChampionships(false);
  }, [handleSelectChampionship]);

  // const handleMyTeamMatches = useCallback(
  //   () => {
  //     setFilterDate(moment(new Date(), 'DD/MM/YYYY'));
  //     handleGetMatchesOfChampionship('AMATEUR');
  //   },
  //   [handleGetMatchesOfChampionship]
  // );

  useEffect(() => {
    if (me.type === 'team') {
      handleGetAmateurChampionship();
    } else {
      handleGetChampionships();
    }
  }, [handleGetAmateurChampionship, handleGetChampionships, me.type]);

  useEffect(() => {
    // setGameToBeFinished({
    //   localScore: 0,
    //   awayScore: 0,
    //   redCards: 0,
    //   yellowCards: 0,
    //   localPlayers: [],
    //   awayPlayers: [],
    // });
    setSelectedMatchDetails(null);
    if (selectedMatchOfChampionship) {
      handleGetSelectedMatchData(
        selectedMatchOfChampionship._champ._id,
        selectedMatchOfChampionship._id
      );
    }
  }, [handleGetSelectedMatchData, selectedMatchOfChampionship]);

  const championshipsViewer = useMemo(() => {
    if (me.type === 'team') {
      if (loadingChampionships) {
        return (
          <SessionContainer>
            <LoadingAndNotFoundContainer>
              <div>
                <Spin style={{ lineHeight: 0 }} indicator={loadingIcon} />
                <p>Carregando...</p>
              </div>
            </LoadingAndNotFoundContainer>
          </SessionContainer>
        );
      }
      return <></>;
    }

    if (loadingChampionships && championships.length === 0) {
      return (
        <SessionContainer>
          <p>Selecione um campeonato</p>
          <LoadingAndNotFoundContainer>
            <div>
              <Spin style={{ lineHeight: 0 }} indicator={loadingIcon} />
              <p>Carregando campeonatos...</p>
            </div>
          </LoadingAndNotFoundContainer>
        </SessionContainer>
      );
    }

    if (!selectedChampionship) {
      return (
        <SessionContainer>
          <p>Selecione um campeonato</p>
          <ul>
            {championships.map((championship) => (
              <ChampionshipSelector
                key={championship._id}
                onClick={() => handleSelectChampionship(championship)}
              >
                <img src={championship.imageUrl} alt={championship.name} />
                <h6>{championship.name}</h6>
              </ChampionshipSelector>
            ))}
            {championshipsPagination.currentPage <
              championshipsPagination.totalPages && (
              <LoadMoreButton
                disabled={loadingChampionships}
                onClick={() =>
                  handleGetChampionships(
                    championshipsPagination.currentPage + 1
                  )
                }
              >
                <p>{!loadingChampionships ? 'Ver mais' : 'Carregando...'}</p>
              </LoadMoreButton>
            )}
          </ul>
        </SessionContainer>
      );
    }

    return (
      <SessionContainer>
        <strong>Campeonato</strong>
        <ChampionshipSelector onClick={() => handleSelectChampionship(null)}>
          <img
            src={selectedChampionship.imageUrl}
            alt={selectedChampionship.name}
          />
          <h6>{selectedChampionship.name}</h6>
          <FiEdit2 size={20} />
        </ChampionshipSelector>
      </SessionContainer>
    );
  }, [
    championships,
    championshipsPagination.currentPage,
    championshipsPagination.totalPages,
    handleGetChampionships,
    handleSelectChampionship,
    loadingChampionships,
    selectedChampionship,
    me.type,
  ]);

  const matchsViewer = useMemo(() => {
    if (!selectedChampionship) {
      return <></>;
    }

    if (loadingMatchsOfChampionship && matchsOfChampionship.length === 0) {
      return (
        <SessionContainer>
          <p>Selecione uma partida</p>
          <LoadingAndNotFoundContainer>
            <div>
              <Spin style={{ lineHeight: 0 }} indicator={loadingIcon} />
              <p>Carregando partidas do campeonato...</p>
            </div>
          </LoadingAndNotFoundContainer>
        </SessionContainer>
      );
    }

    if (matchsOfChampionship.length === 0) {
      return (
        <SessionContainer>
          <p>Selecione uma partida</p>
          <MatchListDatePickerAndNewMatchContainer>
            <DatePicker
              onChange={(momentDate) => {
                let newDate = momentDate;
                if (!newDate) {
                  newDate = moment(new Date(), 'DD/MM/YYYY');
                }
                if (!isSameDay(filterDate.toDate(), newDate.toDate())) {
                  handleGetMatchesOfChampionship(
                    selectedChampionship._id,
                    newDate
                  );
                  setFilterDate(newDate);
                }
              }}
              format={(value) => `a partir de ${value.format('DD/MM/YYYY')}`}
              // defaultValue={moment(new Date(), 'DD/MM/YYYY')}
              value={filterDate}
              locale={locale}
            />
            <Link to="/my_matches/new">
              <CustomAntButton type="primary">Nova partida</CustomAntButton>
            </Link>
          </MatchListDatePickerAndNewMatchContainer>
          <LoadingAndNotFoundContainer>
            <div>
              <h6>
                Não existem <span>partidas</span> disponíveis
              </h6>
            </div>
          </LoadingAndNotFoundContainer>
        </SessionContainer>
      );
    }

    if (!selectedMatchOfChampionship) {
      return (
        <SessionContainer>
          <p>Selecione uma partida</p>
          <MatchListDatePickerAndNewMatchContainer>
            <DatePicker
              onChange={(momentDate) => {
                let newDate = momentDate;
                if (!newDate) {
                  newDate = moment(new Date(), 'DD/MM/YYYY');
                }
                if (!isSameDay(filterDate.toDate(), newDate.toDate())) {
                  handleGetMatchesOfChampionship(
                    selectedChampionship._id,
                    newDate
                  );
                  setFilterDate(newDate);
                }
              }}
              format={(value) => `a partir de ${value.format('DD/MM/YYYY')}`}
              // defaultValue={moment(new Date(), 'DD/MM/YYYY')}
              value={filterDate}
              locale={locale}
            />
            <Link to="/my_matches/new">
              <CustomAntButton type="primary">Nova partida</CustomAntButton>
            </Link>
          </MatchListDatePickerAndNewMatchContainer>
          <ul>
            {matchsOfChampionship.map((match) => (
              <MatchSelector
                key={match._id}
                // onClick={() => formik.setFieldValue('match', match)}
                onClick={() => setSelectedMatchOfChampionship(match)}
              >
                <div>
                  <MatchSelectorTeam>
                    <div>
                      <img src={match.local.image} alt={match.local.name} />
                    </div>
                    <strong>
                      {match.local.initials ||
                        match.local.altName ||
                        match.local.name}
                    </strong>
                  </MatchSelectorTeam>
                  <h6>VS</h6>
                  <MatchSelectorTeam>
                    <div>
                      <img src={match.away.image} alt={match.away.name} />
                    </div>
                    <strong>
                      {match.away.initials ||
                        match.away.altName ||
                        match.away.name}
                    </strong>
                  </MatchSelectorTeam>
                </div>
                <MatchInfoContainer>
                  <div>
                    <small>Data e hora</small>
                    <small>
                      {format(
                        parseISO(match.info.date),
                        "cccc, dd/MM/yyyy 'às' HH:mm",
                        {
                          locale: ptLocale,
                        }
                      )}
                    </small>
                  </div>
                  <div>
                    <small>Local</small>
                    <small>{match.info.place}</small>
                  </div>
                </MatchInfoContainer>
              </MatchSelector>
            ))}
            {matchsOfChampionshipPagination.currentPage <
              matchsOfChampionshipPagination.totalPages && (
              <LoadMoreButton
                disabled={loadingMatchsOfChampionship}
                onClick={() =>
                  handleGetMatchesOfChampionship(
                    selectedChampionship._id,
                    filterDate,
                    matchsOfChampionshipPagination.currentPage + 1
                  )
                }
              >
                <p>
                  {!loadingMatchsOfChampionship ? 'Ver mais' : 'Carregando...'}
                </p>
              </LoadMoreButton>
            )}
          </ul>
        </SessionContainer>
      );
    }

    return (
      <SessionContainer>
        <strong>Partida</strong>
        <MatchSelector
          $selected
          onClick={() => setSelectedMatchOfChampionship(null)}
        >
          <div>
            <MatchSelectorTeam>
              <div>
                <img
                  src={selectedMatchOfChampionship.local.image}
                  alt={selectedMatchOfChampionship.local.name}
                />
              </div>
              <strong>
                {selectedMatchOfChampionship.local.initials ||
                  selectedMatchOfChampionship.local.altName ||
                  selectedMatchOfChampionship.local.name}
              </strong>
            </MatchSelectorTeam>
            <h6>VS</h6>
            <MatchSelectorTeam>
              <div>
                <img
                  src={selectedMatchOfChampionship.away.image}
                  alt={selectedMatchOfChampionship.away.name}
                />
              </div>
              <strong>
                {selectedMatchOfChampionship.away.initials ||
                  selectedMatchOfChampionship.away.altName ||
                  selectedMatchOfChampionship.away.name}
              </strong>
            </MatchSelectorTeam>
          </div>
          <MatchInfoContainer>
            <div>
              <small>Data e hora</small>
              <small>
                {format(
                  parseISO(selectedMatchOfChampionship.info.date),
                  "cccc, dd/MM/yyyy 'às' HH:mm",
                  {
                    locale: ptLocale,
                  }
                )}
              </small>
            </div>
            <div>
              <small>Local</small>
              <small>{selectedMatchOfChampionship.info.place}</small>
            </div>
          </MatchInfoContainer>
          <FiEdit2 size={20} />
        </MatchSelector>
      </SessionContainer>
    );
  }, [
    filterDate,
    handleGetMatchesOfChampionship,
    loadingMatchsOfChampionship,
    matchsOfChampionship,
    matchsOfChampionshipPagination.currentPage,
    matchsOfChampionshipPagination.totalPages,
    selectedChampionship,
    selectedMatchOfChampionship,
  ]);

  const tabsViewer = useMemo(() => {
    if (loadingSelectedMatchDetails) {
      return (
        <SessionContainer>
          <LoadingAndNotFoundContainer>
            <div>
              <Spin style={{ lineHeight: 0 }} indicator={loadingIcon} />
              <p>Carregando detalhes da partida selecionada...</p>
            </div>
          </LoadingAndNotFoundContainer>
        </SessionContainer>
      );
    }

    if (!selectedMatchDetails) {
      return <></>;
    }

    return (
      <SessionContainer>
        <Tabs
          activeKey={currentView}
          onTabClick={(tabKey) => {
            setCurrentView(tabKey);
          }}
          centered
        >
          <TabPane tab="Detalhes" key="details">
            <Details
              selectedMatchDetails={selectedMatchDetails}
              handleSelectChampionship={handleSelectChampionship}
              handleGetSelectedMatchData={() => {
                handleGetSelectedMatchData(
                  selectedChampionship._id,
                  selectedMatchDetails._id
                );
              }}
            />
          </TabPane>
          {shouldShowDocket({ me, myTeam, match: selectedMatchDetails }) && (
            <TabPane tab="Súmula" key="docket">
              <Docket
                selectedMatchDetails={selectedMatchDetails}
                handleSelectChampionship={handleSelectChampionship}
                handleGetSelectedMatchData={() => {
                  handleGetSelectedMatchData(
                    selectedChampionship._id,
                    selectedMatchDetails._id
                  );
                }}
              />
            </TabPane>
          )}
          {me.type === 'admin' && (
            <TabPane tab="PushGame" key="pushGame">
              <PushGame
                selectedMatchDetails={selectedMatchDetails}
                handleSelectChampionship={handleSelectChampionship}
              />
            </TabPane>
          )}
        </Tabs>
      </SessionContainer>
    );
  }, [
    currentView,
    handleSelectChampionship,
    loadingSelectedMatchDetails,
    me,
    myTeam,
    selectedMatchDetails,
    handleGetSelectedMatchData,
    selectedChampionship,
  ]);

  return (
    <Container>
      <TitlePageContainer>
        <h4>Partidas</h4>
      </TitlePageContainer>
      <Content>
        {championshipsViewer}
        {matchsViewer}
        {tabsViewer}
      </Content>
    </Container>
  );
}

export default Matches;
