export enum UserRoleEnum {
  USER = 'user',
  ADMIN = 'admin',
  SPONSOR = 'sponsor',
  INFLUENCER = 'influencer',
}

export enum UserGenderEnum {
  MALE = 'M',
  FEMALE = 'F',
  OTHER = 'O',
}

export enum UserStatusEnum {
  INACTIVE = 0,
  ACTIVE,
}
