import styled, { css } from 'styled-components';

export const Container = styled.li`
  ${props => css`
    list-style: none;
    width: 100%;
    ${props.selected &&
      css`
        border-left: 2px solid ${props.theme.colors.primary['primary-1']};
      `}
    height: 40px;
    padding: 0 32px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: 0.2s all;
    margin-bottom: 8px;

    &:hover {
      background-color: ${props.theme.colors.light['light-2']};
    }

    span {
      font-size: 16px;
      font-weight: 700;
      color: ${props.selected
        ? props.theme.colors.primary['primary-1']
        : props.theme.colors.dark['dark+1']};
      margin-left: 16px;
    }
  `}
`;

export function getStyledIcon(Icon) {
  return styled(Icon).attrs(props => ({
    color: props.selected
      ? props.theme.colors.primary['primary-1']
      : props.theme.colors.dark['dark+1'],
    size: '24px',
  }))``;
}

// export const DashboardIcon = styled(MdDashboard).attrs(props => ({
//   color: props.selected
//     ? props.theme.colors.primary['primary-1']
//     : props.theme.colors.dark['dark+1'],
//   size: '24px',
// }))``;

// export const UsersIcon = styled(MdPeople).attrs(props => ({
//   color: props.selected
//     ? props.theme.colors.primary['primary-1']
//     : props.theme.colors.dark['dark+1'],
//   size: '24px',
// }))``;

// // export const TeamsIcon = styled(IoMdFootball).attrs(props => ({
// //   color: props.selected
// //     ? props.theme.colors.primary['primary-1']
// //     : props.theme.colors.dark['dark+1'],
// //   size: '24px',
// // }))``;

// export const SponsorsIcon = styled(IoMdCash).attrs(props => ({
//   color: props.selected
//     ? props.theme.colors.primary['primary-1']
//     : props.theme.colors.dark['dark+1'],
//   size: '24px',
// }))``;

// export const SettingsIcon = styled(MdSettings).attrs(props => ({
//   color: props.selected
//     ? props.theme.colors.primary['primary-1']
//     : props.theme.colors.dark['dark+1'],
//   size: '24px',
// }))``;

// export const MatchsIcon = styled(IoMdFootball).attrs(props => ({
//   color: props.selected
//     ? props.theme.colors.primary['primary-1']
//     : props.theme.colors.dark['dark+1'],
//   size: '24px',
// }))``;

// export const ChampionshipsIcon = styled(IoMdTrophy).attrs(props => ({
//   color: props.selected
//     ? props.theme.colors.primary['primary-1']
//     : props.theme.colors.dark['dark+1'],
//   size: '24px',
// }))``;

// export const TeamsIcon = styled(BsShieldFill).attrs(props => ({
//   color: props.selected
//     ? props.theme.colors.primary['primary-1']
//     : props.theme.colors.dark['dark+1'],
//   size: '24px',
// }))``;

// export const AthletesOfTheWeekIcon = styled(BsShieldFill).attrs(props => ({
//   color: props.selected
//     ? props.theme.colors.primary['primary-1']
//     : props.theme.colors.dark['dark+1'],
//   size: '24px',
// }))``;

// export const ChatsReports = styled(FiFlag).attrs(props => ({
//   color: props.selected
//     ? props.theme.colors.primary['primary-1']
//     : props.theme.colors.dark['dark+1'],
//   size: '24px',
// }))``;
