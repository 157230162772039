import styled, { css } from 'styled-components';

interface IContainer {
  error?: boolean;
}

export const Container = styled.div<IContainer>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 8px;

  label {
    ${(props) =>
      props.error &&
      css`
        color: ${props.theme.colors.danger};
      `}
  }

  textarea {
    resize: none;
    ${(props) =>
      props.error &&
      css`
        border-color: ${props.theme.colors.danger};

        &:hover {
          border-color: ${props.theme.colors.danger};
        }

        &:focus {
          border-color: ${props.theme.colors.danger};
          box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
        }
      `}
  }

  > span {
    color: ${(props) => props.theme.colors.danger};
    font-size: ${(props) => props.theme.typograph.sizes.small}px;
    font-weight: ${(props) => props.theme.typograph.weights.medium};
  }
`;
