import styled, { css } from 'styled-components';

interface IContainerProps {
  $error?: boolean;
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 8px;

  label {
    ${(props) =>
      props.$error &&
      css`
        color: ${props.theme.colors.danger};
      `}
  }

  > div {
    width: 100%;
    display: flex;
    align-items: center;

    input {
      flex: 1;

      ${(props) =>
        props.$error &&
        css`
          border-color: ${props.theme.colors.danger};

          &:hover {
            border-color: ${props.theme.colors.danger};
            /* box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2); */
          }

          &:focus {
            border-color: ${props.theme.colors.danger};
            box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
          }
        `}
    }

    /* Loading Indicator */
    div {
      margin-left: 8px;
    }
  }

  span {
    color: ${(props) => props.theme.colors.danger};
    font-size: ${(props) => props.theme.typograph.sizes.small}px;
    font-weight: ${(props) => props.theme.typograph.weights.medium};
  }
`;
