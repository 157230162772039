import React from 'react';
import { Button, DatePicker, Spin } from 'antd';
import { FieldArray } from 'formik';
import { LoadingOutlined } from '@ant-design/icons';
import { FiXCircle } from 'react-icons/fi';

import { isSameDay, format, parseISO, subDays } from 'date-fns';
import ptLocale from 'date-fns/locale/pt-BR';

import moment from 'moment';
import 'moment/locale/pt-br';
import locale from 'antd/es/date-picker/locale/pt_BR';

import {
  MatchsSessionContainer,
  MatchSelector,
  MatchSelectorTeam,
  MatchInfoContainer,
  SelectedMatchsContainer,
  SelectedMatch,
  SelectedMatchTeam,
  LoadingAndNotFoundContainer,
  LoadMoreButton,
} from './styles';
import { ButtonsContainer } from '../styles';

const loadingIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;

function Matchs({
  formik,
  nextStep,
  prevStep,
  filterDate,
  handleChangeFilterDate,
  matchsOfChampionship,
  handleGetMatchsOfChampionship,
  loadingMatchsOfChampionship,
  matchsOfChampionshipPagination,
}) {
  function eneableNextStepButton({ errors }) {
    if (!errors.games) {
      return true;
    }

    return false;
  }
  function gameIsOnSelectGames(_matchId, selectedGames) {
    return !!selectedGames.find(selectedGame => selectedGame._id === _matchId);
  }

  return (
    <>
      <FieldArray
        name="games"
        render={({ push, remove }) => (
          <>
            <SelectedMatchsContainer>
              {formik.values.games.map((selectedGame, index) => (
                <SelectedMatch
                  type="button"
                  onClick={() => {
                    remove(index);
                  }}
                  key={selectedGame._id}
                >
                  <FiXCircle size={16} />
                  <SelectedMatchTeam>
                    <div>
                      <img
                        src={selectedGame.local.image}
                        alt={selectedGame.local.abbrev}
                      />
                    </div>
                    <small>
                      <b>{selectedGame.local.abbrev}</b>
                    </small>
                  </SelectedMatchTeam>
                  <small>
                    <b>VS</b>
                  </small>
                  <SelectedMatchTeam>
                    <div>
                      <img
                        src={selectedGame.away.image}
                        alt={selectedGame.away.abbrev}
                      />
                    </div>
                    <small>
                      <b>{selectedGame.away.abbrev}</b>
                    </small>
                  </SelectedMatchTeam>
                </SelectedMatch>
              ))}
            </SelectedMatchsContainer>
            <MatchsSessionContainer>
              {/* <p>Selecione as partidas do partida</p> */}
              <DatePicker
                onChange={momentDate => {
                  let newDate = momentDate;
                  if (!newDate) {
                    newDate = moment(new Date(), 'DD/MM/YYYY');
                  }
                  if (!isSameDay(filterDate.toDate(), newDate.toDate())) {
                    handleGetMatchsOfChampionship({
                      fromThisDate: newDate,
                    });
                    handleChangeFilterDate(newDate);
                  }
                }}
                disabledDate={current =>
                  current &&
                  current < moment(subDays(new Date(), 1)).endOf('day')
                }
                format={value => `a partir de ${value.format('DD/MM/YYYY')}`}
                // defaultValue={moment(new Date(), 'DD/MM/YYYY')}
                value={filterDate}
                locale={locale}
              />
              <ul>
                {loadingMatchsOfChampionship &&
                matchsOfChampionship.length === 0 ? (
                  <LoadingAndNotFoundContainer>
                    <div>
                      <Spin style={{ lineHeight: 0 }} indicator={loadingIcon} />
                      <p>Carregando partidas...</p>
                    </div>
                  </LoadingAndNotFoundContainer>
                ) : (
                  <>
                    {matchsOfChampionship.map(match => (
                      <MatchSelector
                        key={match._id}
                        type="button"
                        disabled={gameIsOnSelectGames(
                          match._id,
                          formik.values.games
                        )}
                        // onClick={() => formik.setFieldValue('match', match)}
                        onClick={() => {
                          if (
                            !gameIsOnSelectGames(match._id, formik.values.games)
                          ) {
                            push(match);
                          }
                        }}
                      >
                        <div>
                          <MatchSelectorTeam>
                            <div>
                              <img
                                src={match.local.image}
                                alt={match.local.name}
                              />
                            </div>
                            <strong>{match.local.name}</strong>
                          </MatchSelectorTeam>
                          <h6>VS</h6>
                          <MatchSelectorTeam>
                            <div>
                              <img
                                src={match.away.image}
                                alt={match.away.name}
                              />
                            </div>
                            <strong>{match.away.name}</strong>
                          </MatchSelectorTeam>
                        </div>
                        <MatchInfoContainer>
                          <div>
                            <small>Data e hora</small>
                            <small>
                              {format(
                                parseISO(match.info.date),
                                "cccc, dd/MM/yyyy 'às' HH:mm",
                                {
                                  locale: ptLocale,
                                }
                              )}
                            </small>
                          </div>
                          <div>
                            <small>Local</small>
                            <small>{match.info.place}</small>
                          </div>
                        </MatchInfoContainer>
                      </MatchSelector>
                    ))}
                    {matchsOfChampionshipPagination.currentPage <
                      matchsOfChampionshipPagination.totalPages && (
                      <LoadMoreButton
                        disabled={loadingMatchsOfChampionship}
                        onClick={() =>
                          handleGetMatchsOfChampionship({
                            fromThisDate: filterDate,
                            page:
                              matchsOfChampionshipPagination.currentPage + 1,
                          })
                        }
                      >
                        <p>
                          {!loadingMatchsOfChampionship
                            ? 'Ver mais'
                            : 'Carregando...'}
                        </p>
                      </LoadMoreButton>
                    )}
                  </>
                )}
              </ul>
            </MatchsSessionContainer>
          </>
        )}
      />
      <ButtonsContainer>
        <Button onClick={prevStep} type="default">
          Voltar
        </Button>
        <Button
          onClick={nextStep}
          type="primary"
          disabled={!eneableNextStepButton(formik)}
        >
          Próximo
        </Button>
      </ButtonsContainer>
    </>
  );
}

export default Matchs;
