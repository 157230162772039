import {
  MdDashboard,
  MdPeople,
  MdSettings,
  MdOutlineStar,
} from 'react-icons/md';
import { IoMdCash, IoMdFootball, IoMdTrophy } from 'react-icons/io';
import { BsShieldFill } from 'react-icons/bs';

import { getStyledIcon } from './MenuItem/styles';
import { IMenuItem } from './types';

const ADMIN_MENU: IMenuItem[] = [
  {
    name: 'dashboard',
    label: 'Dashboard',
    selected: false,
    icon: getStyledIcon(MdDashboard),
  },
  {
    name: 'users',
    label: 'Usuários',
    selected: false,
    icon: getStyledIcon(MdPeople),
  },
  {
    name: 'influencers',
    label: 'Influenciadores',
    selected: false,
    icon: getStyledIcon(MdOutlineStar),
  },
  {
    name: 'sponsors',
    label: 'Patrocinadores',
    selected: false,
    icon: getStyledIcon(IoMdCash),
  },
  {
    name: 'teams',
    label: 'Times',
    selected: false,
    icon: getStyledIcon(BsShieldFill),
  },
  {
    name: 'cards',
    label: 'Cartelas',
    selected: false,
    icon: getStyledIcon(IoMdTrophy),
  },
  {
    name: 'settings',
    label: 'Configurações',
    selected: false,
    icon: getStyledIcon(MdSettings),
  },
];

const TEAM_MENU: IMenuItem[] = [
  {
    name: 'my_team',
    label: 'Meu time',
    selected: false,
    icon: getStyledIcon(BsShieldFill),
  },
  {
    name: 'my_matches',
    label: 'Partidas',
    selected: false,
    icon: getStyledIcon(IoMdFootball),
  },
];

export function getMenu(roles: string[]): IMenuItem[] {
  if (roles.includes('admin')) return ADMIN_MENU;
  if (roles.includes('team')) return TEAM_MENU;

  return [];
}
