import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin-top: 2rem;

  form {
    > div {
      width: 100%;
      display: flex;
      gap: 2rem;
      align-items: flex-end;

      @media only screen and (max-width: ${(props) =>
          props.theme.breakpoints.md}) {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
      }
    }

    > button {
      margin-top: 1.5rem;
      width: 100%;
      max-width: 15rem;

      @media only screen and (max-width: ${(props) =>
          props.theme.breakpoints.sm}) {
        max-width: 100%;
      }
    }
  }
`;

export const InputsContainer = styled.div`
  width: 100%;
  max-width: 15rem;

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    max-width: 100%;
  }
`;
