import { all, takeLatest, put, call } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '@/services/api';
import history from '@/services/history';

import { signInSuccess, signFailure, signOutSuccess } from './actions';

export function* singIn({ payload }) {
  try {
    const { user, password } = payload;

    const response = yield call(api.post, 'auth/local-admin', {
      username: user,
      password,
    });

    const { token } = response.data;

    api.defaults.headers.Authorization = token;

    yield put(signInSuccess(token));
    history.push('/');
  } catch (error) {
    yield put(signFailure());
    toast.error('Falha na autenticação, verifique seus dados');
  }
}

export function* signUp({ payload }) {
  try {
    const { name, email, password } = payload;

    yield call(api.post, 'users', {
      name,
      email,
      password,
      provider: true,
    });

    history.push('/');
  } catch (error) {
    yield put(signFailure());
    toast.error('Falha no cadastro, verifique seus dados');
  }
}

export function setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = token;
  }
}

export function* handleSignOutRequest() {
  try {
    yield call(api.delete, 'auth/logout');

    yield put(signOutSuccess());
  } catch (error) {
    toast.error(
      'Ocorreu um erro inesperado ao sair da plataforma, tente novamente!'
    );
    yield put(signFailure());
  }
}

export function handleSignOutSuccess() {
  history.push('/');
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_IN_REQUEST', singIn),
  takeLatest('@auth/SIGN_UP_REQUEST', signUp),
  takeLatest('@auth/SIGN_OUT_REQUEST', handleSignOutRequest),
  takeLatest('@auth/SIGN_OUT_SUCCESS', handleSignOutSuccess),
]);
