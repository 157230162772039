import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Button, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import {
  Formik,
  FormikHelpers,
  FormikProps,
  validateYupSchema,
  yupToFormErrors,
} from 'formik';
import { toast } from 'react-toastify';

import Steps from '@/components/Steps';
import Breadcrumbs from '@/components/Breadcrumbs';

import api from '@/services/api';

import Loading from '@/components/Loading';
import { UploadRequests } from '@/services/api/requests/Upload';
import CardInformation from './CardInformation';
import Athletes from './Athletes';
import Banner from './Banner';
import Confirm from './Confirm';
import { ICardOption, ICardPaymentPercentage, INewCardFormData } from './types';
import { Container, TitlePageContainer, Content } from './styles';
import { ECardGameOptionTopic, EGameType } from '../types';
import Options from './Options';
import ManageAthletes from './ManageAthletes';
import { getNewCardValidationSchema } from './validators';

const { confirm } = Modal;

const NewAthleteCard: React.FC = () => {
  const history = useHistory();
  const beforeUnloadListener = useRef<(e: BeforeUnloadEvent) => void>(
    () => ({})
  );

  const isFirstRender = useRef(true);
  const newCardInitialFormData = useRef<INewCardFormData>({
    cardInformation: {
      type: undefined,
      name: '',
      price: '',
      startDate: undefined,
      endDate: undefined,
      hasFixedPrize: undefined,
      fixedPrize: '',
      influencers: [],
      percentages: {
        homePercent: '',
        influencerPercent: '',
        winnerPercent: '',
      },
    },
    athletes: [],
    options: {
      goalOptions: [],
      statisticOptions: [],
    },
    banner: {
      file: undefined,
      previewUrl: '',
    },
  });
  const formikRef = useRef<FormikProps<INewCardFormData>>(null);
  const [isLoadingPage, setIsLoadingPage] = useState(true);

  const [currentStep, setCurrentStep] = useState(0);
  const [cardPaymentPercentage, setCardPaymentPercentage] =
    useState<ICardPaymentPercentage>({} as ICardPaymentPercentage);
  const [cardGoalOptions, setCardGoalOptions] = useState<ICardOption[]>([]);
  const [cardStatisticOptions, setCardStatisticOptions] = useState<
    ICardOption[]
  >([]);

  const getOptions = useCallback(async (): Promise<void> => {
    try {
      const { data } = await api.get<{
        docs: ICardOption[];
      }>(`/api/athlete-option-setting`, {
        params: {
          limit: 50,
        },
      });

      const goalOptions = data.docs
        .filter((option) => option.topic === ECardGameOptionTopic.GOAL)
        .sort((a, b) => a.value - b.value);
      const statisticOptions = data.docs
        .filter((option) => option.topic === ECardGameOptionTopic.STATISTIC)
        .sort((a, b) => a.value - b.value);

      setCardGoalOptions(goalOptions);
      setCardStatisticOptions(statisticOptions);
      newCardInitialFormData.current = {
        ...newCardInitialFormData.current,
        options: {
          goalOptions: goalOptions.map((option) => ({
            ...option,
            selected: true,
          })),
          statisticOptions: statisticOptions.map((option) => ({
            ...option,
            selected: true,
          })),
        },
      };
    } catch (error) {
      toast.error('Aconteceu um erro inesperado! Tente novamente...');
    }
  }, []);

  const getDefaultCardPercentages = useCallback(async (): Promise<void> => {
    try {
      const { data } = await api.get<ICardPaymentPercentage>(
        '/api/card-percentage'
      );

      setCardPaymentPercentage(data);
      newCardInitialFormData.current.cardInformation.percentages = {
        homePercent: data.home.toString(),
        influencerPercent: '0',
        winnerPercent: '0',
      };
    } catch (error) {
      toast.error('Aconteceu um erro inesperado! Tente novamente...');
    }
  }, []);

  const getInitialData = useCallback(async () => {
    try {
      await Promise.all([getOptions(), getDefaultCardPercentages()]);
      setIsLoadingPage(false);
    } catch (error) {
      toast.error('Aconteceu um erro inesperado! Tente novamente...');
    }
  }, [getDefaultCardPercentages, getOptions]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;

      beforeUnloadListener.current = (e: BeforeUnloadEvent) => {
        e.preventDefault();
        e.returnValue = '';
      };
      window.addEventListener('beforeunload', beforeUnloadListener.current);

      getInitialData();
    }

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadListener.current);
    };
  }, [getInitialData]);

  const handleToggleCurrentStep = useCallback((newCurrentStep: number) => {
    setCurrentStep(() => {
      if (newCurrentStep < 0) {
        return 0;
      }
      return newCurrentStep;
    });
  }, []);

  const handleSubmit = useCallback(
    async (
      values: INewCardFormData,
      actions: FormikHelpers<INewCardFormData>
    ) => {
      await new Promise((resolve) => {
        confirm({
          title: 'Deseja realmente criar essa cartela?',
          icon: <ExclamationCircleOutlined />,
          content:
            'As informações adicionadas não poderão ser alteradas posteriormente',
          cancelText: 'Cancelar',
          okText: 'Criar cartela',
          onOk() {
            resolve(true);
          },
          onCancel() {
            actions.setSubmitting(false);
          },
        });
      });

      const { cardInformation, options, banner } = values;

      let photoId = null;
      if (banner.file) {
        try {
          const { data } = await UploadRequests.upload({
            file: banner.file,
            from: 'athletesCardGameAvatar',
          });

          photoId = data._id;
        } catch (error) {
          toast.error(
            'Aconteceu um erro inesperado ao enviar o banner da cartela!'
          );

          return;
        }
      }

      const goalOptions =
        cardInformation.type === EGameType.OPTIONS ? options.goalOptions : [];
      const statisticOptions =
        cardInformation.type === EGameType.OPTIONS
          ? options.statisticOptions
          : [];
      const athletes = values.athletes.map((athlete) => {
        const athl: { _team: string; _athlete: string; classicValue?: number } =
          {
            _team: athlete.team._id,
            _athlete: athlete._id,
          };

        if (cardInformation.type === EGameType.CLASSIC) {
          athl.classicValue = athlete.classicValue;
        }

        return athl;
      });

      const influencers = cardInformation.influencers.map((inf) => inf._id);
      const percentages = {
        home: cardInformation.percentages.homePercent,
        influencer: cardInformation.percentages.influencerPercent,
        winner: cardInformation.percentages.winnerPercent,
      };

      const body = {
        name: cardInformation.name,
        price: cardInformation.price,
        gameType: cardInformation.type,
        startDate: cardInformation.startDate?.toDate(),
        endDate: cardInformation.endDate?.toDate(),
        fixedAmount: cardInformation.hasFixedPrize
          ? cardInformation.fixedPrize
          : 0,
        athletes,
        banner: photoId,
        finished: false,
        goalOptions,
        statisticOptions,
        influencers,
        percentages,
      };

      try {
        await api.post('/api/athlete-card', body);
        toast.success('Cartela criada com sucesso!');

        history.push(`/cards`);
      } catch (error) {
        toast.error('Aconteceu um erro inesperado ao cadastrar a cartela!');
      }
    },
    [history]
  );

  if (isLoadingPage) {
    return (
      <Container>
        <Breadcrumbs />
        <TitlePageContainer>
          <h4>Nova cartela</h4>
          <Button onClick={() => history.push(`/cards`)} danger type="text">
            Cancelar
          </Button>
        </TitlePageContainer>
        <Loading />
      </Container>
    );
  }

  return (
    <Container>
      <Breadcrumbs />
      <TitlePageContainer>
        <h4>Nova cartela</h4>
        <Button onClick={() => history.push(`/cards`)} danger type="text">
          Cancelar
        </Button>
      </TitlePageContainer>
      <Content>
        <Steps
          current={currentStep}
          stepsList={[
            { title: 'Informações da cartela' },
            { title: 'Jogadores' },
            { title: 'Opções' },
            { title: 'Banner' },
            { title: 'Confirmar' },
          ]}
        />
        <Formik
          innerRef={formikRef}
          initialValues={newCardInitialFormData.current}
          validationSchema={getNewCardValidationSchema({
            formikRef,
            cardPaymentPercentage,
          })}
          validate={async (values) => {
            const schema = getNewCardValidationSchema({
              formikRef,
              cardPaymentPercentage,
            });
            const isSyncValidation = false;

            try {
              await validateYupSchema(values, schema, isSyncValidation, values);
            } catch (err) {
              return yupToFormErrors(err);
            }

            return {};
          }}
          onSubmit={handleSubmit}
          validateOnMount
        >
          {(formikProps) => (
            <form onSubmit={formikProps.handleSubmit}>
              {currentStep === 0 && (
                <CardInformation
                  formik={formikProps}
                  nextStep={() => handleToggleCurrentStep(1)}
                  cardPaymentPercentage={cardPaymentPercentage}
                />
              )}
              {currentStep === 1 && (
                <Athletes
                  formik={formikProps}
                  prevStep={() => handleToggleCurrentStep(0)}
                  nextStep={() => handleToggleCurrentStep(2)}
                />
              )}
              {currentStep === 2 && (
                <>
                  {formikProps.values.cardInformation.type ===
                    EGameType.OPTIONS && (
                    <Options
                      formik={formikProps}
                      cardGoalOptions={cardGoalOptions}
                      cardStatisticOptions={cardStatisticOptions}
                      prevStep={() => handleToggleCurrentStep(1)}
                      nextStep={() => handleToggleCurrentStep(3)}
                    />
                  )}
                  {formikProps.values.cardInformation.type ===
                    EGameType.CLASSIC && (
                    <ManageAthletes
                      formik={formikProps}
                      prevStep={() => handleToggleCurrentStep(1)}
                      nextStep={() => handleToggleCurrentStep(3)}
                    />
                  )}
                </>
              )}
              {currentStep === 3 && (
                <Banner
                  formik={formikProps}
                  prevStep={() => handleToggleCurrentStep(2)}
                  nextStep={() => handleToggleCurrentStep(4)}
                />
              )}
              {currentStep === 4 && (
                <Confirm
                  formik={formikProps}
                  prevStep={() => handleToggleCurrentStep(3)}
                  handleSubmit={() => formikProps.handleSubmit()}
                />
              )}
            </form>
          )}
        </Formik>
      </Content>
    </Container>
  );
};

export default NewAthleteCard;
