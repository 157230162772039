import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const TitlePageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* AntD Button Changes */
  button {
    margin-left: 1rem;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  align-self: center;
  width: 80%;
  min-width: 36.75rem;
  margin-top: 2rem;

  form {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
  }

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    padding: 0;

    width: 100%;
    min-width: 100%;
    margin-top: 1rem;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: space-between;
  margin-top: 1.5rem;

  button.ant-btn-primary {
    flex: 1;
  }
`;
