import styled from 'styled-components';

export const Container = styled.div``;

export const LargeDevicesContainer = styled.div`
  display: none;

  @media only screen and (min-width: 768px) {
    display: flex;
  }
`;

export const SmallDevicesContainer = styled.div`
  display: none;

  @media only screen and (max-width: 767px) {
    display: flex;
  }
`;
