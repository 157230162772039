import React from 'react';
import { Button } from 'antd';
import {
  FastField,
  FastFieldProps,
  Field,
  FieldProps,
  FormikProps,
} from 'formik';

import Input from '@/components/Input';

import Select from '@/components/Select';
import TextArea from '@/components/TextArea';
import Radio from '@/components/Radio';
import { useParams } from 'react-router';
import { ButtonsContainer } from '../styles';
import { ICardInformationProps } from './types';
import { IAdsFormData, IRouteParams } from '../types';
import { AdsTypeEnum } from '../../types';

const AdsDetails: React.FC<ICardInformationProps> = ({ formik, nextStep }) => {
  const params = useParams<IRouteParams>();

  const adsType = formik.values.adsDetails.type;

  function checkIfIsNextButtonEnabled({
    touched,
    errors,
  }: FormikProps<IAdsFormData>): boolean {
    if (!touched.adsDetails) {
      return false;
    }

    if (!errors.adsDetails) {
      return true;
    }

    return false;
  }

  return (
    <>
      <Field name="adsDetails.type">
        {({ field, meta, form }: FieldProps) => {
          return (
            <Select
              {...field}
              label="Tipo do anúncio"
              identifier="adsType"
              placeholder="Selecione o tipo de anúncio"
              options={[
                { label: 'Imagem', value: AdsTypeEnum.IMAGE },
                { label: 'Vídeo', value: AdsTypeEnum.VIDEO },
              ]}
              error={meta?.touched && meta?.error}
              onChange={(value: string) => {
                form.setFieldValue('adsDetails.type', value);
              }}
              disabled={!!params.ad_id}
            />
          );
        }}
      </Field>
      <FastField name="adsDetails.title">
        {({ field, meta }: FastFieldProps) => {
          return (
            <Input
              {...field}
              label="Título"
              placeholder=""
              identifier="adsTitle"
              type="text"
              error={meta?.touched && meta?.error && meta?.error}
              maxLength={40}
            />
          );
        }}
      </FastField>
      <FastField name="adsDetails.description">
        {({ field, meta }: FastFieldProps) => {
          return (
            <TextArea
              {...field}
              label="Descrição"
              identifier="adsDescription"
              error={meta?.touched && meta?.error && meta?.error}
              maxLength={120}
              autoSize={{
                minRows: 1,
              }}
            />
          );
        }}
      </FastField>
      <FastField name="adsDetails.isTemporary">
        {({ field, meta }: FastFieldProps) => {
          return (
            <Radio
              {...field}
              items={[
                {
                  value: true,
                  label: 'Sim',
                },
                {
                  value: false,
                  label: 'Não',
                },
              ]}
              label="O anúncio é temporário?"
              vertical
              error={meta?.touched && meta?.error && meta?.error}
              disabled={!!params.ad_id}
            />
          );
        }}
      </FastField>
      {adsType && (
        <Field name="adsDetails.url">
          {({ field, meta }: FieldProps) => {
            return (
              <Input
                {...field}
                label={adsType === AdsTypeEnum.IMAGE ? 'URL' : 'URL do Youtube'}
                placeholder={
                  adsType === AdsTypeEnum.IMAGE
                    ? 'ex.: https://www.google.com'
                    : 'Insira um vídeo do Youtube'
                }
                identifier="adsUrl"
                type="text"
                error={meta?.touched && meta?.error && meta?.error}
              />
            );
          }}
        </Field>
      )}

      <ButtonsContainer>
        <Button
          onClick={nextStep}
          type="primary"
          disabled={!checkIfIsNextButtonEnabled(formik)}
        >
          Próximo
        </Button>
      </ButtonsContainer>
    </>
  );
};

export default AdsDetails;
