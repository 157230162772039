import React from 'react';

import CustomAntButton from '@/components/CustomAntButton';

import PushGameCard from './PushGameCard';

import { Container, ButtonContainer, LoadMoreButton } from './styles';
import { LoadingAndNotFoundContainer } from '../styles';

function PushGameList({
  setAddingNewGame,
  pushGames,
  getPushGames,
  pushGamesPagination,
  loadingPushGames,
}) {
  return (
    <Container>
      <ButtonContainer>
        <CustomAntButton type="primary" onClick={() => setAddingNewGame(true)}>
          Novo jogo
        </CustomAntButton>
      </ButtonContainer>
      {pushGames.length > 0 ? (
        <>
          {pushGames.map(pushGame => (
            <PushGameCard
              key={pushGame._id}
              pushGame={pushGame}
              getPushGames={getPushGames}
            />
          ))}
          {pushGamesPagination.currentPage < pushGamesPagination.totalPages && (
            <LoadMoreButton
              disabled={loadingPushGames}
              onClick={() => {
                if (!loadingPushGames) {
                  getPushGames(pushGamesPagination.currentPage + 1);
                }
              }}
            >
              <p>{!loadingPushGames ? 'Ver mais' : 'Carregando...'}</p>
            </LoadMoreButton>
          )}
        </>
      ) : (
        <LoadingAndNotFoundContainer>
          <div>
            <h6>
              Não foram encontrados <span>jogos</span> nessa partida
            </h6>
          </div>
        </LoadingAndNotFoundContainer>
      )}
    </Container>
  );
}

export default PushGameList;
