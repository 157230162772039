import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as dateFns from 'date-fns';

import api from '@/services/api';

import CustomAntButton from '@/components/CustomAntButton';
import AthleteCardPlayer from './AthleteCardPlayer';
import { Container, AthleteCardFinishedSwitchContainer } from './styles';
import {
  IRouteParams,
  IUpdateResultsFormData,
  IUpdateResultsProps,
} from './types';
import { EGameType } from '../../types';

const { confirm } = Modal;

const UpdateResults: React.FC<IUpdateResultsProps> = ({ card }) => {
  const history = useHistory();
  const { params } = useRouteMatch<IRouteParams>();

  const [cardForm, setCardForm] = useState<IUpdateResultsFormData>(
    {} as IUpdateResultsFormData
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    setCardForm({
      athletes: card.athletes.map((athlete) => ({
        ...athlete,
        status: athlete.status || false,
        _athleteGoals: athlete._athleteGoals || [],
        _athleteStatistics: athlete._athleteStatistics || [],
        saved: true,
      })),
      finished: card.finished,
    });
  }, [card]);

  const isFinishButtonEnabled = useMemo(() => {
    const areAllAthletesValid = cardForm?.athletes?.every((athlete) => {
      if (card.gameType === EGameType.CLASSIC) {
        return athlete.status && athlete.saved;
      }

      return (
        athlete.status &&
        !!athlete._athleteGoals.length &&
        !!athlete._athleteStatistics.length &&
        athlete.saved
      );
    });

    const isCurrentDateAfterCardEndDate = dateFns.isAfter(
      new Date(),
      dateFns.parseISO(card.endDate)
    );
    const isCardFinished = card.finished;

    return (
      areAllAthletesValid && isCurrentDateAfterCardEndDate && !isCardFinished
    );
  }, [card, cardForm]);

  const handleFinishCard = useCallback(async () => {
    await new Promise((resolve) => {
      confirm({
        title: 'Deseja realmente finalizar essa cartela?',
        icon: <ExclamationCircleOutlined />,
        content: 'Essa ação é irreversível',
        cancelText: 'Cancelar',
        okText: 'Finalizar cartela',
        onOk() {
          resolve(true);
        },
      });
    });

    const body = {
      finished: true,
    };

    setIsSubmitting(true);
    try {
      await api.put(`/api/end-athlete/${params.id}`, body);
      toast.success('Cartela finalizada com sucesso!');
      history.push(`/cards`);
    } catch (error) {
      setIsSubmitting(false);
      toast.error('Aconteceu um erro inesperado!');
    }
  }, [history, params.id]);

  return (
    <Container>
      {cardForm.athletes ? (
        cardForm.athletes.map((athlete) => (
          <AthleteCardPlayer
            key={athlete._athlete._id}
            athlete={athlete}
            cardForm={cardForm}
            setCardForm={setCardForm}
            disabled={isSubmitting || card?.finished}
            goalOptions={card.goalOptions}
            statisticOptions={card.statisticOptions}
            gameType={card.gameType}
          />
        ))
      ) : (
        <p>Nenhum jogador encontrado nessa cartela</p>
      )}
      {isFinishButtonEnabled && (
        <AthleteCardFinishedSwitchContainer>
          <CustomAntButton
            onClick={handleFinishCard}
            type="primary"
            loading={isSubmitting}
          >
            Finalizar cartela
          </CustomAntButton>
        </AthleteCardFinishedSwitchContainer>
      )}
    </Container>
  );
};

export default UpdateResults;
