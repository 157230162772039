export function getMyTeamDataRequest(user) {
  return {
    type: '@team/GET_TEAM_DATA_REQUEST',
    payload: { user },
  };
}
export function getMyTeamDataSuccess(team) {
  return {
    type: '@team/GET_TEAM_DATA_SUCCESS',
    payload: { team },
  };
}
export function getMyTeamDataFailure() {
  return {
    type: '@team/GET_TEAM_DATA_FAILURE',
  };
}

export function updateMyTeam(team) {
  return {
    type: '@team/UPDATE_TEAM',
    payload: { team },
  };
}
