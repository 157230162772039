import React from 'react';
import { Input as InputAnt } from 'antd';

import { Container } from './styles';
import { ITextAreaProps } from './types';

const { TextArea: TextAreaAnt } = InputAnt;

const TextArea: React.FC<ITextAreaProps> = ({
  label,
  identifier,
  error,
  ...rest
}) => {
  return (
    <Container error={!!error}>
      <label htmlFor={identifier}>{label}</label>
      <TextAreaAnt id={identifier} bordered {...rest} />
      {error && <span>{error}</span>}
    </Container>
  );
};

export default TextArea;
