import React, { useState, useCallback, useRef } from 'react';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import Breadcrumbs from '@/components/Breadcrumbs';
import Steps from '@/components/Steps';

import api from '@/services/api';

import confirm from 'antd/lib/modal/confirm';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { UploadRequests } from '@/services/api/requests/Upload';
import { Container, TitlePageContainer, Content } from './styles';

import TeamData from './TeamData';
import TeamImage from './Image';
import Confirm from './Confirm';
import { INewTeamFormData } from './types';

const New: React.FC = () => {
  const history = useHistory();

  const newCardInitialFormData = useRef<INewTeamFormData>({
    teamData: {
      name: '',
      initials: '',
      type: undefined,
    },
    image: {
      file: undefined,
      previewUrl: '',
    },
  });

  const [currentStep, setCurrentStep] = useState(0);

  const handleToggleCurrentStep = useCallback((targetStep: number) => {
    let step = targetStep;
    if (step < 0) {
      step = 0;
    }

    setCurrentStep(step);
  }, []);

  const handleSubmit = useCallback(
    async (
      values: INewTeamFormData,
      actions: FormikHelpers<INewTeamFormData>
    ) => {
      await new Promise((resolve) => {
        confirm({
          title: 'Deseja realmente criar esse time?',
          icon: <ExclamationCircleOutlined />,
          cancelText: 'Cancelar',
          okText: 'Criar time',
          onOk() {
            resolve(true);
          },
          onCancel() {
            actions.setSubmitting(false);
          },
        });
      });

      try {
        const { teamData, image } = values;
        let photoId = null;
        if (image.file) {
          const { data } = await UploadRequests.upload({
            file: image.file,
            from: 'teamImage',
          });
          photoId = data._id;
        }
        const body = {
          name: teamData.name,
          initials: teamData.initials,
          teamType: teamData.type,
          image: photoId ? UploadRequests.getFileUrl(photoId) : null,
        };
        await api.post('/api/team', body);
        toast.success('Time criado com sucesso!');
        actions.setSubmitting(false);
        history.push('/teams');
      } catch (error) {
        toast.error('Aconteceu um erro inesperado, tente novamente!');
      }
    },
    [history]
  );

  return (
    <Container>
      <Breadcrumbs />
      <TitlePageContainer>
        <h4>Novo time</h4>
        <Button onClick={() => history.goBack()} danger type="text">
          Cancelar
        </Button>
      </TitlePageContainer>
      <Content>
        <Steps
          current={currentStep}
          stepsList={[
            { title: 'Dados do time' },
            { title: 'Imagem' },
            { title: 'Confirmar' },
          ]}
        />
        <Formik
          initialValues={newCardInitialFormData.current}
          validationSchema={Yup.object().shape({
            teamData: Yup.object().shape({
              name: Yup.string().required('O nome do time é obrigatório'),
              initials: Yup.string()
                .required('A abreviatura é obrigatória')
                .length(3),
              type: Yup.string().required('O tipo do time é obrigatório'),
            }),
          })}
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <form onSubmit={formikProps.handleSubmit}>
              {currentStep === 0 && (
                <TeamData
                  formik={formikProps}
                  nextStep={() => handleToggleCurrentStep(1)}
                />
              )}
              {currentStep === 1 && (
                <TeamImage
                  formik={formikProps}
                  prevStep={() => handleToggleCurrentStep(0)}
                  nextStep={() => handleToggleCurrentStep(2)}
                />
              )}
              {currentStep === 2 && (
                <Confirm
                  formik={formikProps}
                  prevStep={() => handleToggleCurrentStep(1)}
                  onSubmit={formikProps.handleSubmit}
                />
              )}
            </form>
          )}
        </Formik>
      </Content>
    </Container>
  );
};

export default New;
