import { produce } from 'immer';

const INITIAL_STATE = {
  profile: null,
  type: null,
  loading: false,
};

export default function user(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case 'persist/REHYDRATE':
      case '@auth/SIGN_IN_SUCCESS':
        draft.loading = true;
        break;
      case '@user/GET_USER_DATA_SUCCESS': {
        draft.profile = action.payload.user;
        draft.loading = false;

        let type = 'admin';
        if (action.payload.user.roles.includes('team')) type = 'team';
        draft.type = type;

        break;
      }
      case '@user/GET_USER_DATA_FAILURE': {
        draft.loading = false;
        break;
      }
      case '@auth/SIGN_OUT_SUCCESS': {
        draft.profile = null;
        draft.type = null;
        draft.loading = false;
        break;
      }

      // case '@user/UPDATE_PROFILE_SUCCESS': {
      //   draft.profile = action.payload.profile;
      //   break;
      // }

      default:
    }
  });
}
