import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useHistory, Link } from 'react-router-dom';
import * as _ from 'lodash';
import { Input, Button, Pagination, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { InfluencerRequests } from '@/services/api/requests/Influencer';
import { UploadRequests } from '@/services/api/requests/Upload';
import DefaultAvatar from '@/assets/DefaultAvatar.svg';

import {
  Container,
  TitlePageContainer,
  SearchAndNewUserContainer,
  UsersListContainer,
  UsersList,
  UserDetailContainer,
  UserDataContainer,
} from './styles';
import { IInfluencerDataProps } from './types';

const { Search } = Input;
const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;

const Influencers: React.FC = () => {
  const history = useHistory();

  const [influencers, setInfluencers] = useState<IInfluencerDataProps>();
  const [searchInputValue, setSearchInputValue] = useState('');
  const [loadingInfluencers, setLoadingInfluencers] = useState(false);

  const handleGetInfluencers = useCallback(
    async (search: string, page?: number) => {
      setLoadingInfluencers(true);
      try {
        const { data } = await InfluencerRequests.getInfluencers({
          search,
          page,
        });

        setInfluencers({
          total: data.total,
          currentPage: data.page,
          pageSize: data.limit,
          items: data.docs,
        });

        setLoadingInfluencers(false);
      } catch (error) {
        setLoadingInfluencers(false);
      }
    },
    []
  );

  const influencerSearchDebounced = useMemo(() => {
    return _.debounce(handleGetInfluencers, 500);
  }, [handleGetInfluencers]);

  useEffect(() => {
    influencerSearchDebounced.cancel();

    setLoadingInfluencers(true);
    influencerSearchDebounced(searchInputValue);
  }, [searchInputValue, influencerSearchDebounced]);

  const contentPage = useMemo(() => {
    if (loadingInfluencers) {
      return <Spin indicator={antIcon} />;
    }

    if (searchInputValue.length && searchInputValue.length <= 3) {
      return <h6>Digite acima de 3 caracteres para realizar a busca</h6>;
    }

    if (influencers?.items?.length) {
      return (
        <>
          <UsersList>
            {influencers?.items.map((influencer) => (
              <UserDetailContainer
                onClick={() => history.push(`/influencers/${influencer?._id}`)}
                key={influencer?.username}
              >
                <img
                  src={
                    influencer?.photo?.filename
                      ? UploadRequests.getFileUrl(influencer.photo.filename)
                      : DefaultAvatar
                  }
                  alt={influencer?.name || 'Avatar'}
                />
                <UserDataContainer>
                  <div>
                    <p>{influencer?.name}</p>
                    {influencer?.status === 0 && <span>Inativo</span>}
                  </div>
                  <span>{influencer?.username}</span>
                  <span>{influencer?.email}</span>
                </UserDataContainer>
              </UserDetailContainer>
            ))}
          </UsersList>
          <Pagination
            current={influencers.currentPage}
            onChange={(page) => handleGetInfluencers(searchInputValue, page)}
            total={influencers.total}
            pageSize={influencers.pageSize}
          />
        </>
      );
    }

    if (influencers?.items?.length === 0) {
      return <h6>Nenhum influenciador encontrado</h6>;
    }

    return '';
  }, [
    handleGetInfluencers,
    history,
    loadingInfluencers,
    searchInputValue,
    influencers,
  ]);

  return (
    <Container>
      <TitlePageContainer>
        <h4>Influenciadores</h4>
        <Link to="/influencers/new">
          <Button type="primary">Novo influenciador</Button>
        </Link>
      </TitlePageContainer>
      <SearchAndNewUserContainer>
        <Search
          onChange={(e) => setSearchInputValue(e.target.value)}
          placeholder="Pesquisar influenciador"
        />
        <Link to="/influencers/new">
          <Button type="primary">Novo influenciador</Button>
        </Link>
      </SearchAndNewUserContainer>
      <UsersListContainer>{contentPage}</UsersListContainer>
    </Container>
  );
};

export default Influencers;
