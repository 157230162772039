import styled from 'styled-components';

export const Container = styled.div`
  > button {
    width: 100%;
  }
`;

export const LoadingAndNotFoundContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  > small {
    width: 100%;
    text-align: center;
    color: ${props => props.theme.colors.dark['dark+3']};
  }

  > div {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 56px;

    p {
      margin-left: 16px;
      color: ${props => props.theme.colors.dark['dark+3']};
    }

    h6 {
      text-align: center;

      span {
        color: ${props => props.theme.colors.primary.primary0};
      }
    }
  }
`;
