import styled from 'styled-components';

export const Container = styled.div``;

export const PlayerDataContainer = styled.ul`
  width: 100%;
  margin-top: 16px;

  li {
    list-style: none;
    width: 100%;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0px;
    }

    small {
      color: ${props => props.theme.colors.primary.primary0};
    }
  }
`;

export const AvatarCropperContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: space-between;
  margin-top: 24px;
  width: 100%;
  max-width: 550px;
  align-self: center;

  button.ant-btn-primary {
    flex: 1;
  }
`;
