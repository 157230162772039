export function validateCpf(cpf) {
  if (!cpf) return 'valid';

  cpf = cpf.replace(/-/g, '').replace(/\./g, '');

  try {
    let soma = 0;
    let resto;

    /* Verifica se o CPF tem os 11 dígitos */
    const testLengthCpf = new RegExp('^[0-9]{11}$');
    if (!testLengthCpf.test(cpf)) {
      throw new Error('lengthInvalid');
    }

    /* Verifica se o CPF tem dos os dígitos iguais(inválido) */
    const testCPFInvalid = new RegExp(
      '^((1{11})|(2{11})|(3{11})|(4{11})|(5{11})|(6{11})|(7{11})|(8{11})|(9{11}))$'
    );
    if (testCPFInvalid.test(cpf)) {
      throw new Error('cpfInvalid');
    }

    /* ALGORITMO DE VALIDAÇÃO */
    for (let i = 1; i <= 9; i++)
      soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf.substring(9, 10))) throw new Error('cpfInvalid');

    soma = 0;
    for (let i = 1; i <= 10; i++)
      soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf.substring(10, 11)))
      throw new Error('cpfInvalid');
    return 'valid';
  } catch (error) {
    return 'O CPF informado não é válido';
  }
}

export function validateCnpj(cnpj) {
  if (!cnpj) return 'valid';

  cnpj = cnpj.replace(/[^\d]+/g, '');

  if (cnpj === '') return 'O CNPJ informado não é válido';

  if (cnpj.length !== 14) return 'O CNPJ informado não é válido';

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj === '00000000000000' ||
    cnpj === '11111111111111' ||
    cnpj === '22222222222222' ||
    cnpj === '33333333333333' ||
    cnpj === '44444444444444' ||
    cnpj === '55555555555555' ||
    cnpj === '66666666666666' ||
    cnpj === '77777777777777' ||
    cnpj === '88888888888888' ||
    cnpj === '99999999999999'
  )
    return 'O CNPJ informado não é válido';

  // Valida DVs
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  const digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos;
    pos -= 1;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== parseInt(digitos.charAt(0)))
    return 'O CNPJ informado não é válido';

  tamanho += 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos;
    pos -= 1;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== parseInt(digitos.charAt(1)))
    return 'O CNPJ informado não é válido';

  return 'valid';
}

export function getYouTubeVideoId(urlToParse) {
  if (urlToParse) {
    const regExp =
      /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|shorts\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;

    const youTubeUrl = urlToParse.match(regExp);
    if (youTubeUrl) {
      const videoId = youTubeUrl[1];
      return videoId;
    }
  }

  return null;
}

// export const validatePhoneRegEx = /^\([0-9]{2}\)([0-9]{5}|[0-9]{4})-[0-9]{4}$/;
// export const validatePhoneRegEx = /^\([0-9]{2}\)([0-9]{5}|[0-9]{4})-[0-9]{4}$/;
