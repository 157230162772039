import React from 'react';
import { Button } from 'antd';

import AvatarCropper from '@/components/AvatarCropper';
import { ButtonsContainer } from '../../../Users/New/styles';

import { ITeamImageProps } from './types';

const Image: React.FC<ITeamImageProps> = ({ formik, nextStep, prevStep }) => {
  function checkIfIsNextButtonEnabled(): boolean {
    return !!formik?.values?.image?.previewUrl;
  }

  return (
    <>
      <AvatarCropper
        croppedAvatar={formik?.values?.image}
        selectImageButtonLabel="Selecione uma imagem para adicionar para o time (.png sem fundo)"
        aspect={1 / 1}
        setCroppedAvatar={({
          file,
          previewUrl,
        }: {
          file?: unknown;
          previewUrl?: string;
        }) => {
          formik.setFieldValue('image.file', file);
          formik.setFieldValue('image.previewUrl', previewUrl);
        }}
        required
        circularCrop={false}
        setHaveChanges={() => ({})}
      />
      <ButtonsContainer>
        <Button onClick={prevStep} type="default">
          Voltar
        </Button>
        <Button
          onClick={nextStep}
          type="primary"
          disabled={!checkIfIsNextButtonEnabled()}
        >
          Próximo
        </Button>
      </ButtonsContainer>
    </>
  );
};

export default Image;
