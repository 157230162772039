import styled from 'styled-components';

export const SessionContainer = styled.div`
  width: 100%;
  margin-bottom: 32px;

  > img {
    width: 104px;
    margin-top: 16px;
    border-radius: 8px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  }

  > p {
    margin-top: 16px;
  }

  > div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 16px;
  }
`;

export const SessionItems = styled.ul`
  width: 100%;
  margin-top: 16px;
  margin-bottom: 0;

  li {
    list-style: none;
    width: 100%;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0px;
    }

    small {
      color: ${props => props.theme.colors.primary.primary0};
    }
  }
`;

export const MatchSelectedContainer = styled.div`
  width: 49.5%;
  border-radius: 8px;
  background: ${props => props.theme.colors.light.light0};
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  padding: 16px;
  position: relative;
  margin-bottom: 8px;

  @media only screen and (max-width: 1199px) {
    width: 100%;
  }

  > div:first-child {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;

    h6 {
      font-weight: ${props => props.theme.typograph.weights.medium};
      color: ${props => props.theme.colors.dark['dark+4']};
      margin: 0 24px;
    }
  }

  > svg {
    position: absolute;
    top: 16px;
    right: 16px;
  }
`;

export const MatchSelectedTeam = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;

  div {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background: ${props => props.theme.colors.light['light-2']};
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 4px;

    img {
      height: 36px;
    }
  }

  strong {
    text-align: center;
  }
`;

export const MatchInfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  border-top: 1px solid ${props => props.theme.colors.light['light-2']};

  div {
    width: 100%;

    & + div {
      margin-top: 4px;
    }

    small {
      color: ${props => props.theme.colors.dark['dark+3']};
      text-align: start;
    }

    small:nth-child(1) {
      color: ${props => props.theme.colors.primary.primary0};
    }
  }
`;
