import React, { useCallback, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Checkbox } from 'antd';
import { toast } from 'react-toastify';

import DefaultAvatar from '@/assets/DefaultAvatar.svg';
import api from '@/services/api';
import { UploadRequests } from '@/services/api/requests/Upload';
import CustomAntButton from '@/components/CustomAntButton';
import { FiMinus, FiPlus } from 'react-icons/fi';
import {
  getAthleteNumberDescription,
  getPlayerPositionLabel,
} from '@/pages/AthleteCards/utils';
import {
  AthleteScore,
  Container,
  OptionsContainer,
  OptionsWrapper,
  QuantityOfPlayerGoalsSelectorContainer,
} from './styles';
import { EGameType } from '../../../types';
import { IAthleteCardPlayerProps } from './types';
import { IRouteParams } from '../types';

const AthleteCardPlayer: React.FC<IAthleteCardPlayerProps> = ({
  athlete,
  cardForm,
  setCardForm,
  disabled,
  goalOptions,
  statisticOptions,
  gameType,
}) => {
  const { params } = useRouteMatch<IRouteParams>();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmitChanges = useCallback(async () => {
    const updatedAthlete = cardForm.athletes.find(
      (athl) => athl._athlete._id === athlete._athlete._id
    );

    const _athleteGoals =
      gameType === EGameType.OPTIONS ? updatedAthlete?._athleteGoals : [];
    const _athleteStatistics =
      gameType === EGameType.OPTIONS ? updatedAthlete?._athleteStatistics : [];
    const athleteScore =
      gameType === EGameType.CLASSIC ? updatedAthlete?.athleteScore : null;

    const body = {
      athlete: {
        _team: updatedAthlete?._team?._id,
        _athlete: updatedAthlete?._athlete?._id,
        athleteScore,
        status: updatedAthlete?.status,
        _athleteGoals,
        _athleteStatistics,
      },
    };

    setIsSubmitting(true);
    try {
      await api.put(`/api/end-athlete/${params.id}`, body);

      toast.success('Dados da cartela atualizados com sucesso!');
      setIsSubmitting(false);

      const updatedCardForm = {
        ...cardForm,
        athletes: cardForm.athletes.map((athl) => {
          if (athl._athlete._id === athlete._athlete._id) {
            return {
              ...athl,
              saved: true,
            };
          }

          return athl;
        }),
      };

      setCardForm(updatedCardForm);
    } catch (error) {
      toast.error('Aconteceu um erro inesperado ao atualizar os dados!');
      setIsSubmitting(false);
    }
  }, [athlete._athlete._id, cardForm, gameType, params.id, setCardForm]);

  const handleChangeStatusOfPlayer = useCallback(
    (status: boolean) => {
      if (disabled) return;

      const updatedCardForm = {
        ...cardForm,
        athletes: cardForm.athletes.map((athl) => {
          if (athl._athlete._id === athlete._athlete._id) {
            return {
              ...athl,
              status,
              _athleteGoals: [],
              _athleteStatistics: [],
              athleteScore: 0,
              saved: false,
            };
          }

          return athl;
        }),
      };

      setCardForm(updatedCardForm);
    },
    [athlete._athlete._id, cardForm, disabled, setCardForm]
  );

  const handleSelectGoalOption = useCallback(
    (_athleteGoal: string, selected: boolean) => {
      if (disabled) return;

      const updatedCardForm = {
        ...cardForm,
        athletes: cardForm.athletes.map((athl) => {
          if (athl._athlete._id === athlete._athlete._id) {
            let { _athleteGoals } = athl;

            if (selected) {
              _athleteGoals.push(_athleteGoal);
            } else {
              _athleteGoals = _athleteGoals.filter(
                (option) => option !== _athleteGoal
              );
            }

            return {
              ...athl,
              _athleteGoals,
              saved: false,
            };
          }

          return athl;
        }),
      };

      setCardForm(updatedCardForm);
    },
    [athlete._athlete._id, cardForm, disabled, setCardForm]
  );

  const handleSelectStatisticOption = useCallback(
    (_athleteStatistic: string, selected: boolean) => {
      if (disabled) return;

      const updatedCardForm = {
        ...cardForm,
        athletes: cardForm.athletes.map((athl) => {
          if (athl._athlete._id === athlete._athlete._id) {
            let { _athleteStatistics } = athl;

            if (selected) {
              _athleteStatistics.push(_athleteStatistic);
            } else {
              _athleteStatistics = _athleteStatistics.filter(
                (option) => option !== _athleteStatistic
              );
            }

            return {
              ...athl,
              _athleteStatistics,
              saved: false,
            };
          }

          return athl;
        }),
      };

      setCardForm(updatedCardForm);
    },
    [athlete._athlete._id, cardForm, disabled, setCardForm]
  );

  const handleChangeQuantityOfPlayerGoals = useCallback(
    (_athleteId: string, score: number) => {
      if (score < 0) {
        return;
      }

      const updatedCardForm = {
        ...cardForm,
        athletes: cardForm.athletes.map((athl) => {
          if (athl._athlete._id === _athleteId) {
            return {
              ...athl,
              athleteScore: score,
              saved: false,
            };
          }

          return athl;
        }),
      };

      setCardForm(updatedCardForm);
    },
    [cardForm, setCardForm]
  );

  return (
    <Container key={athlete?._athlete?._id} $status={athlete.status}>
      <div>
        <div>
          <img
            src={
              athlete?._athlete?.photo
                ? UploadRequests.getFileUrl(athlete._athlete.photo)
                : DefaultAvatar
            }
            alt={athlete?._athlete?.name || ''}
          />
        </div>
        <div>
          <strong>{athlete?._athlete?.name}</strong>
          <small>
            {getAthleteNumberDescription(athlete?._athlete?.number)}
            {getPlayerPositionLabel(athlete?._athlete?.position)}
          </small>
          {gameType === EGameType.CLASSIC && (
            <AthleteScore>{athlete.classicValue} pontos</AthleteScore>
          )}
          <div>
            <img src={athlete?._team?.image} alt={athlete?._team?.name || ''} />
            <p>{athlete?._team?.name}</p>
          </div>
        </div>
      </div>
      <Checkbox
        checked={athlete.status}
        onChange={(e) => handleChangeStatusOfPlayer(e.target.checked)}
        disabled={disabled}
      >
        Jogo iniciado ou finalizado
      </Checkbox>
      {athlete.status && gameType === EGameType.OPTIONS && (
        <OptionsWrapper>
          <OptionsContainer>
            <h6>Gols</h6>
            {goalOptions.map((option) => (
              <Checkbox
                key={option._id}
                checked={
                  !!athlete._athleteGoals.find((opt) => opt === option._id)
                }
                onChange={(e) =>
                  handleSelectGoalOption(option._id, e.target.checked)
                }
                disabled={disabled}
              >
                {option.name} - {option.value} ponto{option.value > 1 && 's'}
              </Checkbox>
            ))}
          </OptionsContainer>
          <OptionsContainer>
            <h6>Estatísticas</h6>
            {statisticOptions.map((option) => (
              <Checkbox
                key={option._id}
                checked={
                  !!athlete._athleteStatistics.find((opt) => opt === option._id)
                }
                onChange={(e) =>
                  handleSelectStatisticOption(option._id, e.target.checked)
                }
                disabled={disabled}
              >
                {option.name} - {option.value} ponto{option.value > 1 && 's'}
              </Checkbox>
            ))}
          </OptionsContainer>
        </OptionsWrapper>
      )}
      {athlete.status && gameType === EGameType.CLASSIC && (
        <QuantityOfPlayerGoalsSelectorContainer>
          <CustomAntButton
            type="text"
            danger
            icon={<FiMinus size={20} />}
            onClick={() => {
              handleChangeQuantityOfPlayerGoals(
                athlete?._athlete?._id,
                athlete.athleteScore - 1
              );
            }}
            disabled={athlete.athleteScore === 0 || disabled}
          />
          <h6>{athlete.athleteScore}</h6>
          <CustomAntButton
            type="text"
            useTextPrimary
            icon={<FiPlus size={20} />}
            onClick={() => {
              handleChangeQuantityOfPlayerGoals(
                athlete?._athlete?._id,
                athlete.athleteScore + 1
              );
            }}
            disabled={disabled}
          />
        </QuantityOfPlayerGoalsSelectorContainer>
      )}
      <CustomAntButton
        type="primary"
        disabled={athlete.saved}
        onClick={handleSubmitChanges}
        loading={isSubmitting}
      >
        Salvar alterações
      </CustomAntButton>
    </Container>
  );
};

export default AthleteCardPlayer;
