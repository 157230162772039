export enum ECardGameOptionTopic {
  GOAL = 'goal',
  STATISTIC = 'statistic',
  OTHERS = 'others',
}

export interface ICardOption {
  _id: string;
  value: number;
  status: boolean;
  name: string;
  topic: ECardGameOptionTopic;
}
