import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.light['light-1']};
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0px 2px 3px #00000030;

  > img {
    width: 100%;
  }

  > small {
    margin-top: 0.5rem;
  }
`;

export const Header = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;

  > strong {
    flex: 1;
    font-size: ${(props) => props.theme.typograph.sizes.xmd}px;
  }
`;

export const YoutubeVideoContainer = styled.div`
  width: 100%;
  padding-top: 56.25%;
  position: relative;

  iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`;

export const UrlLink = styled.a`
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  background-color: ${(props) => props.theme.colors.light['light-2']};
  font-size: ${(props) => props.theme.typograph.sizes.small}px;
  font-weight: ${(props) => props.theme.typograph.weights.medium};
  color: ${(props) => props.theme.colors.dark['dark+3']};
  width: fit-content;
  margin: 0.5rem auto 0;
`;
