import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const TitlePageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  margin-bottom: 32px;
  align-items: center;

  @media only screen and (max-width: 768px) {
    margin-bottom: 16px;
  }

  /* AntD Button Changes */
  button {
    margin-left: 16px;
  }
`;

export const Content = styled.div`
  padding: 0 15%;
  display: flex;
  flex-direction: column;

  form {
    align-self: center;
    display: flex;
    flex-direction: column;
    width: 80%;
    min-width: 728px;
    margin-top: 40px;
  }

  @media only screen and (max-width: 768px) {
    padding: 0;

    form {
      width: 100%;
      min-width: 100%;
      margin-top: 16px;
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: space-between;
  margin-top: 24px;

  button.ant-btn-primary {
    flex: 1;
  }

  button.ant-btn-default {
    margin-right: 16px;
  }
`;
