import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  useParams,
  useHistory,
  useRouteMatch,
  useLocation,
  Link,
  Switch,
  Route,
} from 'react-router-dom';
import { Button, Spin, Modal } from 'antd';
import { toast } from 'react-toastify';
import { LoadingOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { IoMdCheckmarkCircle } from 'react-icons/io';
import { MdEdit, MdBlock } from 'react-icons/md';

import Breadcrums from '@/components/Breadcrumbs';
import CustomAntButton from '@/components/CustomAntButton';

import { InfluencerRequests } from '@/services/api/requests/Influencer';
import { UploadRequests } from '@/services/api/requests/Upload';
import NotFoundImage from '@/assets/NotFound.svg';
import DefaultAvatar from '@/assets/DefaultAvatar.svg';
import { UserRoleEnum } from '@/models/User';

import {
  Container,
  Header,
  Content,
  ImageWrapper,
  UserInformationHeader,
  UserNotFoundContainer,
  ActionButtonAvatar,
} from './styles';

import Details from './Details';
import UpdateAvatar from './UpdateAvatar';
import EditUserInformation from './EditInfluencerInformation';
import EditPersonalData from './EditPersonalData';
import { IRouteParams, IInfluencerState } from './types';

const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;
const { confirm } = Modal;

const Influencer: React.FC = () => {
  const params = useParams<IRouteParams>();
  const history = useHistory();
  const routeMatch = useRouteMatch();
  const location = useLocation();

  const [influencer, setInfluencer] = useState<IInfluencerState>(
    {} as IInfluencerState
  );
  const [loadingInfluencer, setLoadingInfluencer] = useState(true);
  const [avatarModalVisible, setAvatarModalVisible] = useState(false);

  useEffect(() => {
    async function getInfluencer(_id: string): Promise<void> {
      try {
        const { data } = await InfluencerRequests.getInfluencerDetails(_id);

        const isInfluencer = !!data?.roles.find(
          (role: UserRoleEnum) => role === UserRoleEnum.INFLUENCER
        );

        if (!isInfluencer) {
          toast.warn('Esse identificador não pertence a um influenciador');
          return history.push('/influencers');
        }

        setInfluencer({
          influencerInformation: {
            username: data?.username || 'Não informado',
            email: data?.email || 'Não informado',
            code: data?.code || '',
          },
          personalData: {
            name: data?.name || 'Não informado',
            gender: data?.gender || 'Não informado',
            docNumber: data?.docNumber || 'Não informado',
            phone: data?.phones[0] || 'Não informado',
          },
          avatar: data?.photo?.filename || null,
          status: data?.status,
          roles: data.roles,
        });
        setLoadingInfluencer(false);
      } catch (error) {
        setLoadingInfluencer(false);
      }

      return undefined;
    }

    getInfluencer(params?.id);
  }, [history, params.id]);

  const handleDisableInfluencer = useCallback(async () => {
    const _id = params.id;

    if (!_id) {
      return toast.warn(
        'Ocorreu um problema ao desabilitar esse influenciador, atualize a página e tente novamente!'
      );
    }

    try {
      await new Promise((resolve, reject) => {
        confirm({
          title: 'Deseja realmente despromover esse influenciador?',
          icon: <ExclamationCircleOutlined />,
          content: 'Ele não será mais considerado influenciador na plataforma',
          cancelText: 'Não',
          okText: 'Despromover influenciador',
          okButtonProps: {
            danger: true,
          },
          onOk() {
            resolve(true);
          },
          onCancel() {
            reject(new Error('CANCELED_INFLUENCER_DELETE_ACTION'));
          },
        });
      });

      const rolesWithoutInfluencer = influencer.roles.filter(
        (role) => role !== UserRoleEnum.INFLUENCER
      );

      await InfluencerRequests.updateInfluencer(_id, {
        roles: rolesWithoutInfluencer,
      });

      toast.success('Influenciador despromovido com sucesso');

      setInfluencer({
        ...influencer,
        roles: rolesWithoutInfluencer,
      });

      history.push('/influencers');
    } catch (error) {
      if (error.message === 'CANCELED_INFLUENCER_DELETE_ACTION') {
        return null;
      }
    }

    return null;
  }, [history, params.id, influencer]);

  const handleEnableInfluencer = useCallback(async () => {
    const _id = params.id;

    if (!_id) {
      return toast.warn(
        'Ocorreu um problema ao habilitar esse influenciador, atualize a página e tente novamente!'
      );
    }

    try {
      await new Promise((resolve, reject) => {
        confirm({
          title: 'Deseja realmente habilitar esse influenciador?',
          icon: <ExclamationCircleOutlined />,
          content: 'Ele poderá ter acesso a plataforma Lance Futebol',
          cancelText: 'Não',
          okText: 'Ativar influenciador',
          onOk() {
            resolve(true);
          },
          onCancel() {
            reject(new Error('CANCELED_USER_ENEABLE_ACTION'));
          },
        });
      });

      await InfluencerRequests.updateInfluencer(_id, {
        roles: [...influencer.roles, UserRoleEnum.INFLUENCER],
      });

      toast.success('Influenciador ativo com sucesso');
      setInfluencer({
        ...influencer,
        roles: [...influencer.roles, UserRoleEnum.INFLUENCER],
      });
    } catch (error) {
      if (error.message === 'CANCELED_USER_ENEABLE_ACTION') {
        return null;
      }
    }

    return null;
  }, [params.id, influencer]);

  const currentLocation = useMemo(() => {
    const page = location.pathname;

    let currentLocationName = 'main';

    if (page.includes('/update_avatar')) {
      currentLocationName = 'updateAvatar';
    } else if (
      page.includes('/edit_influencer_information') ||
      page.includes('/edit_personal_data')
    ) {
      currentLocationName = 'edit';
    }

    return currentLocationName;
  }, [location.pathname]);

  const changeStatusUserButton = useMemo(() => {
    if (!influencer) return null;

    const { roles } = influencer;

    const isInfluencer = roles?.find((role) => role === 'influencer');

    if (isInfluencer) {
      return (
        <Button onClick={handleDisableInfluencer} type="text" danger>
          Despromover influenciador
        </Button>
      );
    }
    return (
      <CustomAntButton
        onClick={handleEnableInfluencer}
        type="text"
        useTextPrimary
      >
        Ativar influenciador
      </CustomAntButton>
    );
  }, [handleDisableInfluencer, handleEnableInfluencer, influencer]);

  const mobileChangeStatusUserButton = useMemo(() => {
    if (!influencer) return null;

    const { roles } = influencer;

    const isInfluencer = roles?.find((role) => role === 'influencer');

    if (isInfluencer) {
      return (
        <Button
          onClick={handleDisableInfluencer}
          type="text"
          danger
          icon={<MdBlock size={21} />}
        />
      );
    }
    return (
      <CustomAntButton
        onClick={handleEnableInfluencer}
        type="text"
        useTextPrimary
        icon={<IoMdCheckmarkCircle size={21} />}
      />
    );
  }, [handleDisableInfluencer, handleEnableInfluencer, influencer]);

  if (loadingInfluencer) {
    return (
      <Container>
        <Spin
          style={{
            alignSelf: 'center',
            marginTop: 'auto',
            marginBottom: 'auto',
          }}
          indicator={antIcon}
        />
      </Container>
    );
  }

  if (!influencer) {
    return (
      <Container>
        <UserNotFoundContainer>
          <Breadcrums />
          <div>
            <img src={NotFoundImage} alt="Not Found" />
            <h6>Esse influenciador não foi encontrado</h6>
          </div>
        </UserNotFoundContainer>
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        <Breadcrums />
        <div>
          <ImageWrapper
            onClick={() => {
              if (currentLocation === 'main') {
                setAvatarModalVisible(true);
              }
            }}
            $clickEnabled={currentLocation === 'main'}
          >
            <img
              src={
                influencer?.avatar
                  ? UploadRequests.getFileUrl(influencer?.avatar)
                  : DefaultAvatar
              }
              alt={influencer?.personalData?.name || 'Avatar'}
            />
          </ImageWrapper>
          <UserInformationHeader>
            <div>
              <div>
                <h5>{influencer?.personalData?.name}</h5>
                {influencer.status === 0 && <span>Inativo</span>}
              </div>
              <p>{influencer?.influencerInformation?.username}</p>
              <p>{influencer?.influencerInformation?.email}</p>
            </div>
            {currentLocation === 'main' && changeStatusUserButton}
          </UserInformationHeader>
          {currentLocation === 'main' && mobileChangeStatusUserButton}
        </div>
      </Header>
      <Content>
        <Switch>
          <Route exact path={routeMatch.path}>
            <Details influencer={influencer} />
          </Route>
          <Route path={`${routeMatch.path}/update_avatar`}>
            <UpdateAvatar
              influencer={influencer}
              setInfluencer={setInfluencer}
            />
          </Route>
          <Route path={`${routeMatch.path}/edit_influencer_information`}>
            <EditUserInformation
              influencer={influencer}
              setInfluencer={setInfluencer}
              parentRouteMatch={routeMatch}
            />
          </Route>
          <Route path={`${routeMatch.path}/edit_personal_data`}>
            <EditPersonalData
              influencer={influencer}
              setInfluencer={setInfluencer}
              parentRouteMatch={routeMatch}
            />
          </Route>
        </Switch>
      </Content>
      <Modal
        title="Avatar do influenciador"
        visible={avatarModalVisible}
        onCancel={() => {
          setAvatarModalVisible(false);
        }}
        okButtonProps={{
          style: {
            display: 'none',
          },
        }}
        cancelText="Voltar"
      >
        <Link
          to={`${routeMatch.url}/update_avatar`}
          onClick={() => setAvatarModalVisible(false)}
        >
          <ActionButtonAvatar>
            <MdEdit size={24} />
            <span>Atualizar avatar</span>
          </ActionButtonAvatar>
        </Link>
      </Modal>
    </Container>
  );
};

export default Influencer;
