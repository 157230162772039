import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin-top: 40px;
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h5 {
    margin-bottom: 0;
  }
`;
