import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Button, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { setHours, setMinutes, setSeconds } from 'date-fns';
import moment from 'moment';

import { Container, TitlePageContainer, Content } from './styles';

import Steps from '@/components/Steps';

import api from '@/services/api';

import MatchCardInformations from './MatchCardInformations';
import Games from './Games';
import Banner from './Banner';
import Confirm from './Confirm';
import { UploadRequests } from '@/services/api/requests/Upload';

const { confirm } = Modal;

function New() {
  const history = useHistory();
  const { params } = useRouteMatch();
  const [currentStep, setCurrentStep] = useState(0);
  const [focusedField, setFocusedField] = useState(null);

  const [filterDate, setFilterDate] = useState(
    moment(new Date(), 'DD/MM/YYYY')
  );

  const [matchsOfChampionship, setMatchsOfChampionship] = useState([]);
  const [loadingMatchsOfChampionship, setLoadingMatchsOfChampionship] =
    useState(false);
  const [matchsOfChampionshipPagination, setMatchsOfChampionshipPagination] =
    useState({
      currentPage: 1,
      totalPages: 0,
    });

  const unblockPage = useMemo(() => {
    const messageComponents = {
      title: 'Deseja realmente cancelar o cadastro dessa cartela?',
      content: 'Todos os dados inseridos serão perdidos',
      cancelText: 'Voltar',
      okText: 'Cancelar',
    };

    return history.block(JSON.stringify(messageComponents));
  }, [history]);

  function handleBeforeUnload(e) {
    e.returnValue = '';
  }

  const handleGetMatchsOfChampionship = useCallback(
    async ({ fromThisDate = null, page = 1 }) => {
      if (fromThisDate && page === 1) {
        setMatchsOfChampionship([]);
      }
      setLoadingMatchsOfChampionship(true);

      function removeHoursFromDate(date) {
        return setHours(setMinutes(setSeconds(date, 0), 0), 0);
      }

      const queryParams = {
        page,
        limit: 10,
        date: removeHoursFromDate(new Date()),
      };

      if (fromThisDate) {
        queryParams.date = removeHoursFromDate(fromThisDate.toDate());
      }

      try {
        const { data } = await api.get(`/api/championship/${params.id}/games`, {
          params: queryParams,
        });

        const matchsWithAbbrevOfTeams = data.docs.map((match) => ({
          ...match,
          local: {
            ...match.local,
            abbrev: match.local.name.substring(0, 3).toUpperCase(),
          },
          away: {
            ...match.away,
            abbrev: match.away.name.substring(0, 3).toUpperCase(),
          },
        }));

        if (page === 1) {
          setMatchsOfChampionship(matchsWithAbbrevOfTeams);
        } else {
          setMatchsOfChampionship((oldMatchs) => [
            ...oldMatchs,
            ...matchsWithAbbrevOfTeams,
          ]);
        }

        setMatchsOfChampionshipPagination({
          currentPage: data.page,
          totalPages: data.pages,
        });
      } catch (error) {
        toast.error(
          'Ocorreu um erro ao carregar os jogos desse campeonato. Recarregue a página!'
        );
      }
      setLoadingMatchsOfChampionship(false);
    },
    [params.id]
  );

  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      unblockPage();
    };
  }, [unblockPage]);

  useEffect(() => {
    handleGetMatchsOfChampionship({});
  }, [handleGetMatchsOfChampionship]);

  const handleToogleCurrentStep = useCallback((newCurrentStep) => {
    if (newCurrentStep < 0) {
      newCurrentStep = 0;
    }

    setCurrentStep(newCurrentStep);
  }, []);

  async function handleSubmit(values) {
    await new Promise((resolve) => {
      confirm({
        title: 'Deseja realmente criar essa cartela?',
        icon: <ExclamationCircleOutlined />,
        content:
          'As informações adicionadas não poderão ser alteradas posteriormente',
        cancelText: 'Cancelar',
        okText: 'Criar cartela',
        onOk() {
          resolve(true);
        },
      });
    });

    const { matchCardInformations, games, banner } = values;

    let photoId = null;
    if (banner.file) {
      try {
        const { data } = await UploadRequests.upload({
          file: banner.file,
          from: 'matchCardGameAvatar',
        });

        photoId = data._id;
      } catch (error) {
        console.log(error);

        toast.error(
          'Aconteceu um erro inesperado ao enviar o banner da cartela!'
        );

        return;
      }
    }

    const body = {
      _champ: params.id,
      name: matchCardInformations.name,
      price: matchCardInformations.price,
      games: games.map((match) => ({ game: match._id })),
      banner: photoId,
    };

    try {
      await api.post('/api/card-game', body);

      toast.success('Cartela criada com sucesso!');

      window.removeEventListener('beforeunload', handleBeforeUnload);
      unblockPage();

      history.push(`/championships/${params.id}/cards`);
    } catch (error) {
      toast.error('Aconteceu um erro inesperado ao cadastrar a cartela!');
    }
  }

  return (
    <Container>
      {/* <Breadcrums /> */}
      <TitlePageContainer>
        <h4>Nova cartela</h4>
        <Button
          onClick={() => history.push(`/championships/${params.id}/cards`)}
          danger
          type="text"
        >
          Cancelar
        </Button>
      </TitlePageContainer>
      <Content>
        <Steps
          current={currentStep}
          stepsList={[
            { title: 'Informações da cartela' },
            { title: 'Partidas' },
            { title: 'Banner' },
            { title: 'Confirmar' },
          ]}
        />
        <Formik
          initialValues={{
            matchCardInformations: {
              name: '',
              price: '',
            },
            games: [],
            banner: {
              file: null,
              previewUrl: '',
            },
          }}
          validationSchema={Yup.object().shape({
            matchCardInformations: Yup.object().shape({
              name: Yup.string().required('O nome da cartela é obrigatório'),
              price: Yup.number().required(
                'O valor do jogo na cartela é obrigatório'
              ),
            }),
            games: Yup.array()
              .of(
                Yup.object().shape({
                  _id: Yup.string().required(
                    'O identificador do jogo é obrigatório'
                  ),
                })
              )
              .min(1, 'Ao menos 1 jogo deve ser adicionado na cartela'),
          })}
          onSubmit={handleSubmit}
          validateOnMount
        >
          {(formikProps) => (
            <form onSubmit={formikProps.handleSubmit}>
              {currentStep === 0 && (
                <MatchCardInformations
                  formik={formikProps}
                  nextStep={() => handleToogleCurrentStep(1)}
                  setFocusedField={setFocusedField}
                />
              )}
              {currentStep === 1 && (
                <Games
                  formik={formikProps}
                  prevStep={() => handleToogleCurrentStep(0)}
                  nextStep={() => handleToogleCurrentStep(2)}
                  setFocusedField={setFocusedField}
                  filterDate={filterDate}
                  handleChangeFilterDate={(value) => {
                    setFilterDate(value);
                  }}
                  handleGetMatchsOfChampionship={handleGetMatchsOfChampionship}
                  matchsOfChampionship={matchsOfChampionship}
                  loadingMatchsOfChampionship={loadingMatchsOfChampionship}
                  matchsOfChampionshipPagination={
                    matchsOfChampionshipPagination
                  }
                />
              )}
              {currentStep === 2 && (
                <Banner
                  formik={formikProps}
                  prevStep={() => handleToogleCurrentStep(1)}
                  nextStep={() => handleToogleCurrentStep(3)}
                />
              )}
              {currentStep === 3 && (
                <Confirm
                  formik={formikProps}
                  prevStep={() => handleToogleCurrentStep(2)}
                  onSubmit={formikProps.handleSubmit}
                />
              )}
            </form>
          )}
        </Formik>
      </Content>
    </Container>
  );
}

export default New;
