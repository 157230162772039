import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100vh;
  background: ${props => props.theme.colors.light['light-1']};
  width: 100%;
  display: flex;

  /* @media only screen and (max-width: 768px) { */
  @media only screen and (max-width: 1199px) {
    flex-direction: column;
  }
`;

export const ContentPage = styled.div`
  flex: 1;
  flex-grow: 5;
  overflow: auto;

  /* @media only screen and (max-width: 768px) { */
  @media only screen and (max-width: 1199px) {
    width: 100%;
  }
`;
