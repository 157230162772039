import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  > div:nth-child(1) {
    width: 100%;
    margin-bottom: 32px;
  }
`;

export const SessionHeader = styled.div`
  width: 100%;
  display: flex;
  max-width: 424px;
  justify-content: space-between;
  align-items: center;

  /* AntDesign Button with Icon */
  button {
    line-height: 0;
    color: ${props => props.theme.colors.primary.primary0};

    &:hover,
    &:focus,
    &:active {
      color: ${props => props.theme.colors.primary['primary-1']};
    }
  }
`;

export const SessionItems = styled.ul`
  width: 100%;
  margin-top: 16px;

  li {
    list-style: none;
    width: 100%;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0px;
    }

    small {
      color: ${props => props.theme.colors.primary.primary0};
    }
  }
`;
