import React, { useCallback } from 'react';
import { FormikProps } from 'formik';
import { Button } from 'antd';

import AvatarCropper from '@/components/AvatarCropper';

import { ButtonsContainer } from '../styles';
import { IAvatarProps } from './types';
import { INewInfluencerFormData } from '../types';

const Avatar: React.FC<IAvatarProps> = ({ formik, nextStep, prevStep }) => {
  const checkIfIsNextButtonEnabled = useCallback(
    ({ values }: FormikProps<INewInfluencerFormData>) => {
      if (values?.avatar?.file) {
        return true;
      }

      return false;
    },
    []
  );

  return (
    <>
      <AvatarCropper
        croppedAvatar={formik?.values?.avatar}
        setCroppedAvatar={({
          file,
          previewUrl,
        }: INewInfluencerFormData['avatar']): void => {
          formik.setFieldValue('avatar.file', file);
          formik.setFieldValue('avatar.previewUrl', previewUrl);
        }}
        setHaveChanges={() => ({})}
        required
        selectImageButtonLabel="Selecione uma imagem para adicionar como avatar do influenciador"
      />
      <ButtonsContainer>
        <Button onClick={prevStep} type="default">
          Voltar
        </Button>
        <Button
          onClick={nextStep}
          type="primary"
          disabled={!checkIfIsNextButtonEnabled(formik)}
        >
          Próximo
        </Button>
      </ButtonsContainer>
    </>
  );
};

export default Avatar;
