import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import { toast } from 'react-toastify';

import { UploadRequests } from '@/services/api/requests/Upload';
import api from '@/services/api';
import AvatarCropper from '@/components/AvatarCropper';
import useBeforeUnload from '@/hooks/useBeforeUnload';
import { Container, TitlePageContainer, ButtonsContainer } from './styles';

function UpdateAvatar({ team, setTeam }) {
  const history = useHistory();

  const [newAvatar, setNewAvatar] = useState({
    file: null,
    previewUrl: '',
  });
  const [haveChanges, setHaveChanges] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useBeforeUnload({
    when: haveChanges,
    message: 'Deseja realmente sair? As alterações serão perdidas',
  });

  const unblockPage = useMemo(() => {
    const messageComponents = {
      title: 'Deseja realmente cancelar a atualização da imagem?',
      content: 'Todos as alterações serão perdidas',
      cancelText: 'Voltar',
      okText: 'Cancelar',
    };

    if (haveChanges) {
      return history.block(JSON.stringify(messageComponents));
    }
    return () => {};
  }, [haveChanges, history]);

  useEffect(() => {
    return () => {
      unblockPage();
    };
  }, [unblockPage]);

  const handleSubmit = useCallback(async () => {
    const { file } = newAvatar;

    if (!file) {
      toast.warn(
        'Aconteceu um erro inesperado ao atualizar a imagem do time! Atualize a página'
      );
      return;
    }

    setSubmitting(true);

    let newPhotoId = null;

    try {
      const { data } = await UploadRequests.upload({
        file,
        from: 'teamImage',
      });

      newPhotoId = data._id;
    } catch (error) {
      setSubmitting(false);
      toast.error(
        'Aconteceu um erro inesperado ao atualizar a imagem do time!'
      );

      return;
    }

    const body = {
      image: UploadRequests.getFileUrl(newPhotoId),
    };

    try {
      await api.put(`/api/team/${team._id}`, body);

      toast.success('Imagem do time atualizada com sucesso');
      setTeam({ ...team, image: body.image });
      setSubmitting(false);
      unblockPage();

      history.goBack();
    } catch (error) {
      console.log(error);
      toast.error(
        'Aconteceu um erro inesperado ao atualizar a imagem do time!'
      );
      setSubmitting(false);
    }
  }, [newAvatar, setTeam, team, unblockPage, history]);

  return (
    <Container>
      <TitlePageContainer>
        <h5>Atualizar imagem</h5>
        <Button onClick={() => history.goBack()} danger type="text">
          Cancelar
        </Button>
      </TitlePageContainer>
      <AvatarCropper
        croppedAvatar={newAvatar}
        setCroppedAvatar={setNewAvatar}
        setHaveChanges={setHaveChanges}
        selectImageButtonLabel="Selecione uma imagem para adicionar para o time (.png sem fundo)"
      />
      {newAvatar?.previewUrl && (
        <ButtonsContainer>
          <Button onClick={handleSubmit} disabled={submitting} type="primary">
            Atualizar imagem
          </Button>
        </ButtonsContainer>
      )}
    </Container>
  );
}

export default UpdateAvatar;
