import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
  padding: 3rem 2rem;

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    padding: 2rem 1rem;
    min-height: calc(100vh - 61px);
  }
`;

export const TitlePageContainer = styled.div`
  width: 100%;
  display: flex;
  margin: 32px 0px;
  justify-content: space-between;
  align-items: center;

  /* AntD Button Changes */
  button {
    margin-left: 16px;

    @media only screen and (min-width: 601px) {
      display: none;
    }
  }
`;

export const TopicOptionsContainer = styled.div`
  width: 100%;
  max-width: ${(props) => props.theme.breakpoints.sm};

  & + & {
    margin-top: 2rem;
  }

  h6 {
    width: 100%;
    color: ${(props) => props.theme.colors.dark.dark0};
    margin: 0 0 1rem;
  }
`;

export const TopicOptionsHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const TopicOptionsList = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  list-style: none;

  li {
    width: 100%;
    padding: 0.5rem 0;
    display: flex;
    gap: 1rem;
    align-items: center;

    & + li {
      border-top: 1px solid ${(props) => props.theme.colors.light['light-2']};
    }

    > div:first-child {
      flex: 1;
      p {
        width: 100%;
        color: ${(props) => props.theme.colors.dark['dark+1']};
        margin-bottom: 0.25rem;
      }

      small {
        color: ${(props) => props.theme.colors.primary.primary0};
      }
    }
  }
`;

export const OptionActionButtonsContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;
