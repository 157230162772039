import React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import {
  Container,
  TitlePageContainer,
  Content,
  SettingItem,
  AdminIcon,
  AdvertisementIcon,
  CardOptionsIcon,
  ManageGamesPaymentIcon,
} from './styles';

function Settings() {
  const history = useHistory();
  const { url } = useRouteMatch();

  return (
    <Container>
      <TitlePageContainer>
        <h4>Configurações</h4>
      </TitlePageContainer>
      <Content>
        <SettingItem onClick={() => history.push(`${url}/administrators`)}>
          <div>
            <AdminIcon />
            <h5>Administradores</h5>
          </div>
          <p>Gerencie os administradores da plataforma ExGool</p>
        </SettingItem>
        <SettingItem onClick={() => history.push(`${url}/advertisement`)}>
          <div>
            <AdvertisementIcon />
            <h5>Publicidade</h5>
          </div>
          <p>Gerencie a publicidade da plataforma ExGool</p>
        </SettingItem>
        <SettingItem onClick={() => history.push(`${url}/card_options`)}>
          <div>
            <CardOptionsIcon />
            <h5>Opções das cartelas</h5>
          </div>
          <p>Gerencie a opções disponíveis nas cartelas</p>
        </SettingItem>
        <SettingItem
          onClick={() => history.push(`${url}/manage-games-payment`)}
        >
          <div>
            <ManageGamesPaymentIcon />
            <h5>Pagamento dos jogos</h5>
          </div>
          <p>Gerencie as porcentagens nos pagamentos dos jogos</p>
        </SettingItem>
      </Content>
    </Container>
  );
}

export default Settings;
