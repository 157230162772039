import styled from 'styled-components';
import { AutoComplete as AntAutoComplete } from 'antd';
import Loading from '@/components/Loading';
import { darken } from 'polished';

export const Container = styled.div`
  width: 100%;
  max-width: 30rem;
  margin-top: 1rem;

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    max-width: 100%;
  }
`;

export const AutoComplete = styled(AntAutoComplete)`
  width: 100%;
`;

export const SearchAutocompleteContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;

  p {
    margin-left: 1rem;
    color: ${(props) => props.theme.colors.dark.dark0};
    text-align: center;
  }
`;

export const LoadingInfluencer = styled(Loading)`
  margin-top: 0;
`;

export const SearchInfluencerItem = styled.div`
  border: 0;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.5rem 0;
  transition: 0.2s background;

  & + button {
    border-top: 1px solid ${(props) => props.theme.colors.dark['dark+1']};
  }

  img {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 0.5rem;
  }

  div {
    text-align: start;
    small {
      color: ${(props) => props.theme.colors.dark['dark+3']};
    }
  }

  @media only screen and (min-width: ${(props) => props.theme.breakpoints.xl}) {
    padding: 0.5rem;
  }
`;

export const ViewMoreButton = styled.button`
  border: 0;
  background: ${(props) => props.theme.colors.light.light0};
  width: 100%;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s background;
  color: ${(props) => props.theme.colors.dark['dark+1']};
  border-top: 1px solid ${(props) => props.theme.colors.light['light-2']};

  &:disabled {
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background: ${(props) => darken(0.05, props.theme.colors.light.light0)};
  }
`;

export const SelectedInfluencersContainer = styled.div`
  margin-top: 1rem;
`;

export const SelectedInfluencer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1rem;
  transition: 0.2s background;
  margin-top: 0.5rem;
  background: ${(props) => props.theme.colors.light.light0};

  &:not(:first-child) {
    border-radius: 0.5rem;
  }

  img {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 0.5rem;
  }

  > div {
    text-align: start;
    flex: 1;
    small {
      color: ${(props) => props.theme.colors.dark['dark+3']};
    }
  }
`;
