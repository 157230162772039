import styled from 'styled-components';

export const Container = styled.div`
  /* width: min-content; */
  border: 0;
  text-align: start;
  max-width: calc(100vw - 20vw - 40px - 16px);
  border-radius: 8px;
  border-bottom-left-radius: 0;
  padding: 8px 8px 4px;
  background: ${props => props.theme.colors.light['light-2']};
  /* margin-right: auto; */

  & + div {
    margin-top: 4px;
  }

  > p {
    color: ${props => props.theme.colors.dark['dark+1']};
    width: 100%;
  }
`;

export const UserName = styled.small`
  width: 100%;
  font-weight: ${props => props.theme.typograph.weights.bold};
  color: ${props => props.theme.colors.primary['primary+1']};
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const HourAndMessageStatusContainer = styled.div`
  width: 100%;
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  small {
    font-size: 12px;
    color: ${props => props.theme.colors.dark['dark+4']};
  }
`;

export const SelectedForReportMessageGroupContainer = styled.div`
  display: flex;
  align-items: flex-end;

  > img {
    width: 32px;
    height: 32px;
    margin-right: 8px;
    border-radius: 50%;
  }

  > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const MessageAudioPlayerContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  cursor: default;
`;
export const PlayOrStopButton = styled.button`
  border: 0;
  background: transparent;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: ${props => props.theme.colors.dark['dark+1']};
  }
`;
export const MessageAudioDurationAndCurrentTime = styled.small`
  font-size: 12px;
  color: ${props => props.theme.colors.light['light-2']};
  margin-right: auto;
  margin-left: 32px;
`;
