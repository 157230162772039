import React, { useState, useEffect, useCallback, useMemo } from 'react';
import {
  useParams,
  useHistory,
  useRouteMatch,
  useLocation,
  Link,
  Switch,
  Route,
} from 'react-router-dom';
import { Spin, Modal, Dropdown, Menu } from 'antd';
import { toast } from 'react-toastify';
import { LoadingOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { IoMdCheckmarkCircle } from 'react-icons/io';
import { MdEdit, MdBlock, MdOutlineStar } from 'react-icons/md';
import { FiMoreVertical } from 'react-icons/fi';
import { FaUserCog } from 'react-icons/fa';

import Breadcrumbs from '@/components/Breadcrumbs';
import CustomAntButton from '@/components/CustomAntButton';
import { InfluencerRequests } from '@/services/api/requests/Influencer';
import api from '@/services/api';
import { UploadRequests } from '@/services/api/requests/Upload';
import { UserRoleEnum } from '@/models/User';
import NotFoundImage from '@/assets/NotFound.svg';
import DefaultAvatar from '@/assets/DefaultAvatar.svg';

import {
  Container,
  Header,
  Content,
  ImageWrapper,
  UserInformationsHeader,
  UserNotFoundContainer,
  ActionButtonAvatar,
} from './styles';

import Details from './Details';
import UpdateAvatar from './UpdateAvatar';
import EditUserInformations from './EditUserInformations';
import EditPersonalData from './EditPersonalData';
import { IRouteParams, IUser, IUserState } from './types';

const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;
const { confirm } = Modal;

const User: React.FC = () => {
  const params = useParams<IRouteParams>();
  const history = useHistory();
  const routeMatch = useRouteMatch();
  const location = useLocation();

  const [user, setUser] = useState<IUserState>({} as IUserState);
  const [loadingUser, setLoadingUser] = useState(true);
  const [avatarModalVisible, setAvatarModalVisible] = useState(false);

  useEffect(() => {
    async function getUser(_id: string): Promise<void> {
      try {
        const { data } = await api.get<IUser>(`/api/user-admin/${_id}`);

        const isUser = !!data?.roles.find(
          (role: UserRoleEnum) => role === UserRoleEnum.USER
        );

        if (!isUser) {
          toast.warn('Esse identificador não pertence a um usuário');
          return history.push('/users');
        }

        setUser({
          userInformation: {
            username: data?.username || 'Não informado',
            email: data?.email || 'Não informado',
          },
          personalData: {
            name: data?.name || 'Não informado',
            gender: data?.gender || 'Não informado',
            docNumber: data?.docNumber || 'Não informado',
            phone: data?.phones[0] || 'Não informado',
          },
          avatar: data?.photo?.filename || null,
          status: data?.status,
          roles: data.roles,
        });

        setLoadingUser(false);
      } catch (error) {
        setLoadingUser(false);
      }

      return undefined;
    }

    getUser(params?.id);
  }, [history, params.id]);

  const handleDisableUser = useCallback(async () => {
    const _id = params.id;

    if (!_id) {
      return toast.warn(
        'Ocorreu um problema ao desabilitar esse usuário, atualize a página e tente novamente!'
      );
    }

    try {
      await new Promise((resolve, reject) => {
        confirm({
          title: 'Deseja realmente desabilitar esse usuário?',
          icon: <ExclamationCircleOutlined />,
          content: 'Ele não terá mais acesso a plataforma Lance Futebol',
          cancelText: 'Não',
          okText: 'Desativar usuário',
          okButtonProps: {
            danger: true,
          },
          onOk() {
            resolve(true);
          },
          onCancel() {
            reject(new Error('CANCELED_USER_DELETE_ACTION'));
          },
        });
      });

      await api.delete(`/api/user-admin/${_id}`);

      toast.success('Usuário desativado com sucesso');
      setUser({ ...user, status: 0 });
      // history.push('/users');
    } catch (error) {
      if (error.message === 'CANCELED_USER_DELETE_ACTION') {
        return null;
      }
    }

    return null;
  }, [params.id, user]);

  const handleEnableUser = useCallback(async () => {
    const _id = params.id;

    if (!_id) {
      return toast.warn(
        'Ocorreu um problema ao desabilitar esse usuário, atualize a página e tente novamente!'
      );
    }

    try {
      await new Promise((resolve, reject) => {
        confirm({
          title: 'Deseja realmente habilitar esse usuário?',
          icon: <ExclamationCircleOutlined />,
          content: 'Ele poderá ter acesso a plataforma Lance Futebol',
          cancelText: 'Não',
          okText: 'Ativar usuário',
          onOk() {
            resolve(true);
          },
          onCancel() {
            reject(new Error('CANCELED_USER_ENEABLE_ACTION'));
          },
        });
      });

      await api.put(`/api/user-admin/${_id}`, {
        status: 1,
      });

      toast.success('Usuário ativo com sucesso');
      setUser({ ...user, status: 1 });
    } catch (error) {
      if (error.message === 'CANCELED_USER_ENEABLE_ACTION') {
        return null;
      }
    }

    return null;
  }, [params.id, user]);

  const handleTurnAdmin = useCallback(async () => {
    const isAdmin = !!user?.roles.find((role) => role === UserRoleEnum.ADMIN);

    if (isAdmin) return;

    try {
      await new Promise((resolve, reject) => {
        confirm({
          title: 'Deseja realmente tornar esse usuário um Admin?',
          icon: <ExclamationCircleOutlined />,
          cancelText: 'Cancelar',
          okText: 'Tornar admin',
          onOk() {
            resolve(true);
          },
          onCancel() {
            reject(new Error('CANCELED_TURN_ADMIN_ACTION'));
          },
        });
      });

      await api.put(`/api/user-admin/${params.id}`, {
        roles: [...user.roles, UserRoleEnum.ADMIN],
      });

      toast.success('Dados de usuário atualizados!');
      setUser({ ...user, roles: [...user.roles, UserRoleEnum.ADMIN] });
    } catch (error) {
      //
    }
  }, [params.id, user]);

  const handleTurnInfluencer = useCallback(async () => {
    const isInfluencer = !!user?.roles.find(
      (role) => role === UserRoleEnum.INFLUENCER
    );

    if (isInfluencer) return;

    try {
      await new Promise((resolve, reject) => {
        confirm({
          title: 'Deseja realmente tornar esse usuário um Influenciador?',
          icon: <ExclamationCircleOutlined />,
          cancelText: 'Cancelar',
          okText: 'Tornar influenciador',
          onOk() {
            resolve(true);
          },
          onCancel() {
            reject(new Error('CANCELED_TURN_INFLUENCER_ACTION'));
          },
        });
      });

      await InfluencerRequests.updateInfluencer(params.id, {
        roles: [...user.roles, UserRoleEnum.INFLUENCER],
      });

      toast.success('Dados de usuário atualizados!');

      history.push(`/influencers/${params.id}/edit_influencer_information`);

      toast.warn('Preencha o código do influenciador!');

      setUser({ ...user, roles: [...user.roles, UserRoleEnum.INFLUENCER] });
    } catch (error) {
      //
    }
  }, [history, params.id, user]);

  const currentLocation = useMemo(() => {
    const page = location.pathname;

    let currentLocationName = 'main';

    if (page.includes('/update_avatar')) {
      currentLocationName = 'updateAvatar';
    } else if (
      page.includes('/edit_user_informations') ||
      page.includes('/edit_personal_data')
    ) {
      currentLocationName = 'edit';
    }

    return currentLocationName;
  }, [location.pathname]);

  const userRolesDropdownMenu = useMemo(() => {
    if (!user) return null;

    if (user.roles) {
      const isAdmin = !!user?.roles.find((role) => role === UserRoleEnum.ADMIN);
      const isInfluencer = !!user?.roles.find(
        (role) => role === UserRoleEnum.INFLUENCER
      );

      const { status } = user;

      return (
        <Dropdown
          overlay={
            <Menu>
              {status ? (
                <Menu.Item danger onClick={handleDisableUser}>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <MdBlock style={{ marginRight: 8 }} size={19} />
                    Desativar usuário
                  </div>
                </Menu.Item>
              ) : (
                <Menu.Item onClick={handleEnableUser}>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <IoMdCheckmarkCircle style={{ marginRight: 8 }} size={19} />
                    Ativar usuário
                  </div>
                </Menu.Item>
              )}
              {!isAdmin && (
                <Menu.Item onClick={handleTurnAdmin}>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <FaUserCog style={{ marginRight: 8 }} size={19} />
                    Tornar Admin
                  </div>
                </Menu.Item>
              )}

              {!isInfluencer && (
                <Menu.Item onClick={handleTurnInfluencer}>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <MdOutlineStar style={{ marginRight: 8 }} size={19} />
                    Tornar influencer
                  </div>
                </Menu.Item>
              )}
            </Menu>
          }
          placement="bottomRight"
          arrow
        >
          <CustomAntButton type="ghost" icon={<FiMoreVertical height={18} />} />
        </Dropdown>
      );
    }
    return null;
  }, [
    handleDisableUser,
    handleEnableUser,
    handleTurnAdmin,
    handleTurnInfluencer,
    user,
  ]);

  if (loadingUser) {
    return (
      <Container>
        <Spin
          style={{
            alignSelf: 'center',
            marginTop: 'auto',
            marginBottom: 'auto',
          }}
          indicator={antIcon}
        />
      </Container>
    );
  }

  if (!user) {
    return (
      <Container>
        <UserNotFoundContainer>
          <Breadcrumbs />
          <div>
            <img src={NotFoundImage} alt="Not Found" />
            <h6>Esse usuário não foi encontrado</h6>
          </div>
        </UserNotFoundContainer>
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        <Breadcrumbs />
        <div>
          <ImageWrapper
            onClick={() => {
              if (currentLocation === 'main') {
                setAvatarModalVisible(true);
              }
            }}
            $clickEnabled={currentLocation === 'main'}
          >
            <img
              src={
                user?.avatar
                  ? UploadRequests.getFileUrl(user?.avatar)
                  : DefaultAvatar
              }
              alt={user?.personalData?.name || 'Avatar'}
            />
          </ImageWrapper>
          <UserInformationsHeader>
            <div>
              <div>
                <h5>{user?.personalData?.name}</h5>
                {user.status === 0 && <span>Inativo</span>}
              </div>
              <p>{user?.userInformation?.username}</p>
              <p>{user?.userInformation?.email}</p>
            </div>
            {currentLocation === 'main' && userRolesDropdownMenu}
          </UserInformationsHeader>
          {currentLocation === 'main' && userRolesDropdownMenu}
        </div>
      </Header>
      <Content>
        <Switch>
          <Route exact path={routeMatch.path}>
            <Details user={user} />
          </Route>
          <Route path={`${routeMatch.path}/update_avatar`}>
            <UpdateAvatar user={user} setUser={setUser} />
          </Route>
          <Route path={`${routeMatch.path}/edit_user_informations`}>
            <EditUserInformations
              user={user}
              setUser={setUser}
              parentRouteMatch={routeMatch}
            />
          </Route>
          <Route path={`${routeMatch.path}/edit_personal_data`}>
            <EditPersonalData
              user={user}
              setUser={setUser}
              parentRouteMatch={routeMatch}
            />
          </Route>
        </Switch>
      </Content>
      <Modal
        title="Avatar do usuário"
        visible={avatarModalVisible}
        onCancel={() => {
          setAvatarModalVisible(false);
        }}
        okButtonProps={{
          style: {
            display: 'none',
          },
        }}
        cancelText="Voltar"
      >
        <Link
          to={`${routeMatch.url}/update_avatar`}
          onClick={() => setAvatarModalVisible(false)}
        >
          <ActionButtonAvatar>
            <MdEdit size={24} />
            <span>Atualizar avatar</span>
          </ActionButtonAvatar>
        </Link>
      </Modal>
    </Container>
  );
};

export default User;
