import { darken } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  background: ${props => props.theme.colors.light.light0};
  box-shadow: 1px 1px 3px 3px rgba(0, 0, 0, 0.05);
  margin-top: 16px;
`;
export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;
export const Content = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  > div {
    width: 48%;

    @media only screen and (max-width: 768px) {
      width: 100%;

      &:nth-child(2) {
        margin-top: 32px;
      }
    }

    > div {
      width: 100%;
      display: flex;
      align-items: center;
      padding-bottom: 8px;
      border-bottom: 1px solid ${props => props.theme.colors.light['light-1']};
      margin-bottom: 8px;
    }

    > ul {
      margin-bottom: 0;

      > p {
        width: 100%;
        color: ${props => props.theme.colors.dark['dark+3']};
        text-align: center;
        margin-top: 16px;
      }
    }
  }
`;
export const TeamImageContainer = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${props => props.theme.colors.light['light-1']};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;

  img {
    height: 26px;
  }
`;
export const ThrowContainer = styled.li`
  display: flex;
  flex-direction: column;
  list-style: none;

  & + li {
    margin-top: 8px;
  }

  > small {
    color: ${props => props.theme.colors.dark['dark+3']};
    margin-bottom: 4px;
  }
`;
export const SelectPlayerOfThrowButton = styled.button`
  border: none;
  background: ${props => props.theme.colors.light['light-2']};
  width: 100%;
  padding: 8px 16px;
  border-radius: 8px;
  transition: 0.2s opacity;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.05);
  border: 1px solid ${props => props.theme.colors.light['light-2']};

  ${props =>
    props.selected &&
    css`
      border: 1px solid ${props.theme.colors.primary.primary0};
    `}

  &:hover {
    opacity: 0.75;
  }

  > p {
    color: ${props => props.theme.colors.dark['dark+2']};
    width: 100%;
    text-align: center;
  }

  > div {
    display: flex;
    width: 100%;
    align-items: center;

    p:nth-child(1) {
      color: ${props => props.theme.colors.light['dark+1']};
      flex: 1;
      text-align: start;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    p:nth-child(2) {
      color: ${props => props.theme.colors.primary['primary-1']};
      margin-left: 16px;
      flex-shrink: 0;
    }
  }
`;
export const AddThrowContentModal = styled.div`
  width: 100%;
  /* ScreenHeight - Distance between top/bottom - Header Height - ModalBodyPadding Top/Bottom - Footer Height */
  height: calc(100vh - 40px - 55px - 48px - 53px);
  /* > div:nth-child(1) {
    padding: 8px 0;
    border-bottom: 1px solid ${props => props.theme.colors.dark['dark+2']};
    margin-bottom: 24px;

    p {
      strong {
        color: ${props => props.theme.colors.light['light-2']};
      }

      color: ${props => props.theme.colors.primary['primary+1']};
    }
  } */

  /* > div:nth-child(2), */
  /* > div:nth-child(3) { */
  > div {
    &:nth-child(1) {
      margin-bottom: 16px;
    }

    &:nth-child(3) {
      /* ParentContentHeight - FirstDivHeight(withMarginBottom) - SecondDivHeight(withMarginBottomOfSlider) */
      height: calc(100% - 66px - 54px);

      ul {
        margin-bottom: 0;
        overflow-y: scroll;
        /* ParentHeight - SmallElementHeight(withMarginBottom) */
        height: calc(100% - 30px);
      }
    }
    
    > small {
      color: ${props => props.theme.colors.dark['dark+2']};
      margin-bottom: 8px;
    }
  }

  /* > div:nth-child(3) {
    small {
      color: ${props => props.theme.colors.light['light-2']};
    }
  } */
`;
export const TeamPlayerSelector = styled.button`
  width: 100%;
  padding: 8px;
  background: transparent;
  border: 0;
  transition: 0.2s background;
  display: flex;
  align-items: center;

  &:hover {
    background: ${props => darken(0.05, props.theme.colors.light.light0)};
  }

  button + & {
    border-top: 1px solid ${props => props.theme.colors.light['light-2']};
  }

  div {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    strong {
      font-size: inherit;
      color: inherit;
    }

    small {
      color: ${props => props.theme.colors.dark['dark+4']};
    }
  }

  svg {
    color: ${props => props.theme.colors.primary.primary0};
  }
`;
