import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import api from '@/services/api';
import Breadcrumbs from '@/components/Breadcrumbs';

import CustomAntButton from '@/components/CustomAntButton';
import { MdEdit } from 'react-icons/md';
import { FiTrash2 } from 'react-icons/fi';
import { toast } from 'react-toastify';
import confirm from 'antd/lib/modal/confirm';
import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import {
  Container,
  OptionActionButtonsContainer,
  TitlePageContainer,
  TopicOptionsContainer,
  TopicOptionsHeader,
  TopicOptionsList,
} from './styles';
import { ECardGameOptionTopic, ICardOption } from './types';
import EditOptionCard from './EditOptionCard';

const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;

const CardOptions: React.FC = () => {
  const isFirstRender = useRef(true);
  const [isLoadingPage, setIsLoadingPage] = useState(true);

  const [cardGoalOptions, setCardGoalOptions] = useState<ICardOption[]>([]);
  const [cardStatisticOptions, setCardStatisticOptions] = useState<
    ICardOption[]
  >([]);

  const [isNewGoalOptionVisible, setIsNewGoalOptionVisible] = useState(false);
  const [isNewStatisticOptionVisible, setIsNewStatisticOptionVisible] =
    useState(false);
  const [idOfUpdatingOption, setIdOfUpdatingOption] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);

  const setCardOptions = useCallback((options: ICardOption[]) => {
    const goalOptions = options
      .filter((option) => option.topic === ECardGameOptionTopic.GOAL)
      .sort((a, b) => a.value - b.value);
    const statisticOptions = options
      .filter((option) => option.topic === ECardGameOptionTopic.STATISTIC)
      .sort((a, b) => a.value - b.value);

    setCardGoalOptions(goalOptions);
    setCardStatisticOptions(statisticOptions);
  }, []);

  const getCardOptions = useCallback(async (): Promise<void> => {
    try {
      const { data } = await api.get<{
        docs: ICardOption[];
      }>(`/api/athlete-option-setting`, {
        params: {
          limit: 50,
        },
      });

      setCardOptions(data.docs);
    } catch (error) {
      //
    }
    setIsLoadingPage(false);
  }, [setCardOptions]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      getCardOptions();
    }
  }, [getCardOptions]);

  const areButtonsEnabled = useMemo(() => {
    return (
      !isNewGoalOptionVisible &&
      !isNewStatisticOptionVisible &&
      !idOfUpdatingOption &&
      !isDeleting
    );
  }, [
    idOfUpdatingOption,
    isDeleting,
    isNewGoalOptionVisible,
    isNewStatisticOptionVisible,
  ]);

  const handleSavedCardOption = useCallback(
    (savedOption: ICardOption) => {
      const updatedOptions = [
        ...cardGoalOptions,
        ...cardStatisticOptions,
      ].filter((option) => option._id !== savedOption._id);
      updatedOptions.push(savedOption);
      setCardOptions(updatedOptions);
    },
    [cardGoalOptions, cardStatisticOptions, setCardOptions]
  );

  const handleDeleteCardOption = useCallback(
    async (optionId: string) => {
      await new Promise((resolve) => {
        confirm({
          title: 'Deseja realmente excluir essa opção?',
          icon: <ExclamationCircleOutlined />,
          cancelText: 'Cancelar',
          okText: 'Excluir opção',
          onOk() {
            resolve(true);
          },
          okButtonProps: {
            danger: true,
          },
        });
      });

      try {
        setIsDeleting(true);
        await api.delete<ICardOption>(
          `/api/athlete-option-setting/${optionId}`
        );

        toast.success('Opção excluída!');
        const updatedOptions = [
          ...cardGoalOptions,
          ...cardStatisticOptions,
        ].filter((option) => option._id !== optionId);
        setCardOptions(updatedOptions);
      } catch (error) {
        toast.error('Aconteceu um erro inesperado...');
      }
      setIsDeleting(false);
    },
    [cardGoalOptions, cardStatisticOptions, setCardOptions]
  );

  if (isLoadingPage) {
    return (
      <Container>
        <Breadcrumbs />
        <TitlePageContainer>
          <h4>Opções das cartelas</h4>
        </TitlePageContainer>
        <Spin indicator={antIcon} />
      </Container>
    );
  }

  return (
    <Container>
      <Breadcrumbs />
      <TitlePageContainer>
        <h4>Opções das cartelas</h4>
      </TitlePageContainer>
      <TopicOptionsContainer>
        <TopicOptionsHeader>
          <h6>Gols</h6>
          {areButtonsEnabled && (
            <CustomAntButton
              type="primary"
              onClick={() => setIsNewGoalOptionVisible(true)}
            >
              Nova opção
            </CustomAntButton>
          )}
        </TopicOptionsHeader>
        <TopicOptionsList>
          {isNewGoalOptionVisible && (
            <EditOptionCard
              handleClose={() => setIsNewGoalOptionVisible(false)}
              topic={ECardGameOptionTopic.GOAL}
              handleSavedCardOption={handleSavedCardOption}
            />
          )}
          {cardGoalOptions.map((option) => (
            <Fragment key={option._id}>
              {option._id !== idOfUpdatingOption ? (
                <li>
                  <div>
                    <p>{option.name}</p>
                    <small>
                      {option.value} ponto{option.value > 1 && 's'}
                    </small>
                  </div>
                  <OptionActionButtonsContainer>
                    <CustomAntButton
                      type="text"
                      useTextPrimary
                      icon={<MdEdit height={18} />}
                      onClick={() => {
                        setIdOfUpdatingOption(option._id);
                      }}
                      disabled={!areButtonsEnabled}
                    />
                    <CustomAntButton
                      type="text"
                      danger
                      icon={<FiTrash2 height={18} />}
                      onClick={() => handleDeleteCardOption(option._id)}
                      disabled={!areButtonsEnabled}
                    />
                  </OptionActionButtonsContainer>
                </li>
              ) : (
                <EditOptionCard
                  optionToEdit={option}
                  handleClose={() => setIdOfUpdatingOption('')}
                  topic={ECardGameOptionTopic.GOAL}
                  handleSavedCardOption={handleSavedCardOption}
                />
              )}
            </Fragment>
          ))}
        </TopicOptionsList>
      </TopicOptionsContainer>
      <TopicOptionsContainer>
        <TopicOptionsHeader>
          <h6>Estatísticas</h6>
          {areButtonsEnabled && (
            <CustomAntButton
              type="primary"
              onClick={() => setIsNewStatisticOptionVisible(true)}
            >
              Nova opção
            </CustomAntButton>
          )}
        </TopicOptionsHeader>
        <TopicOptionsList>
          {isNewStatisticOptionVisible && (
            <EditOptionCard
              handleClose={() => setIsNewStatisticOptionVisible(false)}
              topic={ECardGameOptionTopic.STATISTIC}
              handleSavedCardOption={handleSavedCardOption}
            />
          )}
          {cardStatisticOptions.map((option) => (
            <Fragment key={option._id}>
              {option._id !== idOfUpdatingOption ? (
                <li>
                  <div>
                    <p>{option.name}</p>
                    <small>
                      {option.value} ponto{option.value > 1 && 's'}
                    </small>
                  </div>
                  <OptionActionButtonsContainer>
                    <CustomAntButton
                      type="text"
                      useTextPrimary
                      icon={<MdEdit height={18} />}
                      onClick={() => setIdOfUpdatingOption(option._id)}
                      disabled={!areButtonsEnabled}
                    />
                    <CustomAntButton
                      type="text"
                      danger
                      icon={<FiTrash2 height={18} />}
                      onClick={() => handleDeleteCardOption(option._id)}
                      disabled={!areButtonsEnabled}
                    />
                  </OptionActionButtonsContainer>
                </li>
              ) : (
                <EditOptionCard
                  optionToEdit={option}
                  handleClose={() => setIdOfUpdatingOption('')}
                  topic={ECardGameOptionTopic.STATISTIC}
                  handleSavedCardOption={handleSavedCardOption}
                />
              )}
            </Fragment>
          ))}
        </TopicOptionsList>
      </TopicOptionsContainer>
    </Container>
  );
};

export default CardOptions;
