import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
  padding: 3.5rem 2rem;

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.sm}) {
    padding: 2rem 1rem;
    min-height: calc(100vh - 3.8125rem);
  }
`;

export const TitlePageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 2rem;
  align-items: center;

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    margin-bottom: 1rem;
  }

  /* AntD Button Changes */
  button {
    margin-left: 1rem;
  }
`;

export const Content = styled.div`
  padding: 0 15%;
  display: flex;
  flex-direction: column;

  form {
    align-self: center;
    display: flex;
    flex-direction: column;
    width: 80%;
    min-width: 36.6875rem;
    margin-top: 2.5rem;
  }

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    padding: 0;

    form {
      width: 100%;
      min-width: 100%;
      margin-top: 1rem;
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: space-between;
  margin-top: 1.5rem;

  button.ant-btn-primary {
    flex: 1;
  }

  button.ant-btn-default {
    margin-right: 1rem;
  }
`;
