import styled, { css, keyframes } from 'styled-components';
import { shade } from 'polished';

import signUpBackgroundImg from '../../assets/sign-up-team-background.png';

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: stretch;
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 40px 0;
  overflow: auto;

  width: 100%;
  max-width: 700px;

  @media only screen and (max-width: 1199px) {
    max-width: 100%;
  }
`;

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: auto 0;

  animation: ${appearFromRight} 1s;

  img {
    width: 178px;
    margin-bottom: 40px;
  }

  h5 {
    margin-bottom: 8px;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;

    form {
      width: 100% !important;
      padding: 0 16px !important;
    }
  }

  form {
    text-align: center;
    width: 382px;

    p {
      color: ${props => props.theme.colors.dark['dark+4']};
      margin-bottom: 40px;
    }

    a {
      color: #f4ede8;
      display: block;
      margin-top: 24px;
      text-decoration: none;
      transition: color 0.2s;

      &:hover {
        color: ${shade(0.2, '#f4ede8')};
      }
    }
  }

  > a {
    color: ${props => props.theme.colors.primary['primary+1']};
    margin-top: 40px;
    text-decoration: none;
    transition: color 0.2s;

    display: flex;
    align-items: center;

    svg {
      margin-right: 16px;
    }

    &:hover {
      color: ${props => shade(0.2, props.theme.colors.primary['primary+1'])};
    }
  }
`;

export const SelectImageButton = styled.button`
  width: 100%;
  background: ${props => props.theme.colors.light['light-2']};
  padding: 8px;
  border: none;
  border-radius: 4px;
  display: flex;
  align-items: center;
  border: 2px solid ${props => props.theme.colors.light['light-2']};
  margin: 8px 0;

  ${props =>
    props.error &&
    css`
      border-color: ${props.theme.colors.danger};
    `}

  img {
    height: 48px;
    width: 48px;
    border-radius: 50%;
    margin-left: 16px;
    object-fit: cover;
  }

  p {
    flex: 1;
    font-size: 14px;
    text-align: center;
    color: ${props => props.theme.colors.dark['dark+1']};

    strong {
      color: inherit;
      font-size: inherit;
    }
  }

  ${props =>
    props.error &&
    css`
      border-color: ${props.theme.colors.danger};

      p {
        color: ${props.theme.colors.danger};
      }
    `}
`;

export const Background = styled.div`
  flex: 1;
  background: url(${signUpBackgroundImg}) no-repeat center;
  background-size: cover;

  @media only screen and (max-width: 1199px) {
    display: none;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: space-between;
  margin-top: 24px;

  button {
    width: 100%;
    margin-top: 8px;
  }

  button.ant-btn-primary {
    flex: 1;
  }

  button.ant-btn-default {
    margin-right: 16px;
  }
`;
