import SignIn from '@/pages/SignIn';
import SignUpTeam from '@/pages/SignUpTeam';

import Dashboard from '../pages/Dashboard';
import Profile from '../pages/Profile';
import Settings from '../pages/Settings';

import Users from '../pages/Users';
import NewUser from '../pages/Users/New';
import User from '../pages/Users/User';

import Influencers from '@/pages/Influencers';
import NewInfluencer from '@/pages/Influencers/New';
import Influencer from '@/pages/Influencers/Influencer';

import Sponsors from '../pages/Sponsors';
import NewSponsor from '../pages/Sponsors/New';
import Sponsor from '../pages/Sponsors/Sponsor';

import Administrators from '../pages/Settings/Administrators';
import NewAdministrator from '../pages/Settings/Administrators/New';
import Administrator from '../pages/Settings/Administrators/Administrator';

import Advertisement from '../pages/Settings/Advertisement';

import Matchs from '../pages/Matchs';
import NewMatch from '../pages/Matchs/New';

import Championships from '../pages/Championships';
import Championship from '../pages/Championships/Championship';

import Teams from '../pages/Teams';
import NewTeam from '../pages/Teams/New';
import Team from '../pages/Teams/Team';

import AthleteCards from '../pages/AthleteCards/List';
import NewAthleteCard from '../pages/AthleteCards/New';
import CardDetails from '@/pages/AthleteCards/CardDetails';

import ChatsReports from '../pages/ChatsReports';
import CardOptions from '@/pages/Settings/CardOptions';
import SetCardAds from '@/pages/AthleteCards/SetCardAds';

import ManageGamesPayment from '@/pages/Settings/ManageGamesPayment';

const COMMON_ROUTES = [
  {
    path: '/',
    name: 'Login',
    exact: true,
    component: SignIn,
    isPrivate: false,
  },
  {
    path: '/signup_team',
    name: 'Novo time',
    component: SignUpTeam,
    isPrivate: false,
  },
  { path: '/profile', name: 'Perfil', component: Profile, isPrivate: true },
];

const ADMIN_ROUTES = [
  ...COMMON_ROUTES,
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    isPrivate: true,
    initial: true,
  },
  {
    path: '/users',
    name: 'Usuários',
    exact: true,
    component: Users,
    isPrivate: true,
  },
  {
    path: '/users/new',
    name: 'Novo usuário',
    exact: true,
    component: NewUser,
    isPrivate: true,
  },
  {
    path: '/users/:id',
    name: 'Detalhes do usuário',
    component: User,
    isPrivate: true,
  },
  {
    path: '/influencers',
    name: 'Influenciadores',
    exact: true,
    component: Influencers,
    isPrivate: true,
  },
  {
    path: '/influencers/new',
    name: 'Novo influenciador',
    exact: true,
    component: NewInfluencer,
    isPrivate: true,
  },
  {
    path: '/influencers/:id',
    name: 'Detalhes do influenciador',
    component: Influencer,
    isPrivate: true,
  },
  {
    path: '/sponsors',
    name: 'Patrocinadores',
    exact: true,
    component: Sponsors,
    isPrivate: true,
  },
  {
    path: '/sponsors/new',
    name: 'Novo patrocinador',
    exact: true,
    component: NewSponsor,
    isPrivate: true,
  },
  {
    path: '/sponsors/:id',
    name: 'Detalhes do patrocinador',
    component: Sponsor,
    isPrivate: true,
  },
  {
    path: '/settings',
    name: 'Configurações',
    exact: true,
    component: Settings,
    isPrivate: true,
  },
  {
    path: '/settings/card_options',
    name: 'Opções das cartelas',
    exact: true,
    component: CardOptions,
    isPrivate: true,
  },
  {
    path: '/settings/administrators',
    name: 'Administradores',
    exact: true,
    component: Administrators,
    isPrivate: true,
  },
  {
    path: '/settings/administrators/new',
    name: 'Novo administrador',
    exact: true,
    component: NewAdministrator,
    isPrivate: true,
  },
  {
    path: '/settings/administrators/:id',
    name: 'Detalhes do administrador',
    component: Administrator,
    isPrivate: true,
  },
  {
    path: '/settings/advertisement',
    name: 'Publicidade',
    // exact: true,
    component: Advertisement,
    isPrivate: true,
  },
  {
    path: '/settings/manage-games-payment',
    name: 'Pagamento dos jogos',
    exact: true,
    component: ManageGamesPayment,
    isPrivate: true,
  },
  {
    path: '/matchs',
    name: 'Partidas',
    exact: true,
    component: Matchs,
    isPrivate: true,
  },
  {
    path: '/championships',
    name: 'Campeonatos',
    exact: true,
    component: Championships,
    isPrivate: true,
  },
  {
    path: '/championships/:id',
    name: 'Detalhes do campeonato',
    component: Championship,
    isPrivate: true,
  },
  {
    path: '/teams',
    name: 'Times',
    exact: true,
    component: Teams,
    isPrivate: true,
  },
  {
    path: '/teams/new',
    name: 'Novo time',
    exact: true,
    component: NewTeam,
    isPrivate: true,
  },
  {
    path: '/teams/:id',
    name: 'Detalhes do time',
    component: Team,
    isPrivate: true,
  },
  {
    path: '/cards',
    name: 'Cartelas',
    exact: true,
    component: AthleteCards,
    isPrivate: true,
  },
  {
    path: '/cards/new',
    name: 'Nova cartela',
    component: NewAthleteCard,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/cards/:id',
    name: 'Detalhes da cartela',
    component: CardDetails,
    exact: true,
    isPrivate: true,
  },
  {
    path: '/cards/:id/new_ads',
    name: 'Novo anúncio',
    component: SetCardAds,
    isPrivate: true,
  },
  {
    path: '/cards/:id/edit_ads/:ad_id',
    name: 'Editar anúncio',
    component: SetCardAds,
    isPrivate: true,
  },
  {
    path: '/chats_reports',
    name: 'Denúncias dos Chats',
    exact: true,
    component: ChatsReports,
    isPrivate: true,
  },
];

const TEAM_ROUTES = [
  ...COMMON_ROUTES,
  {
    path: '/my_team',
    name: 'Meu time',
    component: Team,
    isPrivate: true,
  },
  {
    path: '/my_matches',
    name: 'Minhas partidas',
    exact: true,
    component: Matchs,
    isPrivate: true,
  },
  {
    path: '/my_matches/new',
    name: 'Nova partida',
    component: NewMatch,
    isPrivate: true,
  },
];

export function getRoutes(user_roles) {
  if (user_roles?.includes('admin')) return ADMIN_ROUTES;
  if (user_roles?.includes('team')) return TEAM_ROUTES;

  return COMMON_ROUTES;
}

export function getInitialRouteUrl(user_roles) {
  if (user_roles.includes('admin')) return '/dashboard';
  if (user_roles.includes('team')) return '/my_team';

  return '/';
}
