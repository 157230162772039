import React, { useState, useEffect, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Pagination, Spin, Input } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import * as _ from 'lodash';

import {
  Container,
  TitlePageContainer,
  TeamsListContainer,
  TeamsList,
  TeamDetailContainer,
  SearchContainer,
  NamePageForIndicator,
} from './styles';

import api from '@/services/api';
import CustomAntButton from '@/components/CustomAntButton';

const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;
const { Search } = Input;
function Teams() {
  const history = useHistory();

  const [teams, setTeams] = useState({
    total: 0,
    currentPage: 0,
    pageSize: 0,
    items: [],
    searchCompleted: false,
  });
  const [searchInputValue, setSearchInputValue] = useState('');
  const [loadingTeams, setLoadingTeams] = useState(false);

  async function handleGetTeams(searchTerm, page = 1) {
    setLoadingTeams(true);

    try {
      const { data } = await api.get('/api/team', {
        params: {
          // TODO: Add filters
          teamType: 'all', // 'all', 'professional', 'amateur', and 'house'
          search: searchTerm,
          page,
        },
      });

      setTeams({
        total: data.total,
        currentPage: data.page,
        pageSize: data.limit,
        items: data.docs,
        searchCompleted: true,
      });

      setLoadingTeams(false);
    } catch (error) {
      setLoadingTeams(false);
    }
  }

  useEffect(() => {
    handleGetTeams();
  }, []);

  const teamsSearchDebounced = useMemo(() => {
    return _.debounce(handleGetTeams, 500);
  }, []);

  useEffect(() => {
    teamsSearchDebounced.cancel();

    if (searchInputValue.length > 2) {
      setLoadingTeams(true);
      teamsSearchDebounced(searchInputValue);
    } else {
      setLoadingTeams(false);
    }

    if (!searchInputValue) {
      setTeams({
        total: 0,
        currentPage: 0,
        pageSize: 0,
        items: [],
        searchCompleted: false,
      });
    }
  }, [searchInputValue, teamsSearchDebounced]);

  const contentPage = useMemo(() => {
    if (loadingTeams) {
      return <Spin indicator={antIcon} />;
    }

    if (!searchInputValue) {
      return (
        <h5>
          Pesquise por <NamePageForIndicator>times</NamePageForIndicator>{' '}
          cadastrados no Lance Futebol
        </h5>
      );
    }
    if (searchInputValue.length <= 2) {
      return <h6>Digite acima de 2 caracteres para realizar a busca</h6>;
    }

    if (teams?.items?.length > 0) {
      return (
        <>
          <TeamsList>
            {teams?.items.map((team) => (
              <TeamDetailContainer
                onClick={() => history.push(`/teams/${team?._id}`)}
                key={team?.name}
              >
                <div>
                  <img src={team?.image} alt={team?.name || 'Avatar'} />
                </div>
                <h6>{team?.name}</h6>
              </TeamDetailContainer>
            ))}
          </TeamsList>
          <Pagination
            current={teams.currentPage}
            onChange={(page) => {
              setTeams({ ...teams, currentPage: page });
              handleGetTeams(searchInputValue, page);
            }}
            total={teams.total}
            pageSize={teams.pageSize}
          />
        </>
      );
    }

    if (teams?.items?.length === 0 && teams?.searchCompleted) {
      return <h6>Nenhum time encontrado</h6>;
    }

    return '';
  }, [history, loadingTeams, searchInputValue, teams]);

  // if (loadingTeams) {
  //   return (
  //     <Container>
  //       <TitlePageContainer>
  //         <h4>Times</h4>
  //       </TitlePageContainer>
  //       <TeamsListContainer>
  //         <Spin indicator={antIcon} />
  //       </TeamsListContainer>
  //     </Container>
  //   );
  // }

  return (
    <Container>
      <TitlePageContainer>
        <h4>Times</h4>
        <Link to="/teams/new">
          <CustomAntButton type="primary">Novo time</CustomAntButton>
        </Link>
      </TitlePageContainer>
      <SearchContainer>
        <Search
          onChange={(e) => setSearchInputValue(e.target.value)}
          placeholder="Pesquisar time"
        />
        <Link to="/teams/new">
          <CustomAntButton type="primary">Novo time</CustomAntButton>
        </Link>
      </SearchContainer>
      <TeamsListContainer>{contentPage}</TeamsListContainer>
    </Container>
  );
}

export default Teams;
