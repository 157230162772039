import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { Button, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import { Formik, FastField } from 'formik';
import * as Yup from 'yup';
import * as _ from 'lodash';
import { toast } from 'react-toastify';
import moment from 'moment';

import {
  Container,
  TitlePageContainer,
  Content,
  ButtonsContainer,
} from './styles';

import Input from '@/components/Input';
import DatePicker from '@/components/DatePicker';
import Select from '@/components/Select';

import api from '@/services/api';
import useBeforeUnload from '@/hooks/useBeforeUnload';
import useMergeRefs from '@/hooks/useMergeRefs';

const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;

function disabledDateTime() {
  function range(start, end) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  return {
    // disabledHours: () => range(0, 24).splice(4, 20),
    // disabledMinutes: () => range(30, 60),
    disabledSeconds: () => range(0, 60),
  };
}

function EditCompanyData({ setAdvertisements, parentRouteMatch }) {
  const history = useHistory();
  const params = useParams();
  const mergeRefs = useMergeRefs();

  const formikRef = useRef(null);

  const [haveChanges, setHaveChanges] = useState(false);

  const [advertSettings, setAdvertSettings] = useState(null);
  const [loadingAdvertSettings, setLoadingAdvertSettings] = useState(true);

  const handleGetAdvert = useCallback(async () => {
    try {
      const { data } = await api.get(`/api/slide-main/${params.advert_id}`);

      if (data._sponsor !== params.id) {
        toast.warn('Esse anúncio não pertence a esse administrador!');
        history.push(parentRouteMatch.url);
      }

      setAdvertSettings({
        title: data.title,
        url: data.url,
        weight: data.weight,
        endDate: data.endDate,
      });
      setLoadingAdvertSettings(false);
    } catch (error) {
      /* */
    }
  }, [history, params.advert_id, params.id, parentRouteMatch.url]);

  useBeforeUnload({
    when: haveChanges,
    message: 'Deseja realmente sair? As alterações serão perdidas',
  });

  const unblockPage = useMemo(() => {
    const messageComponents = {
      title: 'Deseja realmente cancelar a atualização dos dados?',
      content: 'Todos as alterações serão perdidas',
      cancelText: 'Voltar',
      okText: 'Cancelar',
    };

    if (haveChanges) {
      return history.block(JSON.stringify(messageComponents));
    }
    return () => {};
  }, [haveChanges, history]);

  useEffect(() => {
    handleGetAdvert();
  }, [handleGetAdvert]);

  useEffect(() => {
    return () => {
      unblockPage();
    };
  }, [unblockPage]);

  const checkIfHaveChanges = useCallback(
    (currentValues) => {
      if (
        _.isEqual(advertSettings, {
          ...currentValues,
          endDate: currentValues.endDate.toDate(),
        })
      ) {
        if (haveChanges) {
          setHaveChanges(false);
        }
        return;
      }

      if (!haveChanges) {
        setHaveChanges(true);
      }
    },
    [advertSettings, haveChanges]
  );

  const handleSubmit = useCallback(
    async (values, actions) => {
      const { advert_id } = params;

      const body = {
        title: values.title,
        url: values.url,
        weight: values.weight,
        endDate: values.endDate.toDate(),
      };

      try {
        await api.put(`/api/slide-main/${advert_id}`, body);

        setAdvertisements((oldState) =>
          oldState.map((ads) => {
            if (ads._id === params.advert_id) {
              return {
                ...ads,
                ...{
                  ...body,
                  endDate: values.endDate.toISOString(),
                },
              };
            }

            return ads;
          })
        );

        toast.success('Dados atualizados com sucesso!');
        unblockPage();
        actions.setSubmitting(false);
        history.push(parentRouteMatch.url);
      } catch (error) {
        console.log(error);

        toast.error(
          'Aconteceu um erro inesperado ao atualizar as configurações desse anúncio!'
        );
      }
      return null;
    },
    [history, params, parentRouteMatch.url, setAdvertisements, unblockPage]
  );

  const eneableSubmitButton = useCallback(
    ({ touched, errors, values }) => {
      if (
        _.isEqual(advertSettings, {
          ...values,
          endDate: values.endDate.toDate(),
        }) ||
        Object.entries(touched).length === 0 ||
        Object.entries(errors).length > 0
      ) {
        return false;
      }

      return true;
    },
    [advertSettings]
  );

  const handleFormikRef = (node) => {
    if (node !== null) {
      checkIfHaveChanges(node.values);
    }
  };

  if (loadingAdvertSettings) {
    return (
      <Container>
        <Spin
          style={{
            alignSelf: 'center',
            marginTop: 'auto',
            marginBottom: 'auto',
          }}
          indicator={antIcon}
        />
      </Container>
    );
  }

  return (
    <Container>
      <TitlePageContainer>
        <h5>Editar anúncio</h5>
        <Button
          onClick={() => history.push(`/sponsors/${params.id}`)}
          danger
          type="text"
        >
          Cancelar
        </Button>
      </TitlePageContainer>
      <Content>
        <h6>Configurações do anúncio</h6>
        <Formik
          innerRef={mergeRefs(formikRef, handleFormikRef)}
          initialValues={{
            title: advertSettings.title,
            url: advertSettings.url,
            weight: advertSettings.weight,
            endDate: moment(advertSettings.endDate).set('second', 0),
          }}
          validationSchema={Yup.object().shape({
            endDate: Yup.date()
              .typeError('A data informada é inválida')
              .required('A data de expiração é obrigatória'),
          })}
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <form onSubmit={formikProps.handleSubmit}>
              <FastField name="title">
                {({ field, meta }) => {
                  return (
                    <Input
                      {...field}
                      label="Nome(opcional)"
                      placeholder="Digite um nome ou palavras-chave para o anúncio"
                      identifier="title"
                      type="text"
                      error={meta?.touched && meta?.error && meta?.error}
                    />
                  );
                }}
              </FastField>
              <FastField name="url">
                {({ field, meta }) => {
                  return (
                    <Input
                      {...field}
                      label="URL(opcional)"
                      placeholder="Digite uma URL personalizada para o anúncio"
                      identifier="url"
                      type="text"
                      error={meta?.touched && meta?.error && meta?.error}
                      onChange={(e) => {
                        e.target.value = e.target.value.replace(/\s+/g, '');
                        field.onChange(e);
                      }}
                    />
                  );
                }}
              </FastField>
              <FastField name="weight">
                {({ field, meta }) => {
                  return (
                    <Select
                      {...field}
                      label="Relevância"
                      identifier="weight"
                      placeholder="Selecione uma relevância"
                      options={[
                        { label: 'Irrelevante', value: -1 },
                        { label: 'Pouco relevante', value: 0 },
                        { label: 'Relevante', value: 1 },
                        { label: 'Muito relevante', value: 2 },
                      ]}
                      error={meta?.touched && meta?.error}
                      onChange={(value) => {
                        formikProps.setFieldValue('weight', value);
                      }}
                    />
                  );
                }}
              </FastField>
              <FastField name="endDate">
                {({ field, meta }) => {
                  return (
                    <DatePicker
                      {...field}
                      label="Data e hora de expiração"
                      identifier="endDate"
                      error={meta?.touched && meta?.error}
                      onChange={(momentDate) => {
                        if (momentDate) {
                          momentDate.set('second', 0);
                        }
                        formikProps.setFieldValue('endDate', momentDate);
                      }}
                      showTime
                      disabledTime={disabledDateTime}
                      format="dddd, DD/MM/YYYY [às] HH:mm"
                    />
                  );
                }}
              </FastField>
              <ButtonsContainer>
                <Button
                  htmlType="submit"
                  disabled={
                    formikProps.isSubmitting ||
                    !eneableSubmitButton(formikProps)
                  }
                  type="primary"
                >
                  {!formikProps.isSubmitting ? 'Atualizar' : 'Carregando...'}
                </Button>
              </ButtonsContainer>
            </form>
          )}
        </Formik>
      </Content>
    </Container>
  );
}

export default EditCompanyData;
