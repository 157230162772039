import Input from '@/components/Input';
import Radio from '@/components/Radio';
import { FastField, FastFieldProps } from 'formik';
import React, { useCallback } from 'react';
import { IPrizeProps } from './types';

const Prize: React.FC<IPrizeProps> = ({ formik, cardPaymentPercentage }) => {
  const { hasFixedPrize } = formik.values.cardInformation;

  const handleCardWinnerPercent = useCallback(
    ({ isFixedPrize }: { isFixedPrize: boolean }) => {
      if (isFixedPrize) {
        formik.setFieldValue('cardInformation.percentages.winnerPercent', '0');
      } else {
        formik.setFieldValue(
          'cardInformation.percentages.winnerPercent',
          cardPaymentPercentage.winner.toString()
        );
      }
    },
    [cardPaymentPercentage.winner, formik]
  );

  return (
    <>
      <FastField name="cardInformation.hasFixedPrize">
        {({ field, meta }: FastFieldProps) => {
          return (
            <Radio
              {...field}
              items={[
                {
                  value: true,
                  label: 'Sim',
                },
                {
                  value: false,
                  label: 'Não',
                },
              ]}
              label="Essa cartela possui premiação fixa?"
              error={meta?.touched && meta?.error && meta?.error}
              onChange={(e) => {
                formik.setFieldValue('cardInformation.fixedPrize', '');
                handleCardWinnerPercent({ isFixedPrize: e.target.value });
                field.onChange(e);
              }}
            />
          );
        }}
      </FastField>
      {hasFixedPrize && (
        <FastField name="cardInformation.fixedPrize">
          {({ field, meta }: FastFieldProps) => {
            return (
              <Input
                {...field}
                label="Valor da premiação"
                identifier="fixedPrize"
                type="text"
                error={meta?.touched && meta?.error && meta?.error}
                addonBefore="R$"
              />
            );
          }}
        </FastField>
      )}
    </>
  );
};

export default Prize;
