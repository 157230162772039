import { useState, useEffect, useCallback } from 'react';

const useChatAudioPlayer = ({ audioElement }) => {
  const [duration, setDuration] = useState(0);
  const [curTime, setCurTime] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [clickedTime, setClickedTime] = useState(0);

  const setFinishAudioPlay = useCallback(() => {
    const audio = audioElement;

    if (audio) {
      // audio.pause();
      audio.currentTime = 0;
      setPlaying(false);
      setCurTime(0);
      setClickedTime(0);
    }
  }, [audioElement]);

  useEffect(() => {
    const audio = audioElement;

    // state setters wrappers
    const setAudioData = () => {
      if (audio) {
        setDuration(audio.duration);
        setCurTime(audio.currentTime);
      }
    };
    const setAudioTime = () => {
      if (audio && playing) {
        // if(playing)
        setCurTime(audio.currentTime);
      }
    };
    // const setFinishAudioPlay = (): void => {
    //   if (audio) {
    //     setCurTime(0);
    //     setPlaying(false);
    //   }
    // };
    if (audio) {
      // if (playing) {
      //   audio.play();
      // } else {
      //   audio.pause();
      // }

      if (clickedTime && clickedTime !== curTime) {
        audio.currentTime = clickedTime;
        setClickedTime(0);
      }

      // DOM listeners: update React state on DOM events
      audio.addEventListener('loadeddata', setAudioData);

      audio.addEventListener('timeupdate', setAudioTime);

      audio.addEventListener('ended', setFinishAudioPlay);
    }
    // effect cleanup
    return () => {
      if (audio) {
        audio.removeEventListener('loadeddata', setAudioData);
        audio.removeEventListener('timeupdate', setAudioTime);
        audio.addEventListener('ended', setFinishAudioPlay);
      }
    };
  }, [audioElement, clickedTime, curTime, playing, setFinishAudioPlay]);

  useEffect(() => {
    const audio = audioElement;

    if (audio) {
      if (playing) {
        audio.play();
      } else {
        audio.pause();
      }
    }
  }, [audioElement, playing]);

  return {
    curTime,
    duration,
    playing,
    setPlaying,
    setClickedTime,
    setFinishAudioPlay,
  };
};

export default useChatAudioPlayer;
