import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Dropdown, Menu, Pagination, Modal, Spin, Input } from 'antd';
import { MdEdit } from 'react-icons/md';
import { Link } from 'react-router-dom';
import { FiMoreVertical } from 'react-icons/fi';
import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Image, Shimmer } from 'react-shimmer';
import * as dateFns from 'date-fns';
import * as _ from 'lodash';
import ptLocale from 'date-fns/locale/pt-BR';
import { toast } from 'react-toastify';

import CustomAntButton from '@/components/CustomAntButton';
import { UploadRequests } from '@/services/api/requests/Upload';
import api from '@/services/api';
import {
  Container,
  InactiveAdvertsContent,
  SearchContainer,
  AdvertisementItemContainer,
  AdvertisementItemContent,
  ReactivateAdvertMenuItem,
  Session,
  SessionHeader,
  SessionItems,
} from './styles';

const { format, parseISO, addDays } = dateFns;
const { confirm } = Modal;
const { Search } = Input;

const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;

function Details({ loadingAdvertisementSettings, advertisementSettings }) {
  const [searchInputValue, setSearchInputValue] = useState('');
  const [showInactiveAdverts, setShowInactiveAdverts] = useState(false);
  const [showingSearchResults, setShowingSearchResults] = useState(false);

  const [adverts, setAdverts] = useState([]);
  const [advertsPagination, setAdvertsPagination] = useState({
    limit: 4,
    currentPage: 1,
    totalPages: 0,
  });
  const [inactiveAdverts, setInactiveAdverts] = useState([]);
  const [inactiveAdvertsPagination, setInactiveAdvertsPagination] = useState({
    limit: 4,
    currentPage: 1,
    totalPages: 0,
  });
  const [loadingAdverts, setLoadingAdverts] = useState(true);

  const handleGetAdverts = useCallback(
    async (props = {}) => {
      const { page = 1, search = '' } = props;

      setLoadingAdverts(true);
      try {
        const { data } = await api.get('/api/slide-main', {
          params: {
            limit: advertsPagination.limit,
            page,
            search,
          },
        });

        setAdverts(data.docs);
        setAdvertsPagination((oldState) => ({
          ...oldState,
          currentPage: data.page,
          totalPages: data.total,
        }));
        if (search) {
          setShowingSearchResults(true);
        } else {
          setShowingSearchResults(false);
        }
      } catch (error) {
        /* */
      }
      setLoadingAdverts(false);
    },
    [advertsPagination.limit]
  );

  const handleGetInactiveAdverts = useCallback(
    async (props = {}) => {
      const { page = 1, search = '' } = props;

      setLoadingAdverts(true);
      try {
        const { data } = await api.get('/api/slide-main', {
          params: {
            limit: inactiveAdvertsPagination.limit,
            page,
            search,
            inactive: true,
          },
        });

        setInactiveAdverts(data.docs);
        setInactiveAdvertsPagination((oldState) => ({
          ...oldState,
          currentPage: data.page,
          totalPages: data.total,
        }));
        if (search) {
          setShowingSearchResults(true);
        } else {
          setShowingSearchResults(false);
        }
      } catch (error) {
        /* */
      }
      setLoadingAdverts(false);
    },
    [inactiveAdvertsPagination.limit]
  );

  useEffect(() => {
    handleGetAdverts();
  }, [handleGetAdverts]);

  const advertsSearchDebounced = useMemo(() => {
    return _.debounce(handleGetAdverts, 500);
  }, [handleGetAdverts]);

  const changeInputSearch = useCallback(
    (newValue) => {
      advertsSearchDebounced.cancel();

      if (newValue.length > 3) {
        setLoadingAdverts(true);
        advertsSearchDebounced(searchInputValue);
      } else {
        setLoadingAdverts(false);
      }

      if (!newValue && showingSearchResults) {
        handleGetAdverts();
      }
      setSearchInputValue(newValue);
    },
    [
      advertsSearchDebounced,
      handleGetAdverts,
      searchInputValue,
      showingSearchResults,
    ]
  );

  const inactiveAdvertsSearchDebounced = useMemo(() => {
    return _.debounce(handleGetInactiveAdverts, 500);
  }, [handleGetInactiveAdverts]);

  const changeInactiveInputSearch = useCallback(
    (newValue) => {
      inactiveAdvertsSearchDebounced.cancel();

      if (newValue.length > 3) {
        setLoadingAdverts(true);
        inactiveAdvertsSearchDebounced(searchInputValue);
      } else {
        setLoadingAdverts(false);
      }

      if (!newValue && showingSearchResults) {
        handleGetInactiveAdverts();
      }
      setSearchInputValue(newValue);
    },
    [
      handleGetInactiveAdverts,
      inactiveAdvertsSearchDebounced,
      searchInputValue,
      showingSearchResults,
    ]
  );

  const handleFinishAdvert = useCallback(
    async (advert_id) => {
      try {
        await new Promise((resolve, reject) => {
          confirm({
            title: 'Deseja realmente finalizar esse anúncio?',
            icon: <ExclamationCircleOutlined />,
            content:
              'Ele não estará mais disponível na plataforma Lance Futebol',
            cancelText: 'Não',
            okText: 'Finalizar anúncio',
            okButtonProps: {
              danger: true,
            },
            onOk() {
              resolve();
            },
            onCancel() {
              reject(new Error('CANCELED_ADMIN_DELETE_ACTION'));
            },
          });
        });

        await api.delete(`/api/slide-main/${advert_id}`);

        toast.success('Anúncio finalizado com sucesso!');
        setAdverts((oldState) =>
          oldState.filter((ads) => ads._id !== advert_id)
        );
      } catch (error) {
        if (error.message === 'CANCELED_ADMIN_DELETE_ACTION') {
          return null;
        }
      }

      return null;
    },
    [setAdverts]
  );

  const handleReactivateAdvert = useCallback(
    async (advert_id) => {
      try {
        await new Promise((resolve, reject) => {
          confirm({
            title: 'Deseja realmente reativar esse anúncio?',
            icon: <ExclamationCircleOutlined />,
            content: 'Ele ficará disponível por 7 dias a partir da data atual',
            cancelText: 'Não',
            okText: 'Reativar anúncio',
            onOk() {
              resolve();
            },
            onCancel() {
              reject(new Error('CANCELED_ADMIN_DELETE_ACTION'));
            },
          });
        });

        await api.put(`/api/slide-main/${advert_id}`, {
          endDate: addDays(new Date(), 7),
        });

        toast.success('Anúncio reativado com sucesso!');
        setInactiveAdverts((oldState) =>
          oldState.filter((ads) => ads._id !== advert_id)
        );
      } catch (error) {
        if (error.message === 'CANCELED_ADMIN_DELETE_ACTION') {
          return null;
        }
      }

      return null;
    },
    [setInactiveAdverts]
  );

  const toogleCurrentView = useCallback(() => {
    setSearchInputValue('');
    setShowInactiveAdverts((oldState) => {
      if (!oldState) {
        handleGetInactiveAdverts();
      } else {
        handleGetAdverts();
      }

      return !oldState;
    });
  }, [handleGetAdverts, handleGetInactiveAdverts]);

  const activeAdvertsView = useMemo(() => {
    if (loadingAdverts) {
      return (
        <Spin
          style={{
            alignSelf: 'center',
            marginTop: 32,
            marginBottom: 'auto',
          }}
          indicator={antIcon}
        />
      );
    }

    if (searchInputValue && searchInputValue.length <= 3) {
      return <h6>Digite acima de 3 caracteres para realizar a busca</h6>;
    }

    if (adverts.length) {
      return (
        <>
          {adverts.map((advert) => (
            <AdvertisementItemContainer key={advert._id}>
              <Image
                src={UploadRequests.getFileUrl(advert._upload)}
                fallback={<Shimmer width={100} height={100} />}
              />
              <AdvertisementItemContent>
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item>
                        <Link to={`/sponsors/${advert._sponsor._id}`}>
                          Ver detalhes do patrocinador
                        </Link>
                      </Menu.Item>
                      <Menu.Item>
                        <Link
                          to={`/sponsors/${advert._sponsor._id}/adverts/${advert._id}`}
                        >
                          Configurações do anúncio
                        </Link>
                      </Menu.Item>
                      {advert.url && (
                        <Menu.Item>
                          <a
                            href={advert.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Seguir link do anúncio
                          </a>
                        </Menu.Item>
                      )}
                      <Menu.Item
                        danger
                        onClick={() => handleFinishAdvert(advert._id)}
                      >
                        Finalizar anúncio
                      </Menu.Item>
                    </Menu>
                  }
                  placement="bottomRight"
                  arrow
                >
                  <CustomAntButton
                    type="primary"
                    icon={<FiMoreVertical height={18} />}
                  />
                </Dropdown>
                <ul>
                  <li>
                    <small>Patrocinador</small>
                    <p>{advert._sponsor.name}</p>
                  </li>
                  <li>
                    <small>Data de expiração</small>
                    <p>
                      {format(
                        parseISO(advert.endDate),
                        "cccc, dd/MM/yyyy 'às' HH:mm",
                        {
                          locale: ptLocale,
                        }
                      )}
                    </p>
                  </li>
                </ul>
              </AdvertisementItemContent>
            </AdvertisementItemContainer>
          ))}
          <Pagination
            current={advertsPagination.currentPage}
            onChange={(page) => {
              setAdvertsPagination((oldState) => ({
                ...oldState,
                currentPage: page,
              }));
              handleGetAdverts({ page, search: searchInputValue });
            }}
            total={advertsPagination.totalPages}
            pageSize={advertsPagination.limit}
          />
        </>
      );
    }

    if (showingSearchResults) {
      return <p>Não existem anúncios ativos correspondentes</p>;
    }

    return <p>Nenhum anúncio ativo disponível</p>;
  }, [
    adverts,
    advertsPagination.currentPage,
    advertsPagination.limit,
    advertsPagination.totalPages,
    handleFinishAdvert,
    handleGetAdverts,
    loadingAdverts,
    searchInputValue,
    showingSearchResults,
  ]);

  const inactiveAdvertsView = useMemo(() => {
    if (loadingAdverts) {
      return (
        <Spin
          style={{
            alignSelf: 'center',
            marginTop: 32,
            marginBottom: 'auto',
          }}
          indicator={antIcon}
        />
      );
    }

    if (searchInputValue && searchInputValue.length <= 3) {
      return <h6>Digite acima de 3 caracteres para realizar a busca</h6>;
    }

    if (inactiveAdverts.length) {
      return (
        <InactiveAdvertsContent>
          <small>Você está visualizando os anúncios inativos</small>
          <CustomAntButton
            onClick={toogleCurrentView}
            type="text"
            useTextPrimary
          >
            Mostrar anúncios ativos
          </CustomAntButton>
          {inactiveAdverts.map((advert) => (
            <AdvertisementItemContainer key={advert._id} $inactive>
              <Image
                src={UploadRequests.getFileUrl(advert._upload)}
                fallback={<Shimmer width={100} height={100} />}
              />
              <AdvertisementItemContent>
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item>
                        <Link to={`/sponsors/${advert._sponsor._id}`}>
                          Ver detalhes do patrocinador
                        </Link>
                      </Menu.Item>
                      <ReactivateAdvertMenuItem
                        onClick={() => handleReactivateAdvert(advert._id)}
                      >
                        Reativar anúncio
                      </ReactivateAdvertMenuItem>
                    </Menu>
                  }
                  placement="bottomRight"
                  arrow
                >
                  <CustomAntButton
                    type="primary"
                    icon={<FiMoreVertical height={18} />}
                  />
                </Dropdown>
                <ul>
                  <li>
                    <small>Patrocinador</small>
                    <p>{advert._sponsor.name}</p>
                  </li>
                  <li>
                    <small>Data de expiração</small>
                    <p>
                      {format(
                        parseISO(advert.endDate),
                        "cccc, dd/MM/yyyy 'às' HH:mm",
                        {
                          locale: ptLocale,
                        }
                      )}
                    </p>
                  </li>
                </ul>
              </AdvertisementItemContent>
            </AdvertisementItemContainer>
          ))}
          <Pagination
            current={inactiveAdvertsPagination.currentPage}
            onChange={(page) => {
              setInactiveAdvertsPagination((oldState) => ({
                ...oldState,
                currentPage: page,
              }));
              handleGetInactiveAdverts({ page, search: searchInputValue });
            }}
            total={inactiveAdvertsPagination.totalPages}
            pageSize={inactiveAdvertsPagination.limit}
          />
        </InactiveAdvertsContent>
      );
    }

    if (showingSearchResults) {
      return <p>Não existem anúncios inativos correspondentes</p>;
    }

    return <p>Nenhum anúncio inativo disponível</p>;
  }, [
    handleGetInactiveAdverts,
    handleReactivateAdvert,
    inactiveAdverts,
    inactiveAdvertsPagination.currentPage,
    inactiveAdvertsPagination.limit,
    inactiveAdvertsPagination.totalPages,
    loadingAdverts,
    searchInputValue,
    showingSearchResults,
    toogleCurrentView,
  ]);

  if (loadingAdvertisementSettings) {
    return (
      <Spin
        style={{
          alignSelf: 'center',
          marginTop: 'auto',
          marginBottom: 'auto',
        }}
        indicator={antIcon}
      />
    );
  }

  return (
    <Container>
      <Session>
        <SessionHeader>
          <h6>Configurações</h6>
          <Link to="/settings/advertisement/edit_advertisement_settings">
            <CustomAntButton
              type="text"
              useTextPrimary
              icon={<MdEdit height={18} />}
            />
          </Link>
        </SessionHeader>
        <SessionItems>
          <li>
            <small>Duração dos slides</small>
            <p>{advertisementSettings?.time}s</p>
          </li>
          <li>
            <small>Quantidade de slides</small>
            <p>{advertisementSettings?.amount} slides</p>
          </li>
        </SessionItems>
      </Session>
      <Session>
        <SessionHeader>
          <h6>Anúncios</h6>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  onClick={toogleCurrentView}
                  danger={!showInactiveAdverts}
                >
                  {!showInactiveAdverts
                    ? 'Mostrar anúncios inativos'
                    : 'Mostrar anúncios ativos'}
                </Menu.Item>
              </Menu>
            }
            placement="bottomRight"
            arrow
          >
            <CustomAntButton
              type="text"
              icon={<FiMoreVertical height={18} />}
            />
          </Dropdown>
        </SessionHeader>
        {!showInactiveAdverts ? (
          <>
            <SearchContainer>
              <Search
                // onChange={e => setSearchInputValue(e.target.value)}
                onChange={(e) => changeInputSearch(e.target.value)}
                value={searchInputValue}
                placeholder="Pesquise um anúncio"
              />
            </SearchContainer>
            {activeAdvertsView}
          </>
        ) : (
          <>
            <SearchContainer>
              <Search
                // onChange={e => setSearchInputValue(e.target.value)}
                onChange={(e) => changeInactiveInputSearch(e.target.value)}
                value={searchInputValue}
                placeholder="Pesquise um anúncio inativo"
              />
            </SearchContainer>
            {inactiveAdvertsView}
          </>
        )}
      </Session>
    </Container>
  );
}

export default Details;
