import React, { useCallback, useMemo, useState } from 'react';

import { Dropdown, Menu } from 'antd';
import { FiBarChart2, FiEdit, FiRefreshCw, FiTrash2 } from 'react-icons/fi';
import { IoEllipsisVertical } from 'react-icons/io5';
import CustomAntButton from '@/components/CustomAntButton';
import { getYouTubeVideoId } from '@/utils/validate';
import { AdsTypeEnum } from '@/pages/AthleteCards/types';
import { MdInsertLink } from 'react-icons/md';
import confirm from 'antd/lib/modal/confirm';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import api from '@/services/api';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import { UploadRequests } from '@/services/api/requests/Upload';
import { IAdCardProps } from './types';
import { Container, Header, UrlLink, YoutubeVideoContainer } from './styles';
import AdStatisticsModal from './AdStatisticsModal';

const AdCard: React.FC<IAdCardProps> = ({ ad, setAds }) => {
  const history = useHistory();

  const [isStatisticModalVisible, setIsStatisticModalVisible] = useState(false);

  const youtubeUrl = useMemo(() => {
    const adsUrl = ad.url;

    if (ad.type === AdsTypeEnum.IMAGE) return null;

    const youTubeVideoId = getYouTubeVideoId(adsUrl);
    return `https://www.youtube.com/embed/${youTubeVideoId}`;
  }, [ad]);

  const handleDelete = useCallback(async () => {
    await new Promise((resolve) => {
      confirm({
        title: 'Deseja realmente excluir esse anúncio?',
        icon: <ExclamationCircleOutlined />,
        cancelText: 'Cancelar',
        okText: 'Excluir anúncio',
        okButtonProps: {
          danger: true,
        },
        onOk() {
          resolve(true);
        },
      });
    });

    try {
      await api.delete(`/api/athlete-card/${ad._card}/ad/${ad._id}`);
      toast.success('Anúncio excluído com sucesso!');
      setAds((oldState) => oldState.filter((_ad) => _ad._id !== ad._id));
    } catch (error) {
      toast.error('Aconteceu um erro inesperado... Tente novamente mais tarde');
    }
  }, [ad._card, ad._id, setAds]);

  const handleRelay = useCallback(async () => {
    await new Promise((resolve) => {
      confirm({
        title: 'Deseja realmente retransmitir esse anúncio?',
        icon: <ExclamationCircleOutlined />,
        cancelText: 'Cancelar',
        okText: 'Retransmitir',
        onOk() {
          resolve(true);
        },
      });
    });

    try {
      await api.put(`/api/athlete-card/${ad._card}/relay/ad/${ad._id}`);
      toast.success('Anúncio retransmitido!');
    } catch (error) {
      toast.error('Aconteceu um erro inesperado... Tente novamente mais tarde');
    }
  }, [ad]);

  return (
    <>
      <Container>
        <Header>
          <strong>{ad.title}</strong>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item onClick={handleRelay}>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <FiRefreshCw style={{ marginRight: 8 }} size={19} />
                    Retransmitir
                  </div>
                </Menu.Item>
                <Menu.Item onClick={() => setIsStatisticModalVisible(true)}>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <FiBarChart2 style={{ marginRight: 8 }} size={19} />
                    Estatísticas
                  </div>
                </Menu.Item>
                <Menu.Item
                  onClick={() =>
                    history.push(`/cards/${ad._card}/edit_ads/${ad._id}`)
                  }
                >
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <FiEdit style={{ marginRight: 8 }} size={19} />
                    Editar
                  </div>
                </Menu.Item>
                <Menu.Item danger onClick={handleDelete}>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <FiTrash2 style={{ marginRight: 8 }} size={19} />
                    Excluir
                  </div>
                </Menu.Item>
              </Menu>
            }
            placement="bottomRight"
            arrow
          >
            <CustomAntButton
              icon={<IoEllipsisVertical size={20} />}
              type="text"
            />
          </Dropdown>
        </Header>
        {ad.type === AdsTypeEnum.IMAGE && (
          <img
            src={UploadRequests.getFileUrl(ad._upload?._id as string)}
            alt="Banner"
          />
        )}
        {ad.type === AdsTypeEnum.VIDEO && (
          <YoutubeVideoContainer>
            <iframe title={youtubeUrl || ''} src={youtubeUrl || ''} />
          </YoutubeVideoContainer>
        )}
        <small>{ad.text}</small>
        <UrlLink href={ad.url} target="_blank">
          <MdInsertLink size={20} />
          Ver mais
        </UrlLink>
      </Container>
      <AdStatisticsModal
        visible={isStatisticModalVisible}
        handleClose={() => setIsStatisticModalVisible(false)}
        targets={ad.targets}
      />
    </>
  );
};

export default AdCard;
