import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from 'antd';
import { toast } from 'react-toastify';

import { Container, TitlePageContainer, ButtonsContainer } from './styles';

import api from '@/services/api';
import AvatarCropper from '@/components/AvatarCropper';
import useBeforeUnload from '@/hooks/useBeforeUnload';
import { UploadRequests } from '@/services/api/requests/Upload';

function UpdateBanner({ championship, setChampionship }) {
  const history = useHistory();
  const params = useParams();

  const [newBanner, setNewBanner] = useState({
    file: null,
    previewUrl: '',
  });
  const [haveChanges, setHaveChanges] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  useBeforeUnload({
    when: haveChanges,
    message: 'Deseja realmente sair? As alterações serão perdidas',
  });

  const unblockPage = useMemo(() => {
    const messageComponents = {
      title: 'Deseja realmente cancelar a atualização do banner?',
      content: 'Todos as alterações serão perdidas',
      cancelText: 'Voltar',
      okText: 'Cancelar',
    };

    if (haveChanges) {
      return history.block(JSON.stringify(messageComponents));
    }
    return () => {};
  }, [haveChanges, history]);

  useEffect(() => {
    return () => {
      unblockPage();
    };
  }, [unblockPage]);

  const handleSubmit = useCallback(async () => {
    const { file } = newBanner;

    if (!file) {
      toast.warn(
        'Aconteceu um erro inesperado ao atualizar o banner do campeonato! Atualize a página'
      );
      return;
    }

    setSubmitting(true);

    let newBannerId = null;

    try {
      const { data } = await UploadRequests.upload({
        file,
        from: 'championshipBanner',
      });

      newBannerId = data._id;
    } catch (error) {
      console.log(error);

      setSubmitting(false);
      toast.error(
        'Aconteceu um erro inesperado ao atualizar o banner do campeonato!'
      );

      return;
    }

    const body = {
      background: newBannerId,
    };

    try {
      await api.put(`/api/championship/${params.id}`, body);

      toast.success('Banner do campeonato atualizado com sucesso');
      setChampionship({ ...championship, background: newBannerId });
      setSubmitting(false);
      unblockPage();

      history.goBack();
    } catch (error) {
      console.log(error);
      toast.error(
        'Aconteceu um erro inesperado ao atualizar o banner do campeonato!'
      );
      setSubmitting(false);
    }
  }, [
    championship,
    history,
    newBanner,
    params.id,
    setChampionship,
    unblockPage,
  ]);

  return (
    <Container>
      <TitlePageContainer>
        <h5>Atualizar banner</h5>
        <Button
          onClick={() => history.push(`/championships/${params.id}`)}
          danger
          type="text"
        >
          Cancelar
        </Button>
      </TitlePageContainer>
      <AvatarCropper
        selectImageButtonLabel="Selecione uma imagem para adicionar como banner do campeonato"
        croppedAvatar={newBanner}
        setCroppedAvatar={setNewBanner}
        setHaveChanges={setHaveChanges}
        aspect={16 / 9}
      />
      {newBanner?.previewUrl && (
        <ButtonsContainer>
          <Button onClick={handleSubmit} disabled={submitting} type="primary">
            Atualizar banner
          </Button>
        </ButtonsContainer>
      )}
    </Container>
  );
}

export default UpdateBanner;
