import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'antd';
import { useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import CustomAntButton from '@/components/CustomAntButton';
import AvatarCropper from '@/components/AvatarCropper';

import api from '@/services/api';

import { Header } from '../styles';
import {
  Container,
  PlayerDataContainer,
  AvatarCropperContainer,
  ButtonsContainer,
} from './styles';
import { UploadRequests } from '@/services/api/requests/Upload';

const { confirm } = Modal;

function UpdatePlayerAvatar({
  player,
  handleToogleEditPlayerAvatar,
  setPlayersWithControllerOptions,
}) {
  const { params } = useRouteMatch();

  const myTeam = useSelector((state) => state.team.data);

  const [newAvatar, setNewAvatar] = useState({
    file: null,
    previewUrl: '',
  });
  const [haveChanges, setHaveChanges] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = useCallback(async () => {
    const { file } = newAvatar;

    if (!file) {
      toast.warn(
        'Aconteceu um erro inesperado ao atualizar o avatar do usuário! Atualize a página'
      );
      return;
    }

    await new Promise((resolve) => {
      confirm({
        title: 'Deseja realmente atualizar o avatar desse jogador?',
        icon: <ExclamationCircleOutlined />,
        content: 'O avatar atual será substituído permanente',
        cancelText: 'Cancelar',
        okText: 'Atualizar',
        onOk() {
          resolve(true);
        },
      });
    });

    setSubmitting(true);

    let newPhotoId = null;

    try {
      const { data } = await UploadRequests.upload({
        file,
        from: 'teamPlayerAvatar',
      });

      newPhotoId = data._id;
    } catch (error) {
      console.log(error);

      setSubmitting(false);
      toast.error(
        'Aconteceu um erro inesperado ao atualizar o avatar do jogador!'
      );

      return;
    }

    const body = {
      player: {
        _id: player._id,
        photo: newPhotoId,
      },
      status: 'update',
    };

    try {
      const { id } = params;
      const teamId = myTeam?._id || id;

      await api.put(`/api/players/${teamId}`, body);

      toast.success('Avatar do jogador atualizado com sucesso');
      setPlayersWithControllerOptions((oldState) =>
        oldState.map((playerWithControllerOptions) => {
          if (playerWithControllerOptions._id === player._id) {
            return {
              ...playerWithControllerOptions,
              photo: newPhotoId,
            };
          }

          return playerWithControllerOptions;
        })
      );
      setSubmitting(false);
      handleToogleEditPlayerAvatar();
    } catch (error) {
      console.log(error);
      toast.error(
        'Aconteceu um erro inesperado ao atualizar o avatar do jogador!'
      );
      setSubmitting(false);
    }
  }, [
    handleToogleEditPlayerAvatar,
    myTeam,
    newAvatar,
    params,
    player._id,
    setPlayersWithControllerOptions,
  ]);

  return (
    <>
      <Header>
        <h5>Atualizar avatar do jogador</h5>
        <CustomAntButton
          type="text"
          danger
          onClick={() => {
            handleToogleEditPlayerAvatar();
          }}
        >
          Cancelar
        </CustomAntButton>
      </Header>
      <Container>
        <PlayerDataContainer>
          <li>
            <small>Nome</small>
            <p>{player.name}</p>
          </li>
          <li>
            <small>Número da camisa</small>
            <p>Camisa {player.number}</p>
          </li>
        </PlayerDataContainer>
        <AvatarCropperContainer>
          <AvatarCropper
            croppedAvatar={newAvatar}
            setCroppedAvatar={setNewAvatar}
            setHaveChanges={setHaveChanges}
            selectImageButtonLabel="Selecione uma imagem para adicionar como avatar do jogador"
          />
          {newAvatar?.previewUrl && (
            <ButtonsContainer>
              <CustomAntButton
                onClick={handleSubmit}
                disabled={submitting}
                type="primary"
              >
                Atualizar avatar
              </CustomAntButton>
            </ButtonsContainer>
          )}
        </AvatarCropperContainer>
      </Container>
    </>
  );
}

export default UpdatePlayerAvatar;
