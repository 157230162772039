import styled, { css } from 'styled-components';

interface IContainer {
  $status?: boolean;
}

export const Container = styled.div<IContainer>`
  border: 0;
  background: ${(props) => props.theme.colors.light.light0};
  width: 100%;
  padding: 16px 0;
  text-align: start;
  display: flex;
  flex-direction: column;

  & + div {
    border-top: 1px solid ${(props) => props.theme.colors.light['light-2']};
  }

  > div:nth-child(1) {
    display: flex;
    margin-bottom: 8px;

    ${(props) =>
      !props.$status &&
      css`
        opacity: 0.7;
      `}

    > div:nth-child(1) {
      width: 72px;
      height: 72px;
      border-radius: 8px;
      border: 1px solid ${(props) => props.theme.colors.dark['dark+4']};
      background: ${(props) => props.theme.colors.light['light-2']};
      transition: 0.2s opacity;
      margin-right: 16px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
      }
    }

    div:nth-child(2) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1;

      small {
        overflow: inherit;
        text-overflow: inherit;
        color: ${(props) => props.theme.colors.dark['dark+2']};
      }

      div {
        margin-top: 8px;
        display: flex;
        align-items: center;

        img {
          height: 18px;
          margin-right: 8px;
        }

        p {
          color: ${(props) => props.theme.colors.dark['dark+2']};
        }
      }
    }

    > h6 {
      flex-shrink: 0;
      color: ${(props) => props.theme.colors.primary.primary0};
    }
  }

  > button {
    margin-top: 1.5rem;
    align-self: flex-start;
  }
`;

export const QuantityOfPlayerGoalsSelectorContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1.5rem;

  h6 {
    color: ${(props) => props.theme.colors.dark.dark0};
    margin: 0 16px;
  }
`;

export const OptionsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;
  margin-top: 2rem;

  @media only screen and (max-width: ${(props) => props.theme.breakpoints.md}) {
    flex-direction: column;
  }
`;

export const OptionsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  > label {
    margin-left: 0 !important;
  }

  h6 {
    font-size: ${(props) => props.theme.typograph.sizes.h6}px;
    font-weight: ${(props) => props.theme.typograph.weights.bold};
    color: ${(props) => props.theme.colors.dark.dark0};
    margin: 0 0 0.5rem;
  }
`;

export const AthleteScore = styled.small`
  color: ${(props) => props.theme.colors.primary['primary-1']} !important;
`;
