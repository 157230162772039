import React, { useCallback } from 'react';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import {
  Formik,
  FastField,
  FormikProps,
  FormikHelpers,
  FastFieldProps,
} from 'formik';
import * as Yup from 'yup';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

import Input from '@/components/Input';
import api from '@/services/api';
import Select from '@/components/Select';
import {
  Container,
  TitlePageContainer,
  Content,
  ButtonsContainer,
} from './styles';
import { ITeam, TeamTypeEnum } from '../../types';
import { IEditTeamDataProps, IEditTeamFormData } from './types';

const EditTeamData: React.FC<IEditTeamDataProps> = ({ team, setTeam }) => {
  const history = useHistory();

  const handleSubmit = useCallback(
    async (
      values: IEditTeamFormData,
      actions: FormikHelpers<IEditTeamFormData>
    ): Promise<void> => {
      const body = {
        name: values.name,
        initials: values.initials?.toUpperCase(),
      };

      try {
        await api.put(`/api/team/${team._id}`, body);

        toast.success('Dados atualizados com sucesso!');
        setTeam({ ...team, ...(body as unknown as ITeam) });
        actions.setSubmitting(false);

        history.push(`/teams/${team._id}`);
      } catch (error) {
        toast.error('Aconteceu um erro inesperado, tente novamente!');
      }
    },
    [history, setTeam, team]
  );

  const checkIfSubmitButtonIsEnabled = useCallback(
    ({ touched, errors, values }: FormikProps<IEditTeamFormData>) => {
      if (
        _.isEqual(team, values) ||
        Object.entries(touched).length === 0 ||
        Object.entries(errors).length > 0
      ) {
        return false;
      }

      return true;
    },
    [team]
  );

  return (
    <Container>
      <TitlePageContainer>
        <h5>Editar</h5>
        <Button onClick={() => history.goBack()} danger type="text">
          Cancelar
        </Button>
      </TitlePageContainer>
      <Content>
        <h6>Dados do time</h6>
        <Formik
          initialValues={
            {
              name: team?.name || '',
              initials: team?.initials || '',
              type: team.teamType,
            } as IEditTeamFormData
          }
          validationSchema={Yup.object().shape({
            name: Yup.string().required('O nome do time é obrigatório'),
            initials: Yup.string().required(
              'As iniciais do time são obrigatórias'
            ),
          })}
          onSubmit={handleSubmit}
        >
          {(formikProps) => (
            <form onSubmit={formikProps.handleSubmit}>
              <FastField name="name">
                {({ field, meta }: FastFieldProps) => {
                  return (
                    <Input
                      {...field}
                      label="Nome"
                      placeholder="Digite o nome do time"
                      identifier="name"
                      type="text"
                      error={meta?.touched && meta?.error && meta?.error}
                    />
                  );
                }}
              </FastField>
              <FastField name="initials">
                {({ field, meta }: FastFieldProps) => {
                  return (
                    <Input
                      {...field}
                      label="Abreviatura"
                      placeholder="Digite as abreviatura do time"
                      identifier="initials"
                      type="text"
                      error={meta?.touched && meta?.error && meta?.error}
                      onChange={(e) => {
                        e.target.value = e.target.value.toUpperCase();
                        field.onChange(e);
                      }}
                    />
                  );
                }}
              </FastField>
              <FastField name="type">
                {({ field, meta }: FastFieldProps) => {
                  return (
                    <Select
                      {...field}
                      label="Tipo do time"
                      identifier="type"
                      placeholder="Selecione"
                      options={[
                        {
                          label: 'Profissional',
                          value: TeamTypeEnum.PROFESSIONAL,
                        },
                        { label: 'Amador', value: TeamTypeEnum.AMATEUR },
                        { label: 'Casa', value: TeamTypeEnum.HOUSE },
                      ]}
                      error={meta?.touched && meta?.error}
                      disabled
                    />
                  );
                }}
              </FastField>
              <ButtonsContainer>
                <Button
                  htmlType="submit"
                  disabled={
                    formikProps.isSubmitting ||
                    !checkIfSubmitButtonIsEnabled(formikProps)
                  }
                  type="primary"
                >
                  {!formikProps.isSubmitting ? 'Atualizar' : 'Carregando...'}
                </Button>
              </ButtonsContainer>
            </form>
          )}
        </Formik>
      </Content>
    </Container>
  );
};

export default EditTeamData;
