import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
  padding: 56px 32px;

  @media only screen and (max-width: 600px) {
    padding: 32px 16px;
    min-height: calc(100vh - 61px);
  }
`;

export const TitlePageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* margin-top: 32px; */
  margin-bottom: 32px;
  margin-top: 32px;
  align-items: center;

  @media only screen and (max-width: 768px) {
    margin-bottom: 16px;
  }

  /* AntD Button Changes */
  button {
    margin-left: 16px;
  }
`;

export const Content = styled.div`
  padding: 0 15%;
  display: flex;
  flex-direction: column;

  form {
    align-self: center;
    display: flex;
    flex-direction: column;
    width: 80%;
    min-width: 728px;
    margin-top: 40px;
  }

  @media only screen and (max-width: 768px) {
    padding: 0;

    form {
      width: 100%;
      min-width: 100%;
      margin-top: 16px;
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: space-between;
  margin-top: 24px;

  button.ant-btn-primary {
    flex: 1;
  }

  button.ant-btn-default {
    margin-right: 16px;
  }
`;

export const LoadingDescription = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  margin: 2rem auto 0;
  width: fit-content;
`;
