import React, { useCallback, useEffect, useState } from 'react';

import CustomAntButton from '@/components/CustomAntButton';
import { useParams, useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import api from '@/services/api';
import { Spin } from 'antd';
import { Container, LoadingDescription } from './styles';
import { IRouteParams } from '../types';
import AdCard from './AdCard';
import { ICardGameAd } from '../../types';

const Ads: React.FC = () => {
  const { url } = useRouteMatch();
  const params = useParams<IRouteParams>();

  const [ads, setAds] = useState<ICardGameAd[]>([]);
  const [isLoadingAds, setIsLoadingCards] = useState(true);

  const getAds = useCallback(async () => {
    setIsLoadingCards(true);
    try {
      const { data } = await api.get<{
        docs: ICardGameAd[];
        page: number;
        pages: number;
        total: number;
      }>(`/api/athlete-card/${params.id}/ad`, {
        params: {
          limit: 50,
        },
      });

      setAds(data.docs);
      setIsLoadingCards(false);
    } catch (error) {
      setIsLoadingCards(false);
    }
    return '';
  }, [params.id]);

  useEffect(() => {
    getAds();
  }, [getAds]);

  if (isLoadingAds) {
    return (
      <Container>
        <Link to={`${url}/new_ads`}>
          <CustomAntButton type="primary">Novo anúncio</CustomAntButton>
        </Link>
        <LoadingDescription>
          <Spin />
          <p>Carregando...</p>
        </LoadingDescription>
      </Container>
    );
  }

  return (
    <Container>
      <Link to={`${url}/new_ads`}>
        <CustomAntButton type="primary">Novo anúncio</CustomAntButton>
      </Link>
      <ul>
        {ads.map((ad) => (
          <AdCard key={ad._id} ad={ad} setAds={setAds} />
        ))}
      </ul>
    </Container>
  );
};

export default Ads;
