import React from 'react';
import { Button } from 'antd';
import { FastField } from 'formik';

import Switch from '@/components/Switch';

import { ButtonsContainer } from '../styles';

function PersonalData({ formik, nextStep, prevStep }) {
  function eneableNextStepButton({ values }) {
    const { permissions } = values;
    if (Object.values(permissions).every(element => element === false)) {
      return false;
    }

    return true;
  }
  return (
    <>
      <FastField name="permissions.manager_user">
        {({ field, form }) => {
          return (
            <Switch
              label="Gestão de usuários"
              checked={field.value}
              onChange={checked => form.setFieldValue(field.name, checked)}
              size="small"
            />
          );
        }}
      </FastField>
      <FastField name="permissions.manager_admin">
        {({ field, form }) => {
          return (
            <Switch
              label="Gestão de administradores"
              checked={field.value}
              onChange={checked => form.setFieldValue(field.name, checked)}
              size="small"
            />
          );
        }}
      </FastField>
      <FastField name="permissions.manager_sponsor">
        {({ field, form }) => {
          return (
            <Switch
              label="Gestão de patrocinadores"
              checked={field.value}
              onChange={checked => form.setFieldValue(field.name, checked)}
              size="small"
            />
          );
        }}
      </FastField>
      <FastField name="permissions.manager_team">
        {({ field, form }) => {
          return (
            <Switch
              label="Gestão de times"
              checked={field.value}
              onChange={checked => form.setFieldValue(field.name, checked)}
              size="small"
            />
          );
        }}
      </FastField>
      <ButtonsContainer>
        <Button onClick={prevStep} type="default">
          Voltar
        </Button>
        <Button
          onClick={nextStep}
          type="primary"
          disabled={!eneableNextStepButton(formik)}
        >
          Próximo
        </Button>
      </ButtonsContainer>
    </>
  );
}

export default PersonalData;
