import * as Yup from 'yup';
import { IGetNewCardValidationSchemaParams, INewCardFormData } from './types';

export function getNewCardValidationSchema(
  params: IGetNewCardValidationSchemaParams
): Yup.ObjectSchema {
  const { formikRef, cardPaymentPercentage } = params;

  return Yup.object().shape({
    cardInformation: Yup.object().shape({
      type: Yup.string().required('O tipo da cartela é obrigatório'),
      name: Yup.string().required('O nome da cartela é obrigatório'),
      price: Yup.number().required('O valor do jogo na cartela é obrigatório'),
      startDate: Yup.mixed().required(
        'A data inicial da cartela é obrigatória'
      ),
      endDate: Yup.mixed()
        .required('A data final da cartela é obrigatória')
        .test(
          'dateInvalid',
          'A data e hora final deve ser maior que a inicial',
          function validate(value) {
            const formikProps = formikRef.current;
            const startDate = formikProps?.values.cardInformation?.startDate;

            if (!value) return false;

            return (value as moment.Moment).isAfter(startDate);
          }
        ),
      hasFixedPrize: Yup.boolean().required(),
      fixedPrize: Yup.number().when('hasFixedPrize', {
        is: true,
        then: (schema: Yup.NumberSchema) =>
          schema.required('O valor de premiação fixa é obrigatório'),
      }),
      percentages: Yup.object().shape({
        homePercent: Yup.number()
          .max(100, 'O valor máximo é 100')
          .test(
            'homePercentInvalid',
            `O percentual deve ser maior ou igual ${cardPaymentPercentage.homeMin}%`,
            function validate(value) {
              const { homeMin } = cardPaymentPercentage;

              if (!value) return false;

              return value >= homeMin;
            }
          )
          .required('A porcentagem da casa é obrigatória'),
        influencerPercent: Yup.number()
          .min(0, 'O valor mínimo é 0')
          .max(100, 'O valor máximo é 100')
          .required('A porcentagem do influenciador é obrigatória'),
        winnerPercent: Yup.number()
          .max(100, 'O valor máximo é 100')
          .required('A porcentagem do vencedor é obrigatória')
          .when(
            '$cardInformation',
            (
              cardInformation: INewCardFormData['cardInformation'],
              schema: Yup.NumberSchema
            ) => {
              const { hasFixedPrize } = cardInformation;
              return hasFixedPrize === false
                ? schema.min(1, 'O valor mínimo é 1')
                : schema;
            }
          ),
      }),
    }),
    athletes: Yup.array()
      .of(
        Yup.object().shape({
          _id: Yup.string().required('O identificador do jogo é obrigatório'),
        })
      )
      .min(1, 'Ao menos 1 jogo deve ser adicionado na cartela'),
  });
}
