import React from 'react';
import { Button } from 'antd';

import { ButtonsContainer } from '../styles';
import { SessionContainer, SessionItems } from './styles';
import { IConfirmProps } from './types';
import { TeamTypeEnum } from '../../types';

const Confirm: React.FC<IConfirmProps> = ({ formik, prevStep, onSubmit }) => {
  const { teamData, image } = formik.values;

  function getTeamType(): string {
    if (teamData.type === TeamTypeEnum.PROFESSIONAL) {
      return 'Profissional';
    }
    if (teamData.type === TeamTypeEnum.AMATEUR) {
      return 'Amador';
    }
    return 'Casa';
  }

  return (
    <>
      <SessionContainer>
        <h6>Dados do time</h6>
        <SessionItems>
          <li>
            <small>Nome</small>
            <p>{teamData.name}</p>
          </li>
          <li>
            <small>Abreviatura</small>
            <p>{teamData.initials}</p>
          </li>
          <li>
            <small>Tipo</small>
            <p>{getTeamType()}</p>
          </li>
        </SessionItems>
      </SessionContainer>
      <SessionContainer>
        <h6>Imagem</h6>
        {image.previewUrl ? (
          <img src={image.previewUrl} alt="team" />
        ) : (
          <p>Não adicionado</p>
        )}
      </SessionContainer>
      <ButtonsContainer>
        <Button onClick={prevStep} type="default">
          Voltar
        </Button>
        <Button
          onClick={onSubmit}
          disabled={formik.isSubmitting}
          type="primary"
        >
          {!formik.isSubmitting ? 'Finalizar' : 'Carregando...'}
        </Button>
      </ButtonsContainer>
    </>
  );
};

export default Confirm;
