import styled from 'styled-components';

export const Container = styled.li`
  width: 100%;
  background-color: ${(props) => props.theme.colors.light.light0};
  border-radius: 4px;
  padding: 1rem;
  border: 1px solid ${(props) => props.theme.colors.light['light-2']};
  box-shadow: 0px 1px 4px #00000020;
  cursor: grab;

  & + & {
    margin-top: 0.5rem;
  }

  > div:last-child {
    width: 100%;
    max-width: 12rem;
  }
`;

export const TeamPlayer = styled.div`
  border: 0;
  width: 100%;
  display: flex;
  text-align: start;
  margin-bottom: 1rem;

  > div:nth-child(1) {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    border: 1px solid ${(props) => props.theme.colors.dark['dark+4']};
    background: ${(props) => props.theme.colors.light['light-2']};
    transition: 0.2s opacity;
    margin-right: 1rem;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  div:nth-child(2) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    small {
      overflow: inherit;
      text-overflow: inherit;
      color: ${(props) => props.theme.colors.dark['dark+2']};
    }

    div {
      margin-top: 0.5rem;
      display: flex;
      align-items: center;

      img {
        height: 1.125rem;
        margin-right: 0.5rem;
      }

      p {
        color: ${(props) => props.theme.colors.dark['dark+2']};
      }
    }
  }
`;
