import React from 'react';
import { Button } from 'antd';
import { IoMdMenu, IoMdClose } from 'react-icons/io';

import logo from '@/assets/logo.svg';
import { Container } from './styles';

export default function MobileHeader({
  mobileSidebarOpened,
  handleToogleSidebarState,
}) {
  return (
    <Container>
      <Button onClick={handleToogleSidebarState} type="text">
        {mobileSidebarOpened ? (
          <IoMdClose size="28" color="#454545" />
        ) : (
          <IoMdMenu size="28" color="#454545" />
        )}
      </Button>
      <img src={logo} alt="Lance Futebol" />
      <div />
    </Container>
  );
}
