import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { Button, Input, Spin, Pagination } from 'antd';
import { FieldArray } from 'formik';
import { LoadingOutlined } from '@ant-design/icons';
import { FiXCircle, FiEdit2 } from 'react-icons/fi';
import * as _ from 'lodash';
import { toast } from 'react-toastify';

import { UploadRequests } from '@/services/api/requests/Upload';
import api from '@/services/api';

import DefaultAvatar from '@/assets/DefaultAvatar.svg';

import {
  Content,
  SearchContainer,
  NamePageForIndicator,
  TeamsListContainer,
  TeamsList,
  TeamDetailContainer,
  TeamSelected,
  TeamPlayer,
  SelectedAthletesContainer,
  SelectedAthlete,
} from './styles';
import { ButtonsContainer } from '../styles';
import {
  getAthleteNumberDescription,
  getPlayerPositionLabel,
} from '../../utils';

const { Search } = Input;
const loadingIcon = <LoadingOutlined style={{ fontSize: 20 }} spin />;

function Matchs({ formik, nextStep, prevStep }) {
  function eneableNextStepButton({ errors }) {
    if (!errors.athletes) {
      return true;
    }

    return false;
  }
  function athleteIsOnSelectAthletes(_athleteId, selectedAthletes) {
    return !!selectedAthletes.find(
      (selectedAthlete) => selectedAthlete._id === _athleteId
    );
  }

  const [searchInputValue, setSearchInputValue] = useState('');

  const [teams, setTeams] = useState([]);
  const [loadingTeams, setLoadingTeams] = useState(false);
  const [teamsPagination, setTeamsPagination] = useState({
    currentPage: 1,
    totalPages: 0,
    limit: 10,
  });

  const [selectedTeam, setSelectedTeam] = useState(null);
  const [loadingSelectedTeamData, setLoadingSelectedTeamData] = useState(false);

  async function handleGetTeams(searchTerm, page = 1) {
    setLoadingTeams(true);

    try {
      const { data } = await api.get('/api/team', {
        params: {
          // TODO: Add filters
          teamType: 'all', // 'all', 'professional', 'amateur', and 'house'
          search: searchTerm,
          page,
        },
      });

      setTeams(data.docs);

      setLoadingTeams(false);
    } catch (error) {
      setLoadingTeams(false);
    }
  }

  const handleGetSelectedTeamData = useCallback(async (_teamId) => {
    setLoadingSelectedTeamData(true);

    try {
      const { data } = await api.get(`/api/team/${_teamId}`);

      setSelectedTeam((oldState) => ({
        ...oldState,
        ...data,
      }));

      setLoadingSelectedTeamData(false);
    } catch (error) {
      setLoadingSelectedTeamData(false);
      toast.error(
        'Aconteceu um erro ao carregar os detalhes do time selecionado. Recarregue a página e tente novamente!'
      );
    }
  }, []);

  const teamsSearchDebounced = useMemo(() => {
    return _.debounce(handleGetTeams, 500);
  }, []);

  useEffect(() => {
    teamsSearchDebounced.cancel();

    if (searchInputValue.length > 3) {
      setLoadingTeams(true);
      teamsSearchDebounced(searchInputValue);
    } else {
      setLoadingTeams(false);
    }

    if (!searchInputValue) {
      setTeams([]);
      setTeamsPagination((oldState) => ({
        ...oldState,
        currentPage: 1,
        totalPages: 0,
      }));
    }
  }, [searchInputValue, teamsSearchDebounced]);

  const handleSelectTeam = useCallback(
    (team) => {
      setSelectedTeam(team);
      handleGetSelectedTeamData(team._id);
    },
    [handleGetSelectedTeamData]
  );

  const teamsListAndSearchContent = useMemo(() => {
    if (loadingTeams) {
      return <Spin indicator={loadingIcon} />;
    }

    if (!searchInputValue) {
      return (
        <h6>
          Pesquise por <NamePageForIndicator>times</NamePageForIndicator>{' '}
          cadastrados no Lance Futebol
        </h6>
      );
    }
    if (searchInputValue.length <= 3) {
      return <h6>Digite acima de 3 caracteres para realizar a busca</h6>;
    }

    if (teams.length > 0) {
      return (
        <>
          <TeamsList>
            {teams.map((team) => (
              <TeamDetailContainer
                onClick={() => handleSelectTeam(team)}
                key={team?.name}
              >
                <div>
                  <img src={team?.image} alt={team?.name || 'Avatar'} />
                </div>
                <h6>{team?.name}</h6>
              </TeamDetailContainer>
            ))}
          </TeamsList>
          <Pagination
            current={teamsPagination.currentPage}
            onChange={(page) => {
              setTeamsPagination({ ...teamsPagination, currentPage: page });
              handleGetTeams(searchInputValue, page);
            }}
            total={teamsPagination.totalPages}
            pageSize={teamsPagination.limit}
          />
        </>
      );
    }

    if (teams.length === 0) {
      return <h6>Nenhum time encontrado</h6>;
    }

    return '';
  }, [
    handleSelectTeam,
    loadingTeams,
    searchInputValue,
    teams,
    teamsPagination,
  ]);

  const selectedTeamContent = useCallback(
    (push) => {
      if (loadingSelectedTeamData) {
        return <Spin indicator={loadingIcon} />;
      }

      if (selectedTeam && !!selectedTeam.players) {
        return (
          <>
            <TeamSelected onClick={() => setSelectedTeam(null)}>
              <img src={selectedTeam.image} alt={selectedTeam.name} />
              <h6>{selectedTeam.name}</h6>
              <FiEdit2 size={20} />
            </TeamSelected>
            <ul>
              {selectedTeam.players.map((player) => (
                <TeamPlayer
                  key={player._id}
                  type="button"
                  disabled={athleteIsOnSelectAthletes(
                    player._id,
                    formik.values.athletes
                  )}
                  // onClick={() => formik.setFieldValue('match', match)}
                  onClick={() => {
                    if (
                      !athleteIsOnSelectAthletes(
                        player._id,
                        formik.values.athletes
                      )
                    ) {
                      push({
                        ...player,
                        classicValue: 10,
                        team: {
                          _id: selectedTeam._id,
                          name: selectedTeam.name,
                          image: selectedTeam.image,
                        },
                      });
                    }
                  }}
                >
                  <div>
                    <img
                      src={
                        player?.photo
                          ? UploadRequests.getFileUrl(player.photo)
                          : player?.photoUri || DefaultAvatar
                      }
                      alt={player.name}
                      onError={(e) => {
                        const target = e.target;
                        target.onerror = null;
                        target.src = DefaultAvatar;
                      }}
                    />
                  </div>
                  <div>
                    <strong>{player.name}</strong>
                    <small>
                      {getAthleteNumberDescription(player.number)}
                      {getPlayerPositionLabel(player.position)}
                    </small>
                  </div>
                </TeamPlayer>
              ))}
            </ul>
          </>
        );
      }

      return '';
    },
    [formik.values.athletes, loadingSelectedTeamData, selectedTeam]
  );

  return (
    <>
      <FieldArray
        name="athletes"
        render={({ push, remove }) => (
          <Content>
            {formik.values.athletes.length > 0 && (
              <>
                <small>Jogadores selecionados</small>
                <SelectedAthletesContainer>
                  {formik.values.athletes.map((selectedAthlete, index) => (
                    <SelectedAthlete
                      type="button"
                      onClick={() => {
                        remove(index);
                      }}
                      key={selectedAthlete._id}
                    >
                      <FiXCircle size={16} />
                      <img
                        src={
                          selectedAthlete?.photo
                            ? UploadRequests.getFileUrl(selectedAthlete.photo)
                            : DefaultAvatar
                        }
                        alt={selectedAthlete.name}
                      />
                      <p>{selectedAthlete.name}</p>
                    </SelectedAthlete>
                  ))}
                </SelectedAthletesContainer>
              </>
            )}
            {!selectedTeam ? (
              <>
                <SearchContainer>
                  <Search
                    onChange={(e) => setSearchInputValue(e.target.value)}
                    value={searchInputValue}
                    placeholder="Pesquisar time"
                  />
                </SearchContainer>
                <TeamsListContainer>
                  {teamsListAndSearchContent}
                </TeamsListContainer>
              </>
            ) : (
              selectedTeamContent(push)
            )}
          </Content>
        )}
      />
      <ButtonsContainer>
        <Button onClick={prevStep} type="default">
          Voltar
        </Button>
        <Button
          onClick={nextStep}
          type="primary"
          disabled={!eneableNextStepButton(formik)}
        >
          Próximo
        </Button>
      </ButtonsContainer>
    </>
  );
}

export default Matchs;
