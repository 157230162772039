import styled from 'styled-components';

export const RelayDetails = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;

  > small {
    width: 100%;
    margin: 0 0 0.5rem;
    color: ${(props) => props.theme.colors.dark['dark+3']};
    grid-column: 1 / 3;
  }

  > div {
    width: 100%;
    line-height: 1.6;

    small {
      color: ${(props) => props.theme.colors.primary.primary0};
    }

    p {
      margin: 0;
      color: ${(props) => props.theme.colors.dark.dark0};
    }
  }

  & + & {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid ${(props) => props.theme.colors.light['light-1']};
  }
`;
